import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Label } from 'components/common/text/index.style'
import RadioCheckbox from 'components/common/customRadioCheckbox'

import {
  FilterRadioItem,
  FilterRadioItems,
  FilterRadioListWrapper,
} from './index.style'

const CustomRadioList = memo(
  ({ state, config, handleSelect, disabled, id }) => {
    const { title, options, key } = config || {}
    const { t } = useTranslation()

    return (
      <FilterRadioListWrapper>
        <Label>{t(title)}</Label>
        <FilterRadioItems>
          {options?.map(({ value, name }, idx) => {
            return (
              <FilterRadioItem
                key={idx}
                onClick={() => handleSelect && handleSelect(value)}
                disabled={disabled}
              >
                <RadioCheckbox
                  radio
                  id={`${id}-${idx}`}
                  value={value === state[key] || value === state}
                  handler={() => {}}
                  disabled={disabled}
                  label={t(name)}
                />
              </FilterRadioItem>
            )
          })}
        </FilterRadioItems>
      </FilterRadioListWrapper>
    )
  }
)

CustomRadioList.displayName = 'CustomRadioList'
export default CustomRadioList
