import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import { memo, useState } from 'react'
import { useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import {
  Backdrop,
  Content,
  Footer,
  FooterLinks,
  Header,
  Loader,
  Title,
} from './index.style'
import { Caption } from 'components/common/text/index.style'
import { NETWORKS_CONFIG } from 'components/SocialNetworksFooter/config/config'
import { NetworkIcon } from 'components/SocialNetworksFooter/index.style'
import Icon from 'components/Icon'
import MainSection from './MainSection'
import NotificationSettingsSection from './NotificationSettingsSection'
import LoginDetailsSection from './LoginDetailsSection'
import UserAccountDialog from './UserAccountDialog'
import PersonalDataSection from './PersonalDataSection'
import ProductKeySection from './ProductKeySection'
import { toggleUserProfile } from 'store/slices/authSlice'

const UserAccount = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()
  const lang = i18n?.language

  const [section, setSection] = useState('personal_account')
  const [dialogConfig, setDialogConfig] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => dispatch(toggleUserProfile(false))

  const openDialog = (conf) => setDialogConfig(conf)

  const sectionsConfig = {
    personal_account: <MainSection setSection={setSection} />,
    notification_settings: <NotificationSettingsSection />,
    login_details: <LoginDetailsSection openDialog={openDialog} />,
    personal_data: <PersonalDataSection openDialog={openDialog} />,
    product_key: (
      <ProductKeySection openDialog={openDialog} setIsLoading={setIsLoading} />
    ),
  }

  return (
    <>
      <CustomDialog
        onClose={handleClose}
        width={'31rem'}
        height={'38rem'}
        padding={'0'}
        gap={'0'}
        outboundClickEnabled={false}
      >
        <Header>
          {section !== 'personal_account' && (
            <CustomButton
              type={'text_accent'}
              gap={'0.25rem'}
              onClick={() => setSection('personal_account')}
            >
              <Icon size={'1rem'} iconType={'arrowLeft'} />
              {t('user_account.back')}
            </CustomButton>
          )}
          <Title textAlign={'center'} fontWeight={theme.fontWeight.semibold}>
            {t(`user_account.${section}`)}
          </Title>
        </Header>
        <Content>
          {sectionsConfig[section]}
          {isLoading && (
            <Loader>
              <Icon
                iconType={'spinner2'}
                className={'spinner-icon'}
                size={'10rem'}
              />
            </Loader>
          )}
        </Content>
        <Footer>
          <Caption
            color={theme.colors.text.tertiary}
            fontWeight={theme.fontWeight.medium}
            lineHeight={theme.text[14]}
          >
            {`© SYNTELLY 2018-${new Date().getFullYear()}`}
          </Caption>
          <FooterLinks>
            {NETWORKS_CONFIG.map(
              ({ icon, link }) =>
                !(lang !== 'ru' && (icon === 'telegram' || icon === 'vk')) && (
                  <a
                    key={icon}
                    href={link}
                    target={'_blank'}
                    rel="noreferrer"
                    alt={icon}
                  >
                    <NetworkIcon iconType={`${icon}Logo`} />
                  </a>
                )
            )}
          </FooterLinks>
        </Footer>
        {!!dialogConfig && <Backdrop />}
      </CustomDialog>
      {!!dialogConfig && (
        <UserAccountDialog
          onClose={() => setDialogConfig(null)}
          {...(dialogConfig ? dialogConfig : {})}
        />
      )}
    </>
  )
}

UserAccount.displayName = 'UserAccount'

export default UserAccount
