import { ChangeEvent, FC, useRef, useState } from 'react'

import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'

import {
  TextArea,
  CustomTextAreaWrapper,
  CustomTextAreaClearButton,
} from './index.style'
import { CustomTextAreaProps } from './index.types'

const CustomTextArea: FC<CustomTextAreaProps> = ({
  onChange,
  value = '',
  placeholder = '',
  disabled = false,
  withClearButton = false,
  initHeight = '',
  className,
}) => {
  const textarea = useRef<HTMLTextAreaElement>(null)
  const initContentHeight = initHeight || '7rem'
  const [contentHeight, setContentHeight] = useState<string>(initContentHeight)

  const updateContentHeight = (reset: boolean) => {
    if (textarea.current) {
      const newHeight = textarea.current?.scrollHeight

      return setContentHeight(
        reset
          ? initContentHeight
          : `${
              newHeight /
              parseFloat(getComputedStyle(document.documentElement).fontSize)
            }rem`
      )
    }
  }

  const showClearButton = withClearButton && value.length > 0

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    onChange(e.target.value)

  const clear = () => {
    onChange('')
    updateContentHeight(true)
  }

  return (
    <CustomTextAreaWrapper height={initContentHeight} className={className}>
      {/* @ts-ignore */}
      <CustomScrollbar
        noScrollY={!value}
        disableTrackYWidthCompensation={true}
        className="margin--0-right"
      >
        <TextArea
          ref={textarea}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            handleChange(e)
            updateContentHeight(e.target.value === '')
          }}
          placeholder={placeholder}
          showClearButton={showClearButton}
          height={contentHeight}
        />
      </CustomScrollbar>
      {showClearButton && (
        <CustomTextAreaClearButton onClick={clear} id="clear">
          <Icon iconType="close" size="1rem" />
        </CustomTextAreaClearButton>
      )}
    </CustomTextAreaWrapper>
  )
}

export default CustomTextArea
