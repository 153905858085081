import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { TooltipsProps } from './index.types'
import { PasswordTooltip } from './passwordTooltip'
import { memo } from 'react'

const Tooltips: FC<TooltipsProps> = memo(
  ({
    id,
    type,
    email = '',
    value = '',
    error,
    warning,
    labelDesc,
    showTooltip,
    tooltipPlace,
    tooltipOffset,
    withPasswordTooltip,
    errorTranslationParams,
  }) => {
    const { t } = useTranslation()

    // обычная подсказка рядом с названием
    if (labelDesc && !(warning || error))
      return showTooltip ? null : (
        <ReactTooltip
          id={`${type}-${id}-info`}
          className="c-tooltip c-tooltip-base"
          classNameArrow="c-tooltip-arrow"
          place={tooltipPlace}
          offset={tooltipOffset}
        >
          {labelDesc}
        </ReactTooltip>
      )

    if (!showTooltip) return null

    if (withPasswordTooltip && showTooltip)
      return (
        <ReactTooltip
          id="password-req-tooltip"
          className="c-tooltip c-tooltip-base c-tooltip-password-check"
          place={tooltipPlace}
          isOpen={showTooltip}
          offset={tooltipOffset}
        >
          <PasswordTooltip password={String(value)} email={email} />
        </ReactTooltip>
      )

    return (
      <ReactTooltip
        id={warning ? `${type}-${id}-warning` : `${type}-${id}-error`}
        className="c-tooltip c-tooltip-base"
        classNameArrow="c-tooltip-arrow"
        place={tooltipPlace}
        offset={tooltipOffset}
        isOpen={showTooltip}
      >
        {t(`${warning || error}`, errorTranslationParams)}
      </ReactTooltip>
    )
  }
)

Tooltips.displayName = 'Tooltips'
export default Tooltips
