import React, { FC, memo } from 'react'

import '../../../styles/toggle.css'
import '../../../styles/pdf2smiles.css'

import { RadioCheckboxProps } from './index.types'

import { RadioCheckboxContainer, RadioCheckboxLabel } from './index.style'

const RadioCheckbox: FC<RadioCheckboxProps> = memo(
  ({
    id,
    value,
    handler,
    label,
    disabled,
    checkbox,
    radio,
    className,
    shouldPropagate,
    type = 'grey',
    withCustomLink,
    withCustomLabel,
    children,
    labelPosition = 'right',
    withWrap,
    size = 'standard',
  }) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (disabled || !handler || e?.target instanceof HTMLAnchorElement) {
        e.stopPropagation()
        return
      }

      handler()
    }

    return (
      <RadioCheckboxContainer
        {...{ withCustomLink, withCustomLabel, labelPosition, disabled }}
        onClick={handleClick}
        checked={value}
      >
        <div
          className={`toggle toggle_is-disabled-${!!disabled} toggle_is-checkbox-${!!checkbox} toggle_is-small-${
            size === 'small'
          } toggle_is-radio-${!!radio} ${className}`}
        >
          <input
            type="checkbox"
            className="toggle-input"
            id={id}
            checked={value}
            onChange={() => {}}
          />
          <label
            htmlFor={id}
            className={`toggle-label ${
              type === 'white' ? 'toggle-label-white' : ''
            } toggle-label_is-checkbox-${!!checkbox}`}
            title={label || ''}
            onClick={(ev) => {
              if (!shouldPropagate) ev.stopPropagation()
            }}
          >
            <small className="toggle-label-slider" />
          </label>
        </div>
        {withCustomLabel ? (
          children
        ) : (
          <RadioCheckboxLabel
            className="radio-checkbox-label"
            {...{ value, disabled, withWrap }}
            ellipsis
          >
            {label}
          </RadioCheckboxLabel>
        )}
      </RadioCheckboxContainer>
    )
  }
)

RadioCheckbox.displayName = 'RadioCheckbox'

export default RadioCheckbox
