import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'

import {
  SubMenuActionsIds,
  TransferToActionId,
  CONTEXT_MENU_ITEM_HEIGHT,
  CONTEXT_MENU_OFFSET_RIGHT,
} from './config'
import './index.css'
import { ContextMenuItemProps } from './index.types'
import { EMPTY_HANDLER } from 'config/constants'

const ContextMenuItem: FC<ContextMenuItemProps> = React.memo(
  ({
    children,
    titleKey,
    className,
    icon,
    onClick,
    subActions,
    subActionsCount,
    id,
    subActionsType,
    text,
    hotkey,
    disabled = false,
    iconPosition = 'right',
    action,
    showSubActions,
    setShowSubActions = EMPTY_HANDLER,
    structuresComparisonCount = 0,
  }) => {
    const { t } = useTranslation()

    const [isNearBottom, setNearBottom] = useState<boolean>(false)
    const [isNearRight, setNearRight] = useState<boolean>(false)

    const isDivider = action && action === 'divider'

    const onMouseEnter = useCallback(
      (ev: React.MouseEvent<HTMLDivElement>) => {
        if (!subActionsCount) {
          showSubActions && setShowSubActions(false)
          return
        }
        setShowSubActions(true)
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { bottom, right } = ev?.currentTarget?.getBoundingClientRect()
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        const subActionsLength = subActions?.props?.children?.length

        setNearRight(windowWidth - right < CONTEXT_MENU_OFFSET_RIGHT)
        setNearBottom(
          windowHeight - bottom <=
            CONTEXT_MENU_ITEM_HEIGHT * subActionsLength +
              16 +
              10 +
              (subActionsLength > 1 ? 8 * subActionsLength - 1 : 0)
        )
      },
      [
        setShowSubActions,
        showSubActions,
        subActions?.props?.children?.length,
        subActionsCount,
      ]
    )

    const onMouseLeave = useCallback(
      (ev: React.MouseEvent<HTMLDivElement>) => {
        const targetId = (ev?.target as HTMLElement)?.id

        if (
          !(
            SubMenuActionsIds.some((el) => `context-menu-${el}` === targetId) ||
            `context-menu-${TransferToActionId}` === targetId
          )
        ) {
          setShowSubActions(false)
        }
      },
      [setShowSubActions]
    )

    const onClickHandler = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) =>
        subActionsCount ? onMouseEnter(e) : onClick(e),
      [onClick, onMouseEnter, subActionsCount]
    )

    return isDivider ? (
      <div className="context-menu-item-divider" />
    ) : (
      <div
        className={`context-menu-item ${className || ''}${
          // eslint-disable-next-line no-extra-boolean-cast
          !!subActions ? ' context-menu-item_with-inner' : ''
        }${isNearBottom ? ' context-menu-item_near-bottom' : ''}${
          isNearRight ? ' context-menu-item_near-right' : ''
        }${disabled ? ' context-menu-item-disabled' : ''}`}
        id={`context-menu-${id}`}
        onClick={onClickHandler}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {icon && iconPosition === 'left' && <Icon iconType={icon} />}
        {(children || titleKey || text) && (
          <div className="context-menu-item-content" title={t(`${titleKey}`)}>
            {action === 'switch_en' || action === 'switch_ru'
              ? titleKey
              : children || t(titleKey || text)}
            {action === 'comparison_list' && structuresComparisonCount > 0 && (
              <span className="context-menu-item-content-count">
                {structuresComparisonCount}
              </span>
            )}
          </div>
        )}

        {icon && iconPosition === 'right' && (
          <Icon iconType={icon} size="1.25rem" />
        )}
        {subActions && (
          <Icon iconType="arrowRight" className="arrow-icon" size="1rem" />
        )}
        {hotkey && (
          <span className="hotkey" style={{ marginLeft: 'auto' }}>
            {hotkey}
          </span>
        )}
        {!!subActions &&
          showSubActions &&
          (subActionsType === 'row' ? (
            <div className="context-menu context-menu_inner context-menu_inner-row">
              {subActions}
            </div>
          ) : (
            <div className="context-menu context-menu_inner">{subActions}</div>
          ))}
      </div>
    )
  }
)

ContextMenuItem.displayName = 'ContextMenuItem'

export { ContextMenuItem }
