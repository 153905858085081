import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { Label, TextMedium } from 'components/common/text/index.style'

import { validate } from '../utils'
import { RequirementsBlock, ReqWithIcon, TooltipContainer } from './index.style'

interface PasswordTooltipProps {
  password: string
  email: string
}

const PasswordTooltip = React.memo(
  ({ password, email }: PasswordTooltipProps) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const passwordParams = validate(password, email)
    return (
      <TooltipContainer>
        <Label>{t('registration.password_security_requirements')}</Label>
        <RequirementsBlock>
          {passwordParams.map((param, ind) => {
            return (
              <ReqWithIcon isValid={param.isValid} key={ind}>
                <Icon
                  // @ts-ignore
                  iconType={param.isValid ? 'tick' : 'description'}
                  size="1rem"
                />
                <TextMedium
                  color={
                    param.isValid
                      ? theme.colors.text.primary
                      : theme.colors.statuses.error
                  }
                >
                  {param.text}
                </TextMedium>
              </ReqWithIcon>
            )
          })}
        </RequirementsBlock>
      </TooltipContainer>
    )
  }
)

PasswordTooltip.displayName = 'PasswordTooltip'

export { PasswordTooltip }
