import styled, { keyframes } from 'styled-components'

import { SkeletonProps } from './index.types'
import { ElementWithChildren } from 'types/common'

interface SkeletonWrapperProps extends SkeletonProps, ElementWithChildren {}

const skeletonAnimation = keyframes`
   from {
    left: -200%;
  }
  to {
    left: 200%;
  }
`

export const SkeletonWrapper = styled.div<SkeletonWrapperProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ minWidth }) => minWidth || '0'};
  background: ${(props) =>
    props.variant === 'light'
      ? `${props.theme.colors.backgrounds.skeletonLight}`
      : `${props.theme.colors.backgrounds.skeletonDark}`};

  border-radius: ${({ borderRadius }) => borderRadius || '0.75rem'};
  position: relative;
  overflow: hidden;
  margin: ${({ margin }) => margin && margin};

  &::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 0;
    width: ${({ width }) => `${width || '0'}`};
    height: ${({ height }) => height};

    background: linear-gradient(
      90deg,
      rgba(218, 224, 234, 0),
      rgba(218, 224, 234, 0.32),
      rgba(218, 224, 234, 0)
    );
    animation: ${skeletonAnimation} 1.5s infinite linear;
  }
`
