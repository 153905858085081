import {
  FC,
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'utils/auth/auth'
import { useAppSelector } from 'hooks/useRedux'
import { hideContextMenu } from 'store/slices/contextMenuSlice'

import './index.css'
import {
  ContextMenuConfig,
  CONTEXT_MENU_ITEM_HEIGHT,
  CONTEXT_MENU_OFFSET_RIGHT,
} from './config'
import { getSubActionsList, renderActionItem } from './utils'
import { OutboundClick } from '../outboundClick/outboundClick'
import {
  ContextMenuAction,
  ContextMenuItems,
  ContextMenuStateItem,
} from './index.types'
import { useContextMenuAction } from '../../../hooks/useContextMenuActions'

const ContextMenu: FC = memo(() => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  const language = i18n.language
  const { isAuthenticated } = useAuth() as any
  const contextMenuRef = useRef<HTMLDivElement>(null)

  const contextMenuStore = useAppSelector(
    (state) => state.contextMenu.contextMenuState
  )
  const structuresComparisonList = useAppSelector(
    (state) => state.structuresCompare.structures
  )
  const selectedDatasets = useAppSelector((state) => state.basket.selected)

  const [showSubActions, setShowSubActions] = useState(false)
  const {
    offset,
    className = '',
    item = {},
    menu = '',
  } = contextMenuStore || {}
  const { offsetY = 0, offsetX = 0 } = offset || {}
  const { exceptions, mouseLeaveHide = false } = item
  const isPropagate = menu !== 'profileMenu'

  const isDatasetSelected = useMemo(() => {
    return selectedDatasets?.find((id: number) => item?.bask?.id === id)
  }, [selectedDatasets, item?.bask?.id])

  const getMenuParams = (
    menu: keyof ContextMenuItems,
    item: ContextMenuStateItem,
    isAuthenticated: boolean,
    language: string
  ) => {
    switch (menu) {
      case 'profileMenu':
        return {
          isAuthenticated,
          language,
        }
      case 'literatureCardMenu':
        return item?.dataType
      case 'literatureFilterMenu':
        return item?.config
      case 'dataSetMenu':
        return isDatasetSelected
      default:
        return item
    }
  }

  const getActionsList = ContextMenuConfig?.[menu]

  const actionsList: ContextMenuAction[] = useMemo(() => {
    const menuParams = getMenuParams(menu, item, isAuthenticated, language)
    return getActionsList ? getActionsList(menuParams) : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActionsList, item, menu, isAuthenticated, language])

  const handleAction = useContextMenuAction(item)

  const handleClick = useCallback(
    (action: ContextMenuAction['action']) => {
      handleAction(action)
      showSubActions && setShowSubActions(false)
      dispatch(hideContextMenu())
    },
    [dispatch, handleAction, showSubActions]
  )

  const handleScroll = useCallback(
    (event: Event) => {
      const target = event?.target
      if (target) {
        const isContextMenuOrChild = contextMenuRef.current?.contains(
          target as HTMLElement
        )
        !isContextMenuOrChild &&
          target !== document &&
          dispatch(hideContextMenu())
      }
    },
    [dispatch]
  )

  const handleMouseLeave = useCallback(() => {
    if (showSubActions) setShowSubActions(false)
    return mouseLeaveHide ? dispatch(hideContextMenu()) : {}
  }, [dispatch, mouseLeaveHide, showSubActions])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, dispatch])

  const isActionDisabled = (action: string) => {
    return item.disabledActions?.[action] || false
  }

  const onOutboundClick = useCallback(() => {
    dispatch(hideContextMenu())
  }, [dispatch])

  const listLength = actionsList?.length || 1

  const offsetStyles = useMemo(() => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const nearRight: boolean = windowWidth - offsetX < CONTEXT_MENU_OFFSET_RIGHT
    const nearBottom =
      windowHeight - offsetY <=
      CONTEXT_MENU_ITEM_HEIGHT * listLength +
        16 +
        10 +
        (listLength > 1 ? 8 * listLength - 1 : 0) //учитываются паддинги меню и gap между элементами +10 для лучшего пользовательского опыта

    return {
      top: nearBottom ? 'auto' : offsetY + 10,
      left: nearRight ? 'auto' : offsetX + 10,
      bottom: nearBottom ? windowHeight - offsetY : 'auto',
      right: nearRight ? windowWidth - offsetX : 'auto',
    }
  }, [offsetX, offsetY, listLength])

  if (!offset) return null

  return (
    <OutboundClick
      exceptions={exceptions}
      onClick={onOutboundClick}
      isPropagate={isPropagate}
    >
      <div
        id="context-menu-wrap"
        className={`context-menu rounded-b ${className}`}
        style={offsetStyles}
        ref={contextMenuRef}
        onMouseLeave={handleMouseLeave}
      >
        {actionsList?.map((actionItem, index) => {
          return renderActionItem({
            ...actionItem,
            index,
            disabled: isActionDisabled(actionItem.action),
            handleClick,
            subActions: getSubActionsList(actionItem),
            showSubActions,
            setShowSubActions,
            structuresComparisonCount: structuresComparisonList?.length,
          })
        })}
      </div>
    </OutboundClick>
  )
})

ContextMenu.displayName = 'ContextMenu'
export default ContextMenu
