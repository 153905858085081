import { t } from 'i18next'

export const getLicenseNotificationText = (type, text, endpoint) => {
  if (type === 'max_file_size') {
    const maxSize = text.split('=')[1]
    if (maxSize)
      return t('notifications.limits.max_available_file_upload', { maxSize })
  }

  if (type === 'mol_max_number') {
    const maxCount = text.split('=')[1]
    if (maxCount) {
      if (endpoint.includes('molecules_move'))
        return t('notifications.limits.max_available_mol_count_move', {
          maxCount,
        })
      if (endpoint.includes('molecules_copy'))
        return t('notifications.limits.max_available_mol_count_copy', {
          maxCount,
        })
    }
  }

  if (
    endpoint.includes('uploadFileToBasket') &&
    (type === 'mol_max_number_by_day' || type === 'mol_max_number_by_month')
  ) {
    return t(`notifications.limits.${text}`)
  }

  if (type === 'period.day') return t('notifications.limits.period_text.day')
  if (type === 'period.month')
    return t('notifications.limits.period_text.month')

  return text
}

export const getIsLicenseActive = (licenseItem) => {
  if (!licenseItem) return null

  const { license_expired_at, license_created_at, license_is_active } =
    licenseItem || {}

  const expired = license_expired_at ? new Date(license_expired_at) : ''
  const created = license_created_at ? new Date(license_created_at) : ''
  const today = new Date()

  if (!!expired && !!created) {
    return today > created && today < expired && license_is_active
  }

  if (!!expired && !created) {
    return today < expired && license_is_active
  }

  if (!expired && !!created) {
    return today > created && license_is_active
  }

  return license_is_active
}
