import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  surveys: null,
  loading: false,
  error: '',
  user_answers: null,
}

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    getSurvey: (state) => {
      state.loading = true
      state.surveys = null
    },
    getSurveysSuccess: (state, action) => {
      state.loading = false
      state.surveys = action.payload
    },
    getSurveysFail: (state, action) => {
      state.loading = false
      state.surveys = null
      state.error = action.payload
    },
    getUserAnswersInit: (state) => {
      state.loading = true
      state.user_answers = null
    },
    getUserAnswersSuccess: (state, action) => {
      state.loading = false
      state.user_answers = action.payload
    },
    getUserAnswersFail: (state, action) => {
      state.loading = false
      state.user_answers = null
      state.error = action.payload
    },
    resetUserAnswers: (state) => {
      state.user_answers = null
    },
  },
})

export const {
  getSurvey,
  getSurveysSuccess,
  getSurveysFail,
  getUserAnswersInit,
  getUserAnswersSuccess,
  getUserAnswersFail,
  resetUserAnswers,
} = surveySlice.actions

export default surveySlice.reducer
