import styled from 'styled-components'

import { ElementWithChildren } from 'types/common'

interface ButtonsProps extends ElementWithChildren {
  btnSize: number
  btnBorderRadius: string
  withWhiteBackground?: boolean
}

export const Buttons = styled.div<ButtonsProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  div {
    height: ${({ btnSize }) => btnSize / 2}rem;
    border-radius: ${({ btnBorderRadius }) =>
      `${btnBorderRadius} ${btnBorderRadius}  0 0`};

    background: ${({ theme, withWhiteBackground }) =>
      withWhiteBackground
        ? theme.colors.backgrounds.secondary
        : theme.colors.backgrounds.primary};
  }

  div:nth-child(2) {
    border-radius: ${({ btnBorderRadius }) =>
      `0 0 ${btnBorderRadius}  ${btnBorderRadius} `};
  }
`
