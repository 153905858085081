import { store, update } from '../../services/rest'
import { readStorage, removeFromStorage } from 'utils/storage/storage'
import { addSeconds, parseJSON } from 'date-fns'
import { store as reduxStore } from 'store'
import { PdfFooter, PdfHeader } from 'components/PdfComponents'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import { projectTheme } from 'styles/theme'
import i18n from 'i18n'
import { footerPDFHeight } from './config'
import { geologicaCurReg } from 'utils/generatePdf/config'
import { networks } from 'components/PdfComponents/config'

import Papa from 'papaparse'
import { setIsSessionInterrupted } from 'store/slices/settingsSlice'
const loadJsPDF = () => import('jspdf')
const loadHtml2canvas = () => import('html2canvas')
const loadSanitizeHtml = () => import('sanitize-html')

export const sdfToSmi = async (file) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }

  let formData = new FormData()
  formData.append('file', file)

  try {
    const { data } = await store('/upload', formData, config)
    return data
  } catch (err) {
    console.log(err)
    return []
  }
}

export const copyToClipboard = (text) =>
  navigator.clipboard.writeText(text?.toString()?.trim())

export const getRandomColor = ({ withHash = false }) => {
  const letters = '0123456789ABCDEF'
  let color = withHash ? '#' : ''
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const validateEnglishInput = (inputText) => {
  const regexp = /^[a-zA-Z0-9\s!@#$%^&*()\\_+{}[\]:;"='<>,.?/~`|-]+$/
  return regexp.test(inputText)
}

export const validateNotStarSymbol = (inputText) => {
  const regexp = /^(?!.*\*).*$/
  return regexp.test(inputText)
}

export const validateEnglishCyrillicInput = (inputText) => {
  const regexp = /^[a-zA-Z0-9\s!@#$%^&*()\\_+{}[\]:;"='<>,.?/~`|-А-Яа-яЁё]+$/
  return regexp.test(inputText)
}

export const emailReg = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
export const validatePasswordLength = (password) => {
  const passwordLengthReg = new RegExp(/.{8,}/)

  return passwordLengthReg.test(password)
}

export const validatePasswordLetters = (password) =>
  /[a-z]/.test(password) && /[A-Z]/.test(password)

export const validatePasswordSymb = (password) =>
  /[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)

export const validateDelimiterSymbols = (str) =>
  /^[^a-zA-Zа-яА-Я0-9]+$/.test(str)

export const validatePasswordTotal = (password, email) =>
  !!password &&
  (!validatePasswordLength(password) ||
    !validatePasswordLetters(password) ||
    !validatePasswordSymb(password) ||
    (!!email && password.includes(email)))

export const isValidSMILES = async (inputValue) => {
  let isValid = false
  try {
    const { status } = await store(`/validate_smiles`, { smiles: inputValue })
    if (status === 200) isValid = true
  } catch (e) {
    isValid = false
  }
  return isValid
}

const addHeaderAndFooter = async (doc) => {
  const { default: html2canvas } = await loadHtml2canvas()
  const pages = doc.getNumberOfPages()

  const headerTempDiv = document.createElement('div')
  headerTempDiv.style.position = 'absolute'
  headerTempDiv.style.left = '-9999px'
  headerTempDiv.width = 210
  headerTempDiv.height = footerPDFHeight

  const footerTempDiv = document.createElement('div')
  footerTempDiv.style.position = 'absolute'
  footerTempDiv.style.left = '-9999px'

  footerTempDiv.width = 210
  footerTempDiv.height = footerPDFHeight
  const ua = navigator.userAgent

  document.body.appendChild(headerTempDiv)
  document.body.appendChild(footerTempDiv)

  if (headerTempDiv) {
    const headerRoot = createRoot(headerTempDiv)
    headerRoot.render(
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={projectTheme}>
          <PdfHeader />
        </ThemeProvider>
      </I18nextProvider>
    )
  }

  if (footerTempDiv) {
    const footerRoot = createRoot(footerTempDiv)
    footerRoot.render(
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={projectTheme}>
          <PdfFooter />
        </ThemeProvider>
      </I18nextProvider>
    )
  }

  await new Promise((resolve) => setTimeout(resolve, 500))

  const [headerCanvas, footerCanvas] = await Promise.all([
    html2canvas(headerTempDiv, { scale: 2 }),
    html2canvas(footerTempDiv, { scale: 2 }),
  ])
  document.body.removeChild(headerTempDiv)
  document.body.removeChild(footerTempDiv)
  const pageHeight = doc.internal.pageSize.height

  for (let page = 1; page <= pages; page++) {
    doc.setPage(page)
    doc.addImage(headerCanvas, 'PNG', 0, 0, 210, footerPDFHeight)

    const isSafari =
      /Safari/.test(ua) && !/Chrome/.test(ua) && /AppleWebKit/.test(ua)
    networks[i18n.language]?.forEach(({ link, safari_coords, coords }) => {
      const [x, y, width, height] = isSafari ? safari_coords : coords
      doc.link(x, y, width, height, { url: link })
    })

    if (page === pages) {
      const container = document.querySelector('.molecule-container-print')
      container.style.paddingBottom = '56.25rem'
    }

    doc.addImage(
      footerCanvas,
      'PNG',
      0,
      pageHeight - footerPDFHeight,
      210,
      footerPDFHeight
    )
  }
}

const generateMoleculePDF = async ({
  doc,
  pageHeight,
  imgWidth,
  canvas,
  pageToUse,
  isFullMode,
}) => {
  const { default: html2canvas } = await loadHtml2canvas()
  const headerFooterHeight = 19 * 2
  const usableHeight = pageHeight - headerFooterHeight
  const tempCanvas = document.createElement('canvas')
  tempCanvas.width = canvas.width
  tempCanvas.height = usableHeight * (canvas.width / imgWidth)
  doc.addFileToVFS('Geologica-Regular.ttf', geologicaCurReg)
  doc.addFont('Geologica-Regular.ttf', 'Geologica Cursive', 'normal')
  doc.setFont('Geologica Cursive')
  await addHeaderAndFooter(doc)
  await doc.html(pageToUse, {
    margin: [footerPDFHeight + 2, 0, footerPDFHeight, 0],
    html2canvas: {
      useCORS: true,
      allowTaint: true,
      logging: false,
      backgroundColor: 'transparent',
    },
    x: 0,
    y: 0,
    width: imgWidth,
    windowWidth: 794, //document.body.clientWidth,
    autoPaging: 'text',
    avoidPageSplit: true,
  })

  const moleculeClassName = isFullMode
    ? '.canvas-molecule-structure'
    : '.canvas-molecule-structure-hidden'

  const molecule = document.querySelector(`${moleculeClassName} canvas`)

  if (molecule) {
    const clone = document.createElement('canvas')
    clone.width = molecule.width
    clone.height = molecule.height

    const ctx = clone.getContext('2d')
    ctx.fillStyle = '#FFF'
    ctx.fillRect(0, 0, clone.width, clone.height)
    ctx.drawImage(molecule, 0, 0)
    clone.style.background = '#FFF'
    clone.style.backgroundColor = '#FFF'

    clone.style.position = 'absolute'
    clone.style.left = '-9999px'
    clone.style.top = '-9999px'
    clone.style.visibility = 'visible'
    clone.style.display = 'flex'

    document.body.appendChild(clone)

    clone.style.border = '2px solid rgba(230, 235, 241, 1)'
    clone.style.borderRadius = '0.25rem'
    const ua = navigator.userAgent
    const isSafari =
      /Safari/.test(ua) && !/Chrome/.test(ua) && /AppleWebKit/.test(ua)
    html2canvas(clone, { scale: 2 }).then((moleculeCanvas) => {
      const moleculeImg = moleculeCanvas.toDataURL('image/png')

      const imgWidth = isSafari ? 52 : 50
      const imgHeight =
        (moleculeCanvas.height * imgWidth) / moleculeCanvas.width

      const posX = isSafari ? 150 : 148
      const posY = isSafari ? 40 : 40
      doc.setPage(1)
      doc.addImage(
        moleculeImg,
        'PNG',
        posX,
        posY,
        imgWidth,
        imgHeight,
        '',
        'MEDIUM'
      )
      document.body.removeChild(clone)
    })
  }

  await addHeaderAndFooter(doc)
  const pages = doc.getNumberOfPages()
  doc.deletePage(pages)

  return doc
}
export const generatePdf = async ({
  id,
  fileName = 'Syntelly',
  dataCanvas, //аттрибут для отрисовки <canvas> изображений внутри страницы
  classForPrint, //доп. класс, добавляемый при генерации PDF для изменения вида страницы
  shouldClone = false, //требуется ли клонировать генерируемый в PDF DOM элемент, используется,
  //если мы хотим сгенерировать страницу и классом поменять ее отображение перед генерацией,
  //клонирование позволит избежать визуальной перерисовки для пользователя и сделает это
  //"под капотом" с клоном генерируемой страницы
  shouldScale = false, //true улучшает качество картинки, иногда убирает/добавляет косяки отображения
  isFullMode = true,
}) => {
  if (!id) return
  try {
    const page = document.getElementById(id)

    if (!page) return
    const clonedPage = page.cloneNode(true)

    if (id === 'molecule-page-container') {
      clonedPage.id = `${id}-clone`

      // убираем личные свойства
      const personalProp = clonedPage.querySelector(
        '#category-personal_properties'
      )
      if (personalProp) personalProp.remove()

      // убираем custom scrollbar
      clonedPage.querySelectorAll('.ScrollbarsCustom').forEach((scr) => {
        const content = scr.querySelector('.ScrollbarsCustom-Content')
        if (content) {
          scr.replaceWith(...content.childNodes)
        } else {
          scr.remove()
        }
      })
    }

    const pageToUse = shouldClone ? clonedPage : page

    if (dataCanvas && shouldClone) {
      //cloneNode метод не поддерживает копирование содержимого <canvas>, поэтому приходится копировать вручную картинки
      //собираем все <canvas> элементы с data-canvas аттрибутом в клоне и вставляем оригинальное содержимое в них
      const originalMoleculeCanvases = document.querySelectorAll(
        `[data-canvas="${dataCanvas}"]`
      )
      const clonedMoleculeCanvases = clonedPage.querySelectorAll(
        `[data-canvas="${dataCanvas}"]`
      )
      if (originalMoleculeCanvases?.length && clonedMoleculeCanvases?.length) {
        clonedMoleculeCanvases.forEach((canvas, index) => {
          canvas
            ?.getContext('2d')
            ?.drawImage(originalMoleculeCanvases[index], 0, 0)
        })
      }
    }
    classForPrint && pageToUse.classList.add(classForPrint) //изменение стилей страницы при генерации PDF

    shouldClone && page.appendChild(clonedPage)

    const { default: html2canvas } = await loadHtml2canvas()
    html2canvas(pageToUse, {
      logging: true, //логирование генерации canvas в консоль
      letterRendering: 1,
      // allowTaint: true,
      // useCORS: true,
      // removeContainer: false,
      scale: shouldScale ? 2 : 1,
    }).then(async (canvas) => {
      const { jsPDF } = await loadJsPDF()
      const doc = new jsPDF('p', 'mm', 'a4')
      const imgWidth = 208
      const pageHeight = 295
      const imgHeight = (canvas.height * imgWidth) / canvas.width

      if (id === 'molecule-page-container') {
        const doc_for_save = await generateMoleculePDF({
          doc,
          pageHeight,
          imgWidth,
          canvas,
          pageToUse,
          isFullMode,
        })
        doc_for_save.save(`${fileName}.pdf`)
        shouldClone && page.removeChild(clonedPage)
        return
      }

      let heightLeft = imgHeight
      let position = 0
      heightLeft -= pageHeight
      const imgData = canvas.toDataURL('image/png', 1) //0.6- качество генерации, влияет на размер файла
      doc.addImage(
        imgData,
        'PNG',
        0,
        position,
        imgWidth,
        imgHeight,
        '',
        'MEDIUM' //качество сжатия, влияет на размер файла
      )
      while (heightLeft > 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(
          imgData,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'MEDIUM'
        )
        heightLeft -= pageHeight
      }
      doc.save(`${fileName}.pdf`)
      shouldClone && document.body.removeChild(clonedPage)
    })
  } catch (error) {
    console.error('Error generating PDF: ', error)
  }
}

export const isMoleculesPath = (path) => {
  return /^\/molecules\/.*/.test(path)
}
export const isMoleculePath = (path) => {
  return /^\/molecule\/.*/.test(path)
}

export const handleDeleteKeys = (keys) => {
  keys.map((key) => removeFromStorage(key))
}

export const handleDownloadPNG = async ({ smiles, saveHandler }) => {
  try {
    const mol = window.RDKit?.get_mol(smiles || 'invalid')
    const canvas = document.createElement('canvas')
    canvas.width = 1000
    canvas.height = 1000
    mol?.draw_to_canvas(canvas, -1, -1)
    const pngDataUrl = canvas.toDataURL('image/png').split(';base64,')[1]
    const url = `data:image/png;base64,${pngDataUrl}`

    fetch(url)
      .then((b) => b?.blob())
      .then((blob) => saveHandler(blob, 'syntelly.png'))
  } catch (e) {
    console.log(e)
  }
}

export const updateLanguage = async (language) => {
  try {
    await update('/user/language', {
      language,
    })
  } catch (e) {
    console.log(e)
  }
}

export const REFRESH_DATE_KEY = 'refresh_data'
export const setExpiredRefreshDate = (seconds) => {
  if (!seconds) {
    removeFromStorage(REFRESH_DATE_KEY)
  } else {
    const exp = addSeconds(new Date(), seconds)
    localStorage.setItem(REFRESH_DATE_KEY, JSON.stringify(exp))
  }
}

export const checkInterruptSession = () => {
  const token = readStorage('s_token')
  if (!token) return false

  const eDate = readStorage(REFRESH_DATE_KEY)
  if (eDate) {
    const expDate = parseJSON(eDate)
    if (new Date() < expDate) {
      const isInterrupted = reduxStore.getState().settings?.isSessionInterrupted
      !isInterrupted && reduxStore.dispatch(setIsSessionInterrupted(true))
      return true
    }
  }
  return false
}

export const cleanHtml = async (dirtyHtml) => {
  const { default: sanitizeHtml } = await loadSanitizeHtml()
  return sanitizeHtml(dirtyHtml, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'div', 'span'],
    allowedAttributes: {
      a: ['href'],
      '*': ['class', 'style'],
    },
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  })
}

export const parseListFromCsvFile = ({
  csv_file,
  delimiterToUse,
  ownDelimiter,
  delimiterState,
  setParsingError,
  setListFromFile,
}) => {
  Papa.parse(csv_file, {
    delimiter:
      delimiterToUse || ownDelimiter?.toString() || delimiterState || ',',
    complete: (results) => {
      const data = results?.data || []
      const list = []
      data?.forEach((el, idx) => idx === 0 && list.push(...el))
      setListFromFile(list)
    },
    error: () => {
      setParsingError(true)
    },
  })
}

export const smilesRegexp = /^[a-zA-Z0-9\s!@#$%^&*()\\_+{}[\]:;"='<>,.?/~`|-]+$/

export const getNoteLengthForCalc = (note) =>
  note ? note?.replace(/\s/g, '')?.length : 0
