import { API_URL } from 'config/config'

export const getCountNoun = (count: number) => {
  const lastDigit = count % 10
  const lastTwoDigits = count % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) return 'many'
  if (lastDigit === 1) return 'one'
  if (lastDigit >= 2 && lastDigit <= 4) return 'few'
  return 'many'
}

export const DOCUMENTS_CONTAINER_ID: string = 'pdf2smiles-documents'

export const PDF2SMILES_UPLOAD_URL = `${API_URL}${'pdf2smiles/create_document'}`
export const PDF2SMILES_UPLOAD_HEADERS: Record<string, string> = {
  'Content-Type': 'multipart/form-data',
}
export const PDF2SMILES_GET_DOCS_URL = `${API_URL}${'pdf2smiles/documents_list'}`
export const PDF2SMILES_UPDATE_DOCUMENT_URL: string = `${API_URL}${'pdf2smiles/update_document'}`
export const PDF2SMILES_DELETE_DOCUMENT_URL: string = `${API_URL}${'pdf2smiles/delete_document'}`
export const PDF2SMILES_DOWNLOAD_MARKUP_URL: string = `${API_URL}${'pdf2smiles/export_markup'}`

export const PDF2SMILES_DOWNLOAD_MARKUP_OPTIONS: { responseType: 'blob' } = {
  responseType: 'blob',
}
export const PDF2SMILES_DOWNLOAD_MARKUP_EXT: Record<'csv' | 'png_zip', string> =
  {
    csv: 'csv',
    png_zip: 'zip',
  }

export const PDF2SMILES_UPLOAD_D0C_SUCCESS: string =
  'notification.file_uploaded_successfully'
export const PDF2SMILES_UPLOAD_D0C_ONLY_ONE: string =
  'You can recognize 1 document at a time'
