import { takeEvery, put, select, delay } from 'redux-saga/effects'
import axios from 'axios'
import { API_URL } from 'config/config'
import { fetch, getHeaders } from 'services/rest'
import {
  getInfraredData,
  getMsData,
  getNmrData,
  spectraInfraredFail,
  spectraInfraredSuccess,
  spectraMsFail,
  spectraMsSuccess,
  spectraNmrFail,
  spectraNmrSuccess,
} from 'store/slices/spectraSlice'
import { getSpectraErrorMessage } from './utils/utils'

function* checkIsTaskReady(id) {
  let status = 'running'
  let currentTaskId
  let spectraResponse
  while (status === 'running') {
    const tasks = yield fetch(`/userTasksStatus`)
    const task = tasks?.data?.find((task) => id === task.uuid)

    if (task) {
      currentTaskId = task
      if (task?.status === 'ok') {
        status = 'ok'
        spectraResponse = yield fetch(`/userTasksStatus/${id}`)
      } else {
        if (task?.status === 'error') {
          throw new Error('error_ms')
        }
      }
    }
    if (currentTaskId && !task) {
      currentTaskId = 'canceled'
      status = 'canceled'
    }

    yield delay(2000)
  }
  return { spectraResponse, currentTaskId }
}

function* spectraMsGet({ payload }) {
  const { smiles, adduct_type, m_z_round, percent_round } = payload || {}
  let currentTaskId
  try {
    const response = yield axios.post(
      `${API_URL}predict-cfmid`,
      {
        smiles,
        adduct_type,
      },
      {
        headers: getHeaders(),
      }
    )

    let msResponse
    if (response?.status === 200) {
      let checkResult = yield* checkIsTaskReady(response?.data?.task_uuid)

      currentTaskId = checkResult.currentTaskId
      msResponse = checkResult.spectraResponse
    }
    if (!msResponse?.data?.result) throw new Error()

    const isotopsResponse = yield axios.post(
      `${API_URL}run-task`,
      {
        service: 'isotops',
        params: { smiles, m_z_round, percent_round },
      },
      {
        headers: getHeaders(),
      }
    )

    yield put(
      spectraMsSuccess({
        ...msResponse.data.result,
        isotopic_distribution: [...isotopsResponse.data.result],
      })
    )
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put(spectraMsSuccess({}))
    } else if (error) {
      yield put(spectraMsFail(getSpectraErrorMessage(error)))
    }
  }
}

function* spectraNmrGet({ payload }) {
  let currentTaskId
  const { smiles, method } = payload || {}
  try {
    const response = yield axios.post(
      `${API_URL}run-task`,
      {
        input: {
          smiles,
        },
        service: 'nmr-hosegen',
        nocache: false,
        type: 'delayed',
        method,
      },
      {
        headers: getHeaders(),
      }
    )

    let nmrResponse
    if (response?.status === 200) {
      let checkResult = yield* checkIsTaskReady(response?.data?.task_uuid)

      currentTaskId = checkResult.currentTaskId
      nmrResponse = checkResult.spectraResponse
    }

    if (!nmrResponse?.data?.result) throw new Error()

    yield put(
      spectraNmrSuccess({
        ...nmrResponse.data.result,
      })
    )
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put(spectraNmrSuccess({}))
    } else if (error) {
      yield put(spectraNmrFail(getSpectraErrorMessage(error)))
    }
  }
}

function* spectraInfraredGet({ payload }) {
  let currentTaskId

  const { smiles } = payload || {}
  try {
    const method = yield select(
      (state) => state.spectra.infrared.shootingMethod
    )
    const response = yield axios.post(
      `${API_URL}run-task`,
      {
        service: 'ir_spectr',
        params: { smiles, adduct_type: method.value },
        type: 'delayed',
      },
      {
        headers: getHeaders(),
      }
    )

    let irResponse
    if (response?.status === 200) {
      let checkResult = yield* checkIsTaskReady(response?.data?.task_uuid)

      currentTaskId = checkResult.currentTaskId
      irResponse = checkResult.spectraResponse
    }
    if (!irResponse?.data?.result) throw new Error()

    yield put(spectraInfraredSuccess([...irResponse.data.result]))
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put(spectraInfraredSuccess([]))
    } else if (error) {
      yield put(spectraInfraredFail(getSpectraErrorMessage(error)))
    }
  }
}

export function* spectraMsGetWatcher() {
  yield takeEvery(getMsData.type, spectraMsGet)
}

export function* spectraNmrGetWatcher() {
  yield takeEvery(getNmrData.type, spectraNmrGet)
}

export function* spectraInfraredGetWatcher() {
  yield takeEvery(getInfraredData.type, spectraInfraredGet)
}
