import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeleteHighlightsType,
  DocumentType,
  EditSmilesType,
  MarkupType,
  Pdf2SmilesInitState,
} from './types/pdf2Smiles.types'

export const initialState: Pdf2SmilesInitState = {
  currentPage: 1,
  currentHighlightId: '',
  isDocumentHeaderCollapsed: false,
  loading: false,
  error: false,
  filename: '',
  file_url: '',
  markup: [],
  needUpdateDocuments: true,
  selectedHighlights: [],
  showDialog: false,
  selectedSmiles: '',
}

export const deleteHighlights = createAction<DeleteHighlightsType>(
  'pdf2Smiles/deleteHighlights'
)
export const editSmiles = createAction<EditSmilesType>('pdf2Smiles/editSmiles')

const pdf2SmilesSlice = createSlice({
  name: 'pdf2Smiles',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>): void => {
      state.currentPage = action.payload
    },
    setCurrentHighlightId: (state, action: PayloadAction<string>): void => {
      state.currentHighlightId = action.payload
    },
    setIsDocumentHeaderCollapsed: (
      state,
      action: PayloadAction<boolean>
    ): void => {
      state.isDocumentHeaderCollapsed = action.payload
    },
    updateMarkup: (state, action: PayloadAction<MarkupType[]>): void => {
      state.markup = action.payload
    },
    getPdf2SmilesDocument: (state, action: PayloadAction<string>): void => {
      Object.assign(state, { ...initialState, loading: true })
    },
    getPdf2SmilesDocumentSuccess: (
      state,
      action: PayloadAction<DocumentType>
    ): void => {
      const { id, filename, file_url, markup } = action.payload
      state.loading = false
      state.filename = filename
      state.file_url = file_url
      state.markup = markup.filter(
        ({ structure }) =>
          Array.isArray(structure?.smiles) && structure.smiles.length > 0
      )
    },
    getPdf2SmilesDocumentError: (state): void => {
      state.loading = false
      state.error = true
    },
    setNeedUpdateDocuments: (state, action: PayloadAction<boolean>): void => {
      state.needUpdateDocuments = action.payload
    },
    setSelectedHighlights: (state, action: PayloadAction<string[]>): void => {
      state.selectedHighlights = action.payload
    },
    toggleShowDialog: (
      state,
      action: PayloadAction<number | undefined>
    ): void => {
      const id = action?.payload ?? null
      state.showDialog = id
    },
    setSelectedSmiles: (state, action: PayloadAction<string>): void => {
      state.selectedSmiles = action.payload
    },
  },
})

export const {
  setCurrentPage,
  setCurrentHighlightId,
  setIsDocumentHeaderCollapsed,
  getPdf2SmilesDocumentSuccess,
  getPdf2SmilesDocumentError,
  setNeedUpdateDocuments,
  setSelectedSmiles,
  toggleShowDialog,
  setSelectedHighlights,
  getPdf2SmilesDocument,
  updateMarkup,
} = pdf2SmilesSlice.actions

export default pdf2SmilesSlice.reducer
