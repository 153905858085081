import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dialog: { open: false, config: {} },
  compound_id: null,
}

const reactionsSearchSlice = createSlice({
  name: 'reactionsSearch',
  initialState,
  reducers: {
    setOpenReactionsDialog: (state, action) => {
      state.dialog.open = action.payload
    },
    setReactionsDialogConfig: (state, action) => {
      state.dialog.config = { ...action.payload }
    },
    setReactionsCompoundId: (state, action) => {
      state.compound_id = action.payload
    },
  },
})

export const {
  setOpenReactionsDialog,
  setReactionsDialogConfig,
  setReactionsCompoundId,
} = reactionsSearchSlice.actions

export default reactionsSearchSlice.reducer
