import i18n from 'i18n'
import { v4 as uuidv4 } from 'uuid'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Icon from 'components/Icon'
import { store } from 'services/rest'
import CustomInput from 'components/common/customInput'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import CustomButton from 'components/common/customButton'
import { validatePasswordTotal } from 'utils/common/common'

import {
  Text,
  Text2,
  Input,
  Content,
  Wrapper,
  CloseBtn,
  ContactUs,
  ThinBlock,
  CopyRight,
  InputBlock,
  InputError,
  InputLabel,
  FieldsBlock,
  SuccessMessage,
} from './index.style'
import { PRIVACY_POLICY_LINK_EN, PRIVACY_POLICY_LINK_RU } from './config'
import { clearUserTempCreds, setUserTempCreds } from 'store/slices/authSlice'
import { addNotification } from 'store/slices/notificationsSlice'

const RegistrationPage = ({ handleGoLogin, push, path }) => {
  const logoImage = useGetLogoImage()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tempUserCreds = useSelector((state) => state.auth?.userCreds)
  const userLang = i18n.language

  const [data, setData] = useState({
    password: tempUserCreds?.password ?? '',
    password2: '',
    email: tempUserCreds?.email ?? '',
    name: '',
    company: '',
    position: '',
    agreed: false,
    token: '',
  })

  const [error, setError] = useState('')
  const [errorType, setErrorType] = useState('')
  const [loading, setLoading] = useState(false)

  const [showOk, setShowOk] = useState(false)

  const [isEmailError, setIsEmailError] = useState(false)
  const [password2Error, setPassword2Error] = useState('')
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(false)

  const { password, email, password2 } = data

  const isPasswordValidationError = validatePasswordTotal(password, email)

  useEffect(() => {
    if (!password2 && password2Error) setPassword2Error('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password2])

  const validateEmail = useCallback(() => {
    const emailReg = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

    return emailReg.test(data.email)
  }, [data.email])

  useEffect(() => {
    if (!data.email || validateEmail()) {
      if (isEmailError) {
        setIsEmailError(false)
        setError('')
      }
    } else {
      setIsEmailError(true)
      setError(t('account.emailError'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, validateEmail])

  const handleChange = (value, type) => {
    if (type === 'password' || type === 'password2') setIsPasswordsMatch(false)
    if (type === 'email' && errorType === 'email') {
      setErrorType('')
      setError('')
    }

    setData({ ...data, [type]: value })
  }

  const handleSubmit = async () => {
    if (!validateEmail()) {
      setIsEmailError(true)
      setError(t('account.emailError'))
      return
    }

    try {
      setError('')
      setErrorType('')
      setLoading(true)
      const { data: response } = await store('/profile/register', data)

      setLoading(false)
      if (response.status === 'error') {
        let errorText = response.error
        let needTranslateText = false
        let translationTextParams
        setErrorType(response.errorType)
        if (response.errorType === 'email') {
          setIsEmailError(true)
          if (errorText.startsWith('User with email')) {
            translationTextParams = { email: data.email }
            errorText = 'registration.email_error'
            needTranslateText = true
          }
        } else if (response.errorType === 'invite')
          if (errorText.startsWith('Invite token is invalid. Please ask')) {
            errorText = 'registration.invite_token_is_invalid'
            needTranslateText = true
          }
        if (errorText.startsWith('Invite token is required')) {
          errorText = 'registration.invite_token_is_required'
          needTranslateText = true
        }

        setError(t(errorText, { email: data.email }))

        const id = uuidv4()
        const notify = {
          id,
          name: 'notification.error',
          text: errorText,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
          needTranslateText,
          translationTextParams,
        }
        dispatch(addNotification(notify))
      } else if (response.status === 'ok') {
        setShowOk(true)
      }
    } catch (e) {
      setError(t('notification.unhandled'))
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e || 'notification.unhandled',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: !e,
      }
      dispatch(addNotification(notify))
      setLoading(false)
    } finally {
      dispatch(clearUserTempCreds())
    }
  }

  const validate = () => {
    if (
      !data.password ||
      !data.password2 ||
      !data.email ||
      !data.name ||
      !data.company ||
      !data.position
    ) {
      return false
    }

    return true
  }

  const isSignUpDisabled =
    !validate() ||
    !password ||
    !password2 ||
    isPasswordValidationError ||
    password2Error ||
    !isPasswordsMatch

  const handleClickLogin = () => {
    if (path === '/register') {
      if (!!data.email || !!data.password) {
        dispatch(
          setUserTempCreds({ email: data.email, password: data.password })
        )
      }
      push('/login')
    } else {
      handleGoLogin()
    }
  }

  const handleBlur = () => {
    if (password && password2) {
      if (password !== password2) {
        setPassword2Error('account.password2Error')
        setIsPasswordsMatch(false)
      } else {
        setPassword2Error('')
        if (!isPasswordsMatch) {
          setIsPasswordsMatch(true)
        }
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  return (
    <Wrapper>
      <div onClick={(e) => e.stopPropagation()}>
        {!showOk && (
          <Content>
            <CloseBtn onClick={() => push('/login')}>
              <Icon iconType="close" />
            </CloseBtn>
            <img
              src={logoImage}
              alt="syntelly"
              height="70px"
              style={{ margin: '0 auto' }}
            />
            <FieldsBlock method="dialog">
              <InputBlock type="email">
                <InputLabel htmlFor="email">E-mail</InputLabel>
                <Input
                  error={isEmailError || errorType === 'email'}
                  id="email"
                  onChange={(e) => handleChange(e.target.value, 'email')}
                  value={data.email}
                  placeholder={t('account.emailPlaceholder')}
                  required
                  type="email"
                  autoComplete="email"
                />
                {isEmailError && (
                  <InputError>
                    {isEmailError ? error : t('account.emailError')}
                  </InputError>
                )}
              </InputBlock>
              <InputBlock type="token">
                <InputLabel>
                  {t('registration.inviteToken')}
                  <ContactUs>
                    <a href="mailto:admin@syntelly.com">
                      {t('registration.contactUs')}
                    </a>
                  </ContactUs>
                </InputLabel>
                <Input
                  id="token"
                  onChange={(e) => handleChange(e.target.value, 'token')}
                  value={data.token}
                  placeholder={t('account.tokenPlaceholder')}
                  required
                  autoComplete="one-time-code"
                />
                {error && !isEmailError && (
                  <InputError>{error ? error : ''}</InputError>
                )}
              </InputBlock>

              <InputBlock type="password">
                <CustomInput
                  id="password"
                  onChange={(value) => handleChange(value, 'password')}
                  value={password}
                  placeholder={t('registration.password_placeholder')}
                  type="password"
                  required
                  onBlur={handleBlur}
                  onKeyUp={handleKeyUp}
                  label={t('registration.password')}
                  autoComplete="new-password"
                  isValidated={!!password && !isPasswordValidationError}
                  withPasswordTooltip={isPasswordValidationError}
                  email={email}
                  warning={isPasswordValidationError}
                />
              </InputBlock>

              <InputBlock type="password2">
                <CustomInput
                  id="password2"
                  onChange={(value) => handleChange(value, 'password2')}
                  value={password2}
                  placeholder={t('registration.password2_placeholder')}
                  type="password"
                  required
                  label={t('registration.retypePassword')}
                  onBlur={handleBlur}
                  onKeyUp={handleKeyUp}
                  autoComplete="new-password"
                  warning={password2Error}
                  isValidated={
                    !!password2 && !password2Error && isPasswordsMatch
                  }
                />
              </InputBlock>

              <InputBlock type="name">
                <InputLabel>{t('registration.fullName')}</InputLabel>
                <Input
                  id="name"
                  onChange={(e) => handleChange(e.target.value, 'name')}
                  value={data.name}
                  placeholder={t('account.namePlaceholder')}
                  required
                  autoComplete="name"
                />
              </InputBlock>

              <InputBlock type="company">
                <InputLabel>{t('registration.company')}</InputLabel>
                <Input
                  id="company"
                  onChange={(e) => handleChange(e.target.value, 'company')}
                  value={data.company}
                  placeholder={t('account.companyPlaceholder')}
                  required
                  autoComplete="company"
                />
              </InputBlock>

              <InputBlock type="position">
                <InputLabel>{t('registration.position')}</InputLabel>
                <Input
                  id="position"
                  onChange={(e) => handleChange(e.target.value, 'position')}
                  value={data.position}
                  placeholder={t('registration.positionPlaceholder')}
                  required
                  autoComplete="position-title"
                />
              </InputBlock>
            </FieldsBlock>

            <ThinBlock>
              <CustomButton
                width={'100%'}
                type="accent"
                disabled={isSignUpDisabled}
                onClick={handleSubmit}
              >
                {loading ? (
                  <Icon size="1rem" iconType="loader" />
                ) : (
                  t('registration.signUp')
                )}
              </CustomButton>

              <Text>
                {t('registration.registeringToThisWebsite')}

                <a
                  href={
                    userLang === 'ru'
                      ? PRIVACY_POLICY_LINK_RU
                      : PRIVACY_POLICY_LINK_EN
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('registration.privacyPolicy')}
                </a>
              </Text>
            </ThinBlock>
            <Text2>
              {t('registration.alreadyRegistered')}
              <span onClick={handleClickLogin}>{t('account.signIn')}</span>
            </Text2>
            <CopyRight>
              {t('account.copyRight').toUpperCase()}
              {new Date().getFullYear()}
            </CopyRight>
          </Content>
        )}

        {showOk && (
          <Content>
            <CloseBtn onClick={() => push('/login')}>
              <Icon iconType="close" />
            </CloseBtn>
            <img src={logoImage} alt="syntelly" height="70px" />
            <SuccessMessage style={{ marginTop: '1rem' }}>
              {t('registration.activation')}
            </SuccessMessage>
          </Content>
        )}
      </div>
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  path: state.router.location.pathname,
})

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage)
