import { useTranslation } from 'react-i18next'
import { memo, useEffect, useState } from 'react'
import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import NotificationText from '../NotificationText'
import { Body, Header, Bottom, Container } from './index.style'
import { useUndo } from 'hooks/useUndo'
import { useDispatch } from 'react-redux'
import {
  setDrawnSmiles,
  setPaginationConfig,
  setSearchSortingConfig,
  setSearchText,
} from 'store/slices/searchSlice'
import { setLitFilterConfig } from 'store/slices/literatureSlice'
import { removeNotification } from 'store/slices/notificationsSlice'
import { setFilterConfig, setOpenFilter } from 'store/slices/filterSlice'
import {
  setBingoSearchState,
  setLitSearchState,
  setReactionsSearchState,
  setSearchV2State,
} from 'store/slices/crudSlice'

const NotificationCancel = memo(({ item }) => {
  const { t } = useTranslation()
  const { handleUndo } = useUndo()
  const dispatch = useDispatch()

  const [time, setTime] = useState(5)

  let timer1 = setTimeout(() => setTime((prev) => prev - 1), 1000)

  const { id, name, text: itemText, params } = item || {}

  const { text, actionType, searchType } = params || {}

  useEffect(() => {
    return () => {
      clearTimeout(timer1)
    }
  }, [timer1])

  const handleCancelAction = () => {
    if (actionType === 'searchQuery') {
      const {
        text,
        searchV2,
        litSearch,
        pagination,
        searchType,
        bingoSearch,
        drawnSmiles,
        filterConfig,
        litFilterConfig,
        sortingConfig,
        reactionsSearch,
      } = params

      dispatch(setSearchText(text))
      dispatch(setDrawnSmiles(drawnSmiles))
      dispatch(setPaginationConfig(pagination))
      dispatch(setFilterConfig(filterConfig))
      dispatch(setOpenFilter(true))
      if (searchType === 'structure') {
        dispatch(setSearchV2State(searchV2))
        dispatch(setBingoSearchState(bingoSearch))
      }
      if (searchType === 'literature') {
        dispatch(setLitFilterConfig(litFilterConfig))
        dispatch(setSearchSortingConfig(sortingConfig))
        dispatch(setLitSearchState(litSearch))
      }
      if (searchType === 'reaction') {
        dispatch(setReactionsSearchState(reactionsSearch))
      }
    }
    if (actionType === 'baskets-join' || actionType === 'baskets-delete') {
      handleUndo && handleUndo()
    }
    dispatch(removeNotification(id))
  }

  return (
    <Container>
      <Body>
        <Header>
          <Icon iconType="warning" />
          <span>
            {t(
              name,
              name === 'notification.search_query_removed'
                ? { type: t(`search.${searchType}`).toLowerCase(), text }
                : {}
            )}
          </span>
        </Header>
        {!!itemText && <NotificationText {...{ item, t }} />}
      </Body>
      <Bottom>
        <CustomButton width="100%" type="accent" onClick={handleCancelAction}>
          {t('notification.cancel', {
            time,
          })}
        </CustomButton>
      </Bottom>
    </Container>
  )
})

NotificationCancel.displayName = 'NotificationCancel'

export default NotificationCancel
