import { put, select, takeEvery } from 'redux-saga/effects'
import { store } from 'services/rest'
import { v4 as uuidv4 } from 'uuid'
import { getEndOfPeriod, getStartOfPeriod } from './utils/utils'
import {
  applyFeedbackFilter,
  getFeedback,
  getfeedbackError,
  getfeedbackSuccess,
  setFeedbackPaginationConfig,
} from 'store/slices/feedbackSlice'
import { addNotification } from 'store/slices/notificationsSlice'

function* getFeedbackFn({ payload }) {
  const { limit = 24, offset = 0 } = payload || {}
  const filter = yield select((state) => state.feedback.filter)
  const preparedModules = filter?.modules?.map((el) => el?.id)
  const preparedFilter = {
    ...(filter?.theme !== 'all' ? { type_list: [filter?.theme] } : {}),
    ...(preparedModules?.length ? { module_list: preparedModules } : {}),
    ...(filter?.email ? { user_email: filter?.email } : {}),
    ...(filter?.date?.[0]
      ? {
          created_at_from: getStartOfPeriod(filter.date[0], 'day'),
        }
      : {}),
    ...(filter?.date?.[1]
      ? { created_at_to: getEndOfPeriod(filter.date[1], 'day') }
      : {}),
  }
  const preparedSort = {
    [filter?.sort?.type]: filter?.sort?.direction || 'desc',
  }
  try {
    const { data } = yield store('/feedback/get', {
      params: {
        filter: preparedFilter,
        sort: preparedSort,
        limit,
        offset,
      },
    })
    yield put(getfeedbackSuccess(data?.items))

    yield put(
      setFeedbackPaginationConfig({
        pagesAmount: Math.ceil(+data?.total / limit),
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      })
    )
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      text: e.message,
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
      needTranslateText: false,
    }
    yield put(getfeedbackError())
    yield put(addNotification(notify))
  }
}

export function* getFeedbackWatcher() {
  yield takeEvery(getFeedback.type, getFeedbackFn)
}

export function* applyFeedbackFilterWatcher() {
  yield takeEvery(applyFeedbackFilter.type, getFeedbackFn)
}
