import { DefaultTheme } from 'styled-components'

export const colorsPalette = {
  backgrounds: {
    primary: '#FFFFFF',
    secondary: '#F5F7F9',
    tertiary: '#E6EBF1',
    quaternary: '#DAE0EA',
    skeletonDark: '#EFF2F6',
    skeletonLight: '#F5F7F9',
    contextMenu: '#F3F5F8D6',
    contextMenuHover: '#DAE0EA5C',
    hint: '#6C737D',
    scroll: '#DAE0EA85',
    separator: '#E6EBF1',
    accent: '#42C1BF',
    accentSecondary: '#B3E6E5',
    accentTertiary: '#ECF9F9',
    blur: '#6E809D14',
    success: '#12B76A29',
    error: '#F0443829',
  },
  text: {
    primary: '#2D3745',
    secondary: '#6E809D',
    tertiary: '#CED6E3',
    white: '#FFFFFF',
    whiteSecondary: '#FFFFFFCC',
    accentPrimary: '#42C1BF',
    accentSecondary: '#B3E6E5',
    accentHover: '#3AA9A7',
    deleteDefault: '#D92D20',
    deleteHover: '#B42318',
    deleteDisabled: '#FECDCA',
  },
  icons: {
    primary: '#2D3745',
    secondary: '#6E809D',
    tertiary: '#CED6E3',
    quaternary: '#E6EBF1',
    white: '#FFFFFF',
    whiteSecondary: '#FFFFFF99',
    accentPrimary: '#42C1BF',
    accentSecondary: '#B3E6E5',
    accentTertiary: '#ECF9F9',
    deleteHover: '#8F2A23',
  },
  buttons: {
    primary: '#FFFFFF',
    secondary: '#F5F7F9',
    accent: '#42C1BF',
    accentHover: '#3AA9A7',
    accentDisabled: '#8EDAD9',
  },
  statuses: {
    success: '#12B76A',
    successHover: '#A6F4C5',
    successBg: '#12B76A14',
    attention: '#F79009',
    attentionHover: '#FEDF89',
    attentionBg: '#F7900914',
    error: '#F04438',
    errorHover: '#FEE4E2',
    errorBg: '#F0443814',
    link: '#2E90FA',
    linkHover: '#B2DDFF',
    linkBg: '#2E90FA14',
    linkNew: '#165DFF',
  },
} as const

export const fontSizes = {
  0: '0rem',
  11: '0.688rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  24: '1.5rem',
  28: '1.75rem',
} as const

export const fontWeight = {
  extraLight: 200,
  light: 300,
  medium: 400,
  semibold: 500,
} as const

export const shadows = {
  default:
    '0px 4px 12px 0px rgba(45, 55, 69, 0.04), 0px -4px 12px 0px rgba(45, 55, 69, 0.04), 0px 0px 4px 0px rgba(45, 55, 69, 0.02)',
  highlight:
    '0px 0px 8px 0px rgba(66, 193, 191, 0.1), 0px -6px 12px 0px rgba(66, 193, 191, 0.06), 0px 6px 12px 0px rgba(66, 193, 191, 0.06)',
  overhand:
    '0px 0px 4px 0px rgba(45, 55, 69, 0.04), 0px 4px 8px 0px rgba(45, 55, 69, 0.06)',
  below:
    '0px -4px 8px rgba(45, 55, 69, 0.06), 0px 0px 4px rgba(45, 55, 69, 0.04)',
  right:
    '4px 0px 8px rgba(45, 55, 69, 0.06), 0px 0px 4px rgba(45, 55, 69, 0.04)',
  left: '-4px 0px 8px rgba(45, 55, 69, 0.06), 0px 0px 4px rgba(45, 55, 69, 0.04)',
  toggleOff:
    '2px 0px 2px rgba(45, 55, 69, 0.12), 0px 1px 2px rgba(45, 55, 69, 0.12)',
  toggleOn:
    '-2px 0px 2px rgba(45, 55, 69, 0.12), 0px 1px 2px rgba(45, 55, 69, 0.12)',
  checkbox: '0px 0px 2px rgba(45, 55, 69, 0.24)',
  switch: '0px 1px 4px rgba(45, 55, 69, 0.16)',
  accent:
    '0px 6px 12px 0px rgba(66, 193, 191, 0.06), 0px -6px 12px 0px rgba(66, 193, 191, 0.06), 0px 0px 8px 0px rgba(66, 193, 191, 0.10)',
  blur: '0px 0px 8px 0px rgba(45, 55, 69, 0.20)',
} as const

export const projectTheme: DefaultTheme = {
  colors: colorsPalette,
  text: fontSizes,
  fontWeight,
  shadow: shadows,
}
