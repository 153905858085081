import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = []

export const removeCompletedTasks = createAction('spectra/removeCompletedTasks')

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    removeTask: (state, action) => {
      return state.filter((task) => task.id !== action.payload)
    },
    updateTasks: (state, action) => {
      return action.payload
    },
  },
})

export const { removeTask, updateTasks } = tasksSlice.actions

export default tasksSlice.reducer
