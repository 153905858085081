import { v4 as uuidv4 } from 'uuid'
import { store } from 'services/rest'
import { put, select, takeEvery } from 'redux-saga/effects'
import { removeCompletedTasks } from 'store/slices/tasksSlice'
import { addNotification } from 'store/slices/notificationsSlice'

function* removeCompletedTasksFn() {
  try {
    const tasks = yield select((state) => state.tasks)
    yield store('/close-completed-tasks', {
      tasks_uuid: [...tasks.map((el) => el.uuid)],
    })
  } catch (e) {
    const notify = {
      id: uuidv4(),
      name: 'notification.error',
      text: 'notification.completed_tasks_removing_failed',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

export function* removeCompletedTasksWatcher() {
  yield takeEvery(removeCompletedTasks.type, removeCompletedTasksFn)
}
