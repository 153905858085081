import { put, takeEvery } from 'redux-saga/effects'
import { storeV2 } from 'services/rest'
import {
  getSurvey,
  getSurveysFail,
  getSurveysSuccess,
  getUserAnswersFail,
  getUserAnswersInit,
  getUserAnswersSuccess,
} from 'store/slices/surveySlice'

function* getSurveys({ payload }) {
  const { onRegistration = true, limit = 1, offset = 0 } = payload || {}
  try {
    const { data } = yield storeV2('/survey/get', {
      params: {
        filter: {
          on_registration: onRegistration,
        },
        limit,
        offset,
      },
    })

    yield put(getSurveysSuccess(data.items))
  } catch (error) {
    yield put(getSurveysFail(error?.message))
  }
}

function* getUserAnswers({ payload }) {
  const { user_id, survey_id } = payload || {}
  try {
    const { data } = yield storeV2('/survey/answer/get', {
      params: {
        filter_: {
          user_id,
          survey_id,
        },
      },
    })

    yield put(getUserAnswersSuccess(data.items[0]?.answers || []))
  } catch (error) {
    yield put(getUserAnswersFail(error?.message))
  }
}

export function* getSurveysWatcher() {
  yield takeEvery(getSurvey.type, getSurveys)
}

export function* getUserAnswersWatcher() {
  yield takeEvery(getUserAnswersInit.type, getUserAnswers)
}

const watchers = [getSurveysWatcher(), getUserAnswersWatcher()]

export default watchers
