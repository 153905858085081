import styled from 'styled-components'

import { Label } from 'components/common/text/index.style'

export const FilterDoubleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FilterDoubleInputTitle = styled(Label)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
`

export const DoubleInputContainer = styled.div`
  height: ${({ opened, isMolecularWeight }) => {
    if (!opened) return '0rem'
    return isMolecularWeight ? '6.875rem' : '2.5rem'
  }};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened, isMolecularWeight }) => {
    if (!opened) return '0rem'
    return isMolecularWeight ? '6.875rem' : '2.5rem'
  }};
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  transition: all 0.3s;
  display: flex;
  column-gap: 0.5rem;
`
