import { takeEvery, put, delay, select } from 'redux-saga/effects'
import { API_URL } from 'config/config'
import { fetch, store } from 'services/rest'
import {
  DEFAULT_STAGE_END,
  DEFAULT_STAGE_START,
} from 'pages/SynthCost/config/config'
import {
  predict,
  setSynthWarningNotifyIds,
  synthGetResultFail,
  synthGetResultInit,
  synthGetResultSuccess,
  synthGetTaskFail,
  synthGetTaskSuccess,
} from 'store/slices/synthcostSlice'

function* synthGetTask() {
  try {
    const { product, weight, stage_start, stage_end, reagent } = yield select(
      (state) => state.synthcost.settings
    )
    const response = yield store(`${API_URL}run-task`, {
      service: 'price_retro',
      params: {
        end_smiles: product,
        weight,
        max_deep: Number(stage_end) || DEFAULT_STAGE_END,
        min_deep: Number(stage_start) || DEFAULT_STAGE_START,
        start_smiles: reagent,
      },
      method: 'price_retro',
      type: 'delayed',
    })
    if (response.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response.data.task_uuid
        const tasks = yield fetch(`/userTasksStatus`)

        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          yield put(synthGetTaskSuccess(task))
          if (task?.status === 'ok') {
            status = 'ok'
            yield put(synthGetResultInit({ task }))
          }
        }
        yield delay(2000)
      }
    } else {
      yield put(synthGetTaskFail(response.statusText))
    }
  } catch (error) {
    yield put(synthGetTaskFail(error.response.data.detail))
  }
}

function* synthGetResult({ payload }) {
  const { task } = payload || {}
  try {
    const response = yield fetch(`${API_URL}userTasksStatus/${task.uuid}`)
    if (response.status === 200) {
      yield put(synthGetResultSuccess(response.data.result))
      if (
        Object.values(response.data.result).every((el) => el.length === 0) &&
        response.data.uuid
      ) {
        yield put(setSynthWarningNotifyIds(response.data.uuid))
      }
    } else {
      yield put(synthGetResultFail(response.statusText))
    }
  } catch (error) {
    yield put(synthGetResultFail('Error'))
  }
}

export function* synthGetTaskWatcher() {
  yield takeEvery(predict.type, synthGetTask)
}

export function* synthGetDataWatcher() {
  yield takeEvery(synthGetResultInit.type, synthGetResult)
}

const watchers = [synthGetTaskWatcher(), synthGetDataWatcher()]
export default watchers
