import { useTranslation } from 'react-i18next'
import { memo, useMemo, useRef } from 'react'
import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import { INITIAL_TYPES_LIST } from 'components/Search/LiteratureFilter/constants'

import {
  FilterBody,
  StyledButton,
  FilterHeader,
  FilterWrapper,
  FilterButtons,
} from './index.style'
import { filterConfig, filterComponents } from './config'
import { resetPagination, setSearchText } from 'store/slices/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLitFilterConfig,
  setShouldBlocksIndexes,
} from 'store/slices/literatureSlice'
import {
  resetFilter,
  resetMarkushMoleculeWeight,
  setFilterConfig,
  setLeftFilterLitDocType,
  setOpenFilter,
} from 'store/slices/filterSlice'
import {
  resetSearch,
  setBingoSearch,
  setLiteratureSearch,
  setReactionsSearch,
  setSearchV2,
} from 'store/slices/crudSlice'
import {
  BINGO_PAGINATION_LIMIT,
  LIT_PAGINATION_LIMIT,
} from 'store/sagas/utils/config'

const Filter = memo(({ qtyOnPage }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filterOpen = useSelector((state) => state.filter.open)
  const filters = useSelector((state) => state.filter.config)
  const showHistory = useSelector((state) => state.searchHistory.showHistory)
  const text = useSelector((state) => state.search.searchText)
  const searchType = useSelector((state) => state.search.searchType)

  const filterBodyRef = useRef(null)

  const handleClose = () => dispatch(setOpenFilter(false))

  const handleSetFilters = (state) =>
    !filters.disabled && dispatch(setFilterConfig({ ...filters, ...state }))

  const isStructureSearch = searchType === 'structure'
  const isLiteratureSearch = searchType === 'literature'
  const isReactionsSearch = searchType === 'reaction'

  const handleMatchTypeSelect = (value) => {
    handleSetFilters({ match_type: value })
    //deprecate this
    if (
      value === 'markush structures' ||
      filters.match_type === 'markush structures'
    ) {
      dispatch(setSearchText(''))
      dispatch(resetSearch())
    }
    if (value !== 'markush structures' && !filters.molecular_weight_disabled)
      dispatch(resetMarkushMoleculeWeight())
  }

  const handleSearch = () => {
    dispatch(resetSearch())
    dispatch(resetPagination())
    if (isStructureSearch) {
      filters.match_type === 'exact match'
        ? dispatch(
            setSearchV2({
              text,
              label: text,
            })
          )
        : dispatch(
            setBingoSearch({
              text,
              label: text,
              limit: qtyOnPage?.structure ?? BINGO_PAGINATION_LIMIT,
            })
          )
    } else if (isLiteratureSearch) {
      dispatch(
        setLiteratureSearch({
          limit: qtyOnPage?.literature ?? LIT_PAGINATION_LIMIT,
        })
      )
    } else if (isReactionsSearch) {
      dispatch(setReactionsSearch({ text, limit: qtyOnPage?.reaction }))
    }
  }

  const handleReset = () => {
    if (!filters.disabled) {
      dispatch(resetFilter())
      dispatch(setLitFilterConfig({}))
      if (filters.match_type === 'markush structures') {
        dispatch(setSearchText(''))
        dispatch(resetSearch())
      }

      if (searchType === 'literature') {
        dispatch(setLeftFilterLitDocType(['article', 'patent']))
        dispatch(setShouldBlocksIndexes(INITIAL_TYPES_LIST))
      }
    }
  }

  const filterOptions = useMemo(() => filterConfig[searchType], [searchType])

  return (
    <FilterWrapper opened={filterOpen}>
      <FilterHeader opened={filterOpen}>
        <span>{t('filter.title')}</span>
        <CustomButton gap={'0.25rem'} type={'text'} onClick={handleClose}>
          <Icon iconType={'arrowLeft'} size="1rem" />
          <span>{t('filter.collapse')}</span>
        </CustomButton>
      </FilterHeader>

      <CustomScrollbar
        innerRef={filterBodyRef}
        className="margin-4-right no-inset"
      >
        <FilterBody
          isLiteratureSearch={searchType === 'literature'}
          opened={filterOpen}
          ref={filterBodyRef}
          isDisabled={filters.disabled || showHistory}
        >
          {' '}
          {filterOptions?.map((el, key) => {
            const { type, config } = el
            const isHidden =
              (config.key === 'similarity' &&
                filters.match_type !== 'similar structures') ||
              (config.key === 'molecular_weight' &&
                filters.match_type !== 'markush structures')

            return (
              !isHidden && (
                <div key={key}>
                  {filterComponents[type]({
                    setState: handleSetFilters,
                    state: filters,
                    searchType,
                    config,
                    ...(type === 'filterRadioList'
                      ? {
                          handleSelect: handleMatchTypeSelect,
                          disabled: filters.disabled,
                          id: 'filter-radio-match-type',
                        }
                      : {}),
                  })}
                </div>
              )
            )
          })}
        </FilterBody>
      </CustomScrollbar>
      <FilterButtons opened={filterOpen}>
        <StyledButton
          id="left-filter-del-btn"
          type="secondary"
          onClick={handleReset}
          disabled={filters.disabled}
          gap="0.25rem"
        >
          {t('filter.reset')}
        </StyledButton>
        <StyledButton
          id="left-filter-apply-btn"
          type="secondary"
          onClick={handleSearch}
          disabled={filters.disabled || showHistory}
          gap="0.25rem"
        >
          {t('filter.applyFilters')}
        </StyledButton>
      </FilterButtons>
    </FilterWrapper>
  )
})

Filter.displayName = 'Filter'
export default Filter
