import React from 'react'

const LoaderRestyling = () => {
  return (
    <div className={`loader`}>
      <div className="logo-left" />
      <div className="logo-right" />
      <div className="logo-bottom" />
    </div>
  )
}

LoaderRestyling.displayName = 'LoaderRestyling'
export default LoaderRestyling
