import { FC, memo, useCallback, useEffect, useRef } from 'react'

import { isNotificationClicked } from './utils'
import { OutboundClickProps } from './index.types'
import { KETCHER_OUTBOUND_ID } from 'components/Ketcher/config/config'

export const OutboundClick: FC<OutboundClickProps> = memo(
  ({
    children,
    onClick,
    outboundClickEnabled = true,
    exceptions,
    exceptionalParentIds, //массив id родительского элемента, клики на дочерние элементы которого не вызовут onClick
    onEsc = true,
    outBoundId,
    isPropagate = true,
    className,
  }) => {
    const ref = useRef<HTMLDivElement>(null)
    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (!ref.current) return
        const eventTarget = event.target as HTMLElement
        const tag = eventTarget?.tagName
        const target =
          tag && tag === 'path'
            ? eventTarget?.closest('svg') || eventTarget
            : eventTarget

        const isOutside = (ref: HTMLDivElement | null) => !ref?.contains(target)

        const ketcherMenuSvgClicked =
          outBoundId &&
          outBoundId === KETCHER_OUTBOUND_ID &&
          exceptions?.some((item) => {
            return (
              (typeof target?.className === 'string' &&
                target.className?.includes(item)) ||
              (typeof target?.parentElement?.className === 'string' &&
                target.parentElement.className?.includes(item))
            )
          })

        if (isNotificationClicked(event)) return

        if (exceptions && exceptions.length) {
          if (
            isOutside(ref.current) &&
            !exceptions.some((item) => {
              return (
                target.classList.contains(item) ||
                target.closest(`.${item}`) ||
                ketcherMenuSvgClicked
              )
            }) &&
            (!exceptionalParentIds?.some((exceptionalParentId) =>
              document.getElementById(exceptionalParentId)
            ) ||
              !exceptionalParentIds?.some((exceptionalParentId) =>
                target?.closest(`[id=${exceptionalParentId}]`)
              ))
          ) {
            onClick()
            !isPropagate && event.stopPropagation()
          }
        } else if (isOutside(ref.current)) {
          onClick()
          !isPropagate && event.stopPropagation()
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClick, exceptions]
    )

    const escFunction = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Escape') onEsc && onClick()
      },
      [onClick, onEsc]
    )

    useEffect(() => {
      if (outboundClickEnabled) {
        document.addEventListener('click', handleClickOutside, true)
        document.addEventListener('contextmenu', handleClickOutside, true)
        document.addEventListener('keydown', escFunction, true)
      }
      return () => {
        document.removeEventListener('click', handleClickOutside, true)
        document.removeEventListener('contextmenu', handleClickOutside, true)
        document.addEventListener('keydown', escFunction, true)
      }
    }, [handleClickOutside, escFunction, outboundClickEnabled])

    if (!outboundClickEnabled) {
      return <>{children}</>
    }

    return (
      <div className={className} ref={ref}>
        {children}
      </div>
    )
  }
)

OutboundClick.displayName = 'OutboundClick'
