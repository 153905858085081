import { FC } from 'react'

import { CustomButtonProps } from './index.types'
import { memo } from 'react'
import { CustomButtonElement } from './index.style'
import { EMPTY_HANDLER } from 'config/constants'

const CustomButton: FC<CustomButtonProps> = memo(
  ({
    onClick,
    children,
    active = false,
    disabled = false,
    defaultColor,
    defaultPadding = '',
    width = 'max-content',
    minWidth,
    maxWidth,
    type,
    height = '',
    minHeight,
    gap = '0px',
    className,
    onMouseOver = EMPTY_HANDLER,
    onMouseLeave = EMPTY_HANDLER,
    size = 'standard',
    borderRadius = '',
    bgColor,
    withIcon,
    noAnimation = false,
    id,
    ...props
  }) => (
    <CustomButtonElement
      {...(id ? { id } : {})}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      active={active}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      minHeight={minHeight}
      height={height}
      type={type}
      className={className}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      gap={gap}
      size={size}
      defaultColor={defaultColor}
      defaultPadding={defaultPadding}
      borderRadius={borderRadius}
      bgColor={bgColor}
      withIcon={withIcon}
      noAnimation={noAnimation}
      {...props}
    >
      {children}
    </CustomButtonElement>
  )
)

CustomButton.displayName = 'CustomButton'
export default CustomButton
