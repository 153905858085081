import { put, takeEvery } from 'redux-saga/effects'
import { fetch } from 'services/rest'
import {
  getEnIupacError,
  getEnIupacInit,
  getEnIupacSuccess,
  getRuIupac,
  getRuIupacError,
  getRuIupacSuccess,
} from 'store/slices/smilesIupacSlice'

function* converSmilesToRuIupac({ payload }) {
  const { smiles, lang } = payload || {}

  try {
    const { data } = yield fetch(
      `/smiles2iupac_article?smiles=${smiles}&lang=${lang}`
    )
    if (data.status === 'error') {
      yield put(getRuIupacError(data?.error))
    }
    if (data.status === 'ok') {
      yield put(getRuIupacSuccess(data?.data))
    }
  } catch (error) {
    yield put(getRuIupacError(error?.message))
  }
}

function* converSmilesToEnIupac({ payload }) {
  const { smiles } = payload || {}

  try {
    const { data } = yield fetch(`/smiles2iupac_article?smiles=${smiles}`)
    if (data.status === 'error') {
      yield put(getEnIupacError(data?.error))
    }
    if (data.status === 'ok') {
      yield put(getEnIupacSuccess(data?.data))
    }
  } catch (error) {
    yield put(getEnIupacError(error?.message))
  }
}

export function* converSmilesToRuIupacWatcher() {
  yield takeEvery(getRuIupac.type, converSmilesToRuIupac)
}

export function* converSmilesToEnIupacWatcher() {
  yield takeEvery(getEnIupacInit.type, converSmilesToEnIupac)
}
