import { FC, memo } from 'react'
import ReactDom from 'react-dom'
import { useTheme } from 'styled-components'
import Icon from 'components/Icon'
import {
  Dialog,
  Backdrop,
  StyledButton,
  CustomDialogHeader,
} from './index.style'
import { CustomDialogProps } from './index.types'
import { CustomCloseIcon } from '../customCloseIcon'
import { OutboundClick } from '../outboundClick/outboundClick'

const CustomDialog: FC<CustomDialogProps> = memo(
  ({
    onClose,
    outboundClickEnabled = true,
    closeByEsc = true,
    width,
    height,
    maxHeight,
    padding,
    children,
    outboundExceptions,
    exceptionalParentIds,
    gap,
    hidden,
    overflowY,
    showCloseIcon = true,
    position = 'absolute',
    minWidth,
    outBoundId,
    pos = 'relative',
    right = 'auto',
    left = 'auto',
    top = 'auto',
    bottom = 'auto',
    withPortal = false,
    isPropagate,
    withBackdrop = true,
    onBodyClick,
    zIndex,
    align,
    id = 'custom-dialog',
  }) => {
    const theme = useTheme()
    return withPortal ? (
      ReactDom.createPortal(
        (
          <Backdrop {...{ hidden, position, withBackdrop, zIndex }}>
            <OutboundClick
              {...{
                outboundClickEnabled,
                exceptionalParentIds,
                outBoundId,
                isPropagate,
              }}
              onClick={onClose}
              onEsc={closeByEsc}
              exceptions={outboundExceptions}
            >
              <Dialog
                data-test="custom-dialog-modal"
                {...{
                  id,
                  gap,
                  pos,
                  align,
                  width,
                  height,
                  padding,
                  minWidth,
                  maxHeight,
                  overflowY,
                }}
                onClick={onBodyClick}
              >
                {showCloseIcon && (
                  <CustomCloseIcon
                    // @ts-ignore
                    className="custom-close-icon"
                    onClick={onClose}
                  />
                )}
                {children}
              </Dialog>
            </OutboundClick>
          </Backdrop>
        ) as any,
        document.body
      )
    ) : (
      <Backdrop {...{ hidden, position, withBackdrop }}>
        <OutboundClick
          {...{ outboundClickEnabled, exceptionalParentIds, outBoundId }}
          onClick={onClose}
          onEsc={closeByEsc}
          exceptions={outboundExceptions}
        >
          <Dialog
            data-test="custom-dialog-modal"
            {...{
              id,
              pos,
              gap,
              top,
              left,
              width,
              right,
              height,
              bottom,
              padding,
              minWidth,
              maxHeight,
              overflowY,
            }}
          >
            {showCloseIcon && (
              <StyledButton
                size="big"
                onClick={onClose}
                type="icon"
                defaultColor={theme.colors.icons.tertiary}
              >
                {/* @ts-ignore */}
                <Icon
                  iconType={'close'}
                  size="1.25rem"
                  data-test="close-icon"
                />
              </StyledButton>
            )}
            {children}
          </Dialog>
        </OutboundClick>
      </Backdrop>
    )
  }
)

CustomDialog.displayName = 'CustomDialog'

export { CustomDialogHeader, CustomDialog }
