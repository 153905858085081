import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Icon from 'components/Icon'

import {
  CardBody,
  FeedbackType,
  CardFooterDateBlock,
  CardFooterDateItem,
  AttachmentWrapper,
  CardInnerWrapper,
  CardInnerHeader,
  CardInnerTags,
  CardInnerDate,
  PDFAttach,
  ImageShowButton,
  FullImageContainer,
  ImagesContainer,
  AttachImage,
  AttachImagesBlock,
  FullImage,
  EmailWithIcon,
} from './index.style'

import {
  Caption,
  Label,
  TextLarge,
  TitleSecondary,
  TitleTertiary,
} from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import { modules, themeOptions } from 'components/Feedback/FeedbackForm/config'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import React, { useState } from 'react'
import { CustomDialog } from 'components/common/customDialog'
import { FilesScrollbarWrapper } from 'components/Feedback/FeedbackForm/index.style'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'
import { copyToClipboard } from 'utils/common/common'
import { useDispatch } from 'react-redux'
import { addSimpleNotification } from 'store/slices/notificationsSlice'

const FeedbackCardInner = ({ card }) => {
  const {
    comment,
    ishandled,
    user_id,
    user_email,
    type_id,
    attach,
    created_at,
    module_ids,
    feedback_id,
    handleClose,
  } = card || {}
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedImage, setSelectedImage] = useState(null)
  const isPDFAttach = attach?.[0]?.original_file_name?.includes('.pdf')
  const feedback_theme = themeOptions.find(
    (option) => option.id === type_id
  )?.label

  const handleOpenFeedback = () => {}
  const handleDownload = () => {
    const a = document.createElement('a')
    a.setAttribute('download', `${attach?.[0]?.original_file_name}`)
    a.setAttribute('href', attach?.[0]?.file_url)
    a.click()
  }

  const handleCopyEmail = () => {
    copyToClipboard(user_email)
    dispatch(addSimpleNotification({ text: 'account.email_copied' }))
  }

  return (
    <>
      <CustomScrollbar>
        <CardInnerWrapper onClick={handleOpenFeedback}>
          <CardInnerHeader>
            <CardInnerTags>
              {feedback_theme && (
                <>
                  <FeedbackType
                    withBorder={true}
                    borderColor={theme.colors.backgrounds.accent}
                    maxWidth="40%"
                    data-tooltip-id={`feedback-theme-tooltip-${feedback_id}`}
                  >
                    <Caption
                      color={theme.colors.text.accentPrimary}
                      ellipsis={true}
                    >
                      {t(feedback_theme)}
                    </Caption>
                  </FeedbackType>
                  <ReactTooltip
                    id={`feedback-theme-tooltip-${feedback_id}`}
                    className={`c-tooltip c-tooltip-base c-tooltip-toggle`}
                    classNameArrow="c-tooltip-arrow"
                    place="top"
                  >
                    {t(feedback_theme)}
                  </ReactTooltip>
                </>
              )}

              {!!module_ids?.length &&
                module_ids?.map((module_id) => {
                  const module = modules.find(
                    (module) => module.id === module_id
                  )
                  return (
                    <>
                      <FeedbackType
                        withBorder={true}
                        color={theme.colors.text.primary}
                        background={theme.colors.backgrounds.secondary}
                        maxWidth={module_ids?.length > 1 ? '40%' : '50%'}
                        data-tooltip-id={`feedback-module-tooltip-${feedback_id}-${module_id}`}
                      >
                        <Caption ellipsis={true}>{t(module?.label)}</Caption>
                      </FeedbackType>
                      <ReactTooltip
                        id={`feedback-module-tooltip-${feedback_id}-${module_id}`}
                        className={`c-tooltip c-tooltip-base c-tooltip-toggle`}
                        classNameArrow="c-tooltip-arrow"
                        place="top"
                      >
                        {t(module?.label)}
                      </ReactTooltip>
                    </>
                  )
                })}
            </CardInnerTags>
            <EmailWithIcon>
              <TitleSecondary color={theme.colors.text.primary} ellipsis={true}>
                {user_email}
              </TitleSecondary>
              <CustomButton
                onClick={handleCopyEmail}
                type="icon_secondary"
                bgColor="transparent"
                width="1.25rem"
              >
                <Icon iconType="copy" size="1.25rem" />
              </CustomButton>
            </EmailWithIcon>
            <CardInnerDate>
              {created_at && (
                <CardFooterDateBlock>
                  <CardFooterDateItem>
                    <Icon iconType="calendar_2" size="1rem" />
                    <Caption
                      color={theme.colors.text.secondary}
                      fontWeight={theme.fontWeight.medium}
                    >
                      {formatUtcDigitDateByFormat(
                        new Date(created_at),
                        'dd.MM.yyyy'
                      )}
                    </Caption>
                  </CardFooterDateItem>
                  <CardFooterDateItem>
                    <Icon iconType="timer" size="1rem" />
                    <Caption
                      color={theme.colors.text.secondary}
                      fontWeight={theme.fontWeight.medium}
                    >
                      {formatUtcDigitDateByFormat(
                        new Date(created_at),
                        'HH:mm'
                      )}
                    </Caption>
                  </CardFooterDateItem>
                </CardFooterDateBlock>
              )}

              {!!attach?.length && (
                <AttachmentWrapper>
                  <Icon
                    iconType={isPDFAttach ? 'fileIcon' : 'imageIcon'}
                    size="1rem"
                  />
                  <Caption color={theme.colors.text.secondary}>
                    {isPDFAttach
                      ? `PDF (${attach?.length})`
                      : `JPEG/PNG (${attach?.length})`}
                  </Caption>
                </AttachmentWrapper>
              )}
            </CardInnerDate>
          </CardInnerHeader>
          <CardBody>
            <TextLarge
              fontWeight={theme.fontWeight.medium}
              color={theme.colors.text.secondary}
            >
              {comment}
            </TextLarge>
          </CardBody>
          {isPDFAttach ? (
            <PDFAttach>
              <Icon
                iconType="fileIcon"
                size="1.5rem"
                style={{ minWidth: '1.5rem' }}
              />
              <Label fontWeight={theme.fontWeight.semibold} name="ellipsis">
                {attach?.[0]?.original_file_name}
              </Label>

              <CustomButton
                type="primary"
                onClick={handleDownload}
                style={{ marginLeft: 'auto' }}
              >
                <Icon iconType="upload" size="1rem" />
                {t('molecule_viewer.download')}
              </CustomButton>
            </PDFAttach>
          ) : (
            <FilesScrollbarWrapper
              isOnlyPDF={false}
              isScrollLeft={true}
              isScrollRight={false}
            >
              <CustomScrollbar
                noScrollY={true}
                noScrollX={false}
                disableTrackXWidthCompensation={true}
                height="8em"
              >
                <AttachImagesBlock>
                  {attach?.map((item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <ImagesContainer>
                          <AttachImage
                            id={`attach-img-${item?.original_file_name}`}
                            src={item?.file_url}
                            alt={item?.original_file_name}
                            onClick={() => setSelectedImage(item)}
                          />
                          <ImageShowButton
                            className="feedback-image-view"
                            onClick={() => setSelectedImage(item)}
                          >
                            <Icon iconType="eyeOpen" size="1.25rem" />
                          </ImageShowButton>
                        </ImagesContainer>
                      </React.Fragment>
                    )
                  })}
                </AttachImagesBlock>
              </CustomScrollbar>
            </FilesScrollbarWrapper>
          )}
        </CardInnerWrapper>
        {selectedImage && (
          <CustomDialog
            onClose={() => setSelectedImage(null)}
            gap="24px"
            width="800px"
            height="90vh"
            withPortal={true}
          >
            <FullImageContainer>
              <TitleTertiary fontWeight={theme.fontWeight.semibold}>
                {t('common.imgs_view')}
              </TitleTertiary>
              <FullImage>
                <img
                  id={`attach-img-${selectedImage?.original_file_name}`}
                  src={selectedImage?.file_url}
                  alt={selectedImage?.original_file_name}
                />
                <CustomButton
                  type="primary"
                  onClick={handleDownload}
                  style={{
                    position: 'absolute',
                    right: '0.5rem',
                    top: '0.5rem',
                    marginLeft: 'auto',
                  }}
                >
                  <Icon iconType="upload" size="1rem" />
                  {t('molecule_viewer.download')}
                </CustomButton>
              </FullImage>
            </FullImageContainer>
          </CustomDialog>
        )}
      </CustomScrollbar>
    </>
  )
}

export default FeedbackCardInner
