import { FC } from 'react'

import { CustomTabProps } from './index.types'
import { CustomTabElement } from './index.style'

const CustomTab: FC<CustomTabProps> = ({
  onClick,
  children,
  disabled = false,
  className,
  size = 'standard', //small, xs
  type = 'white',
  isActive = false,
  width = '',
  minWidth,
  height = '',
  ...props
}) => (
  <CustomTabElement
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
    size={size}
    type={type}
    className={className}
    isActive={isActive}
    width={width}
    minWidth={minWidth}
    height={height}
    {...props}
  >
    {children}
  </CustomTabElement>
)

export default CustomTab
