import { push } from 'connected-react-router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route } from 'react-router-dom'
import { readStorage } from 'utils/storage/storage'
import history from '../services/history'
import { useAuth } from '../utils/auth/auth'

const AdminRoute = (props) => {
  const [isAdmin, setIsAdmin] = useState('')

  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    const adminRole = readStorage('role')
    const isAdmin = adminRole === 'customer_admin' || adminRole === 'syn_admin'
    setIsAdmin(isAdmin)
    if (!isAdmin) {
      dispatch(push('/'))
    }
    if (!isAuthenticated) {
      const prevPath =
        history.location.pathname === '/login'
          ? history.location.state?.prevPath
          : history.location.pathname
      history.push('/login', { prevPath })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const { component: Component } = props

  return isAdmin ? <Route component={Component} {...props} /> : null
}

export default AdminRoute
