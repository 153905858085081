import { FC, memo } from 'react'

import { SkeletonProps } from './index.types'
import { SkeletonWrapper } from './index.style'

const SkeletonComponent: FC<SkeletonProps> = memo(
  ({ width, minWidth, height, variant = 'light', borderRadius, margin }) => {
    return (
      <SkeletonWrapper
        {...{ width, height, minWidth, variant, borderRadius, margin }}
      />
    )
  }
)

SkeletonComponent.displayName = 'SkeletonComponent'
export default SkeletonComponent
