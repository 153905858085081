import { useSpring } from 'react-spring'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Icon from 'components/Icon'
import handleCancel from 'components/Notifications/utils/utils'
import {
  NOTIFICATION_INNER,
  AUTO_REMOVED_SUCCESS_TASKS,
} from 'components/Notifications/config/config.js'

import {
  CloseBtn,
  NotificationElem,
  NotificationInner,
} from '../../index.style'
import { removeSynthWarningId } from 'store/slices/synthcostSlice'
import { removeTask } from 'store/slices/tasksSlice'
import { removeLastActionsIDsFromDB } from 'store/slices/basketSlice'
import { removeNotification } from 'store/slices/notificationsSlice'

const NotificationItem = memo(
  ({ item, itemsCompressed, className, isOnlyOne, setIsActionsVisible }) => {
    const {
      notification_type,
      autoRemove,
      timeout,
      uuid,
      progress,
      status,
      params,
    } = item || {}
    const { actionType, deleteIDs } = params || {}

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const synthcost_warning_ids = useSelector(
      (state) => state.synthcost.warning_ids
    )
    const [isNeedToDelete, setIsNeedToDelete] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const [isRemoved, setIsRemoved] = useState(false)

    const isLongTaskFinished =
      notification_type === 'progress' && progress === 100 && status === 'ok'
    const isLongTaskWarning =
      isLongTaskFinished && synthcost_warning_ids?.some((id) => id === uuid)
    const isLongTaskError =
      notification_type === 'progress' && status === 'error'

    const isCanceledTask =
      isLongTaskFinished &&
      AUTO_REMOVED_SUCCESS_TASKS.some((el) => t(el) === item?.name)

    const removeNotLastNotification = ({ withConfirm }) => {
      // progress task is in progress
      if (notification_type === 'progress') {
        if (withConfirm) setIsNeedToDelete(true)
        // progress task is ready or with error
        else if ((isLongTaskFinished || isLongTaskError) && uuid) {
          handleCancel(item)
          isLongTaskWarning && dispatch(removeSynthWarningId(uuid))
          setTimeout(() => dispatch(removeTask(item.id)), 500)
        }
      }
      // not progress task
      else {
        dispatch(removeNotification(item.id))
      }
    }

    const removeLastNotification = ({ withConfirm }) => {
      if (notification_type === 'progress') {
        if (withConfirm) setIsNeedToDelete(true)
        else if ((isLongTaskFinished || isLongTaskError) && uuid) {
          setIsActionsVisible(false)
          // wait some time for buttons hiding
          setTimeout(() => {
            setIsRemoved(true)
            isLongTaskWarning && dispatch(removeSynthWarningId(uuid))
            handleCancel(item)
            // wait some time for transition and then remove task
            setTimeout(() => dispatch(removeTask(item.id)), 500)
          }, 200)
        }
      } else {
        setIsActionsVisible(false)
        // wait some time for buttons hiding
        setTimeout(() => {
          setIsRemoved(true)
          // wait some time for transition and then remove notification
          setTimeout(() => dispatch(removeNotification(item.id)), 500)
        }, 200)
      }
    }

    const handleRemove = ({ withConfirm }) => {
      setIsClicked(true)
      isOnlyOne
        ? removeLastNotification({ withConfirm })
        : removeNotLastNotification({ withConfirm })
    }

    useEffect(() => {
      if (autoRemove) {
        setTimeout(() => {
          if (
            (actionType === 'baskets-delete' ||
              actionType === 'baskets-join') &&
            deleteIDs?.length
          ) {
            dispatch(removeLastActionsIDsFromDB(deleteIDs))
            //тотальное удаление id баскетов из БД,
            //при простом удалении/объединении через интерфейс,
            //они на самом деле скрываются, но не удаляются
          }
          dispatch(removeNotification(item.id))
        }, timeout || 5000)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [inViewRef, inView] = useInView({
      threshold: 0.5,
      triggerOnce: false,
    })

    const springProps = useSpring({
      opacity: inView ? 1 : 0.2,
      config: { duration: 100 },
    })

    if (isCanceledTask) return null

    return (
      <NotificationElem
        ref={inViewRef}
        data-test="notification-element-div"
        className={className}
        style={itemsCompressed ? {} : springProps}
        data-is-removed={isRemoved}
      >
        {(item.notification_type !== 'progress' ||
          isLongTaskFinished ||
          isLongTaskError ||
          item.notification_type === 'success') && (
          <CloseBtn
            disabled={isClicked}
            data-test="close-notify-btn"
            onClick={handleRemove}
            className="close-notify-btn"
          >
            <Icon iconType="close" />
          </CloseBtn>
        )}
        <NotificationInner>
          {item.notification_type
            ? NOTIFICATION_INNER[item.notification_type]({
                item,
                setIsNeedToDelete,
                isNeedToDelete,
                handleRemove,
                setIsClicked,
                setIsRemoved,
              })
            : null}
        </NotificationInner>
      </NotificationElem>
    )
  }
)

NotificationItem.displayName = 'NotificationItem'
export default NotificationItem
