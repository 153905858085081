import { memo } from 'react'
import {
  Condition,
  ConditionHeader,
  ConditionHeaderTitle,
  ControlButtons,
  TrashIcon,
} from './index.style'
import { PlusIcon } from '../../index.style'
import { useTranslation } from 'react-i18next'
import { CONFLICT_OPTIONS, LANGUAGES_OPTIONS } from '../../constants'
import CustomButton from 'components/common/customButton'
import { useDispatch } from 'react-redux'
import FilterRow from '../FilterRow'
import { Subheadline } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import {
  setLitFilterConfig,
  setShouldBlocksIndexes,
} from 'store/slices/literatureSlice'
import { useSelector } from 'react-redux'
import { setLeftFilterLitDocType } from 'store/slices/filterSlice'

const ConditionBlock = memo(({ currentElement, parentIndex }) => {
  const config = useSelector((state) => state.literature.filter.config)
  const docType = useSelector((state) => state.literature.filter.docType)
  const shouldBlocks = useSelector(
    (state) => state.literature.filter.shouldBlocksIndexes
  )
  const theme = useTheme()
  const disabledAddButton =
    currentElement === 'published_date' ||
    (currentElement === 'language' &&
      LANGUAGES_OPTIONS.length === config?.language?.values.length) ||
    (currentElement !== 'name' &&
      config[currentElement].values.length === 1 &&
      config[currentElement].values[0] === '')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDeleteRow = (index) => {
    if (index === 0) {
      const configCopy = structuredClone(config)
      delete configCopy[currentElement]
      if (!shouldBlocks.includes(currentElement)) {
        dispatch(setShouldBlocksIndexes([...shouldBlocks, currentElement]))
      }
      dispatch(setLitFilterConfig(configCopy))

      if (
        !Object.keys(configCopy).length ||
        !Object.keys(configCopy).find(
          (el) =>
            CONFLICT_OPTIONS.patent.includes(el) ||
            CONFLICT_OPTIONS.article.includes(el)
        )
      ) {
        dispatch(setLeftFilterLitDocType(['article', 'patent']))
      }
    } else {
      const currentTypeObject = config[currentElement]
      const newValues = config[currentElement].values
      let notValues = config[currentElement]?.not_values || []

      notValues = notValues.filter(val => val !== index)

      notValues = notValues.map((notIndex) =>
        notIndex > index ? notIndex - 1 : notIndex
      )

      dispatch(
        setLitFilterConfig({
          ...config,
          [currentElement]: {
            ...currentTypeObject,
            values: [
              ...newValues.slice(0, index),
              ...newValues.slice(index + 1),
            ],
            not_values: notValues,
          },
        })
      )
    }
  }

  const handleAddRow = () => {
    const currentTypeObject = config[currentElement]
    const newValues = config[currentElement].values
    if (
      currentElement !== 'name' &&
      newValues.length === 1 &&
      newValues[0] === ''
    )
      return

    dispatch(
      setLitFilterConfig({
        ...config,
        [currentElement]: {
          ...currentTypeObject,
          values: [...newValues, ''],
        },
      })
    )
  }

  return (
    <Condition>
      <ConditionHeader>
        <ConditionHeaderTitle>
          {currentElement === 'name'
            ? t(`filter.literature.${docType}`)
            : currentElement === 'company'
            ? t(`filter.literature.owner`)
            : t(`filter.literature.${currentElement}`)}
        </ConditionHeaderTitle>
        <ControlButtons>
          {currentElement !== 'name' &&
            currentElement !== 'text' &&
            currentElement !== 'claims' && (
              <CustomButton
                type="text"
                onClick={() => handleAddRow()}
                disabled={disabledAddButton}
              >
                <PlusIcon iconType="add" marginright="4px" size="1rem" />
                <Subheadline color="inherit">
                  {t('filter.literature.add')}
                </Subheadline>
              </CustomButton>
            )}
          <CustomButton type="text" onClick={() => handleDeleteRow(0)}>
            <TrashIcon iconType="deleted" size="1rem" marginRight="0.25rem" />
            <Subheadline color={theme.colors.statuses.error}>
              {t('filter.literature.delete_condition')}
            </Subheadline>
          </CustomButton>
        </ControlButtons>
      </ConditionHeader>
      <FilterRow
        currentElement={currentElement}
        handleDeleteRow={handleDeleteRow}
      />
    </Condition>
  )
})

ConditionBlock.displayName = 'ConditionBlock'
export default ConditionBlock
