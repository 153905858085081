import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { CustomDialog } from 'components/common/customDialog'
import Icon from 'components/Icon'
import { TextContent } from './index.style'
import { TextMedium, TitleTertiary } from 'components/common/text/index.style'
import CustomButton from 'components/common/customButton'
import { useAuth } from 'utils/auth/auth'
import { closeFeedbackForm } from 'store/slices/feedbackSlice'
import { setIsSessionInterrupted } from 'store/slices/settingsSlice'
import { cancel } from 'store/slices/confirmationSlice'

const InterruptedSessionWindow = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { logout } = useAuth()
  const isSessionInterrupted = useSelector(
    (state) => state.settings.isSessionInterrupted
  )

  const closeWindow = () => {
    logout()
    dispatch(cancel())
    dispatch(closeFeedbackForm())
    dispatch(setIsSessionInterrupted(false))
  }

  if (!isSessionInterrupted) return null

  return (
    <CustomDialog
      width={'25.5rem'}
      withPortal={true}
      gap="1.5rem"
      onClose={() => {}}
      closeByEsc={false}
      showCloseIcon={false}
      outboundClickEnabled={false}
      align={'center'}
    >
      <Icon iconType="warningDark" size={'4rem'} />
      <TextContent>
        <TitleTertiary
          fontWeight={theme.fontWeight.semibold}
          textAlign={'center'}
        >
          {t('interrupted_session.login_title')}
        </TitleTertiary>
        <TextMedium
          fontWeight={theme.fontWeight.light}
          color={theme.colors.text.secondary}
          textAlign={'center'}
        >
          {t('interrupted_session.description')}
        </TextMedium>
      </TextContent>
      <CustomButton
        type="secondary"
        width={'100%'}
        onClick={closeWindow}
        size={'big'}
      >
        {t('interrupted_session.logout_from_account')}
      </CustomButton>
    </CustomDialog>
  )
}

export default InterruptedSessionWindow
