import { createSlice } from '@reduxjs/toolkit'
import { INIT_QTY_ON_PAGE } from './settingsSlice'

export const initialState = {
  pagination: {
    pagesAmount: 0,
    total: 0,
    perPage: INIT_QTY_ON_PAGE,
    activePage: 1,
  },
  searchType: 'structure',
  sorting: {
    type: 'relevance',
    direction: 'desc',
  },
  searchText: '',
  drawnSmiles: '',
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setPaginationConfig: (state, action) => {
      state.pagination = { ...action.payload }
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload
    },
    setSearchSortingParam: (state, action) => {
      const { key, value } = action.payload || {}
      if (!state.sorting) state.sorting = { ...initialState.sorting }
      state.sorting[key] = value
    },
    setSearchSortingConfig: (state, action) => {
      state.sorting = action.payload
    },
    resetSearchSortingConfig: (state) => {
      state.sorting = { ...initialState.sorting }
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setDrawnSmiles: (state, action) => {
      state.drawnSmiles = action.payload
    },
    updateSearchText: (state, action) => {
      state.drawnSmiles = action.payload
    },
    updateSearchState: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const {
  setPaginationConfig,
  resetPagination,
  setSearchType,
  setSearchSortingParam,
  setSearchSortingConfig,
  resetSearchSortingConfig,
  setSearchText,
  setDrawnSmiles,
  updateSearchText,
  updateSearchState,
} = searchSlice.actions

export default searchSlice.reducer
