import axios from 'axios'
import { t } from 'i18next'
import { fetch, getHeaders, store } from 'services/rest'
import { takeEvery, put, delay } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { API_URL } from '../../config/config'
import { reactionEncode } from '../../utils/reactions/reactions'
import {
  calcReaction,
  calcRetro,
  calcSynth,
  reactionCalcFail,
  reactionCalcSuccess,
} from 'store/slices/reactionsSlice'
import { addNotification } from 'store/slices/notificationsSlice'

function* doCalculate({ mode, data, model, naug, beam }) {
  const apiUrl = `${API_URL}apply_transformer`
  const smiles = data.join('.')

  try {
    const resp = yield axios.post(
      apiUrl,
      { smiles, model, naug, beam },
      {
        headers: getHeaders(),
      }
    )
    let reactions
    if (mode === 'forward') {
      reactions = resp.data.map((reaction) => {
        let rightSide = reaction.products.map((product) => product.smiles)
        return { ...reaction, encoded: reactionEncode(data, rightSide) }
      })
    } else if (mode === 'reverse') {
      reactions = resp.data.map((reaction) => {
        let leftSide = reaction.products.map((product) => product.smiles)
        return { ...reaction, encoded: reactionEncode(leftSide, data) }
      })
    }

    yield put(reactionCalcSuccess(reactions))
  } catch (err) {
    yield put(reactionCalcFail(err))
  }
}

function* calculateSynth({ payload }) {
  const { data, model, naug, beam } = payload || {}
  const smiles = data.join('.')
  try {
    const response = yield store(`${API_URL}apply_transformer`, {
      smiles,
      model,
      naug,
      beam,
    })

    if (response?.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response?.data?.task_uuid
        const tasks = yield fetch(`/userTasksStatus`)
        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          if (task?.status === 'ok') {
            status = 'ok'
            const resp = yield fetch(
              `/userTasksStatus/${response.data.task_uuid}`
            )

            if (resp.data.result[0].products[0].smiles.includes('fail'))
              yield put(reactionCalcSuccess([]))
            else {
              let reactions = resp.data.result.map((reaction) => {
                let rightSide = reaction.products.map(
                  (product) => product.smiles
                )
                return { ...reaction, encoded: reactionEncode(data, rightSide) }
              })

              yield put(reactionCalcSuccess(reactions))
            }
          }
        }
        yield delay(2000)
      }
    }
  } catch (error) {
    const err =
      error?.response?.data?.detail ||
      t('reactions_prediction.synthesis_failed')
    yield put(reactionCalcFail(err))
  }
}

function* calculateRetro({ payload }) {
  const { smiles } = payload || {}
  try {
    const response = yield store(`${API_URL}run-task`, {
      service: 'reactions_tree',
      params: {
        smiles,
      },
      type: 'delayed',
    })
    if (response?.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response?.data?.task_uuid
        const tasks = yield fetch(`/userTasksStatus`)
        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          if (task?.status === 'ok') {
            status = 'ok'
            const res = yield fetch(
              `/userTasksStatus/${response.data.task_uuid}`
            )
            yield put(reactionCalcSuccess(res?.data?.result))
          }
        }
        yield delay(2000)
      }
    }
  } catch (error) {
    const err =
      error?.response?.data?.detail ||
      t('reactions_prediction.retrosynthesis_failed')
    yield put(reactionCalcFail(err))

    const id = uuidv4()
    const isSmilesError =
      error?.response?.data?.result?.error_message?.endsWith('is not a smiles')

    const notify = {
      id,
      name: isSmilesError
        ? 'notification.we_couldnt_find_desired_structure'
        : 'notification.retrosynthesis_failed',
      text: isSmilesError
        ? 'notification.check_your_spelling_or_enter_SMILES'
        : 'reactions_prediction.retrosynthesis_failed',
      notification_type: 'warning',
      autoRemove: true,
      timeout: 5000,
    }

    yield put(addNotification(notify))
  }
}

export function* doCalculateWatcher() {
  yield takeEvery(calcReaction.type, doCalculate)
}

export function* calculateSynthWatcher() {
  yield takeEvery(calcSynth.type, calculateSynth)
}

export function* calculateRetroWatcher() {
  yield takeEvery(calcRetro.type, calculateRetro)
}

const watchers = [
  doCalculateWatcher(),
  calculateSynthWatcher(),
  calculateRetroWatcher(),
]
export default watchers
