import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  loading: false,
  error: '',
  history: [],
  pastResult: null,
  showHistory: false,
  historyFilter: 'all',
}

const searchHistorySlice = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {
    getSearchHistory: (state) => {
      state.loading = true
    },
    getSearchHistorySuccess: (state, action) => {
      state.loading = false
      state.history = action.payload
    },
    getSearchHistoryError: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    repeatSearch: (state, action) => {
      const { showPastResult, data } = action.payload || {}
      state.loading = false
      state.pastResult = showPastResult ? data : null
    },
    resetPastResult: (state) => {
      state.loading = false
      state.error = false
      state.pastResult = null
    },
    openSearchHistory: (state, action) => {
      state.showHistory = action.payload
    },
    setHistoryFilter: (state, action) => {
      state.historyFilter = action.payload
    },
  },
})

export const {
  getSearchHistory,
  getSearchHistorySuccess,
  getSearchHistoryError,
  repeatSearch,
  resetPastResult,
  openSearchHistory,
  setHistoryFilter,
} = searchHistorySlice.actions

export default searchHistorySlice.reducer
