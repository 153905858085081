import { put, select, takeEvery } from 'redux-saga/effects'
import { storeV2 } from 'services/rest'
import { readStorage } from 'utils/storage/storage'
import { v4 as uuidv4 } from 'uuid'
import { getISOTimeZoneDate } from './utils/utils'
import {
  addNewCompany,
  addNewCompanyFail,
  addNewCompanySuccess,
  closeApplicationDialog,
  currentCustomerEditSuccess,
  currentCustomerLicenseToggleSuccess,
  currentCustomerLicenseUserToggleSuccess,
  customerEditSuccess,
  customerUpdate,
  getApplications,
  getApplicationsFail,
  getApplicationsSuccess,
  getCurrentCustomer,
  getCurrentCustomerSuccess,
  getCurrentCustomerUsers,
  getCurrentCustomerUsersFail,
  getCurrentCustomerUsersSuccess,
  getCustomers,
  getCustomersFail,
  getCustomersSuccess,
  getUsers,
  getUsersFail,
  getUsersSuccess,
  licenseToggleAccess,
  licenseToggleAccessSuccess,
  licenseUserToggleAccess,
  licenseUserToggleAccessSuccess,
  refreshCustomerToken,
  removeUserFromCompany,
  removeUserFromCompanySuccess,
  setAddCompanyStage,
  setApplicationsPaginationConfig,
  setCurrentCustomerUsersPaginationConfig,
  setCustomersPaginationConfig,
  setUsersPaginationConfig,
  updateApplication,
  updateApplicationFail,
  updateApplicationSuccess,
  updateCurrentCustomerLicenseUsers,
} from 'store/slices/adminPanelSlice'
import {
  addNotification,
  addSimpleNotification,
} from 'store/slices/notificationsSlice'

function* getCustomersFn({ payload }) {
  const { limit, offset } = payload || {}
  try {
    const {
      status,
      input_value,
      license_started_at_from,
      license_started_at_to,
      license_expired_at_from,
      license_expired_at_to,
    } = yield select((state) => state.adminPanel.filter.customers) || {}
    const prepared_filters = {
      ...status?.filter_config,
      ...(input_value ? { name: input_value } : {}),
      ...(license_started_at_from ? { license_started_at_from } : {}),
      ...(license_started_at_to ? { license_started_at_to } : {}),
      ...(license_expired_at_from ? { license_expired_at_from } : {}),
      ...(license_expired_at_to ? { license_expired_at_to } : {}),
    }

    const { data } = yield storeV2('customer/get', {
      params: {
        limit,
        offset,
        ...(Object.keys(prepared_filters)?.length > 0
          ? { filter: prepared_filters }
          : {}),
      },
    })
    yield put(getCustomersSuccess({ total: data?.total, items: data?.items }))

    yield put(
      setCustomersPaginationConfig({
        pagesAmount: Math.ceil(data?.total / limit),
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      })
    )
  } catch (e) {
    console.log(e)
    yield put(getCustomersFail())

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_companies_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* getCurrentCustomerFn({ payload }) {
  const isSynAdmin = payload || {}
  try {
    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')
    const { data } = yield storeV2('customer/get', {
      params: {
        filter: { ids: [isSynAdmin ? currentCustomerId : storageCustomerId] },
      },
    })

    yield put(getCurrentCustomerSuccess(data?.items))
  } catch (e) {
    console.log(e)
  }
}

function* getUsersFn({ payload }) {
  const { limit, offset } = payload || {}
  try {
    const { activity_id_list, status, input_value } = yield select(
      (state) => state.adminPanel.filter.users
    ) || {}
    const prepared_filters = {
      ...status?.filter_config,
      ...(input_value
        ? { email: input_value, name: input_value, surname: input_value }
        : {}),
      ...(activity_id_list?.length
        ? { activity_id_list: activity_id_list.map((i) => i.value) }
        : {}),
    }

    const { data } = yield storeV2('user/get', {
      params: {
        limit,
        offset,
        ...(Object.keys(prepared_filters)?.length > 0
          ? { filter: prepared_filters }
          : {}),
      },
    })
    yield put(
      getUsersSuccess({
        items: data?.result?.items,
        total: data?.result?.total,
      })
    )

    yield put(
      setUsersPaginationConfig({
        pagesAmount: Math.ceil(data?.result?.total / limit),
        total: data?.result?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      })
    )
  } catch (e) {
    console.log(e)
    yield put(getUsersFail())

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_users_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* getApplicationsFn({ payload }) {
  const { limit, offset, isFirstRequest } = payload || {}
  try {
    let notHandledTotal = null
    const { data } = yield storeV2('license/request/get', {
      params: { limit, offset },
    })

    if (isFirstRequest) {
      const { data: notHandledData } = yield storeV2('license/request/get', {
        params: {
          limit: 1,
          offset: 0,
          filter: {
            is_handled: null,
          },
        },
      })
      notHandledTotal = notHandledData?.total
    }

    yield put(
      getApplicationsSuccess({
        total: data?.total,
        items: data?.items,
        isFirstRequest,
        notHandledTotal,
      })
    )

    yield put(
      setApplicationsPaginationConfig({
        pagesAmount: Math.ceil(data?.total / limit),
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      })
    )
  } catch (e) {
    console.log(e)
    yield put(getApplicationsFail())

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_applications_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* updateApplicationFn({ payload }) {
  const { id, value, user_id, status } = payload || {}
  try {
    yield storeV2('license/request/update', {
      params: { id_: id, is_handled: value },
    })

    yield put(
      updateApplicationSuccess({ id, value, needUpdateTotal: status === null })
    )
    yield put(closeApplicationDialog())

    // если во вкладке с юзерами открыта страница, где есть юзер, заявка которого одобрена/отклонена, надо обновить данные
    const { items: users, pagination: usersPagination } = yield select(
      (state) => state.adminPanel.users
    )

    if (users?.some((el) => el.user_id === user_id)) {
      yield put(
        getUsers({
          limit: usersPagination.perPage,
          offset: (usersPagination.activePage - 1) * usersPagination.perPage,
        })
      )
    }
  } catch (e) {
    console.log(e)
    yield put(updateApplicationFail())

    const id = uuidv4()
    const notify = {
      id,
      name: value
        ? 'admin.approve_application_error'
        : 'admin.reject_application_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* getCurrentCustomerUsersFn({ payload }) {
  const { isSynAdmin, limit, offset } = payload
  try {
    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')

    const { data } = yield storeV2('user/get', {
      params: {
        limit,
        offset,
        filter: {
          customer_id: isSynAdmin ? currentCustomerId : storageCustomerId,
        },
      },
    })
    yield put(getCurrentCustomerUsersSuccess(data?.result?.items))

    yield put(
      setCurrentCustomerUsersPaginationConfig({
        pagesAmount: Math.ceil(data?.result?.total / limit),
        total: data?.result?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      })
    )
  } catch (e) {
    console.log(e)
    yield put(getCurrentCustomerUsersFail())

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_users_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* customerUpdateFn({ payload }) {
  try {
    const { isCurrentCustomerPage, ...restParams } = payload || {}
    const {
      companyName,
      adminEmail,
      calendarValues,
      accsNumber,
      licenseId,
      customerId,
    } = restParams

    const { data: licenseData } = yield storeV2('license/update', {
      params: {
        id: licenseId,
        started_at: getISOTimeZoneDate(calendarValues[0]),

        expired_at: getISOTimeZoneDate(calendarValues[1]),

        max_users_count: accsNumber === '' ? null : accsNumber,
      },
    })
    const { data: customerData } = yield storeV2('customer/update', {
      params: {
        id: customerId,
        admin: adminEmail,
        name: companyName,
      },
    })

    yield put(
      customerEditSuccess({
        licenseResult: licenseData.result,
        customerResult: customerData.result,
      })
    )
    if (isCurrentCustomerPage) {
      yield put(
        currentCustomerEditSuccess({
          licenseResult: licenseData.result,
          customerResult: customerData.result,
        })
      )
    }
  } catch (e) {
    console.log(e)
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.customer_update_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* licenseToggleAccessFn({ payload }) {
  try {
    const { isCurrentCustomer, ...restParams } = payload || {}
    const { data } = yield storeV2('license/update', { params: restParams })
    yield put(
      licenseToggleAccessSuccess({
        id: data?.result?.id,
        is_active: data?.result?.is_active,
      })
    )
    if (isCurrentCustomer) {
      yield put(
        currentCustomerLicenseToggleSuccess({
          id: data?.result?.id,
          is_active: data?.result?.is_active,
        })
      )
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.toggle_access_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* licenseUserToggleAccessFn({ payload }) {
  try {
    const { isCurrentCustomer, license_id, customer_id, user_id } =
      payload || {}
    let customerData = null
    if (license_id === null) {
      customerData = yield storeV2('customer/get', {
        params: {
          filter: { ids: [customer_id] },
        },
      })
    }

    const { data } = yield storeV2('user/update', {
      params: {
        user_id,
        license_id: license_id
          ? null
          : customerData?.data?.items?.[0]?.license_id,
      },
    })

    yield put(
      licenseUserToggleAccessSuccess({
        user_id: data?.result?.user_id,
        license_id: data?.result?.license_id,
        customer_id: data?.result?.customer_id,
      })
    )
    if (isCurrentCustomer) {
      yield put(
        currentCustomerLicenseUserToggleSuccess({
          user_id: data?.result?.user_id,
          license_id: data?.result?.license_id,
        })
      )

      // если лицензия обновилась, меняем количество юзеров на странице компании
      if (license_id !== data?.result?.license_id) {
        yield put(
          updateCurrentCustomerLicenseUsers({
            withLicense: !!data?.result?.license_id,
          })
        )
      }
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.toggle_access_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* removeUserFromCompanyFn({ payload }) {
  try {
    const { isCurrentCustomer, user_id, isSynAdmin } = payload || {}

    const { activePage, perPage } = yield select(
      (state) => state.adminPanel.currentCustomerUsers.pagination
    )

    const { data } = yield storeV2('user/update', {
      params: {
        user_id,
        license_id: null,
        customer_id: null,
      },
    })

    yield put(removeUserFromCompanySuccess({ user_id: data?.result?.user_id }))

    if (isCurrentCustomer) {
      yield put(
        getCurrentCustomerUsers({
          isSynAdmin,
          offset: (activePage - 1) * perPage,
          limit: perPage,
        })
      )
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.remove_user_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* addNewCompanyFn({ payload }) {
  try {
    const { data } = yield storeV2('customer/create', {
      params: { ...payload },
    })
    yield put(addNewCompanySuccess(data?.result))
    yield put(setAddCompanyStage('success'))
  } catch (e) {
    console.log(e)

    yield put(addNewCompanyFail())

    const errMsg = e?.response?.data?.result?.error_message
    const getErrorName = () => {
      if (
        errMsg &&
        errMsg.includes('Customer with name') &&
        errMsg.includes('already exists')
      ) {
        return 'admin.add_company_error_name_exists'
      }
      return 'admin.add_company_error'
    }

    const id = uuidv4()
    const notify = {
      id,
      name: getErrorName(),
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* refreshCustomerTokenFn({ payload }) {
  try {
    const { companyName, adminEmail, customerId, isSynAdmin } = payload || {}

    const { data: customerData } = yield storeV2('customer/update', {
      params: {
        id: customerId,
        admin: adminEmail,
        name: companyName,
        refresh_invite_token: true,
      },
    })

    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')
    const { data } = yield storeV2('customer/get', {
      params: {
        filter: { ids: [isSynAdmin ? currentCustomerId : storageCustomerId] },
      },
    })

    yield put(getCurrentCustomerSuccess(data?.items))

    yield put(
      addSimpleNotification({ text: 'notification.product_key_refreshed' })
    )
  } catch (e) {
    console.log(e)
  }
}

export function* getCustomersWatcher() {
  yield takeEvery(getCustomers.type, getCustomersFn)
}

export function* licenseToggleAccessWatcher() {
  yield takeEvery(licenseToggleAccess.type, licenseToggleAccessFn)
}

export function* licenseUserToggleAccessWatcher() {
  yield takeEvery(licenseUserToggleAccess.type, licenseUserToggleAccessFn)
}

export function* removeUserFromCompanyWatcher() {
  yield takeEvery(removeUserFromCompany.type, removeUserFromCompanyFn)
}

export function* getUsersWatcher() {
  yield takeEvery(getUsers.type, getUsersFn)
}

export function* getApplicationsWatcher() {
  yield takeEvery(getApplications.type, getApplicationsFn)
}

export function* updateApplicationWatcher() {
  yield takeEvery(updateApplication.type, updateApplicationFn)
}

export function* getCurrentCustomerUsersWatcher() {
  yield takeEvery(getCurrentCustomerUsers.type, getCurrentCustomerUsersFn)
}

export function* customerUpdateWatcher() {
  yield takeEvery(customerUpdate.type, customerUpdateFn)
}
export function* getCurrentCustomerWatcher() {
  yield takeEvery(getCurrentCustomer.type, getCurrentCustomerFn)
}

export function* addNewCompanyWatcher() {
  yield takeEvery(addNewCompany.type, addNewCompanyFn)
}

export function* refreshCustomerTokenWatcher() {
  yield takeEvery(refreshCustomerToken.type, refreshCustomerTokenFn)
}

const watchers = [
  getCustomersWatcher(),
  licenseToggleAccessWatcher(),
  licenseUserToggleAccessWatcher(),
  removeUserFromCompanyWatcher(),
  getUsersWatcher(),
  getApplicationsWatcher(),
  updateApplicationWatcher(),
  getCurrentCustomerUsersWatcher(),
  customerUpdateWatcher(),
  getCurrentCustomerWatcher(),
  addNewCompanyWatcher(),
  refreshCustomerTokenWatcher(),
]

export default watchers
