import { FC, memo } from 'react'

import { CustomTagProps } from './index.types'
import { CustomTagElement } from './index.style'
import { EMPTY_HANDLER } from 'config/constants'

const CustomTag: FC<CustomTagProps> = memo(
  ({
    onClick = EMPTY_HANDLER,
    children,
    className,
    type = 'grey',
    width = '',
    height = '',
    isIconInteractable = false,
    defaultColor,
    isBordered = false,
    isPointer = true,
    radius,
    padding,
    fontSize,
    ...props
  }) => (
    <CustomTagElement
      onClick={onClick}
      type={type}
      className={className}
      width={width}
      height={height}
      isIconInteractable={isIconInteractable}
      defaultColor={defaultColor}
      padding={padding}
      radius={radius}
      fontSize={fontSize}
      isPointer={isPointer}
      isBordered={isBordered}
      {...props}
    >
      {children}
    </CustomTagElement>
  )
)

CustomTag.displayName = 'CustomTag'
export default CustomTag
