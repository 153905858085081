import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import history from '../services/history'
import { useAuth } from '../utils/auth/auth'

const AuthRoute = (props) => {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      const prevPath =
        history.location.pathname === '/login'
          ? history.location.state?.prevPath
          : history.location.pathname
      history.push('/login', { prevPath })
    }
  }, [isAuthenticated])

  const { component: Component } = props

  return isAuthenticated ? <Route component={Component} {...props} /> : null
}

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default AuthRoute
