import { createSlice } from '@reduxjs/toolkit'

export const INIT_QTY_ON_PAGE = 20
export const INIT_QTY_ON_PAGE_SETTING = {
  dataset: INIT_QTY_ON_PAGE,
  structure: INIT_QTY_ON_PAGE,
  reaction: INIT_QTY_ON_PAGE,
  literature: INIT_QTY_ON_PAGE,
}

export const initialState = {
  viewSize: 2,
  withData: true,
  isSidebarCollapsed: false,
  basketType: 'thematic',
  showPublicPdf: true,
  lang: 'en',
  showTips: true,
  isSessionInterrupted: false,
  qtyOnPage: INIT_QTY_ON_PAGE_SETTING,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsMolsize: (state, action) => {
      state.viewSize = action.payload
    },
    settingsSidebarToggle: (state) => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed
    },
    setBasketType: (state, action) => {
      state.basketType = action.payload
    },
    setShowPublicPdf: (state, action) => {
      state.showPublicPdf = action.payload
    },
    settingsLangChange: (state, action) => {
      state.lang = action.payload
    },
    settingsShowTipsToggle: (state, action) => {
      state.showTips = action.payload
    },
    setIsSessionInterrupted: (state, action) => {
      state.isSessionInterrupted = action.payload
    },
    updateQtyOnPage: (state, action) => {
      state.qtyOnPage = {
        ...state.qtyOnPage,
        ...action.payload,
      }
    },
    settingsRehydrate: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const {
  settingsRehydrate,
  settingsMolsize,
  settingsSidebarToggle,
  setBasketType,
  setShowPublicPdf,
  settingsLangChange,
  settingsShowTipsToggle,
  setIsSessionInterrupted,
  updateQtyOnPage,
} = settingsSlice.actions

export default settingsSlice.reducer
