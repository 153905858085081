import { FC } from 'react'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'

import { Buttons } from './index.style'
import { memo } from 'react'
import { NumberInputButtonsProps } from '../RightButtons/index.types'

const NumberInputButtons: FC<NumberInputButtonsProps> = memo(
  ({
    min,
    max,
    value,
    btnSize,
    iconSize,
    onIncrement,
    onDecrement,
    btnBorderRadius,
    withWhiteBackground,
  }) => {
    return (
      <Buttons {...{ btnSize, btnBorderRadius, withWhiteBackground }}>
        <CustomButton
          type="icon_secondary"
          disabled={Number(value) >= Number(max)}
          onClick={onIncrement}
        >
          <Icon iconType="arrowTop" size={iconSize} />
        </CustomButton>

        <CustomButton
          type="icon_secondary"
          disabled={Number(value) <= Number(min)}
          onClick={onDecrement}
        >
          <Icon iconType="arrowDown" size={iconSize} />
        </CustomButton>
      </Buttons>
    )
  }
)

NumberInputButtons.displayName = 'NumberInputButtons'
export default NumberInputButtons
