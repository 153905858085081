import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_TYPES_LIST } from 'components/Search/LiteratureFilter/constants'

const initialState = {
  filter: {
    open: false,
    config: {},
    docType: '',
    shouldBlocksIndexes: INITIAL_TYPES_LIST,
  },
  dialog: { open: false, config: {} },
  prevSearchDataId: null,
  listView: 'line',
  litPage: { open: false, config: {} },
  syntellyId: null,
  lastSearchedText: null, //по этому слову осуществлялся поиск в лит-ре в предыдущий раз,
  // если оно отлично от нового введенного, то при поиске обновятся
  //уточняющие фильтры abstract и title. Cделано это чтобы можно было
  //доуточнять abstract/title в фильтре при неизменном тексте поискового инпута
  //и при этом при явной попытке пользователя начать новый поиск, дать ему произойти
  //т.к. не обновленные abstract/title выдадут старый результат, не смотря на новый введенный текст в поисковик
}
const literatureSlice = createSlice({
  name: 'literature',
  initialState,
  reducers: {
    setOpenLitFilter: (state, action) => {
      state.filter.open = action.payload
    },

    setLitFilterConfig: (state, action) => {
      state.filter.config = action.payload
    },

    setLitFilterDocType: (state, action) => {
      state.filter.docType = action.payload
    },

    setOpenLitDialog: (state, action) => {
      state.dialog.open = action.payload
    },

    setLitDialogConfig: (state, action) => {
      state.dialog.config = action.payload
    },

    setPrevSearchDataId: (state, action) => {
      state.prevSearchDataId = action.payload
    },

    setLiteratureListView: (state, action) => {
      state.listView = action.payload
    },

    setLitPageOpen: (state, action) => {
      const { open, config } = action.payload || {}
      state.litPage.open = open
      state.litPage.config = config
    },

    setLiteratureSyntellyId: (state, action) => {
      state.syntellyId = action.payload
    },
    setLastSearchedText: (state, action) => {
      state.lastSearchedText = action.payload
    },
    setShouldBlocksIndexes: (state, action) => {
      state.filter.shouldBlocksIndexes = action.payload
    },

    updateLastSearchValues: (state, action) => {
      state.prevSearchDataId = action.payload.prevId
      state.syntellyId = null
      state.lastSearchedText = action.payload.text
    },
  },
})

export const {
  setOpenLitFilter,
  setLitFilterConfig,
  setLitFilterDocType,
  setOpenLitDialog,
  setLitDialogConfig,
  setPrevSearchDataId,
  setLiteratureListView,
  setLitPageOpen,
  setLiteratureSyntellyId,
  setLastSearchedText,
  setShouldBlocksIndexes,
  updateLastSearchValues,
} = literatureSlice.actions

export default literatureSlice.reducer
