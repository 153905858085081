import { t } from 'i18next'

import {
  validatePasswordSymb,
  validatePasswordLength,
  validatePasswordLetters,
} from 'utils/common/common'

import {
  SMALL_BTN_SIZE,
  LARGE_BTN_SIZE,
  SMALL_ICON_SIZE,
  STANDART_BTN_SIZE,
  STANDART_ICON_SIZE,
  SMALL_BTN_PADDING_SIZE,
  SMALL_BTN_BORDER_RADIUS,
  STANDART_BTN_PADDING_SIZE,
  SMALL_INPUT_BORDER_RADIUS,
  STANDART_BTN_BORDER_RADIUS,
  STANDART_INPUT_BORDER_RADIUS,
} from './config'
import { CustomInputSizeType } from './index.types'

export const validate = (password: string, email: string) => [
  {
    text: t('registration.includes_no_less_8'),
    isValid: validatePasswordLength(password),
  },
  {
    text: t('registration.includes_a_z'),
    isValid: validatePasswordLetters(password),
  },
  {
    text: t('registration.includes_symb'),
    isValid: validatePasswordSymb(password),
  },
  {
    text: t('registration.no_includes_email'),
    isValid: email ? !password.includes(email) : true,
  },
]

export const getStyleParams = (
  size: CustomInputSizeType,
  withLargeText: boolean
) => {
  return {
    iconSize: size === 'small' ? SMALL_ICON_SIZE : STANDART_ICON_SIZE,
    btnBorderRadius:
      size === 'small' ? SMALL_BTN_BORDER_RADIUS : STANDART_BTN_BORDER_RADIUS,
    inputBorderRadius:
      size === 'small'
        ? SMALL_INPUT_BORDER_RADIUS
        : STANDART_INPUT_BORDER_RADIUS,
    btnPaddingSize:
      size === 'small' ? SMALL_BTN_PADDING_SIZE : STANDART_BTN_PADDING_SIZE,
    btnSize: withLargeText
      ? LARGE_BTN_SIZE
      : size === 'small'
      ? SMALL_BTN_SIZE
      : STANDART_BTN_SIZE,
  }
}
