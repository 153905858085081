import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { memo, useEffect, useState, useCallback, useRef } from 'react'
import { fetch } from 'services/rest'
import usePrevious from 'pages/Baskets/lib/usePrevious'
import {
  NotificationsList,
  NotificationsWrapper,
  NotificationsContainer,
} from './index.style'
import { getName, getText } from './utils/utils'
import NotificationItem from './components/notificationItem'
import NotificationActions from './components/NotificationActions'
import { useAuth } from 'utils/auth/auth'
import { checkInterruptSession } from 'utils/common/common'
import { updateTasks } from 'store/slices/tasksSlice'
import {
  hideNotifications,
  hideSimpleNotification,
  removeSimpleNotification,
} from 'store/slices/notificationsSlice'
import { shallowEqual } from 'react-redux'

const Notifications = () => {
  const { t } = useTranslation()
  const { isAuthenticated, logout } = useAuth()
  const countRetry = useRef(0)
  const intervalRef = useRef()
  const notificationsListRef = useRef()
  const synthcost_warning_ids = useSelector(
    (state) => state.synthcost.warning_ids
  )
  const litSearchStatus = useSelector((state) => state.crud.litSearch.status)
  const userdata = useSelector((state) => state.auth.userdata)
  const tasks = useSelector((state) => state.tasks, shallowEqual)

  const mmapOptions = useSelector((state) => state.mmap.options)

  const notifications = useSelector(
    (state) => state.notifications.notificationsList
  )
  const simpleNotification = useSelector(
    (state) => state.notifications.simpleNotification
  )
  const isHidden = useSelector((state) => state.notifications.isHidden)
  const dispatch = useDispatch()
  const [isActionsVisible, setIsActionsVisible] = useState(false)
  const [itemsCompressed, setItemsCompressed] = useState(true)

  const prevTasks = usePrevious(tasks)
  const prevNotifications = usePrevious(notifications)
  const allNotifications = (
    isAuthenticated ? [...tasks, ...notifications] : [...notifications]
  ).sort((a, b) => a.id - b.id)
  const isSessionInterrupted = useSelector(
    (state) => state.settings.isSessionInterrupted
  )

  const getTasksStatus = useCallback(async () => {
    try {
      const tasksStatus = await fetch(`/userTasksStatus`)
      const tasksWithNames = tasksStatus.data
        .filter((taskStatus) => taskStatus.name !== null)
        .map((el) => ({
          ...el,
          notification_type: 'progress',
          defaultName: el.name,
          name: getName({ name: el.name, t, el, synthcost_warning_ids }),
          text:
            el?.name === 'synmap'
              ? mmapOptions.find(
                  ({ id, isGenerator }) => isGenerator && id === el?.uuid
                )?.name
              : getText({ name: el.name, t, el }),
        }))

      dispatch(updateTasks(tasksWithNames))
    } catch (err) {
      if (isSessionInterrupted) clearInterval(intervalRef.current)
      if (countRetry.current >= 2) {
        clearInterval(intervalRef.current)
        if (err?.response?.status === 403) {
          let needInterrupt = false
          if (
            err?.response?.data?.result?.error_message ===
            'User is not authenticated'
          ) {
            needInterrupt = checkInterruptSession()
          }
          if (!needInterrupt) logout()
        }
      }

      countRetry.current += 1
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tasks.length, synthcost_warning_ids])

  useEffect(() => {
    if (Object.keys(userdata).length !== 0) {
      getTasksStatus()
      intervalRef.current = setInterval(() => getTasksStatus(), 3000)
      countRetry.current = 0
    }

    return () => clearInterval(intervalRef.current)
  }, [userdata, getTasksStatus])

  useEffect(() => {
    if (
      isHidden &&
      litSearchStatus !== 'loading' &&
      (prevTasks !== tasks || prevNotifications !== notifications)
    )
      dispatch(hideNotifications())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, notifications.length, prevNotifications, tasks.length])

  const toggleCompress = () => {
    const container = document.querySelector('#notifications-list')
    const elements = container.querySelectorAll('.element')

    elements?.forEach((element, index) => {
      if (index < elements.length - 3) {
        itemsCompressed
          ? element.classList.remove('hidden')
          : element.classList.add('hidden')
      }
    })

    if (itemsCompressed && container) {
      container.style.justifyContent = 'flex-end'

      setTimeout(() => {
        container.style.justifyContent = 'flex-start'
        notificationsListRef.current.scrollTo({
          top: -1000,
          behaviour: 'smooth',
        })
      }, 500)
    }

    setTimeout(() => setItemsCompressed(!itemsCompressed), 50)
  }

  useEffect(() => {
    if (!itemsCompressed) {
      notificationsListRef?.current?.scrollTo({
        top: -1000,
        behaviour: 'smooth',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications.length])

  useEffect(() => {
    let hideTimer
    let removeTimer
    if (simpleNotification.text && simpleNotification.visible) {
      hideTimer = setTimeout(() => {
        dispatch(hideSimpleNotification())
        removeTimer = setTimeout(() => {
          dispatch(removeSimpleNotification())
        }, 500)
      }, 3000)
      return () => {
        clearTimeout(hideTimer)
        clearTimeout(removeTimer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleNotification.text, simpleNotification.visible])

  if (!tasks.length && !notifications.length) return null

  return (
    <NotificationsWrapper isHidden={isHidden}>
      <NotificationsContainer className="notifications-container">
        {!isHidden && (
          <NotificationActions
            {...{
              tasks,
              isHidden,
              toggleCompress,
              itemsCompressed,
              allNotifications,
              isActionsVisible,
              setIsActionsVisible,
            }}
          />
        )}

        <NotificationsList
          itemsCompressed={itemsCompressed}
          ref={notificationsListRef}
          count={allNotifications.length}
          isHidden={isHidden}
          id="notifications-list"
          onMouseEnter={() => setIsActionsVisible(true)}
          onMouseLeave={(e) => {
            const currentTarget = e?.currentTarget
            const relatedTarget = e.relatedTarget
            if (
              currentTarget &&
              relatedTarget instanceof Node &&
              !currentTarget?.contains(e.relatedTarget)
            )
              setIsActionsVisible(false)
          }}
        >
          {allNotifications.map((item, ind, arr) => (
            <NotificationItem
              key={item.id}
              item={item}
              itemsCompressed={itemsCompressed}
              className={
                ind < arr.length - 3 && itemsCompressed
                  ? 'element hidden'
                  : 'element'
              }
              setIsActionsVisible={setIsActionsVisible}
              isOnlyOne={arr.length === 1}
            />
          ))}
        </NotificationsList>
      </NotificationsContainer>
    </NotificationsWrapper>
  )
}

Notifications.displayName = 'Notifications'

export default Notifications
