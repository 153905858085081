import { useTheme } from 'styled-components'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import { useTranslation } from 'react-i18next'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useState, useRef, memo, useEffect } from 'react'

import { fetch } from 'services/rest'

import CustomFilter from './CustomFilter'
import CustomTooltip from './CustomTooltip'
import FloatingFilter from './FloatingFilter'
import { DRUG_PARAMS } from './config/config'
import { textCells } from './CustomFilter/config/config'
import {
  ActionButtons,
  ButtonsBlock,
  DatasetTableWrapper,
  Header,
  Table,
} from './index.style'
import { checkFilterConditions } from './CustomFilter/utils/utils'
import MultiSelectWithGroups from './CustomFilter/MultiSelectWithGroups'
import { TextComparator, NumberComparator } from './utils/utils'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import BasketsListModal from 'components/BasketsListModal'
import { useDispatch } from 'react-redux'
import { addMoleculesPack } from 'store/slices/crudSlice'

const DatasetTable = memo(({ rows, basketName }) => {
  const gridRef = useRef()
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const options = Object.keys(rows[0]).map((el) => ({ value: el, label: el }))

  const [paintingModel, setPaintingModel] = useState({})
  const [groupedOptions, setGroupedOptions] = useState([])
  const [selectedColumns, setSelectedColumns] = useState(options)
  const [isAddMoleculeDialogOpen, setIsAddMoleculeDialogOpen] = useState(false)

  const getFullName = (catInner = {}) => {
    const { name, full_title, measurement_unit } = catInner
    if (!full_title) return name
    if (full_title && !measurement_unit) return full_title
    return `${full_title} (${measurement_unit})`
  }

  const getCategories = async () => {
    try {
      const { data } = await fetch('/categories')
      let groups = []

      options.forEach((opt) => {
        const cat = data.find((cat) => {
          return cat?.inner?.some((catInner) => {
            const catName = getFullName(catInner)
            return catName === opt.value
          })
        })
        let category = cat
          ? `molviewer.${cat.category_name}`
          : DRUG_PARAMS.includes(opt?.value)
          ? 'molviewer.drug'
          : 'no_group'

        if (groups.some((el) => el.group === category))
          groups = groups.map((el) =>
            el.group === category
              ? { ...el, options: [...el.options, opt] }
              : el
          )
        else groups = [...groups, { group: category, options: [opt] }]
      })
      setGroupedOptions(groups)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangePaintingModel = (type, column, newState, operator) => {
    if (type === 'apply') {
      const model = operator
        ? [
            {
              conditions: [...newState],
              operator,
            },
          ]
        : [{ ...newState[0] }]
      const newModel = { ...paintingModel }

      newModel[column] = {
        model,
        operator,
      }
      setPaintingModel(newModel)
    } else {
      if (!paintingModel?.[column]) return
      const newModel = { ...paintingModel }
      delete newModel[column]
      setPaintingModel(newModel)
    }
  }

  const handleSaveToDataset = (selected, filterValue) => {
    if (!(gridRef.current && gridRef.current.api)) return

    const raw_list = []
    const { id: basket, name: basket_name } = selected?.[0] ?? null
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      const smiles = node?.data?.['SMILES_standardized']
      smiles && raw_list.push(smiles)
    })
    if (raw_list?.length === 0 || !basket) return

    const list = raw_list?.map((el) => {
      return { smiles: el }
    })
    dispatch(
      addMoleculesPack({
        basket,
        list,
        basket_name,
        t,
      })
    )
  }

  const handleResetSorting = () => {
    const columnState = gridRef?.current?.api?.getColumnState() || []
    columnState.forEach((state) => {
      state.sort = null
    })
    gridRef.current?.api?.applyColumnState({
      state: columnState,
      applyOrder: true,
    })
  }

  const handleReset = () => {
    if (!(gridRef.current && gridRef.current.api)) return

    gridRef.current.api.setFilterModel(null)
    setSelectedColumns(options)
    setPaintingModel({})
    handleResetSorting()
  }

  const columnDefs =
    rows.length > 0
      ? options.map(({ value }) => ({
          headerName: value,
          field: value,
          sortable: true,
          filter: CustomFilter,
          headerTooltip: value,
          tooltipComponent: CustomTooltip,
          tooltipValueGetter: (params) => params.value,
          comparator: textCells.includes(value)
            ? TextComparator
            : NumberComparator,
          hide: !selectedColumns.some((el) => el.value === value),
          filterParams: {
            paintingModel,
            columnType: textCells.includes(value) ? 'string' : 'number',

            onChangePaintingModel,
          },
          cellStyle: (params) => {
            if (!paintingModel?.[value]) return {}
            if (
              checkFilterConditions(
                params.value,
                paintingModel[value].model[0],
                textCells.includes(value) ? 'string' : 'number',
                paintingModel[value].operator
              )
            )
              return {
                background: theme.colors.backgrounds.accentSecondary,
              }
            return {}
          },
          floatingFilterComponent: FloatingFilter,
          floatingFilterComponentParams: {
            paintingModel,
          },
        }))
      : []
  return (
    <>
      <DatasetTableWrapper>
        <Header>
          {t('dataset_table.table_analysis_of_the_dataset', {
            name: basketName,
          })}
        </Header>
        <ButtonsBlock>
          <MultiSelectWithGroups
            withTranslation={false}
            allSelectedLabel={t('dataset_table.all_columns')}
            options={groupedOptions}
            width="15rem"
            selectedValue={selectedColumns}
            onChange={(value) => setSelectedColumns(value)}
            withGroups={true}
            withSearch={true}
          />
          <ActionButtons>
            <CustomButton
              gap="4px"
              type="secondary"
              onClick={() => setIsAddMoleculeDialogOpen(true)}
            >
              <Icon iconType="add" size="1rem" />
              {t('dataset_table.save')}
            </CustomButton>
            <CustomButton gap="4px" type="secondary" onClick={handleReset}>
              <Icon iconType="refresh" size="1rem" />
              {t('dataset_table.reset')}
            </CustomButton>
          </ActionButtons>
        </ButtonsBlock>

        <Table className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={rows}
            columnDefs={columnDefs}
            tooltipShowDelay={0}
            tooltipHideDelay={10000}
            defaultColDef={{
              floatingFilter: true,
              filter: true,
              sortable: true,
            }}
          />
        </Table>
      </DatasetTableWrapper>
      {isAddMoleculeDialogOpen && (
        <BasketsListModal
          onAgree={handleSaveToDataset}
          onClose={() => setIsAddMoleculeDialogOpen(false)}
          withPublic={false}
          withNew={true}
          actionText={t('molecule_viewer.add_to_dataset')}
          onlyOne
        />
      )}
    </>
  )
})

DatasetTable.displayName = 'DatasetTable'

export default DatasetTable
