import store from 'store'

import { hideContextMenu } from 'store/slices/contextMenuSlice'

import { ContextMenuItem } from './contextMenuItem'
import { ContextMenuAction, RenderActionItem } from './index.types'
import { ContextMenuConfig, ContextMenuItemsConfig } from './config'

export const renderActionItem = (item: RenderActionItem) => {
  const {
    action,
    subActions,
    subActionsType,
    index,
    handleClick,
    hotkey,
    disabled = false,
    showSubActions,
    setShowSubActions,
    structuresComparisonCount,
  } = item

  if (Object.prototype.hasOwnProperty.call(action, 'handler')) {
    const { icon, handler, id } = action as any

    return (
      <ContextMenuItem
        // {...action}
        key={`${index}-${icon || id}`}
        onClick={(ev) => {
          ev.stopPropagation()
          handler()
          store.dispatch(hideContextMenu())
        }}
        id={id || icon}
        subActions={
          subActions ? (
            <>
              {subActions?.map((subItem) =>
                renderActionItem({
                  ...subItem,
                  handleClick,
                })
              )}
            </>
          ) : null
        }
        showSubActions={showSubActions}
        setShowSubActions={setShowSubActions}
        subActionsCount={subActions?.length}
      />
    )
  }
  const menuItem = ContextMenuItemsConfig[action as any]
  if (!item || !menuItem) return null

  return (
    <ContextMenuItem
      {...menuItem}
      action={action}
      key={action}
      hotkey={hotkey}
      disabled={disabled}
      onClick={(ev) => {
        ev.stopPropagation()
        handleClick && handleClick(action)
      }}
      subActionsType={subActionsType}
      id={menuItem?.id || action}
      subActions={
        !!subActions && (
          <>
            {subActions?.map((subItem) =>
              renderActionItem({ ...subItem, handleClick })
            )}
          </>
        )
      }
      showSubActions={showSubActions}
      setShowSubActions={setShowSubActions}
      subActionsCount={subActions?.length}
      structuresComparisonCount={structuresComparisonCount}
    />
  )
}

export const getSubActionsList = (action: ContextMenuAction) => {
  if (action?.action === 'transfer_to')
    return ContextMenuConfig?.['goToSectionMenu']()
  return null
}
