import { css, CSSObject, default as styled } from 'styled-components'

import {
  FontSizeType,
  TextColorType,
  FontWeightType,
  ElementWithChildren,
} from 'types/common'

interface DefaultTextProps extends ElementWithChildren {
  id?: number | string
  className?: string
  color?: TextColorType
  fontSize?: FontSizeType
  fontWeight?: FontWeightType
  ellipsis?: boolean
  textAlign?: string
  lineHeight?: FontSizeType
  nowrap?: boolean
}

interface ExtraProps {
  name?: string
  minWidth?: string
  pointer?: boolean
  pointerEventsNone?: boolean
  extraStyles?: CSSObject
}

interface ExtendedTextProps extends DefaultTextProps, ExtraProps {}

export const TitlePrimary = styled.h1<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[24]};

  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[28]};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const TitleSecondary = styled.h2<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[20]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.semibold};
  line-height: ${({ theme }) => theme.text[24]};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const TitleTertiary = styled.h3<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[16]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[20]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const Headline = styled.div<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[14]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.light};
  line-height: ${({ theme }) => theme.text[18]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
`

export const TextButtonLarge = styled.p<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[14]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[20]};
  margin: 0;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};
`

export const TextButtonMedium = styled.p<DefaultTextProps>`
  color: ${({ color, theme }) => color || theme.colors.text.accentPrimary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[12]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[16]};
  margin: 0;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};
`

export const TextButtonRegular = styled(TextButtonMedium)

export const Label = styled.div<ExtendedTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[12]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[16]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
  ${({ name }) =>
    (name === 'smiles' || name === 'ellipsis') &&
    `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: block; width: 100%;`};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
  @media print {
    ${({ name }) =>
      name === 'smiles' &&
      `text-overflow: clip; overflow: unset; white-space: wrap; display: block; width: 100%;`};
  }
  min-width: ${({ minWidth }) => minWidth && minWidth};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
  ${({ pointerEventsNone }) =>
    pointerEventsNone &&
    css`
      pointer-events: none;
    `}
`
// need to deprecate TextBody after styling
export const TextBody = styled.div<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[14]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme, lineHeight }) => lineHeight || theme.text[18]};
`

export const TextLarge = styled.p<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[14]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.extraLight};
  line-height: ${({ theme }) => theme.text[18]};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const TextMedium = styled.p<DefaultTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[12]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.extraLight};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ? lineHeight : theme.text[16]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`
// need to depreacte Subheadline after styling
export const Subheadline = styled.div<ExtendedTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[12]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.medium};
  line-height: ${({ theme, lineHeight }) => lineHeight || theme.text[16]};
  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}

  ${({ name }) =>
    name === 'smiles'
      ? `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: block; width: 100%;`
      : name === 'personal_properties'
      ? `word-break: break-word; max-width: 85%;`
      : ''};

  @media print {
    ${({ name }) =>
      name === 'smiles' &&
      `text-overflow: clip; overflow: unset; white-space: wrap; display: block; width: 100%;`};
  }
`

export const Caption = styled.div<ExtendedTextProps>`
  color: ${({ theme, color }) => color || theme.colors.text.primary};
  font-size: ${({ theme, fontSize }) => fontSize || theme.text[11]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.light};
  line-height: ${({ theme, lineHeight }) => lineHeight || theme.text[12]};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}
  min-width: ${({ minWidth }) => minWidth && minWidth};
`

export const Separator = styled.div<DefaultTextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, color }) => color || theme.colors.text.primary};
`
