/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next'
import { memo, useEffect, useRef, useState } from 'react'

import CustomInput from 'components/common/customInput'
import CustomScrollbar from 'components/CustomScrollbar'
import { Label } from 'components/common/text/index.style'
import RadioCheckbox from 'components/common/customRadioCheckbox'

import {
  Container,
  ValuesWithSearch,
  SelectedValuesContainer,
} from './index.style'

import ApplyResetBtns from '../ApplyResetBtns'
import ConditionsBlock from '../ConditionsBlock'
import { initFilterState } from '../config/config'
import {
  checkFilterConditions,
  checkObjDisablity,
  onChangeFilters,
} from '../utils/utils'

const FilterContent = memo(
  ({
    api,
    model,
    values,
    column,
    columnType,
    typeOptions,
    appliedFilter,
    filterOperator,
    isFilterOpened,
    filterException,
    setFilterOperator,
  }) => {
    const { t } = useTranslation()

    const scrollRef = useRef(null)

    const [valuesForRender, setValuesForRender] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [filteredOptionsForRender, setFilteredOptionsForRender] = useState([])
    const [filtersState, setFiltersState] = useState(initFilterState)
    const [exceptions, setExceptions] = useState([])
    const [page, setPage] = useState(1)
    const itemsPerPage = 100

    const exceptionsForRender = appliedFilter
      ? exceptions.filter((el) =>
          checkFilterConditions(el, appliedFilter, columnType)
        )
      : exceptions
    const isAllSelected = !exceptionsForRender.length

    const allOptions = [...valuesForRender, ...exceptionsForRender]

    const filteredOptions = inputValue
      ? allOptions.filter((el) =>
          el.toLowerCase().includes(inputValue.toLowerCase())
        )
      : allOptions

    const isApplyBtnDisabled = () => {
      if (!filterOperator) return checkObjDisablity(filtersState[0])
      else return filtersState.some((el) => checkObjDisablity(el))
    }

    useEffect(() => {
      setValuesForRender(values)
    }, [values.length])

    useEffect(() => {
      convertModelToFilterState()
    }, [model])

    useEffect(() => {
      setExceptions(filterException?.values || [])
    }, [filterException?.values || [].length])

    useEffect(() => {
      setFilteredOptionsForRender([
        ...filteredOptions.slice(0, page * itemsPerPage),
      ])
    }, [page, filteredOptions?.length])

    const convertModelToFilterState = () => {
      let newFilterState = []
      if (!appliedFilter) newFilterState = initFilterState
      else if (appliedFilter?.conditions) {
        newFilterState = [...appliedFilter.conditions]
        if (filterException) newFilterState.push(filterException)
        setFilterOperator(appliedFilter.operator)
      } else {
        newFilterState = [
          { ...appliedFilter },
          { type: '', filter: '', filter2: '' },
        ]
        setFilterOperator(null)
      }
      setFiltersState(newFilterState)
    }

    const handleReset = async () => {
      setFiltersState(initFilterState)
      setFilterOperator(null)
      setExceptions([])
      setValuesForRender(values)
      await api.setColumnFilterModel(column, null)
      await api.onFilterChanged()
    }

    const handleApply = async () => {
      let filterModels = []
      let notEmptyFilter = filtersState.filter(
        (el) => el.type && el.type !== 'set'
      )
      if (!notEmptyFilter.length) {
        if (filterOperator) setFilterOperator(null)
        if (!exceptions.length) filterModels = null
      } else if (notEmptyFilter.length === 1 || !filterOperator) {
        if (filterOperator) setFilterOperator(null)
        filterModels = [{ ...notEmptyFilter[0] }]
      } else if (notEmptyFilter.length === 2) {
        filterModels = [
          {
            conditions: [...filtersState],
            operator: filterOperator,
          },
        ]
      }

      if (exceptions.length)
        filterModels.push({
          type: 'set',
          values: exceptions,
        })

      await api.setColumnFilterModel(column, {
        filterModels,
      })
      await api.onFilterChanged()

      // await api.hidePopupMenu()
    }

    const handleSelectAll = () => {
      if (isAllSelected) {
        setExceptions((prev) => [...prev, ...valuesForRender])
        setValuesForRender([])
      } else {
        setValuesForRender((prev) => [...prev, ...exceptions])
        setExceptions([])
      }
    }

    const handleScroll = () => {
      const e = scrollRef?.current
      if (e) {
        const scrollTop = e.scrollTop
        const scrollHeight = e.scrollHeight
        const clientHeight = e.clientHeight
        const isInTheBottom = scrollHeight - scrollTop - clientHeight === 0
        if (isInTheBottom) setPage((prev) => prev + 1)
      }
    }

    return (
      <>
        <Container>
          <Label>{t('dataset_table.by_name')}</Label>

          <ConditionsBlock
            onChangeOperator={(value) => setFilterOperator(value)}
            onChangeFilter={(ind, type, value) =>
              onChangeFilters(ind, type, value, setFiltersState)
            }
            {...{ values, filtersState, typeOptions }}
            operator={filterOperator}
          />

          <ValuesWithSearch>
            <CustomInput
              placeholder={t('dataset_table.search')}
              value={inputValue}
              onChange={setInputValue}
              withWhiteBackground
              withClearButton
            />
            <CustomScrollbar
              innerRef={scrollRef}
              onScroll={handleScroll}
              disableTrackYWidthCompensation={true}
              fixedHeight={false}
              maxHeight={10.5}
              className="table-values-scroll margin-4-right"
              trigger={`${filteredOptionsForRender?.length}-${isFilterOpened}`}
            >
              <SelectedValuesContainer>
                {filteredOptions.length ? (
                  <>
                    {!inputValue && (
                      <RadioCheckbox
                        id="filter-values-select-all"
                        checkbox
                        handler={handleSelectAll}
                        value={isAllSelected}
                        label={t('dataset_table.select_all')}
                        size="small"
                      />
                    )}

                    {filteredOptionsForRender.map((el) => {
                      return (
                        <RadioCheckbox
                          key={el || 'blank'}
                          checkbox
                          value={!exceptions.includes(el)}
                          handler={() => {
                            if (exceptions.includes(el)) {
                              setExceptions((prev) => [
                                ...prev.filter((value) => el !== value),
                              ])

                              setValuesForRender((prev) => [...prev, el])
                            } else {
                              setExceptions((prev) => [...prev, el])
                              setValuesForRender((prev) =>
                                prev.filter((opt) => opt !== el)
                              )
                            }
                          }}
                          label={el || t('dataset_table.blank')}
                          id={`filter-values-select-${el || 'blank'}`}
                          size="small"
                        />
                      )
                    })}
                  </>
                ) : values?.length ? (
                  t('dataset_table.value_not_found')
                ) : (
                  t('dataset_table.no_values')
                )}
              </SelectedValuesContainer>
            </CustomScrollbar>
          </ValuesWithSearch>
        </Container>
        <ApplyResetBtns
          onReset={handleReset}
          onApply={handleApply}
          isApplyBtnDisabled={isApplyBtnDisabled()}
        />
      </>
    )
  }
)

FilterContent.displayName = 'FilterContent'
export default FilterContent
