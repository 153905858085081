import { FC } from 'react'

import Icon from 'components/Icon'

import { LabelBlockProps } from './index.types'
import { CustomInputLabel, InputLabelContainer } from './index.style'
import { memo, useCallback, useMemo } from 'react'

const CustomInputLabelBlock: FC<LabelBlockProps> = memo(
  ({
    id,
    type,
    label,
    error,
    onFocus,
    warning,
    labelDesc,
    isValidated,
    isValidating,
    showTooltip,
    needTooltip,
    setShowTooltip,
    withPasswordTooltip,
  }) => {
    const tooltipId = useMemo(() => {
      if (labelDesc && !(warning || error)) return `${type}-${id}-info`
      if (withPasswordTooltip) return 'password-req-tooltip'
      if (error) return `${type}-${id}-error`
      return `${type}-${id}-warning`
    }, [error, id, labelDesc, type, warning, withPasswordTooltip])

    const onMouseEnter = useCallback(
      () => needTooltip && !showTooltip && setShowTooltip(true),
      [needTooltip, setShowTooltip, showTooltip]
    )

    const onMouseLeave = useCallback(
      () => showTooltip && setShowTooltip(false),
      [setShowTooltip, showTooltip]
    )

    return (
      <InputLabelContainer
        data-tooltip-id={tooltipId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <>
          <CustomInputLabel htmlFor={id} onFocus={onFocus}>
            {label}
          </CustomInputLabel>
          {isValidating && (
            <Icon
              size="1rem"
              iconType="loader"
              className="icon-login-validating"
            />
          )}
          {(!!error || !!warning) && (
            <>
              <Icon
                size="1rem"
                iconType={warning ? 'warning2' : 'description'}
                className={warning ? 'icon-login-warning' : 'icon-login-error'}
              />
            </>
          )}
          {isValidated && (
            <Icon
              size="1rem"
              iconType="tick"
              className="icon-login-validated"
            />
          )}
          {labelDesc && !(error || warning) && (
            <Icon size="1rem" iconType="info" className="icon-info" />
          )}
        </>
      </InputLabelContainer>
    )
  }
)

CustomInputLabelBlock.displayName = 'CustomInputLabelBlock'
export default CustomInputLabelBlock
