import { put, takeEvery } from 'redux-saga/effects'
import { fetch } from '../../services/rest'
import { getMeFail, getMeSuccess, loadMe } from 'store/slices/authSlice'

function* getme() {
  try {
    const { data } = yield fetch('/profile')
    yield put(getMeSuccess(data))
  } catch (e) {
    console.log(e)
    yield put(getMeFail())
  }
}

function* loadmeWatcher() {
  yield takeEvery(loadMe.type, getme)
}

const watchers = [loadmeWatcher()]
export default watchers
