export const BINGO_PAGINATION_LIMIT = 50
export const LIT_PAGINATION_LIMIT = 20
export const REACTIONS_PAGINATION_LIMIT = 20

export const KEY_RU = 'key_ru'
export const KEY_EN = 'key_en'

export const INNER_LANG_OPERATOR = 'or_operator'

export const KEYS_WITH_LANG = [
  'abstract',
  'applicant',
  'authors',
  'claims',
  'company',
  'text',
  'title',
]

export const BASKETS_ENDPOINT_BY_TYPE = {
  thematic: 'public',
  private: 'personal',
  companies: 'customer',
}

export const REACTION_SEARCH_PATH = 'search/reaction/compound/'
export const LIT_SEARCH_PATH = '/search/literature'
export const BINGO_SEARCH_PATH = '/search/structure/bingo'

export const MOLECULE_SEARCH_PATH = '/search/structure/global'
export const BASKET_SEARCH_PATH = '/search/structure/basket/'

export const TIPS_SEARCH_PATH = '/search/structure/tips/global'
export const BASKET_TIPS_SEARCH_PATH = '/search/structure/tips/basket/'

export const SYN_MAP_DEFAULT_COLORS = [
  '#2196F3',
  '#F57C00',
  '#66BB6A',
  '#F44336',
  '#9C27B0',
  '#8D6E63',
  '#F06292',
  '#78909C',
  '#8BC34A',
  '#00BCD4',
]

export const SYN_MAP_BASKET_2D_URL = '/molmap/calculate/baskets'
export const SYN_MAP_BASKET_3D_URL = '/umap/calculate/baskets'
export const SYN_MAP_GEN_3D_URL = '/umap/calculate/smiles_list'
