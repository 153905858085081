import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

import CustomDualRange from '../../../common/customDualRange'

const FilterRange = memo(({ setState, state, config }) => {
  const { step, title, values, key } = config
  const isSimilarity = config.key === 'similarity'
  const { [key]: inputValues } = state
  const searchType = useSelector((state) => state.search.searchType)

  const isReactionsSearch = searchType === 'reaction'
  const isRangeDisabled =
    (state.match_type !== 'similar structures' && !isReactionsSearch) ||
    state.disabled

  const onChange = (id, value) => {
    setState({
      ...state,
      [config.key]: {
        ...(isSimilarity ? { ...state.similarity } : { ...state.yield }),
        [id]: value,
      },
    })
  }

  const rangeValues = useMemo(() => Object.values(inputValues), [inputValues])
  const defaultValues = useMemo(() => values.map((v) => v.default), [values])
  const placeholders = useMemo(
    () => values.map(({ placeholder }) => placeholder),
    [values]
  )

  return (
    <CustomDualRange
      min={0}
      max={100}
      step={step}
      onChange={onChange}
      values={rangeValues}
      defaultValues={defaultValues}
      placeholders={placeholders}
      disabled={isRangeDisabled}
      title={title}
    />
  )
})

FilterRange.displayName = 'FilterRange'
export default FilterRange
