import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WindowContainer, Window, CloseButton, Header } from './index.style'
import ReactDom from 'react-dom'

const CustomWindow = ({
  isOpen,
  config,
  children,
  window_id,
  handleClose,
  outboundClickEnabled = true,
}) => {
  const { t } = useTranslation()

  const { header, width, headerPadding, zIndex, side = 'right' } = config || {}

  return ReactDom.createPortal(
    <WindowContainer
      id={window_id}
      isOpen={isOpen}
      onClick={outboundClickEnabled ? handleClose : () => {}}
      zIndex={zIndex}
    >
      <Window
        data-test="custom-window-inner"
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
        width={width}
        side={side}
      >
        <Header padding={headerPadding}>{t(header)}</Header>
        <CloseButton iconType="close" size="1.25rem" onClick={handleClose} />
        {children}
      </Window>
    </WindowContainer>,
    document.body
  )
}

const mapStateToProps = (state) => {
  return {}
}

CustomWindow.displayName = 'CustomWindow'

export default connect(mapStateToProps)(CustomWindow)
