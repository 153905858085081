import React, { FC, memo, useMemo } from 'react'
import { customIcons } from './iconConfig'
import { CustomIconProps } from './index.types'

const DEFAULT_STROKE_WIDTH = 1.2
const DEFAULT_SIZE_PX = 24
const DEFAULT_SIZE_REM = 1.5

const getScaledStrokeWidth = (size: string) => {
  if (size.includes('px')) {
    return `${
      DEFAULT_STROKE_WIDTH / (Number(size.replace('px', '')) / DEFAULT_SIZE_PX)
    }`
  } else {
    return `${
      DEFAULT_STROKE_WIDTH /
      (Number(size.replace('rem', '')) / DEFAULT_SIZE_REM)
    }`
  }
}

const Icon: FC<CustomIconProps> = memo(
  ({
    iconType,
    className,
    title,
    id,
    onClick,
    size,
    strokeWidth,
    width,
    dataTest = '',
    ...props
  }) => {
    const Icon = customIcons[iconType]

    const sizes = useMemo(
      () =>
        size
          ? {
              width: width ?? size,
              height: size,
              strokeWidth: getScaledStrokeWidth(size),
            }
          : { strokeWidth: DEFAULT_STROKE_WIDTH },
      [size, width]
    )

    if (!Icon) return null

    return (
      <Icon
        className={className}
        id={id}
        title={title}
        onClick={onClick}
        data-test={dataTest}
        {...sizes}
        {...props}
      />
    )
  }
)

Icon.displayName = 'Icon'
export default Icon
