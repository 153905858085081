import i18n from 'i18n'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import { setStorage } from 'utils/storage/storage'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import LicensePurchaseInfo from 'components/LicensePurchaseInfo'
import SocialNetworksFooter from 'components/SocialNetworksFooter'
import { LICENSE_NOTIFY_DATE_KEY } from 'components/LoginPage/config'
import { Label, TitleTertiary } from 'components/common/text/index.style'

import { getRenderData } from './utils/utils'
import { Body, Buttons, Container, MainSection } from './index.style'
import { setLicenseExpiredInfo } from 'store/slices/authSlice'
import { openFeedbackForm } from 'store/slices/feedbackSlice'

const TrialPeriodNotification = ({ licenseExpiredInfo }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { diffDays, isFreeTrial } = licenseExpiredInfo
  const { icon, days } = getRenderData(diffDays)

  const lang = i18n?.language
  const isFeedbackFormOpen = useSelector((state) => state.feedback.show)

  const handleSkip = () => {
    setStorage(LICENSE_NOTIFY_DATE_KEY, new Date())
    dispatch(setLicenseExpiredInfo(null))
  }

  const handleOpenSurvey = () => {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.href =
      lang === 'ru'
        ? 'https://forms.gle/MZsz1hM9gfDXkCUo9'
        : 'https://forms.gle/wdJvMdJoGxw8VXq8A '
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handleOpenFeedbackForm = () => {
    dispatch(openFeedbackForm())
  }

  if (isFeedbackFormOpen) return null

  return (
    <CustomDialog width="33.75rem" showCloseIcon={false} onClose={() => {}}>
      <Container>
        <Icon iconType={icon} size="4rem" />
        <MainSection>
          <TitleTertiary textAlign="center">
            {t('account.your_trial_period_will_end', {
              value: isFreeTrial
                ? t('account.trial_period')
                : t('account.license'),
            })}{' '}
            <span>{days}</span>
          </TitleTertiary>{' '}
          <LicensePurchaseInfo {...{ isFreeTrial }} textAlign="center" />
        </MainSection>
        <Buttons>
          <CustomButton width="50%" type="secondary" onClick={handleSkip}>
            {t('account.skip')}
          </CustomButton>
          <CustomButton
            width="50%"
            type="accent"
            onClick={handleOpenFeedbackForm}
          >
            {t('account.contact_us')}
          </CustomButton>
        </Buttons>
        <Body>
          <Label textAlign="center">{t('account.we_would_be_grateful')}</Label>
          <CustomButton type="text_accent" onClick={handleOpenSurvey}>
            {t('account.take_a_survey')}
            <Icon iconType="arrowRight" size="1rem" />
          </CustomButton>
        </Body>
        <SocialNetworksFooter />
      </Container>
    </CustomDialog>
  )
}

export default TrialPeriodNotification
