import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showBlur: false,
}

const blurSlice = createSlice({
  name: 'blur',
  initialState,
  reducers: {
    showBlur: (state) => {
      state.showBlur = true
    },
    hideBlur: (state) => {
      state.showBlur = false
    },
  },
})

export const { showBlur, hideBlur } = blurSlice.actions

export default blurSlice.reducer
