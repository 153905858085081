import { useTheme } from 'styled-components'
import { useTransition } from 'react-spring'
import { useTranslation } from 'react-i18next'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
} from 'react'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'

import {
  PseudoLabel,
  AnimatedNumber,
  CustomInputField,
  CustomInputValue,
  CustomInputWrapper,
  CustomInputFieldWrapper,
} from './index.style'
import Tooltips from './Tooltips'
import { getStyleParams } from './utils'
import RightButtons from './RightButtons'
import CustomInputLabelBlock from './LabelBlock'
import { CustomInputProps } from './index.types'
import { EMPTY_HANDLER, EMPTY_OBJECT } from 'config/constants'

const CustomInput = memo(
  React.forwardRef(
    (
      {
        onChange = () => '',
        value = '',
        label = '',
        placeholder = '',
        withClearButton = false,
        onKeyUp,
        onKeyDown,
        autoFocus,
        className,
        innerClassName,
        onBlur,
        onFocus = EMPTY_HANDLER,
        additionalValue,
        type = 'text',
        showSearchIcon = false,
        focused = false,
        disabled = false,
        withWhiteBackground = false,
        autoComplete = 'off',
        error = '',
        warning = '',
        isValidating = false,
        isValidated = false,
        id = 'inputField',
        withPasswordTooltip = false,
        email = '',
        dataTest = 'inputField',
        step = 1,
        min = 1,
        max = 100,
        width = '100%',
        padding = '',
        gap = '0.5rem',
        afterLabelPadding = '0.75rem',
        pseudoBeforeText = '',
        pseudoAfterText = '',
        tooltipPlace = 'top-start',
        tooltipOffset = 14,
        errorTranslationParams,
        readOnly = false,
        clearBtnProps = EMPTY_OBJECT,
        size = 'standard',
        withLargeText = false,
        withAddButton = false,
        onAddItem = EMPTY_HANDLER,
        withHideButton = false,
        labelDesc = '',
      }: CustomInputProps,
      propsRef
    ) => {
      const currentRef = useRef<HTMLInputElement>(null)

      const inputRef =
        (propsRef as React.RefObject<HTMLInputElement>) || currentRef

      const theme = useTheme()
      const { t } = useTranslation()

      const showClearButton = withClearButton && value.toString().length > 0
      const showAddButton = withAddButton && String(value).length > 0
      const showHideButton = withHideButton && String(value).length > 0

      const needTooltip = withPasswordTooltip || error || warning

      const [showTooltip, setShowTooltip] = useState<boolean>(false)
      const [animDirection, setAnimDirection] = useState<'up' | 'down' | null>(
        null
      )
      const [isValueHidden, setIsValueHidden] =
        useState<boolean>(withHideButton)

      const {
        iconSize,
        btnSize,
        btnPaddingSize,
        btnBorderRadius,
        inputBorderRadius,
      } = useMemo(
        () => getStyleParams(size, withLargeText),
        [size, withLargeText]
      )

      useEffect(() => {
        let timerId: ReturnType<typeof setTimeout>
        if (needTooltip) {
          setShowTooltip(true)
          timerId = setTimeout(() => {
            setShowTooltip(false)
          }, 3000)
        } else setShowTooltip(false)

        return () => clearTimeout(timerId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [needTooltip])

      const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          if (type === 'number') {
            animDirection && setAnimDirection(null)
          }
          onChange(e.target.value)
        },
        [animDirection, onChange, type]
      )

      const inputBorder = useMemo(() => {
        if (label) return 'none'
        if (warning || withPasswordTooltip)
          return `1.2px solid ${theme.colors.statuses.attention}`
        if (error) return `1.2px solid ${theme.colors.statuses.attention}`
        if (isValidated) return `1.2px solid ${theme.colors.statuses.success}`
        return 'none'
      }, [
        error,
        isValidated,
        label,
        theme.colors.statuses.attention,
        theme.colors.statuses.success,
        warning,
        withPasswordTooltip,
      ])

      const tooltipId = useMemo(() => {
        if (label) return ''
        if (withPasswordTooltip) return 'password-req-tooltip'
        return warning ? `${type}-${id}-warning` : `${type}-${id}-error`
      }, [id, label, type, warning, withPasswordTooltip])

      const getTransitions = useTransition(value, {
        from: {
          transform: `translateY(${animDirection === 'up' ? '-100%' : '100%'})`,
          opacity: 0,
        },
        enter: {
          transform: 'translateY(0%)',
          opacity: 1,
        },
        leave: {
          transform: `translateY(${animDirection === 'up' ? '100%' : '-100%'})`,
          opacity: 0,
        },
        config: { tension: 250 },
      })

      const handleIncrement = useCallback(() => {
        setAnimDirection('up')
        onChange((Number(value) + 1).toString())

        setTimeout(() => {
          setAnimDirection(null)
        }, 200)
      }, [onChange, value])

      const handleDecrement = useCallback(() => {
        setAnimDirection('down')
        onChange((Number(value) - 1).toString())

        setTimeout(() => {
          setAnimDirection(null)
        }, 200)
      }, [onChange, value])

      const onMouseEnter = useCallback(
        () =>
          label || !needTooltip
            ? undefined
            : !showTooltip && setShowTooltip(true),
        [label, needTooltip, showTooltip]
      )

      const onMouseLeave = useCallback(
        () => (label ? () => {} : showTooltip && setShowTooltip(false)),
        [label, showTooltip]
      )

      const onClickPseudoLabel = useCallback(() => {
        inputRef?.current?.focus()
      }, [inputRef])

      const onHideBtnClick = useCallback(
        () => setIsValueHidden(!isValueHidden),
        [isValueHidden]
      )

      return (
        <CustomInputWrapper className={className} width={width} gap={gap}>
          {label && (
            <CustomInputLabelBlock
              {...{
                id,
                type,
                label,
                error,
                onFocus,
                warning,
                isValidated,
                showTooltip,
                needTooltip,
                isValidating,
                setShowTooltip,
                withPasswordTooltip,
                labelDesc,
              }}
            />
          )}
          <Tooltips
            {...{
              id,
              type,
              error,
              email,
              value,
              warning,
              labelDesc,
              showTooltip,
              tooltipPlace,
              tooltipOffset,
              withPasswordTooltip,
              errorTranslationParams,
            }}
          />
          <CustomInputFieldWrapper
            style={{ border: inputBorder }}
            {...{
              size,
              padding,
              focused,
              iconSize,
              showClearButton,
              inputBorderRadius,
              withWhiteBackground,
            }}
            className={innerClassName}
            withBeforeLabel={!!pseudoBeforeText}
            data-tooltip-id={tooltipId}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {/* @ts-ignore */}
            {showSearchIcon && <Icon iconType="search" size={iconSize} />}

            {type === 'number' &&
              !!animDirection &&
              getTransitions((style, item) => (
                <AnimatedNumber {...{ size, style, animDirection }}>
                  <Label>{item}</Label>
                </AnimatedNumber>
              ))}

            {!!pseudoBeforeText.length && String(value).length !== 0 && (
              <PseudoLabel onClick={onClickPseudoLabel}>
                {pseudoBeforeText}
              </PseudoLabel>
            )}
            <CustomInputField
              {...{
                id,
                min,
                max,
                step,
                type: withHideButton
                  ? isValueHidden
                    ? 'password'
                    : 'text'
                  : type,
                onBlur,
                onKeyUp,
                onFocus,
                padding,
                disabled,
                onKeyDown,
                autoFocus,

                autoComplete,
                withLargeText,
                showSearchIcon,
                withWhiteBackground,
              }}
              name={id}
              ref={inputRef}
              spellCheck={false}
              data-test={dataTest}
              onChange={handleChange}
              showClearButton={showClearButton && !disabled}
              value={
                animDirection
                  ? ''
                  : additionalValue
                  ? `${t(additionalValue)} ${value}`
                  : value
              }
              readOnly={!!animDirection || readOnly}
              placeholder={animDirection ? '' : placeholder}
              withBeforeLabel={
                !!pseudoBeforeText.length && String(value).length !== 0
              }
              // padding={padding}
            />
            {pseudoAfterText &&
              pseudoBeforeText.length === 0 &&
              String(value).length !== 0 && (
                <CustomInputValue afterLabelPadding={afterLabelPadding}>
                  <span style={{ opacity: 0, marginRight: '2px' }}>
                    {value}
                  </span>

                  <span style={{ position: 'relative' }}>
                    {pseudoAfterText}
                  </span>
                </CustomInputValue>
              )}
            <RightButtons
              {...{
                min,
                max,
                type,
                value,
                btnSize,
                onChange,
                disabled,
                iconSize,
                onAddItem,
                clearBtnProps,
                showAddButton,
                btnPaddingSize,
                showClearButton,
                btnBorderRadius,
                withWhiteBackground,
                showHideButton,
                isValueHidden,
                onHideBtnClick,
              }}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
            />
          </CustomInputFieldWrapper>
        </CustomInputWrapper>
      )
    }
  )
)

CustomInput.displayName = 'CustomInput'

export default CustomInput
