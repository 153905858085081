import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  structuresParams: [],
}

const compareParamsSlice = createSlice({
  name: 'compareParams',
  initialState,
  reducers: {
    toggleStructureSelectedParam: (state, action) => {
      const { name, category_title, header } = action.payload
      const existingIndex = state.structuresParams.findIndex(
        (el) => el.name === name
      )

      if (existingIndex !== -1) {
        state.structuresParams.splice(existingIndex, 1)
      } else {
        state.structuresParams.push({ name, category_title, header })
      }
    },

    clearStructureSelectedParams: (state) => {
      state.structuresParams = []
    },
    compareParamsRehydrate: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const {
  toggleStructureSelectedParam,
  clearStructureSelectedParams,
  compareParamsRehydrate,
} = compareParamsSlice.actions

export default compareParamsSlice.reducer
