import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  nmr: {
    method: {},
    data: {},
    loading: false,
    error: false,
    error_text: 'error',
    chartActiveAtoms: [],
    additionalAtom: null,
  },
  infrared: {
    data: [],
    loading: false,
    error: false,
    error_text: 'error',
    shootingMethod: { value: 'gas', label: 'gas' },
  },
  smiles: '',
  data: {},
  loading: false,
  error: false,
  error_text: '',
  spectraType: 'nmr',
  ion_mode: { value: 'positive', label: 'Positive' },
  adduct_type: { value: '[M+H]+', label: '[M+H]+' },
  roundingRI: { value: 2, label: '2' },
  roundingMZ: { value: 2, label: '2' },
  calculatedMSType: 'low_energy',
}

const spectraSlice = createSlice({
  name: 'spectra',
  initialState,
  reducers: {
    getMsData: (state) => {
      state.data = {}
      state.loading = true
      state.error = false
    },
    spectraMsSuccess: (state, action) => {
      state.data = action.payload
      state.loading = false
      state.error = false
    },
    spectraMsFail: (state, action) => {
      state.data = {}
      state.loading = false
      state.error = true
      state.error_text = action.payload
    },
    getNmrData: (state) => {
      state.nmr.data = {}
      state.nmr.loading = true
      state.nmr.error = false
    },
    spectraNmrSuccess: (state, action) => {
      state.nmr.data = action.payload
      state.nmr.loading = false
      state.nmr.error = false
    },
    spectraNmrFail: (state, action) => {
      state.nmr.data = {}
      state.nmr.loading = false
      state.nmr.error = true
      state.nmr.error_text = action.payload
    },
    getInfraredData: (state) => {
      state.infrared.data = []
      state.infrared.loading = true
      state.infrared.error = false
    },
    spectraInfraredSuccess: (state, action) => {
      state.infrared.data = action.payload
      state.infrared.loading = false
      state.infrared.error = false
    },
    spectraInfraredFail: (state, action) => {
      state.infrared.data = []
      state.infrared.loading = false
      state.infrared.error = true
      state.infrared.error_text = action.payload
    },
    resetSpectra: (state) => {
      state.nmr.method = {}
      state.nmr.data = {}
      state.nmr.loading = false
      state.nmr.error = false
      state.nmr.error_text = 'error'
      state.nmr.chartActiveAtoms = []
      state.nmr.additionalAtom = { shift: null, intensity: null }
      state.infrared.data = []
      state.infrared.loading = false
      state.infrared.error = false
      state.infrared.error_text = 'error'
      state.infrared.shootingMethod = { value: 'gas', label: 'gas' }
      state.data = {}
      state.ion_mode = { value: 'positive', label: 'Positive' }
      state.adduct_type = { value: '[M+H]+', label: '[M+H]+' }
      state.roundingRI = { value: 2, label: '2' }
      state.roundingMZ = { value: 2, label: '2' }
      state.calculatedMSType = 'low_energy'
      state.infraredShootingMethod = { value: 'gas', label: 'gas' }
      state.loading = false
      state.error = false
    },
    clearSpectraData: (state) => {
      state.nmr.method =
        state.spectraType === 'nmr' ? { ...state.nmr.method } : {}
      state.nmr.data = {}
      state.nmr.loading = false
      state.nmr.error = false
      state.nmr.error_text = 'error'
      state.nmr.chartActiveAtoms = []
      state.nmr.additionalAtom = { shift: null, intensity: null }

      state.infrared.data = []
      state.infrared.loading = false
      state.infrared.error = false
      state.infrared.error_text = 'error'
      state.infrared.shootingMethod =
        state.spectraType === 'infraredSpectrometry'
          ? { ...state.infrared.shootingMethod }
          : { value: 'gas', label: 'gas' }

      state.data = {}

      if (state.spectraType === 'ms') {
        state.ion_mode = { ...state.ion_mode }
        state.adduct_type = { ...state.adduct_type }
        state.roundingRI = { ...state.roundingRI }
        state.roundingMZ = { ...state.roundingMZ }
      } else {
        state.ion_mode = { value: 'positive', label: 'Positive' }
        state.adduct_type = { value: '[M+H]+', label: '[M+H]+' }
        state.roundingRI = { value: 2, label: '2' }
        state.roundingMZ = { value: 2, label: '2' }
      }
      state.calculatedMSType = 'low_energy'
      state.loading = false
      state.error = false
    },
    setSpectraType: (state, action) => {
      state.spectraType = action.payload
    },
    setIonMode: (state, action) => {
      state.ion_mode = action.payload
    },
    setAdductType: (state, action) => {
      state.adduct_type = action.payload
    },
    setRoundingMZ: (state, action) => {
      state.roundingMZ = action.payload
    },
    setRoundingRI: (state, action) => {
      state.roundingRI = action.payload
    },
    setNmrMethod: (state, action) => {
      state.nmr.method = action.payload
    },
    setCalculatedMSType: (state, action) => {
      state.calculatedMSType = action.payload
    },
    setInfraredShootingMethod: (state, action) => {
      state.infrared.shootingMethod = action.payload
    },
    setNmrChartActiveAtom: (state, action) => {
      state.nmr.chartActiveAtoms = action.payload
    },
    setNmrAdditionalAtom: (state, action) => {
      state.nmr.additionalAtom = action.payload
    },
    setSpectraSmiles: (state, action) => {
      state.smiles = action.payload
    },
  },
})

export const {
  getMsData,
  spectraMsSuccess,
  spectraMsFail,
  getNmrData,
  spectraNmrSuccess,
  spectraNmrFail,
  getInfraredData,
  spectraInfraredSuccess,
  spectraInfraredFail,
  resetSpectra,
  clearSpectraData,
  setSpectraType,
  setIonMode,
  setAdductType,
  setRoundingMZ,
  setRoundingRI,
  setNmrMethod,
  setCalculatedMSType,
  setInfraredShootingMethod,
  setNmrChartActiveAtom,
  setNmrAdditionalAtom,
  setSpectraSmiles,
} = spectraSlice.actions

export default spectraSlice.reducer
