import styled from 'styled-components'

import { ElementWithChildren } from 'types/common'

interface ReqWithIconProps extends ElementWithChildren {
  isValid: boolean
}

export const ReqWithIcon = styled.div<ReqWithIconProps>`
  display: flex;
  gap: 0.25rem;
  color: ${({ isValid, theme }) =>
    isValid ? theme.colors.icons.accentPrimary : theme.colors.statuses.error};
`

export const TooltipContainer = styled.div<ElementWithChildren>`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`
export const RequirementsBlock = styled.div<ElementWithChildren>`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
