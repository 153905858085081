import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserTask } from 'types/common'
import { INIT_SETTINGS } from 'pages/SynthCost/config/config'
import { Settings, SynthCostData } from 'pages/SynthCost/index.types'

import { SynthCostInitState, WarningId } from './types/synthcostTypes'

const initialState: SynthCostInitState = {
  task: null,
  data: {},
  loading: false,
  error: '',
  settings: INIT_SETTINGS,
  warning_ids: [],
}

const synthcostSlice = createSlice({
  name: 'synthcost',
  initialState,
  reducers: {
    predict: (state) => {
      state.task = null
      state.data = {}
      state.error = ''
      state.loading = true
    },
    resetPrediction: (state) => {
      state.data = {}
      state.error = ''
      state.task = null
      state.loading = false
    },
    saveSettings: (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload
    },
    resetSettings: (state) => {
      state.settings = initialState.settings
    },
    resetSynthCostTask: (state) => {
      state.task = null
      state.loading = false
    },
    resetSynthCostError: (state) => {
      state.error = ''
    },
    removeSynthWarningId: (state, action: PayloadAction<WarningId>) => {
      state.warning_ids = state.warning_ids.filter(
        (id) => id !== action.payload
      )
    },
    setSynthWarningNotifyIds: (state, action: PayloadAction<WarningId>) => {
      state.warning_ids = [...state.warning_ids, action.payload]
    },
    synthGetTaskSuccess: (state, action: PayloadAction<UserTask>) => {
      state.task = action.payload
    },
    synthGetTaskFail: (state, action: PayloadAction<string>) => {
      state.id = null
      state.error = action.payload
      state.loading = false
    },
    synthGetResultInit: (state) => {
      state.data = {}
    },
    synthGetResultSuccess: (state, action: PayloadAction<SynthCostData>) => {
      state.task = null
      state.data = action.payload
      state.error = ''
      state.loading = false
    },
    synthGetResultFail: (state, action: PayloadAction<string>) => {
      state.data = {}
      state.error = action.payload
      state.loading = false
    },
  },
})

export const {
  predict,
  resetPrediction,
  saveSettings,
  resetSettings,
  resetSynthCostTask,
  resetSynthCostError,
  removeSynthWarningId,
  setSynthWarningNotifyIds,
  synthGetTaskSuccess,
  synthGetTaskFail,
  synthGetResultInit,
  synthGetResultSuccess,
  synthGetResultFail,
} = synthcostSlice.actions

export default synthcostSlice.reducer
