import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
  text: null,
  onConfirm: null,
  description: null,
  confirmFirst: false,
  buttonsTitle: null,
  hideTitle: false,
}

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    confirm: (state, action) => {
      const {
        text,
        onConfirm,
        description,
        confirmFirst,
        buttonsTitle,
        hideTitle,
      } = action.payload || {}
      state.show = true
      state.text = text
      state.onConfirm = onConfirm
      state.description = description
      state.confirmFirst = confirmFirst
      state.buttonsTitle = buttonsTitle
      state.hideTitle = hideTitle
    },
    cancel: (state, action) => {
      return initialState
    },
  },
})

export const { confirm, cancel } = confirmationSlice.actions

export default confirmationSlice.reducer
