import { createSlice } from '@reduxjs/toolkit'

let today_date = new Date()
today_date.setDate(today_date.getDate() + 1)
let year_before_date = new Date()
year_before_date.setFullYear(year_before_date.getFullYear() - 1)
today_date = today_date.toISOString().split('T')[0]
year_before_date = year_before_date.toISOString().split('T')[0]

const initialFilter = {
  with_current_period_items: true,
  with_previous_period_items: true,
  license_is_free_trial: null,
  created_at_from: year_before_date,
  created_at_to: today_date,
  search_input: '',
}
const initialState = {
  selectedChartSection: 1,
  selected_sub_section: 0,
  loading: false,
  filter_type: 'users',
  filter_by: initialFilter,
  group_by: [],
  data: null,
}

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    getStatistics: (state) => {
      state.loading = true
    },
    getStatisticsSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getStatisticsError: (state) => {
      state.loading = false
    },
    selectChartSection: (state, action) => {
      state.selectedChartSection = action.payload
    },
    setStatisticsNameList: (state, action) => {
      const key = `${state.filter_type}_filter`
      if (!state[key]) {
        state[key] = {}
      }
      if (!state[key].name_list) {
        state[key].name_list = []
      }
      state[key].name_list = [...action.payload]
    },
    handleFilterType: (state, action) => {
      state.filter_type = action.payload
    },
    clearStatisticsFilter: (state) => {
      state.filter_by = initialFilter
    },
    setStatisticsFilter: (state, action) => {
      const { key, value } = action.payload || {}
      state.filter_by[key] = value
    },
    setStatisticsSubSection: (state, action) => {
      state.selected_sub_section = action.payload
    },
    setStatisticsLoading: (state, action) => {
      const { value } = action.payload || {}
      state.loading = value
    },
  },
})

export const {
  getStatistics,
  getStatisticsSuccess,
  getStatisticsError,
  selectChartSection,
  setStatisticsNameList,
  handleFilterType,
  clearStatisticsFilter,
  setStatisticsFilter,
  setStatisticsSubSection,
  setStatisticsLoading,
} = statisticsSlice.actions

export default statisticsSlice.reducer
