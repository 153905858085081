import { v4 as uuidv4 } from 'uuid'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  destroyV2,
  fetch,
  patchV2,
  store,
  storeV2,
  updateV2,
} from 'services/rest'
import {
  getMoleculeBasketId,
  getMoleculeServices,
  getMoleculeStructuralData,
} from 'store/selectors'
import { addServiceResponceToData } from 'utils/molecule/addServiceResponceToData'
import { getSortedCategories } from 'utils/molecule/getSortedCategories'
import { handleCalculationsError } from 'utils/molecule/handleCalculationsError'
import {
  addNotification,
  addSimpleNotification,
} from 'store/slices/notificationsSlice'
import {
  editMoleculeNote,
  loadApplicability,
  loadApplicabilitySuccess,
  loadCategories,
  loadCategoriesError,
  loadCategoriesSuccess,
  loadMolecule,
  loadMoleculeError,
  loadMoleculeSuccess,
  loadPersonalProperties,
  loadPersonalPropertiesError,
  loadPersonalPropertiesSuccess,
  loadRuIupac,
  loadRuIupacError,
  loadRuIupacSuccess,
  loadServices,
  loadServicesError,
  loadServicesSuccess,
  setMoleculeNoteDialogId,
  updateMoleculeNoteConfig,
  updateMoleculePropsFinished,
  updateMoleculePropsInit,
  updateMoleculePropsSuccess,
  updatePersonalProperties,
  updatePersonalPropertiesError,
  updatePersonalPropertiesSuccess,
} from 'store/slices/moleculeSlice'
import {
  updateMolecules,
  updateSearchV2ExactResult,
  updateSearchV2SimilarResults,
} from 'store/slices/crudSlice'

const EXCLUDED_SERVICES = ['nmr', 'reactions', 'user_params']

function* getCategories() {
  try {
    const { data } = yield fetch('/categories')
    yield put(
      loadCategoriesSuccess(
        getSortedCategories(data).filter(
          ({ category_name }) => !EXCLUDED_SERVICES.includes(category_name)
        )
      )
    )
  } catch (error) {
    yield put(loadCategoriesError())
  }
}

function* getServices() {
  try {
    const { data } = yield fetch('/services_list')
    yield put(
      loadServicesSuccess(
        data.filter((service) => !EXCLUDED_SERVICES.includes(service.name))
      )
    )
  } catch (error) {
    yield put(loadServicesError())
  }
}

function* getMoleculeProps({ payload }) {
  const { mainId, basketId, isBestMatch } = payload || {}
  let dataToUse
  try {
    if (!Number.isInteger(+mainId)) {
      const { data } = yield storeV2('molecule/preview', {
        params: {
          smiles: mainId,
        },
      })
      const { result } = data || {}
      dataToUse = result
    }
    if (Number.isInteger(+mainId)) {
      const { data: fetchData } = yield fetch(`/molecule/${mainId}`, 2)
      const structural = fetchData?.result || {}
      dataToUse = structural
      // tlight now does not count itself when moving to a molecule, we need to send a request
      yield storeV2('traffic_light/calculate', {
        molecules_ids: [+mainId],
      })
    }

    const services = yield select(getMoleculeServices)

    let isNeedHidePred = false
    if (dataToUse.smiles) {
      const { data } = yield store('/check_inorganic', {
        smiles: dataToUse.smiles,
      })
      if (!data?.result) isNeedHidePred = true
    }

    const canUpdateProps = !!dataToUse.smiles && !!services?.length

    let molData = {
      ...dataToUse,
      structural: dataToUse,
    }

    yield put(
      loadMoleculeSuccess({
        mainId,
        basketId,
        data: molData,
        loading: canUpdateProps,
        isNeedHidePred,
      })
    )

    if (canUpdateProps) {
      yield put(updateMoleculePropsInit({ updateAllProps: !isBestMatch }))
    }
  } catch (error) {
    yield put(loadMoleculeError())
  }
}

function* getSynonyms() {
  try {
    const { baseID } = yield select(getMoleculeStructuralData)
    if (!baseID) return

    const { data } = yield fetch(`/synonym/structure_id/${baseID}`, 2)

    // резервный эндпоинт, если придется где-то искать чисто по синонимам:
    // POST /api/v2/synonym/query

    if (!data?.result) return

    yield put(
      updateMoleculePropsSuccess({
        data: {
          synonyms: data.result,
        },
      })
    )
  } catch (e) {
    console.log(e)
  }
}

function* getCasNumbers() {
  try {
    const { baseID } = yield select(getMoleculeStructuralData)
    if (!baseID) return

    const { data } = yield fetch(`synonym/structure_id/${baseID}/cas`, 2)

    if (!data) return

    yield put(
      updateMoleculePropsSuccess({
        data: {
          cas_number: data,
        },
      })
    )
  } catch (e) {
    console.log(e)
  }
}

function* getServiceData({ name, view_type, functions, method }) {
  try {
    const structural = yield select(getMoleculeStructuralData)
    const basketId = yield select(getMoleculeBasketId)

    const { data } = yield store('/calculations', {
      service: name,
      ...(name === 'iupacarticle' ? { method: 'smiles2iupac' } : {}),
      input: Number.isInteger(basketId)
        ? { ...structural, basketId }
        : structural,
      ...(name === 'rdkit' && {
        method,
        params: {
          smiles: structural.smiles,
        },
      }),
    })

    if (
      data.status === 'ok' &&
      !(name === 'iupacarticle' && data?.result?.success === false)
    ) {
      const { data: updatedData, sources } = addServiceResponceToData(
        view_type,
        name === 'rdkit'
          ? [
              {
                index: `rdkit_${method}`,
                value: data.result,
              },
            ]
          : name === 'iupacarticle'
          ? Array.isArray(data.result)
            ? data.result[0]?.value
            : data?.result?.iupac
          : data.result,
        name
      )

      if (data || sources) {
        yield put(updateMoleculePropsSuccess({ data: updatedData, sources }))
      }
    } else if (
      data.status === 'error' ||
      (name === 'iupacarticle' && data?.result?.success === false)
    ) {
      yield handleCalculationsError(name)
    }
  } catch (error) {
    yield handleCalculationsError(name)
    // todo: add notification
  }
}

function* updateMoleculeProps({ payload }) {
  const { updateAllProps = true } = payload || {}
  const services = yield select(getMoleculeServices)

  const [rdkitFiltered] = services.filter((el) => el?.name === 'rdkit')
  const rdkitList = rdkitFiltered.functions.map((el) =>
    call(getServiceData, { ...rdkitFiltered, method: el?.name })
  )

  const promisesList = []

  if (updateAllProps) {
    for (let i = 0; i < services.length; i++) {
      if (services[i]?.name !== 'rdkit')
        promisesList.push(call(getServiceData, services[i]))
    }

    yield all([
      ...promisesList,
      ...rdkitList,
      call(getSynonyms),
      call(getCasNumbers),
    ])
  } else {
    promisesList.push(
      call(
        getServiceData,
        services.find((el) => el?.name === 'iupacarticle')
      )
    )
    yield all([...promisesList, ...rdkitList, call(getCasNumbers)])
  }

  yield put(updateMoleculePropsFinished())
}

function* loadRuIupacFn({ payload }) {
  const { iupac } = payload || {}
  const isErrorIupac = iupac === 'Unable to generate valid IUPAC name'
  if (isErrorIupac) {
    yield put(loadRuIupacError())
    return
  }
  try {
    const res = yield store('/run-task', {
      service: 'iupac_en_to_ru',
      params: {
        iupac_en: iupac,
      },
    })
    if (res.status === 200) {
      yield put(loadRuIupacSuccess(res.data.result))
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* loadApplicabilityFn({ payload }) {
  const { smiles } = payload || {}
  try {
    const res = yield store('/run-task', {
      service: 'applicability_domain',
      params: {
        smiles: [smiles],
      },
      method: '',
      type: 'instant',
    })
    if (res.status === 200) {
      yield put(loadApplicabilitySuccess(res.data.result[0]))
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* editMoleculeNoteFn({ payload }) {
  const {
    molId,
    basketId,
    params,
    hasNote,
    isBestMatch,
    isSimilarResults,
    isSingleMolecule,
  } = payload || {}
  const { alias, note } = params

  const needDelete = !(alias || note)

  const m = needDelete ? destroyV2 : hasNote ? patchV2 : storeV2
  try {
    const res = yield m(`molecule/molecular_notes/${basketId}/${molId}`, {
      params,
    })
    if (res?.status === 201 || res?.status === 200) {
      // лучшее совпадение
      if (isBestMatch) {
        const exactResult = yield select(
          (state) => state.crud.searchV2.result.exact_match
        )

        // заменяем на карточке заметку на актуальную
        yield put(updateSearchV2ExactResult({ ...exactResult, ...params }))
        // похожие результаты
      } else if (isSimilarResults) {
        const similarResults = yield select(
          (state) => state.crud.searchV2.result.similar_results
        )

        // заменяем на карточке заметку на актуальную
        yield put(
          updateSearchV2SimilarResults(
            similarResults.map((mol) =>
              mol.id === molId ? { ...mol, ...params } : mol
            )
          )
        )
      }
      const molecules = yield select((state) => state.crud.molecules)
      yield put(
        updateMolecules(
          molecules.map((mol) =>
            mol.id === molId ? { ...mol, ...params } : mol
          )
        )
      )

      if (isSingleMolecule) {
        yield put(updateMoleculeNoteConfig({ ...params, basketId }))
      }

      yield put(setMoleculeNoteDialogId(null))

      const notify = {
        id: uuidv4(),
        name: needDelete
          ? 'molecule_viewer.molecule_note_deleted_success'
          : hasNote
          ? 'molecule_viewer.molecule_note_edited_success'
          : 'molecule_viewer.molecule_note_created_success',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      yield put(addNotification(notify))
    }
  } catch (e) {
    console.log('e', e)
  }
}

function* loadPersonalPropertiesFn({ payload }) {
  const { basket_id, structure_id } = payload || {}
  try {
    const { data } = yield fetch(
      `/molecule/custom_mol_params/${basket_id}/${structure_id}`,
      2
    )
    yield put(loadPersonalPropertiesSuccess(data?.custom_mol_params ?? []))
  } catch (error) {
    yield put(loadPersonalPropertiesError())
  }
}

function* updatePersonalPropertiesFn({ payload }) {
  const { data } = payload || {}
  const { baseID: structure_id } = yield select(getMoleculeStructuralData)
  const { id: basket_id } = yield select((state) => state.basket.basketSelected)
  const personalProperties = yield select(
    (state) => state.molecule.personal_properties
  )
  const { data: stateData } = personalProperties ?? []

  try {
    const methodToUse = stateData?.length ? updateV2 : storeV2

    if (Array.isArray(data) && data?.length === 0) {
      yield destroyV2(
        `/molecule/custom_mol_params/${basket_id}/${structure_id}`
      )
      yield put(
        addSimpleNotification({
          text: 'notification.changes_save_success',
        })
      )
    } else {
      yield methodToUse(
        `/molecule/custom_mol_params/${basket_id}/${structure_id}`,
        {
          params: {
            custom_mol_params: data,
          },
        }
      )
      yield put(
        addSimpleNotification({
          text: 'notification.changes_save_success',
        })
      )
    }

    yield put(updatePersonalPropertiesSuccess(data))
  } catch (error) {
    yield put(
      addSimpleNotification({
        text: 'notification.changes_save_error',
      })
    )
    yield put(updatePersonalPropertiesError())
  }
}

export function* getCategoriesWatcher() {
  yield takeEvery(loadCategories.type, getCategories)
}

export function* getServicesWatcher() {
  yield takeEvery(loadServices.type, getServices)
}

export function* getMoleculePropsWatcher() {
  yield takeEvery(loadMolecule.type, getMoleculeProps)
}

export function* updateMoleculePropsWatcher() {
  yield takeEvery(updateMoleculePropsInit.type, updateMoleculeProps)
}

export function* loadRuIupacWatcher() {
  yield takeEvery(loadRuIupac.type, loadRuIupacFn)
}

export function* loadApplicabilityWatcher() {
  yield takeEvery(loadApplicability.type, loadApplicabilityFn)
}

export function* editMoleculeNoteWatcher() {
  yield takeEvery(editMoleculeNote.type, editMoleculeNoteFn)
}
export function* loadPersonalPropertiesWatcher() {
  yield takeEvery(loadPersonalProperties.type, loadPersonalPropertiesFn)
}

export function* updatePersonalPropertiesWatcher() {
  yield takeEvery(updatePersonalProperties.type, updatePersonalPropertiesFn)
}

const watchers = [
  getCategoriesWatcher(),
  getServicesWatcher(),
  getMoleculePropsWatcher(),
  updateMoleculePropsWatcher(),
  loadRuIupacWatcher(),
  loadApplicabilityWatcher(),
  editMoleculeNoteWatcher(),
  loadPersonalPropertiesWatcher(),
  updatePersonalPropertiesWatcher(),
]
export default watchers
