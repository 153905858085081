import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  section: 'users_and_companies',
  addCompanyDialogOpen: false,
  currentCustomerId: '',
  currentLicenseId: '',
  filter: {
    type: 'users',
    licenseActivationDate: { from: '', to: '' },
    licenseExpDate: { from: '', to: '' },
    status: 'all',
    users: {
      activity_id_list: [],
      status: {
        id: -1,
        value: -1,
        label: 'admin_users_customers_filters.all',
        filter_config: {},
      },
      input_value: '',
    },
    customers: {
      status: {
        id: -1,
        value: -1,
        label: 'admin_users_customers_filters.all',
        filter_config: {},
      },
      input_value: '',
    },
  },
  customers: {
    total: null,
    items: [],
    loading: false,
    error: false,
    firstRequest: true,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  currentCustomer: {
    item: {},
    loading: false,
    error: false,
  },
  currentCustomerUsers: {
    items: [],
    loading: false,
    error: false,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  users: {
    total: null,
    items: [],
    loading: false,
    error: false,
    firstRequest: true,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  applications: {
    total: null,
    notHandledTotal: null,
    items: [],
    loading: false,
    error: false,
    firstRequest: true,
    updating: false,
    openedApplicationDialog: null,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  addCompany: {
    stage: 'create',
    item: {},
    loading: false,
    error: false,
  },
}

export const customerUpdate = createAction('adminPanel/customerUpdateInit')
export const licenseToggleAccess = createAction(
  'adminPanel/licenseToggleAccessInit'
)
export const licenseUserToggleAccess = createAction(
  'adminPanel/licenseUserToggleAccessInit'
)
export const removeUserFromCompany = createAction(
  'adminPanel/removeUserFromCompanyInit'
)
export const refreshCustomerToken = createAction(
  'adminPanel/refreshCustomerToken'
)

const adminPanelSlice = createSlice({
  name: 'adminPanel',
  initialState,
  reducers: {
    getCustomers: (state) => {
      state.customers.items = []
      state.customers.loading = true
      state.customers.error = false
      state.customers.firstRequest = false
    },
    getCustomersSuccess: (state, action) => {
      const { total, items } = action.payload || {}
      state.customers.total = total
      state.customers.items = items
      state.customers.loading = false
      state.customers.error = false
      state.customers.firstRequest = false
    },
    getCurrentCustomer: (state) => {
      state.currentCustomer.item = {}
      state.currentCustomer.loading = true
      state.currentCustomer.error = false
    },
    updateCurrentCustomerLicenseUsers: (state, action) => {
      const { withLicense } = action.payload
      state.currentCustomer.item.license_users_count = withLicense
        ? state.currentCustomer.item.license_users_count + 1
        : state.currentCustomer.item.license_users_count - 1
    },
    getUsers: (state) => {
      state.users.items = []
      state.users.loading = true
      state.users.error = false
      state.users.firstRequest = false
    },
    getApplications: (state) => {
      state.applications.items = []
      state.applications.loading = true
      state.applications.error = false
      state.applications.firstRequest = false
    },
    openApplicationDialog: (state, action) => {
      state.applications.openedApplicationDialog = action.payload
    },
    closeApplicationDialog: (state) => {
      state.applications.openedApplicationDialog = null
    },
    updateApplication: (state) => {
      state.applications.updating = true
    },
    getCurrentCustomerUsers: (state) => {
      state.currentCustomerUsers.items = []
      state.currentCustomerUsers.loading = true
      state.currentCustomerUsers.error = false
    },
    setAdminPanelSection: (state, action) => {
      state.section = action.payload
    },
    setAdminPanelFilterType: (state, action) => {
      state.filter.type = action.payload
    },
    setLicenseActivationDate: (state, action) => {
      const { date, inputType } = action.payload
      state.filter.licenseActivationDate[inputType] = date
    },
    setLicenseExpirationDate: (state, action) => {
      const { date, inputType } = action.payload
      state.filter.licenseExpDate[inputType] = date
    },

    setFilterStatus: (state, action) => {
      state.filter.status = action.payload
    },
    setUsersPaginationConfig: (state, action) => {
      state.users.pagination = action.payload
    },
    setApplicationsPaginationConfig: (state, action) => {
      state.applications.pagination = action.payload
    },
    setCurrentCustomerUsersPaginationConfig: (state, action) => {
      state.currentCustomerUserspagination = action.payload
    },
    setCustomersPaginationConfig: (state, action) => {
      state.customers.pagination = action.payload
    },
    setCurrentCustomerId: (state, action) => {
      const { customerId, licenseId } = action.payload
      state.currentCustomerId = customerId
      state.currentLicenseId = licenseId
    },
    openAddCompanyDialog: (state) => {
      state.addCompanyDialogOpen = true
    },
    closeAddCompanyDialog: (state) => {
      state.addCompanyDialogOpen = false
    },
    setAddCompanyStage: (state, action) => {
      state.addCompany.stage = action.payload
    },
    addNewCompany: (state) => {
      state.addCompany.loading = true
      state.addCompany.error = false
      state.addCompany.item = {}
    },
    setAdminFilterUsers: (state, action) => {
      const { key, value } = action.payload
      state.filter.users[key] = value
    },
    setAdminFilterCustomers: (state, action) => {
      const { key, value } = action.payload
      state.filter.customers[key] = value
    },
    clearAdminFilter: (state, action) => {
      state.filter.users.activity_id_list = []
      state.filter.users.status = {
        id: -1,
        value: -1,
        label: 'admin_users_customers_filters.all',
        filter_config: {},
      }
      state.filter.users.input_value = ''
      state.filter.customers.status = {
        id: -1,
        value: -1,
        label: 'admin_users_customers_filters.all',
        filter_config: {},
      }
      state.filter.customers.input_value = ''
    },
    addNewCompanySuccess: (state, action) => {
      state.addCompany.loading = false
      state.addCompany.error = false
      state.addCompany.item = action.payload
    },
    addNewCompanyFail: (state) => {
      state.addCompany.stage = 'create'
      state.addCompany.loading = false
      state.addCompany.error = true
      state.addCompany.item = {}
    },
    getCustomersFail: (state) => {
      state.customers.total = null
      state.customers.items = []
      state.customers.loading = false
      state.customers.error = true
      state.customers.firstRequest = false
    },
    getCurrentCustomerSuccess: (state, action) => {
      state.currentCustomer.item = { ...action.payload?.[0] }
      state.currentCustomer.loading = false
      state.currentCustomer.error = false
    },
    getCurrentCustomerFail: (state) => {
      state.currentCustomer.item = {}
      state.currentCustomer.loading = false
      state.currentCustomer.error = true
    },
    getUsersSuccess: (state, action) => {
      const { total, items } = action.payload
      state.users.total = total
      state.users.items = items
      state.users.loading = false
      state.users.error = false
      state.users.firstRequest = false
    },
    getUsersFail: (state) => {
      state.users.total = null
      state.users.items = []
      state.users.loading = false
      state.users.error = true
      state.users.firstRequest = false
    },
    updateApplicationSuccess: (state, action) => {
      const { id, value, needUpdateTotal } = action.payload
      state.applications.items = state.applications.items.map((el) =>
        el.id === id ? { ...el, is_handled: value } : el
      )
      state.applications.updating = false
      if (needUpdateTotal) {
        state.applications.notHandledTotal =
          state.applications.notHandledTotal - 1
      }
    },
    updateApplicationFail: (state) => {
      state.applications.updating = false
    },
    getApplicationsSuccess: (state, action) => {
      const { total, items, isFirstRequest, notHandledTotal } = action.payload
      state.applications.total = total
      state.applications.items = items
      state.applications.loading = false
      state.applications.error = false
      state.applications.firstRequest = false
      state.applications.notHandledTotal = isFirstRequest
        ? notHandledTotal
        : state.applications.notHandledTotal
    },
    getApplicationsFail: (state) => {
      state.applications.total = null
      state.applications.items = []
      state.applications.loading = false
      state.applications.error = true
      state.applications.firstRequest = false
    },
    getCurrentCustomerUsersSuccess: (state, action) => {
      state.currentCustomerUsers.items = action.payload
      state.currentCustomerUsers.loading = false
      state.currentCustomerUsers.error = false
    },
    getCurrentCustomerUsersFail: (state) => {
      state.currentCustomerUsers.items = []
      state.currentCustomerUsers.loading = false
      state.currentCustomerUsers.error = true
    },
    licenseToggleAccessSuccess: (state, action) => {
      const { id, is_active } = action.payload
      state.customers.items = state.customers.items.map((el) =>
        el.license_id === id ? { ...el, license_is_active: is_active } : el
      )
    },

    currentCustomerLicenseToggleSuccess: (state, action) => {
      const { id, is_active } = action.payload
      state.currentCustomer.item.license_is_active = is_active
    },
    licenseUserToggleAccessSuccess: (state, action) => {
      const { user_id, license_id, customer_id } = action.payload

      state.users.items = state.users.items.map((el) =>
        el.user_id === user_id ? { ...el, license_id: license_id } : el
      )

      state.customers.items = state.customers.items.map((item) =>
        item?.customer_id === customer_id
          ? {
              ...item,
              license_users_count: license_id
                ? item.license_users_count + 1
                : item.license_users_count - 1,
            }
          : item
      )
    },
    removeUserFromCompanySuccess: (state, action) => {
      const { user_id } = action.payload
      state.users.items = state.users.items.map((el) =>
        el.user_id === user_id
          ? {
              ...el,
              customer_id: null,
              license_id: null,
              license_is_free_trial: null,
              license_is_active: null,
              license_expired_at: null,
            }
          : el
      )
    },
    currentCustomerLicenseUserToggleSuccess: (state, action) => {
      const { user_id, license_id } = action.payload
      state.currentCustomerUsers.items = state.currentCustomerUsers.items.map(
        (el) =>
          el.user_id === user_id ? { ...el, license_id: license_id } : el
      )
    },
    customerEditSuccess: (state, action) => {
      const { licenseResult, customerResult } = action.payload
      const { admin, name } = customerResult
      const { max_users_count, started_at, expired_at, id } = licenseResult

      state.customers.items = state.customers.items.map((el) =>
        el.license_id === id
          ? {
              ...el,
              customer_admin: admin,
              customer_name: name,
              license_max_users_count: max_users_count,
              license_started_at: started_at,
              license_expired_at: expired_at,
            }
          : el
      )
    },
    currentCustomerEditSuccess: (state, action) => {
      const { licenseResult, customerResult } = action.payload
      const { admin, name } = customerResult
      const { max_users_count, started_at, expired_at } = licenseResult

      state.currentCustomer.item.customer_admin = admin
      state.currentCustomer.item.customer_name = name
      state.currentCustomer.item.license_max_users_count = max_users_count
      state.currentCustomer.item.license_started_at = started_at
      state.currentCustomer.item.license_expired_at = expired_at
    },
  },
})

export const {
  getCustomers,
  getCurrentCustomer,
  getCustomersSuccess,
  updateCurrentCustomerLicenseUsers,
  getUsers,
  getApplications,
  openApplicationDialog,
  closeApplicationDialog,
  updateApplication,
  getCurrentCustomerUsers,
  setAdminPanelSection,
  setAdminPanelFilterType,
  setLicenseActivationDate,
  setLicenseExpirationDate,
  setFilterStatus,
  setUsersPaginationConfig,
  setApplicationsPaginationConfig,
  setCurrentCustomerUsersPaginationConfig,
  setCustomersPaginationConfig,
  setCurrentCustomerId,
  openAddCompanyDialog,
  closeAddCompanyDialog,
  setAddCompanyStage,
  addNewCompany,
  setAdminFilterUsers,
  setAdminFilterCustomers,
  clearAdminFilter,
  addNewCompanySuccess,
  addNewCompanyFail,
  getCustomersFail,
  getCurrentCustomerSuccess,
  getCurrentCustomerFail,
  getUsersSuccess,
  getUsersFail,
  updateApplicationSuccess,
  updateApplicationFail,
  getApplicationsSuccess,
  getApplicationsFail,
  getCurrentCustomerUsersSuccess,
  getCurrentCustomerUsersFail,
  licenseToggleAccessSuccess,
  currentCustomerLicenseToggleSuccess,
  licenseUserToggleAccessSuccess,
  removeUserFromCompanySuccess,
  currentCustomerLicenseUserToggleSuccess,
  customerEditSuccess,
  currentCustomerEditSuccess,
} = adminPanelSlice.actions

export default adminPanelSlice.reducer
