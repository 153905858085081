import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, FC, memo, useMemo, useState } from 'react'

import Icon from 'components/Icon'
import { checkIfNumbers } from 'components/Filter/utils/utils'

import {
  Range,
  RangeInput,
  RangeSlider,
  RangeValues,
  RangeSelected,
  DualRangeTitle,
  RangeContainer,
  RangeValueBlock,
  DualRangeHideBtn,
  RangeValuesInput,
  DualRangeWrapper,
  RangeInputWrapper,
  RangeValuePlaceholder,
} from './index.style'
import { CustomDualRangeProps } from './index.types'

const CustomDualRange: FC<CustomDualRangeProps> = memo(
  ({
    min,
    max,
    step,
    onChange,
    values,
    defaultValues,
    placeholders,
    disabled = false,
    title = '',
    type: variant = 'grey',
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [isOpened, setIsOpened] = useState<boolean>(true)
    const [isChanging, setIsChanging] = useState<boolean>(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
      if (!checkIfNumbers(e.target.value)) return

      const value = +e.target.value
      const isOutOfRange = value > max || value < min
      const isZeroMoreThanFirst = id === 0 && value > values[1]
      const isFirstLessThanZero = id === 1 && value < values[0]

      if (isOutOfRange || isZeroMoreThanFirst || isFirstLessThanZero) return

      onChange(id, value)
      if (!isChanged) setIsChanged(true)
    }

    const handleOpen = () => setIsOpened(!isOpened)
    const startChange = () => setIsChanging(true)
    const finishChange = () => setIsChanging(false)

    const startValue = useMemo(() => values[0] ?? min, [values, min])
    const endValue = useMemo(() => values[1] ?? max, [values, max])

    return (
      <DualRangeWrapper disabled={disabled} isChanging={isChanging}>
        <DualRangeTitle
          onClick={handleOpen}
          fontWeight={theme.fontWeight.medium}
          lineHeight={theme.text[16]}
          pointer={true}
        >
          {t(title)}
          <DualRangeHideBtn variant={variant}>
            {/* @ts-ignore */}
            <Icon iconType="arrowDown" size="0.75rem" />
          </DualRangeHideBtn>
        </DualRangeTitle>
        <RangeContainer opened={isOpened}>
          <RangeValues variant={variant}>
            <RangeValueBlock isChanged={isChanged}>
              <RangeValuePlaceholder>
                {t(placeholders[0])}
              </RangeValuePlaceholder>
              <RangeValuesInput
                value={values[0]}
                placeholder={`${defaultValues[0]}`}
                onChange={(e) => handleChange(e, 0)}
                disabled={disabled}
              />
            </RangeValueBlock>
            <span>—</span>
            <RangeValueBlock isChanged={isChanged}>
              <RangeValuePlaceholder>
                {t(placeholders[1])}
              </RangeValuePlaceholder>
              <RangeValuesInput
                value={values[1]}
                placeholder={`${defaultValues[1]}`}
                onChange={(e) => handleChange(e, 1)}
                disabled={disabled}
              />
            </RangeValueBlock>
          </RangeValues>
          <Range>
            <RangeSlider>
              <RangeSelected
                style={{
                  left: `calc(${(startValue / max) * 100}% - 0.25rem)`,
                  right: `${100 - (endValue / max) * 100}%`,
                }}
                disabled={disabled}
              />
            </RangeSlider>
            <RangeInputWrapper
              onMouseDown={startChange}
              onMouseUp={finishChange}
            >
              {values.map((value, idx) => {
                return (
                  <RangeInput
                    type="range"
                    name={`${idx}`}
                    key={idx}
                    min={min}
                    max={max}
                    value={value ?? defaultValues[idx]}
                    step={step}
                    onChange={(e) => handleChange(e, idx)}
                    disabled={disabled}
                  />
                )
              })}
            </RangeInputWrapper>
          </Range>
        </RangeContainer>
      </DualRangeWrapper>
    )
  }
)

CustomDualRange.displayName = 'CustomDualRange'
export default CustomDualRange
