import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  basketSelected: null,
  name: '',
  showAddDialog: false,
  desc: '',
  editMode: false,
  editId: null,
  showLogDialog: false,
  selected: [],
  showJoinCopyDialog: null,
  basketsRemoveInProgress: false,
  basketsRemoveError: false,
  searchName: '',
  exportDialog: null,
  datasetTable: null,
  sorting: {
    type: 'relevance',
    direction: 'desc',
  },
}

export const checkCustomerDatasetsAccess = createAction(
  'basket/checkCustomerDatasetsAccess'
)

export const handleTransferToCustomerDatasets = createAction(
  'basket/transferToCustomerDatasets'
)

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    selectBasket: (state, action) => {
      state.basketSelected = action.payload
    },
    setShowAddDialog: (state, action) => {
      state.showAddDialog = action.payload
    },
    setBasketName: (state, action) => {
      state.name = action.payload
    },
    setBasketDesc: (state, action) => {
      state.desc = action.payload
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload
    },
    setEditId: (state, action) => {
      state.editId = action.payload
    },
    setShowLogDialog: (state, action) => {
      state.showLogDialog = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setShowJoinCopyDialog: (state, action) => {
      state.showJoinCopyDialog = action.payload
    },
    removeLastActionsIDsFromDB: (state) => {
      state.basketsRemoveInProgress = true
      state.basketsRemoveError = false
    },
    setBasketsSortingParam: (state, action) => {
      const { key, value } = action.payload || {}
      state.sorting[key] = value
    },
    setBasketsSearchName: (state, action) => {
      state.searchName = action.payload
    },
    showExportDialog: (state, action) => {
      const { id, name, actionType } = action.payload || {}
      if (!state.exportDialog) {
        state.exportDialog = {
          id: null,
          name: null,
          actionType: null,
        }
      }

      state.exportDialog.id = id
      state.exportDialog.name = name
      state.exportDialog.actionType = actionType
    },
    closeExportDialog: (state) => {
      state.exportDialog = null
    },
    showDatasetTable: (state, action) => {
      const { id, name, rows } = action.payload || {}
      if (!state.datasetTable) {
        state.datasetTable = {
          id: null,
          name: null,
          rows: null,
        }
      }
      state.datasetTable.id = id
      state.datasetTable.name = name
      state.datasetTable.rows = rows
    },
    closeDatasetTable: (state) => {
      state.datasetTable = null
    },
    removeBasketIdsFromDbSuccess: (state) => {
      state.basketsRemoveInProgress = false
      state.basketsRemoveError = false
    },
    removeBasketIdsFromDbError: (state) => {
      state.basketsRemoveInProgress = true
      state.basketsRemoveError = true
    },
  },
})

export const {
  selectBasket,
  removeLastActionsIDsFromDB,
  setBasketsSortingParam,
  setBasketsSearchName,
  showExportDialog,
  closeExportDialog,
  showDatasetTable,
  closeDatasetTable,
  removeBasketIdsFromDbSuccess,
  removeBasketIdsFromDbError,
  setShowJoinCopyDialog,
  setSelected,
  setShowLogDialog,
  setEditId,
  setEditMode,
  setBasketDesc,
  setBasketName,
  setShowAddDialog,
} = basketSlice.actions

export default basketSlice.reducer
