import { css } from 'styled-components'

export const scrollbarStyles = css`
  scrollbar-color: rgba(203, 206, 209, 0.6) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.75rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(203, 206, 209, 0.24);
    border-radius: 0.75rem;
    border: 0.25rem solid transparent;
    background-clip: padding-box;
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 0.125rem solid transparent;
  }
`
