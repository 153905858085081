import { createAction, createSlice } from '@reduxjs/toolkit'

export const initialState = {
  open: false,
  config: {
    match_type: 'exact match',
    disabled: false,
    similarity: {
      0: 0,
      1: 100,
    },
    yield: {
      0: 0,
      1: 100,
    },
    compound_types: ['reactants', 'products'],
    reaction_display: ['yield_unknown'],
    molecular_weight_disabled: true,
    molecular_weight_default: { 0: '', 1: '' },
    molecular_weight: { 0: '', 1: '' },
    language: [],
    published_date: {},
  },
}
export const getMarkushMoleculeWeight = createAction(
  'filter/getMarkushMoleculeWeight'
)

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setOpenFilter: (state, action) => {
      state.open = action.payload
    },
    setFilterConfig: (state, action) => {
      state.config = action.payload
    },
    resetFilter: (state) => {
      Object.assign(state.config, initialState.config)
    },
    resetMarkushMoleculeWeight: (state) => {
      state.config.molecular_weight_default = { 0: '', 1: '' }
      state.config.molecular_weight = { 0: '', 1: '' }
      state.config.molecular_weight_disabled = true
    },
    setLeftFilterLitDocType: (state, action) => {
      state.config.document_type = action.payload
    },
    setFilterButtonsActive: (state) => {
      state.config.disabled = false
    },
    setFilterButtonsDisabled: (state) => {
      state.config.disabled = true
    },
    setMarkushMoleculeWeight: (state, action) => {
      state.config.molecular_weight = {
        0: action.payload,
        1: (Number(action.payload) + 100).toFixed(3),
      }
      state.config.molecular_weight_default = {
        0: action.payload,
        1: (Number(action.payload) + 100).toFixed(3),
      }
    },
    setMarkushMoleculeMaxWeight: (state, action) => {
      state.config.molecular_weight[1] = action.payload
    },
    setMarkushInputsDisabled: (state, action) => {
      state.config.molecular_weight_disabled = action.payload
    },
  },
})

export const {
  setOpenFilter,
  resetMarkushMoleculeWeight,
  resetFilter,
  setMarkushMoleculeWeight,
  setMarkushMoleculeMaxWeight,
  setFilterButtonsDisabled,
  setFilterButtonsActive,
  setLeftFilterLitDocType,
  setMarkushInputsDisabled,
  setFilterConfig,
} = filterSlice.actions

export default filterSlice.reducer
