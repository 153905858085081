import styled from 'styled-components'

import CustomButton from '../customButton'
import { scrollbarStyles } from '../scrollbar/scrollbar.style'
import { BackdropProps, DialogDefaultProps } from './index.types'

interface CustomDialogHeaderProps extends Pick<DialogDefaultProps, 'padding'> {}

export const Backdrop = styled.div<BackdropProps>`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex || 2000};
  width: 100vw;
  height: 100vh;
  background: ${({ withBackdrop, theme }) =>
    withBackdrop && theme.colors.backgrounds.blur};
  backdrop-filter: ${({ withBackdrop }) => withBackdrop && 'blur(6px)'};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media print {
    position: static;
    width: 100%;
    height: auto;
    backdrop-filter: none;
    display: block;
  }
`

export const Dialog = styled.div<DialogDefaultProps>`
  position: ${({ pos }) => pos};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  display: flex;
  min-width: ${({ minWidth }) => minWidth && minWidth};
  width: ${({ width }) => width || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || 'none'};
  height: ${({ height, maxHeight }) => height || maxHeight || 'auto'};
  padding: ${({ padding }) => padding || '1.5rem'};
  flex-direction: column;
  align-items: ${({ align }) => align || 'flex-start'};
  gap: ${({ gap }) => gap || '1.5rem'};
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  overflow-y: ${({ overflowY }) => overflowY ?? 'default'};
  box-shadow: ${({ theme }) => theme.shadow.default};

  ${scrollbarStyles}

  @media print {
    position: static;
    width: 100%;
    padding: 0;
    box-shadow: none;
    max-height: none;
    padding: 0;
    display: block;
  }
`

export const CustomDialogHeader = styled.div<CustomDialogHeaderProps>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.25rem;
  word-break: break-word;
  padding: ${({ padding }) => padding && padding};
`

export const StyledButton = styled(CustomButton as any)`
  position: absolute;
  top: 0rem;
  width: ${({ size }) => (size === 'big' ? '3.5rem' : '3.25rem')};
  height: ${({ size }) => (size === 'big' ? '3.5rem' : '3.25rem')};
  right: 0rem;
  z-index: 10;
`
