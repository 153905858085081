import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAuth } from '../../utils/auth/auth'
import { fetch } from 'services/rest'
import { updateLanguage } from 'utils/common/common'
import { useDispatch } from 'react-redux'
import { loadMe } from 'store/slices/authSlice'
import { getStructuresComparisonList } from 'store/slices/structuresCompareSlice'

const Loader = () => {
  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.settings.lang)

  const checkLanguage = useCallback(async () => {
    const { data } = await fetch('/user/language')
    if (lang !== data?.current_language) {
      updateLanguage(lang)
    }
  }, [lang])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadMe())
      checkLanguage()
      dispatch(getStructuresComparisonList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <div />
}

export default Loader
