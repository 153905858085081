import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  withSearch: null,
  baskets: [],
  openedBasketID: null,
  molecules: [],
  public: true,
  error: false,
  search: [],
  lastChange: [],
  searchString: '',
  loading: false,
  moleculesLoading: false,
  lastChangeLoading: false,
  pending: false,
  isBasketsAfterSearch: false,
  searchV2: {
    ids: [],
    smiles: '',
    text: '',
    label: '',
    name: '',
    status: 'hold',
    total: 0,
    result: {
      exact_match: null,
      similar_results: [],
    },
  },
  bingoSearch: {
    status: 'hold',
    result: [],
    cache: {
      pageNumber: 0,
      pageItems: [],
    },
    taskId: '',
    isLongTask: false,
  },
  litSearch: {
    status: 'hold',
    result: {
      smiles: '',
      data: [],
    },
    taskId: '',
    totalLitFound: 0,
  },
  reactionsSearch: {
    status: 'hold',
    result: {
      data: [],
    },
    count: 0,
  },
}

export const calcTlight = createAction('crud/calcTlight')
export const showSearchV2Result = createAction('crud/showSearchV2Result')
export const rehydrateStore = createAction('hydration/rehydrate')

const crudSlice = createSlice({
  name: 'crud',
  initialState,
  reducers: {
    loadBaskets: (state, action) => {
      state.baskets = []
      state.isBasketsAfterSearch = false
      state.loading = true
      state.error = false
    },
    loadBasketsSuccess: (state, action) => {
      const { data, isBasketsAfterSearch } = action.payload || {}
      state.baskets = data
      state.isBasketsAfterSearch = isBasketsAfterSearch
      state.loading = false
      state.search = []
    },
    loadBasketsFail: (state, action) => {
      state.baskets = []
      state.error = action.payload
      state.loading = false
    },
    loadLastChange: (state, action) => {
      state.lastChangeLoading = true
      state.error = false
    },
    loadLastChangeSuccess: (state, action) => {
      state.lastChange = action.payload
      state.lastChangeLoading = false
      state.error = false
    },
    loadLastChangeFail: (state, action) => {
      state.lastChangeLoading = false
      state.error = action.payload
    },
    updateCrudState: (state, action) => {
      return { ...state, ...action.payload }
    },
    loadMoreMolecules: (state) => {
      state.loading = true
      state.moleculesLoading = true
    },
    loadMoreMoleculesSuccess: (state, action) => {
      state.molecules = [...action.payload]
      state.loading = false
      state.moleculesLoading = false
      state.error = false
    },
    loadMolecules: (state) => {
      state.molecules = []
      state.loading = true
      state.moleculesLoading = true
      state.error = false
      state.pending = false
    },
    loadMoleculesFail: (state, action) => {
      state.molecules = []
      state.openedBasketID = null
      state.error = action.payload
      state.loading = false
      state.moleculesLoading = false
      state.public = true
    },
    loadMoleculesSuccess: (state, action) => {
      const {
        data,
        withSearch,
        public: payload_public,
        pending,
        openedBasketID,
      } = action.payload || {}
      state.molecules = data
      state.withSearch = withSearch
      state.loading = false
      state.moleculesLoading = false
      state.public = payload_public
      state.error = false
      state.pending = pending
      state.openedBasketID = openedBasketID
    },
    resetMolecules: (state) => {
      state.molecules = []
      state.openedBasketID = null
      state.error = false
      state.loading = false
      state.moleculesLoading = false
      state.public = true
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setSearchError: (state) => {
      state.searchV2.status = 'error'
    },
    setSearchLoading: (state) => {
      state.searchV2.status = 'loading'
    },
    setSearchResult: (state, action) => {
      const { result, total } = action.payload || {}
      state.searchV2.status = 'done'
      state.searchV2.result = result
      state.searchV2.total = total
    },
    setSearchTransitLoading: (state) => {
      state.searchV2.status = 'transit_loading'
    },
    setSearchTransitError: (state) => {
      state.searchV2.status = 'transit_error'
    },
    setSearchTransitDone: (state) => {
      state.searchV2.status = 'transit_done'
    },
    deleteMolecule: (state) => {
      state.loading = true
      state.error = false
    },
    deleteMoleculeFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMoleculeSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    moveMolecules: (state) => {
      state.loading = true
      state.error = false
    },
    moveMoleculesFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    copyMolecules: (state) => {
      state.loading = true
      state.error = false
    },
    copyMoleculesFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    moveMoleculesSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    addMolecule: (state, action) => {
      state.loading = true
      state.error = false
    },
    addMoleculeFail: (state) => {
      state.loading = false
      state.error = false
    },
    addMoleculeSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    addMoleculesFromFile: (state) => {
      state.loading = true
      state.error = false
    },
    addMoleculesFromFileFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    addMoleculesFromFileSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    addMoleculesPack: (state) => {
      state.loading = true
      state.error = false
    },
    addMoleculesPackFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    addMoleculesPackSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    updateMolecule: (state) => {
      state.loading = true
      state.error = false
    },
    updateMoleculeFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    updateMoleculeSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    joinBaskets: (state) => {
      state.loading = true
      state.error = false
    },
    joinBasketsFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    joinBasketsSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    addBasket: (state) => {
      state.loading = true
      state.error = false
    },
    addBasketFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    addBasketSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    deleteBasket: (state) => {
      state.loading = true
      state.error = false
    },
    deleteBasketFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteBasketSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    copyBasket: (state) => {
      state.loading = true
      state.error = false
    },
    copyBasketFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    copyBasketSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    updateBasket: (state) => {
      state.loading = true
      state.error = false
    },
    updateBasketFail: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    updateBasketSuccess: (state) => {
      state.loading = false
      state.error = false
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload
    },
    setSearchV2: (state, action) => {
      const { label = '', name = '' } = action.payload || {}
      state.searchV2.label = label
      state.searchV2.name = name
    },
    resetSearch: (state) => {
      state.searchV2 = { ...initialState.searchV2 }
      state.bingoSearch = { ...initialState.bingoSearch }
    },
    setBingoSearch: (state) => {
      state.bingoSearch.result = []
    },
    setBingoSearchTaskId: (state, action) => {
      state.bingoSearch.taskId = action.payload
    },
    setBingoSearchIsLongtask: (state, action) => {
      state.bingoSearch.isLongTask = action.payload
    },
    setBingoSearchLoading: (state) => {
      state.bingoSearch.status = 'loading'
    },
    setBingoSearchError: (state) => {
      state.bingoSearch.status = 'error'
      state.bingoSearch.isLongTask = false
    },
    setBingoSearchResult: (state, action) => {
      const { result, total } = action.payload || {}
      state.bingoSearch.status = 'done'
      state.bingoSearch.result = [...result]
      state.bingoSearch.total = total || null
    },
    setBingoSearchState: (state, action) => {
      state.bingoSearch = action.payload
    },
    setSearchV2State: (state, action) => {
      state.searchV2 = action.payload
    },
    setLitSearchState: (state, action) => {
      state.litSearch = action.payload
    },
    setReactionsSearchState: (state, action) => {
      state.reactionsSearch = action.payload
    },
    setCachedPage: (state, action) => {
      const { pageItems, pageNumber } = action.payload || {}
      state.bingoSearch.cache.pageItems = [...pageItems]
      state.bingoSearch.cache.pageNumber = pageNumber
    },
    cancelBingoSearch: (state) => {
      state.bingoSearch.status = 'done'
      state.bingoSearch.result = []
    },
    setLiteratureSearch: (state) => {
      state.litSearch.result.smiles = ''
      state.litSearch.result.data = []
    },
    setLiteratureSearchResult: (state, action) => {
      const { status, result, totalLitFound } = action.payload || {}
      state.litSearch.status = status || 'done'
      state.litSearch.result.data = result.result.data
      state.litSearch.result.smiles =
        result.metadata.query.params?.structures_ids?.smiles_list?.[0]
      state.litSearch.totalLitFound = totalLitFound
    },
    setLiteratureSearchLoading: (state) => {
      state.litSearch.status = 'loading'
    },
    setLiteratureSearchError: (state) => {
      state.litSearch.status = 'error'
      state.litSearch.taskId = ''
    },
    setLiteratureSearchTaskId: (state, action) => {
      state.litSearch.taskId = action.payload
    },
    resetLitSearch: (state) => {
      state.litSearch.status = 'hold'
      state.litSearch.result.smiles = ''
      state.litSearch.result.data = []
      state.litSearch.taskId = ''
      state.litSearch.totalLitFound = 0
    },
    setReactionsSearch: (state) => {
      state.reactionsSearch.result.data = []
    },
    setReactionsSearchResult: (state, action) => {
      const { status, result } = action.payload || {}
      const { items, count } = result || {}
      state.reactionsSearch.status = status || 'done'
      state.reactionsSearch.result.data = items
      state.reactionsSearch.count = count
    },
    setReactionsSearchLoading: (state) => {
      state.reactionsSearch.status = 'loading'
    },
    setReactionsSearchError: (state) => {
      state.reactionsSearch.status = 'error'
    },

    resetReactionsSearch: (state) => {
      state.reactionsSearch.status = 'hold'
      state.reactionsSearch.result.data = []
      state.reactionsSearch.count = 0
    },
    updateMolecules: (state, action) => {
      state.molecules = action.payload
    },
    updateSearchV2ExactResult: (state, action) => {
      state.searchV2.result.exact_match = action.payload
    },
    updateSearchV2SimilarResults: (state, action) => {
      state.searchV2.result.similar_results = action.payload
    },
  },
})

export const {
  loadBaskets,
  loadBasketsSuccess,
  loadBasketsFail,
  loadLastChange,
  loadLastChangeSuccess,
  loadLastChangeFail,
  updateCrudState,
  loadMoreMolecules,
  loadMoreMoleculesSuccess,
  loadMolecules,
  loadMoleculesFail,
  loadMoleculesSuccess,
  resetMolecules,
  setSearch,
  setSearchError,
  setSearchLoading,
  setSearchResult,
  setSearchTransitLoading,
  setSearchTransitError,
  setSearchTransitDone,
  deleteMolecule,
  deleteMoleculeFail,
  deleteMoleculeSuccess,
  moveMolecules,
  moveMoleculesFail,
  moveMoleculesSuccess,
  copyMolecules,
  copyMoleculesFail,
  addMolecule,
  addMoleculeFail,
  addMoleculeSuccess,
  addMoleculesFromFile,
  addMoleculesFromFileFail,
  addMoleculesFromFileSuccess,
  addMoleculesPack,
  addMoleculesPackFail,
  addMoleculesPackSuccess,
  updateMolecule,
  updateMoleculeFail,
  updateMoleculeSuccess,
  joinBaskets,
  joinBasketsFail,
  joinBasketsSuccess,
  addBasket,
  addBasketFail,
  addBasketSuccess,
  deleteBasket,
  deleteBasketFail,
  deleteBasketSuccess,
  copyBasket,
  copyBasketFail,
  copyBasketSuccess,
  updateBasket,
  updateBasketFail,
  updateBasketSuccess,
  setSearchString,
  setSearchV2,
  resetSearch,
  setBingoSearch,
  setBingoSearchTaskId,
  setBingoSearchIsLongtask,
  setBingoSearchLoading,
  setBingoSearchError,
  setBingoSearchResult,
  setBingoSearchState,
  setSearchV2State,
  setLitSearchState,
  setReactionsSearchState,
  setCachedPage,
  cancelBingoSearch,
  setLiteratureSearch,
  setLiteratureSearchResult,
  setLiteratureSearchLoading,
  setLiteratureSearchError,
  setLiteratureSearchTaskId,
  resetLitSearch,
  setReactionsSearch,
  setReactionsSearchResult,
  setReactionsSearchLoading,
  setReactionsSearchError,
  resetReactionsSearch,
  updateMolecules,
  updateSearchV2ExactResult,
  updateSearchV2SimilarResults,
} = crudSlice.actions

export default crudSlice.reducer
