import { memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as FileSaver from 'file-saver'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetch } from 'services/rest'
import DatasetTable from 'components/DatasetTable'
import { CustomDialog } from 'components/common/customDialog'
import ExportBasketDialog from 'components/ExportBasketDialog'
import { parseCSVString } from './utils/utils'
import {
  closeDatasetTable,
  closeExportDialog,
  setSelected,
  showDatasetTable,
} from 'store/slices/basketSlice'
import { addNotification } from 'store/slices/notificationsSlice'
import { loadMolecules } from 'store/slices/crudSlice'
import { shallowEqual } from 'react-redux'

const DatasetDialogs = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const tasksStatus = useSelector((state) => state.tasks, shallowEqual)
  const { exportDialog, datasetTable, selected } = useSelector(
    (state) => state.basket || {}
  )

  const { id: datasetId, name: datasetName, actionType } = exportDialog || {}

  const getDataForDatasetTable = async (id) => {
    try {
      const { data } = await fetch(
        `/download_basket?type=csv&basket_id=${id}&is_tabular_analyze=true`
      )

      const parsedData = parseCSVString(data)
      return parsedData
    } catch (e) {
      console.log(e)
    }
  }

  const handleShowDatasetTable = async () => {
    const parsedData = await getDataForDatasetTable(datasetId)

    dispatch(closeExportDialog())

    if (!parsedData?.length) {
      const notify = {
        id: uuidv4(),
        name: 'dataset_table.failed_to_open',
        text: 'dataset_table.dataset_is_empty',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }

      dispatch(addNotification(notify))
      return
    }

    // необходима актуализация светофоров в карточках после расчета датасета
    // при условии что мы открываем табличный анализ, находясь в этом же датасете
    if (location?.pathname === `/molecules/${datasetId}`)
      dispatch(loadMolecules({ basket: datasetId }))

    dispatch(
      showDatasetTable({ id: datasetId, name: datasetName, rows: parsedData })
    )
  }

  const calculateForExport = async () => {
    try {
      await fetch(`/calc_basket?basket_id=${datasetId}`)
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: 'baskets.failed_to_calculate_dataset',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }

      dispatch(addNotification(notify))
    }
  }

  const handleDownload = async (format) => {
    dispatch(closeExportDialog())
    const notify = {
      id: uuidv4(),
      name: 'baskets.export_in_progress',
      text: 'baskets.export_in_progress_small',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }

    dispatch(addNotification(notify))

    try {
      if (selected.length !== 0) dispatch(setSelected([]))

      const response = await fetch(
        `/download_basket?type=${format}&basket_id=${datasetId}`
      )

      if (response.status !== 200) {
        const errorText = response.data?.message || response.statusText
        throw new Error(`Error: ${errorText}`)
      }

      if (format === 'xlsx') {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        FileSaver.saveAs(blob, `syntelly-${datasetId}.xlsx`)
      } else {
        const blob = new Blob([response.data], {
          type: 'application/json',
        })

        FileSaver.saveAs(blob, `syntelly-${datasetId}.${format}`)
      }

      const successNotify = {
        id: uuidv4(),
        name: 'baskets.export_success',
        text: 'baskets.export_success',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }

      dispatch(addNotification(successNotify))
    } catch (e) {
      console.error(e)
      const errorNotify = {
        id: uuidv4(),
        name: 'baskets.export_error',
        text: 'baskets.export_error',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }

      dispatch(addNotification(errorNotify))
    }
  }

  return (
    <>
      {!!datasetId && (
        <ExportBasketDialog
          datasetName={datasetName ?? ''}
          handleShowDatasetTable={handleShowDatasetTable}
          handleClose={() => dispatch(closeExportDialog())}
          calculateForExport={calculateForExport}
          tasksStatus={tasksStatus}
          datasetId={datasetId}
          type={actionType}
          handleDownload={handleDownload}
        />
      )}

      {!!datasetTable && (
        <CustomDialog
          width="98vw"
          maxHeight="98vh"
          onClose={() => dispatch(closeDatasetTable())}
          withPortal={true}
          id="dataset-table-dialog"
          exceptionalParentIds={['baskets-list-modal']}
          outboundExceptions={['notifications-container']}
        >
          <DatasetTable
            rows={datasetTable.rows}
            basketName={datasetTable.name}
          />
        </CustomDialog>
      )}
    </>
  )
}

DatasetDialogs.displayName = 'DatasetDialogs'

export default DatasetDialogs
