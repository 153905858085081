/*eslint-disable*/
import React, { useEffect } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { YMInitializer } from 'react-yandex-metrika'
import '../../styles/basic.css'
import 'katex/dist/katex.min.css'
import store from '../../store'
import history from '../../services/history'
import { AuthProvider } from '../../utils/auth/auth'
import ViewContainer from '../ViewContainer'
import { hotjar } from 'react-hotjar'
import TechnicalWorks from 'components/TechnicalWorks'
import ErrorBoundary from 'components/ErrorBoundary'
import { ThemeProvider } from 'styled-components'
import { projectTheme } from 'styles/theme'
import ContextMenu from 'components/common/contextMenu'
import { ANALYTICS_ENABLE } from 'config/config'
import { YM_ACCOUNTS, YM_CONFIG } from './config/config'

const App = () => {
  // const isTechnicalWorksActive = false
  // const targetTechnicalDate = new Date(Date.UTC(2023, 10, 4, 0, 0, 0)) //04.11.2023 00-00 Moscow

  useEffect(() => {
    if (ANALYTICS_ENABLE) {
      hotjar.initialize(2409731, 6)
    }
  }, [])

  // if (isTechnicalWorksActive)
  //   return <TechnicalWorks targetDate={targetTechnicalDate} />

  return (
    <AuthProvider>
      <Provider store={store}>
        <ContextMenu />
        <ThemeProvider theme={projectTheme}>
          {ANALYTICS_ENABLE && (
            <YMInitializer
              accounts={YM_ACCOUNTS}
              options={YM_CONFIG}
              version="2"
            />
          )}
          <ErrorBoundary>
            <ConnectedRouter history={history}>
              <ViewContainer />
            </ConnectedRouter>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  )
}

export default App
