import React, { useState, useEffect, useMemo, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  NewButton,
  Wrapper,
  ModalHeader,
  ModalDialog,
  Modal,
  ModalBtns,
  SelectedList,
  ListContainer,
  TagText,
  ListWrapper,
  DatasetTypesSelector,
  DatasetTypes,
  LoaderWrapper,
} from './index.style'
import Icon from 'components/Icon'
import LoaderRestyling from 'components/LoaderRestyling'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import CustomTag from 'components/common/customTag'
import { useTheme } from 'styled-components'
import {
  Caption,
  TextMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import { CustomCloseIcon } from 'components/common/customCloseIcon'
import CustomTab from 'components/common/customTab'
import {
  BASKET_TYPES,
  NON_PUBLIC_BASKET_TYPES,
} from 'pages/Baskets/config/config'
import { CustomDialog } from 'components/common/customDialog'
import { addBasket, loadBaskets } from 'store/slices/crudSlice'

const BasketsListModal = memo(
  ({
    onAgree,
    onClose,
    withPublic,
    withNew = true,
    actionText,
    currentBasketId,
    onlyOne,
    allowedTypes = null,
    defaultDatasetType = 'private',
  }) => {
    const baskets = useSelector((state) => state.crud.baskets)
    const loading = useSelector((state) => state.crud.loading)
    const selectedDatasets = useSelector((state) => state.mmap.selectedDatasets)
    const selected_basket = useSelector((state) => state.basket.basketSelected)

    const dispatch = useDispatch()
    const [selected, setSelected] = useState([])
    const [view, setView] = useState([])
    const [filterValue, setFilterValue] = useState('')
    const [datasetType, setDatasetType] = useState(defaultDatasetType)
    const { t } = useTranslation()
    const theme = useTheme()

    useEffect(() => {
      loadBaskets &&
        dispatch(loadBaskets({ basketType: datasetType, withSearch: false }))
    }, [datasetType, dispatch])

    useEffect(() => {
      const processedSets = baskets.filter(
        (basket) => basket.status !== 'pending'
      )
      setView(processedSets)
    }, [baskets])

    const handleCreate = useCallback(() => {
      dispatch(addBasket({ name: filterValue, datasetType }))
    }, [datasetType, dispatch, filterValue])

    const handleAgree = useCallback(() => {
      onAgree(selected, filterValue)
      onClose()
    }, [filterValue, onAgree, onClose, selected])

    const handleSelect = useCallback(
      (id, name) => () => {
        const isSelected = selected.find(({ id: itemId }) => itemId === id)
        if (isSelected) {
          const filtered = selected.filter((el) => el.id !== id)
          setSelected(filtered)
        } else if (onlyOne && selected.length > 0 && !isSelected)
          setSelected([{ id, name }])
        else setSelected([...selected, { id, name }])
      },
      [onlyOne, selected]
    )

    const handleRemoveSelected = useCallback(
      (id) => {
        const filtered = selected.filter((el) => el.id !== id)
        setSelected(filtered)
      },
      [selected]
    )

    const filtered = useMemo(
      () =>
        (filterValue
          ? view.filter(({ name }) =>
              name.toLowerCase().includes(filterValue.toLowerCase())
            )
          : view
        ).filter((basket) => {
          return !(
            selected_basket?.id === basket.id ||
            selectedDatasets.includes(basket.id) ||
            basket.id.toString() === currentBasketId
          )
        }) ?? [],
      [currentBasketId, filterValue, selectedDatasets, view, selected_basket]
    )

    const datasetTypes = allowedTypes
      ? allowedTypes
      : withPublic
      ? BASKET_TYPES
      : NON_PUBLIC_BASKET_TYPES

    return (
      <CustomDialog
        withPortal={true}
        id="baskets-list-modal"
        onClose={onClose}
        outboundClickEnabled={false}
      >
        <Modal>
          <ModalDialog>
            <ModalHeader>
              <TitleTertiary fontWeight={theme.fontWeight.semibold}>
                {t('baskets.modal.choose_set_text')}
              </TitleTertiary>
              <CustomCloseIcon onClick={onClose} />
            </ModalHeader>
            <DatasetTypesSelector>
              <TextMedium fontWeight={theme.fontWeight.medium}>
                {t('baskets.modal.dataset_type')}
              </TextMedium>
              <DatasetTypes>
                {datasetTypes.map((dt) => (
                  <CustomTab
                    key={dt}
                    onClick={() => setDatasetType(dt)}
                    type={'grey'}
                    size={'small'}
                    isActive={datasetType === dt}
                  >
                    {t(`baskets.dataset_type_selectors.${dt}`)}
                  </CustomTab>
                ))}
              </DatasetTypes>
            </DatasetTypesSelector>

            <CustomInput
              placeholder={t(
                withNew
                  ? 'baskets.modal.placeholder'
                  : 'baskets.modal.placeholder_filter'
              )}
              onChange={setFilterValue}
              value={filterValue}
              withClearButton={true}
            />
            {!onlyOne && !!selected.length && (
              <SelectedList>
                {selected.map(({ name, id }, key) => {
                  return (
                    <CustomTag key={key}>
                      <TagText
                        lineHeight={theme.text[14]}
                        fontWeight={theme.fontWeight.medium}
                        ellipsis
                      >
                        {name}
                      </TagText>
                      <CustomButton
                        type={'icon_secondary'}
                        width={'1rem'}
                        height={'1rem'}
                        defaultPadding={'0'}
                        onClick={() => handleRemoveSelected(id)}
                      >
                        <Icon iconType={'close'} size={'1rem'} />
                      </CustomButton>
                    </CustomTag>
                  )
                })}
              </SelectedList>
            )}
            <ListContainer>
              <Caption
                color={theme.colors.text.secondary}
                lineHeight={theme.text[14]}
                fontWeight={theme.fontWeight.medium}
              >
                {t('baskets.modal.choose_from_list')}
              </Caption>
              <Wrapper>
                <CustomScrollbar
                  className="margin--8-right"
                  disableTrackYWidthCompensation={true}
                >
                  {!loading && (
                    <ListWrapper>
                      {filtered.map((el) => {
                        const isSelected = selected.find(
                          ({ id }) => id === el.id
                        )
                        return (
                          <NewButton
                            onClick={handleSelect(el.id, el.name)}
                            selected={isSelected}
                            key={el.id}
                            data-test="choose-baskets-list"
                          >
                            <TextMedium
                              fontWeight={theme.fontWeight.medium}
                              ellipsis
                            >
                              {el.name}
                            </TextMedium>
                            {isSelected && (
                              <Icon iconType="tick" size={'1rem'} />
                            )}
                          </NewButton>
                        )
                      })}
                    </ListWrapper>
                  )}
                  {loading && (
                    <LoaderWrapper>
                      <LoaderRestyling />
                    </LoaderWrapper>
                  )}
                </CustomScrollbar>
              </Wrapper>
            </ListContainer>
            <ModalBtns>
              {withNew && (
                <CustomButton
                  type={'secondary'}
                  onClick={handleCreate}
                  disabled={
                    !filterValue ||
                    filtered.some((el) => el?.name === filterValue)
                  }
                  width={'100%'}
                >
                  {t('baskets.modal.create_new_basket')}
                </CustomButton>
              )}
              <CustomButton
                type={selected.length ? 'accent' : 'secondary'}
                onClick={!selected.length ? undefined : handleAgree}
                disabled={!selected.length}
                width={'100%'}
              >
                {actionText}
              </CustomButton>
            </ModalBtns>
          </ModalDialog>
        </Modal>
      </CustomDialog>
    )
  }
)

BasketsListModal.displayName = 'BasketsListModal'
export default BasketsListModal
