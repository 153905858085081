import styled from 'styled-components'

import Icon from 'components/Icon'
import { CustomIconProps } from 'components/Icon/index.types'

import { getPosition, getSize } from './utils/utils'

interface CloseIconProps extends CustomIconProps {
  right: string
  top: string
  width: string
  height: string
}

export const CloseIcon = styled(Icon as any)<CloseIconProps>`
  color: ${({ color, theme }) => color || theme.colors.icons.tertiary};
  position: absolute;
  right: ${getPosition};
  top: ${getPosition};
  cursor: pointer;
  width: ${getSize};
  height: ${getSize};
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }

  @media print {
    display: none;
  }
`
