import { memo } from 'react'
import { useSelector } from 'react-redux'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { Trans, useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import {
  GoToBasketBtn,
  NotificationBtns,
} from 'components/Notifications/index.style'

import {
  HeaderText,
  NotificationHeader,
  NotificationWarningBody,
  NotificationWarningContainer,
} from './index.style'
import NotificationText from '../NotificationText'
import CustomButton from '../../../common/customButton'
import { openFeedbackForm } from 'store/slices/feedbackSlice'
import { selectBasket } from 'store/slices/basketSlice'
import { removeNotification } from 'store/slices/notificationsSlice'
import { addMolecule } from 'store/slices/crudSlice'

const NotificationWarning = memo(({ push, item }) => {
  const { text, notification_type, withActions, translationNameParams } = item
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showBlur = useSelector((state) => state.blur.showBlur)

  const handleGoToBasket = () => {
    dispatch(selectBasket(null))
    push(`/molecules/${item.params.target}`)
    dispatch(removeNotification(item.id))
  }

  const firstButton = {
    text: t('notifications.error.write_about_an_error'),
    type: 'secondary',
    onClick: () => dispatch(openFeedbackForm()),
  }

  const handleRepeatAddMolecules = () => {
    dispatch(removeNotification(item.id))
    const {
      basket,
      smiles,
      shouldGoToBasket,
      label,
      t,
      isTargetEqualsSource,
      isSelected,
    } = item.params

    dispatch(
      addMolecule({
        basket,
        smiles,
        shouldGoToBasket,
        label,
        t,
        isTargetEqualsSource,
        isSelected,
      })
    )
  }

  const secondButton =
    item?.params?.type === 'createMolecule'
      ? {
          text: t('notifications.error.add_again'),
          type: 'secondary',
          onClick: handleRepeatAddMolecules,
        }
      : {
          text: t('notifications.error.repeat'),
          type: 'secondary',
          onClick: () => console.log('repeat'),
        }

  const buttonsConfig = [firstButton, secondButton]

  return (
    <NotificationWarningContainer type={notification_type}>
      <NotificationWarningBody>
        <NotificationHeader>
          <Icon iconType={notification_type} />

          <HeaderText>
            <Trans
              i18nKey={item.name}
              components={{ span: <span /> }}
              values={{ ...translationNameParams }}
            />
          </HeaderText>
        </NotificationHeader>
        {!!text && <NotificationText {...{ item, t }} />}
      </NotificationWarningBody>
      {notification_type === 'error' && withActions && (
        <NotificationBtns>
          {buttonsConfig.map(({ text, type, onClick }, i) => (
            <CustomButton type={type} onClick={onClick} width={'50%'} key={i}>
              {text}
            </CustomButton>
          ))}
        </NotificationBtns>
      )}
      {(notification_type === 'success' || notification_type === 'warning') &&
        withActions &&
        item?.params?.target && (
          <NotificationBtns>
            <GoToBasketBtn
              type="secondary"
              width="100%"
              onClick={handleGoToBasket}
              disabled={showBlur}
            >
              {t('notifications.go_to_set')}
            </GoToBasketBtn>
          </NotificationBtns>
        )}
    </NotificationWarningContainer>
  )
})

NotificationWarning.displayName = 'NotificationWarning'

const mapDispatchToProps = {
  push,
}

export default connect(null, mapDispatchToProps)(NotificationWarning)
