import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notificationsList: [],
  isHidden: false,
  simpleNotification: {
    text: null,
    visible: false,
    withOpenBtn: null,
    route: null,
  },
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notificationsList.push(action.payload)
    },
    removeNotification: (state, action) => {
      state.notificationsList = state.notificationsList.filter(
        (task) => task.id !== action.payload
      )
    },
    hideNotifications: (state) => {
      state.isHidden = !state.isHidden
    },
    addSimpleNotification: (state, action) => {
      const { text, withOpenBtn, route } = action.payload || {}
      state.simpleNotification = {
        visible: true,
        text: text,
        withOpenBtn: withOpenBtn,
        route: route,
      }
    },
    hideSimpleNotification: (state) => {
      state.simpleNotification.visible = false
    },
    removeSimpleNotification: (state) => {
      state.simpleNotification = {
        text: null,
        visible: false,
        withOpenBtn: null,
        route: null,
      }
    },
    updateNotification: (state, action) => {
      const { id, updated_data } = action.payload || {}
      const notification = state.notificationsList.find(
        (notification) => notification.id === id
      )
      if (notification) {
        Object.assign(notification, updated_data)
      }
    },
  },
})

export const {
  addNotification,
  removeNotification,
  hideNotifications,
  addSimpleNotification,
  hideSimpleNotification,
  removeSimpleNotification,
  updateNotification,
} = notificationsSlice.actions

export default notificationsSlice.reducer
