import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import LicensePurchaseInfo from 'components/LicensePurchaseInfo'
import SocialNetworksFooter from 'components/SocialNetworksFooter'
import { Label, TitleTertiary } from 'components/common/text/index.style'

import {
  Info,
  Footer,
  Buttons,
  Content,
  SurveySection,
  LicenseKeyBlock,
} from './index.style'
import { isLicenseExpired } from './utils/utils'
import { openFeedbackForm } from 'store/slices/feedbackSlice'

const NoLicenseInfo = ({
  loading,
  onActivate,
  licenseError,
  setLicenseError,
  setIsLicenseWarning,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const [licenseKey, setLicenseKey] = useState('')
  const [isLicenseBlockShow, setIsLicenseBlockShow] = useState(false)

  const { error_data: data } = licenseError || {}

  const lang = i18n?.language

  const isNoLicense = !data?.license_id
  const isFreeTrial = data?.license_is_free_trial
  const isExpired = data?.license_expired_at
    ? isLicenseExpired(data?.license_expired_at)
    : false

  const getErrorText = () => {
    if (isNoLicense) return t('account.no_license')
    if (isExpired)
      return isFreeTrial
        ? t('account.trial_period_expired')
        : t('account.license_expired')
    else return t('account.license_invalid')
  }

  const handleBuyLicense = () => {
    dispatch(openFeedbackForm())
  }

  const handleOpenSurvey = () => {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.href =
      lang === 'ru'
        ? 'https://forms.gle/MZsz1hM9gfDXkCUo9'
        : 'https://forms.gle/wdJvMdJoGxw8VXq8A '
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <>
      <Content>
        <CustomButton
          type="text_accent"
          onClick={() => {
            setLicenseError(null)
          }}
        >
          <Icon iconType="arrowLeft" size="1rem" />
          {t('registration.back')}
        </CustomButton>
        <Info>
          <TitleTertiary fontWeight={theme.fontWeight.semilbold}>
            {t('account.unfortunately')} {getErrorText()}
          </TitleTertiary>
          <LicensePurchaseInfo isFreeTrial={isFreeTrial || isNoLicense} />
        </Info>
        <Buttons>
          <CustomButton type="accent" onClick={handleBuyLicense}>
            {t('account.buy_a_license')}
          </CustomButton>
          {!isLicenseBlockShow && (
            <CustomButton
              type="secondary"
              onClick={() => setIsLicenseBlockShow(true)}
            >
              {t('account.already_have_key')}
            </CustomButton>
          )}
        </Buttons>
        {isLicenseBlockShow && (
          <LicenseKeyBlock>
            <CustomInput
              withClearButton
              label={t('registration.license_key')}
              placeholder={t('account.enter_license_key')}
              value={licenseKey}
              onChange={(value) => setLicenseKey(value)}
            />
            <CustomButton
              type="accent"
              onClick={() =>
                onActivate(data.user_id, licenseKey, data?.access_token)
              }
              disabled={loading || !licenseKey}
            >
              {t('account.activate')}
            </CustomButton>
          </LicenseKeyBlock>
        )}
      </Content>
      <Footer>
        <SurveySection>
          <Label>{t('account.we_would_be_grateful_2')}</Label>
          <CustomButton type="accent" onClick={handleOpenSurvey}>
            {t('account.take_a_survey')}
          </CustomButton>
        </SurveySection>
        <SocialNetworksFooter direction="row" width="100%" />
      </Footer>
    </>
  )
}

export default NoLicenseInfo
