import { Fragment, memo, useCallback, useMemo, useState } from 'react'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import {
  FilterCondition,
  PlusIcon,
  FilterLitConditionWrapper,
  ConditionHeader,
  Condition,
  ConditionWrapper,
  StyledTrashButton,
  FilterLitConditionBtns,
  FilterLitConditionHeader,
  FilterLitConditionBody,
  FilterLitConditionText,
} from './index.style'
import { useTranslation } from 'react-i18next'
import {
  Caption,
  Label,
  Subheadline,
  TextMedium,
} from 'components/common/text/index.style'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { CONFLICT_OPTIONS } from '../../../Search/LiteratureFilter/constants'
import { HideBtn } from 'components/Filter/index.style'
import { getCountNoun } from 'pages/Pdf2Smiles/config/config'
import {
  ConditionRelationsBlock,
  ConditionRelationsInner,
} from 'components/Search/LiteratureFilter/index.style'
import { setSearchText } from 'store/slices/searchSlice'
import { handleShowContextMenu } from 'store/slices/contextMenuSlice'
import {
  setLitFilterConfig,
  setLitFilterDocType,
  setOpenLitFilter,
  setShouldBlocksIndexes,
} from 'store/slices/literatureSlice'
import { confirm } from 'store/slices/confirmationSlice'
import { setLeftFilterLitDocType } from 'store/slices/filterSlice'
const FilterLitConditions = memo(
  () => {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()

    const leftFilterConfig = useSelector((state) => state.filter.config)
    const ctxMenu = useSelector((state) => state.contextMenu.contextMenuState)

    const rightFilterConfig = useSelector(
      (state) => state.literature.filter.config
    )
    const isFilterOpen = useSelector((state) => state.literature.filter.open)
    const docType = useSelector((state) => state.literature.filter.docType)
    const shouldBlocks = useSelector(
      (state) => state.literature.filter.shouldBlocksIndexes
    )

    const isCtxMenuActive = ctxMenu?.menu === 'literatureFilterMenu'
    const [isFilterOpened, setFilterOpened] = useState(true)

    const addToShouldList = (currentElement) => {
      if (!shouldBlocks.includes(currentElement)) {
        dispatch(setShouldBlocksIndexes([...shouldBlocks, currentElement]))
      }
    }

    const handleAddNewCondition = (option) => {
      addToShouldList(option)
      const handleAddName = () => {
        dispatch(
          confirm({
            text: t('confirmation.add_condition'),
            onConfirm: () => {
              dispatch(setLitFilterConfig({ name: '' }))
              dispatch(setLitFilterDocType(option))
              dispatch(setSearchText(''))
              handleOpenFilter()
            },
            description: t('filter.literature.name_warning'),
          })
        )
      }
      switch (option) {
        case 'doi':
          handleAddName()

          break
        case 'patent_number':
          handleAddName()

          break
        default:
          dispatch(
            setLitFilterConfig({
              ...rightFilterConfig,
              [option]: {
                operator: 'should',
                exact: false,
                values: [''],
              },
            })
          )
          handleOpenFilter()
          break
      }
    }

    const isNamePresent = useMemo(
      () => Object.keys(rightFilterConfig).includes('name'),
      [rightFilterConfig]
    )

    const handleOpenFilter = useCallback(() => {
      dispatch(setOpenLitFilter(true))
    }, [dispatch])

    const handleOpenContextMenu = (e) => {
      if (Object.keys(rightFilterConfig).includes('name')) return
      e.stopPropagation()
      const offsetX = e.pageX
      const offsetY = e.pageY
      dispatch(
        handleShowContextMenu({
          offset: { offsetX, offsetY },
          menu: 'literatureFilterMenu',
          item: {
            handler: handleAddNewCondition,
            config: {
              documentTypes: leftFilterConfig?.document_type || [],
              rightConfig: Object.keys(rightFilterConfig),
            },
          },
        })
      )
    }

    const handleDeleteRow = (currentElement) => {
      const configCopy = structuredClone(rightFilterConfig)
      delete configCopy[currentElement]

      if (
        !Object.keys(configCopy).length ||
        !Object.keys(configCopy).find(
          (el) =>
            CONFLICT_OPTIONS.patent.includes(el) ||
            CONFLICT_OPTIONS.article.includes(el)
        )
      ) {
        dispatch(setLeftFilterLitDocType(['article', 'patent']))
      }
      addToShouldList(currentElement)
      dispatch(setLitFilterConfig(configCopy))
    }

    const getTranslate = (text) => {
      if (text === 'name') return t(`filter.literature.${docType}`)
      if (text === 'company') return t(`filter.literature.owner`)
      return t(`filter.literature.${text}`)
    }

    const litConditionsLength = Object.entries(rightFilterConfig)?.length
    const isEmptyLitConditions = litConditionsLength === 0 || !rightFilterConfig

    return isEmptyLitConditions ? (
      <CustomButton
        style={{ marginBottom: '0.25rem' }}
        type="secondary"
        gap="0.25rem"
        onClick={handleOpenContextMenu}
        disabled={Object.keys(rightFilterConfig).includes('name')}
        active={isCtxMenuActive}
      >
        <PlusIcon iconType="add" size="1rem" />
        <Subheadline color="inherit">
          {t('filter.literature.addCondition')}
        </Subheadline>
      </CustomButton>
    ) : (
      <FilterLitConditionWrapper
        id="lit-filter-condition-wrapper"
        gap={isFilterOpened ? '0.375rem' : '0'}
      >
        <FilterLitConditionHeader>
          <FilterLitConditionText>
            <TextMedium
              fontWeight={theme.fontWeight.medium}
              color={theme.colors.text.primary}
            >
              {t('filter.literature.header_short')}
            </TextMedium>
            {litConditionsLength && (
              <Caption
                color={theme.colors.text.secondary}
                fontWeight={400}
              >{`${litConditionsLength} 
                ${t(
                  `filter.literature.conditions.${getCountNoun(
                    litConditionsLength
                  )}`
                )}`}</Caption>
            )}
          </FilterLitConditionText>

          <HideBtn
            opened={isFilterOpened}
            onClick={() => setFilterOpened(!isFilterOpened)}
            bg={theme.colors.backgrounds.primary}
          >
            <Icon iconType="arrowDown" size="0.75rem" />
          </HideBtn>
        </FilterLitConditionHeader>
        <FilterLitConditionBody isOpened={isFilterOpened}>
          {Object.entries(rightFilterConfig)?.map((el, i) => {
            if (
              (el?.[0] !== 'name' &&
                el[1]?.values?.length === 1 &&
                el[1]?.values[0] === '') ||
              (el?.[0] === 'name' && !el?.[1])
            )
              return null
            return (
              <Fragment key={`left-filter-condition-${el?.[0]}-${i}`}>
                <FilterCondition>
                  <ConditionHeader>
                    <TextMedium
                      fontWeight={theme.fontWeight.medium}
                      color={theme.colors.text.primary}
                    >
                      {getTranslate(el[0])}
                    </TextMedium>
                    <StyledTrashButton onClick={() => handleDeleteRow(el?.[0])}>
                      <Icon iconType="deleted" size="1rem" />
                    </StyledTrashButton>
                  </ConditionHeader>
                  {el?.[0] !== 'name' &&
                    el?.[1]?.values?.map((condition, i) => (
                      <ConditionWrapper key={`${condition}-${i}`}>
                        {i !== 0 && (
                          <Caption
                            color={theme.colors.text.secondary}
                            fontWeight={theme.fontWeight.medium}
                          >
                            {t(
                              `filter.literature.${
                                el?.[1]?.not_values?.length &&
                                el?.[1]?.not_values.includes(i)
                                  ? 'not_operator'
                                  : el?.[1]?.operator
                              }_lowercase`
                            )}
                          </Caption>
                        )}
                        <Condition>
                          <Caption
                            style={{ maxHeight: '100%' }}
                            color={theme.colors.text.primary}
                            fontWeight={theme.fontWeight.medium}
                            ellipsis={true}
                          >
                            {condition}
                          </Caption>
                        </Condition>
                      </ConditionWrapper>
                    ))}
                  {el?.[0] === 'name' && (
                    <ConditionWrapper key={`${el?.[0]}-${i}`}>
                      <Condition>
                        <Caption
                          style={{ maxHeight: '100%' }}
                          color={theme.colors.text.primary}
                          fontWeight={theme.fontWeight.medium}
                        >
                          {el?.[1]}
                        </Caption>
                      </Condition>
                    </ConditionWrapper>
                  )}
                </FilterCondition>
                {i < litConditionsLength - 1 && (
                  <ConditionRelationsBlock
                    borderColor={theme.colors.icons.quaternary}
                    marginLeft="0.75rem"
                    height="2.25rem"
                  >
                    <ConditionRelationsInner>
                      <Caption
                        color={theme.colors.text.tertiary}
                        fontWeight={400}
                      >
                        {t('filter.literature.condition_relations')}
                      </Caption>
                      <Label fontWeight={400}>
                        {t(
                          `${
                            shouldBlocks?.filter((option) => option === el?.[0])
                              ?.length
                              ? 'filter.literature.should'
                              : 'filter.literature.must'
                          }`
                        )}
                      </Label>
                    </ConditionRelationsInner>
                  </ConditionRelationsBlock>
                )}
              </Fragment>
            )
          })}
          <FilterLitConditionBtns>
            <CustomButton
              gap="0.25rem"
              onClick={handleOpenContextMenu}
              disabled={Object.keys(rightFilterConfig).includes('name')}
              active={isCtxMenuActive}
              bgColor={theme.colors.backgrounds.primary}
              width="50%"
            >
              <PlusIcon iconType="add" size="1rem" />
              <Subheadline color="inherit">
                {t('filter.literature.add')}
              </Subheadline>
            </CustomButton>
            <CustomButton
              bgColor={theme.colors.backgrounds.primary}
              gap="0.25rem"
              onClick={handleOpenFilter}
              disabled={isNamePresent}
              active={isCtxMenuActive}
              width="50%"
            >
              <Icon iconType="pen" size="1rem" />
              {t('admin.edit_short')}
            </CustomButton>
          </FilterLitConditionBtns>
        </FilterLitConditionBody>
      </FilterLitConditionWrapper>
    )
  },

  arePropsEqual
)

function arePropsEqual(oldProps, newProps) {
  const oldConfig = oldProps.isFilterOpen
  const newConfig = newProps.isFilterOpen
  if ((newConfig && oldConfig) || (newConfig && !oldConfig)) return true
  return false
}

FilterLitConditions.displayName = 'FilterLitConditions'

export default FilterLitConditions
