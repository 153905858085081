import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useState } from 'react'
import { useTheme } from 'styled-components'
import { validate as isValidUUID } from 'uuid'

import { BottomBlock, TopBlock, Wrapper } from './index.style'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { storeV2 } from 'services/rest'
import { Headline, TextMedium } from 'components/common/text/index.style'
import { toggleUserProfile } from 'store/slices/authSlice'
import { openFeedbackForm } from 'store/slices/feedbackSlice'

const ProductKeySection = memo(({ openDialog, setIsLoading }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()

  const userdata = useSelector((state) => state.auth.userdata)
  const { id: user_id } = userdata

  const [productKey, setProductKey] = useState('')
  const [isInvalidKey, setIsInvalidKey] = useState(false)
  const [isValidated, setIsValidated] = useState(false)

  const write = () => {
    dispatch(toggleUserProfile(false))
    dispatch(openFeedbackForm())
  }

  const activate = async () => {
    setIsLoading(true)
    try {
      await storeV2('user/update', {
        params: {
          user_id,
          customer_invite_token: productKey,
        },
      })
      openDialog({
        type: 'success',
        title: 'user_account.dialog.license_is_activated',
        text: 'user_account.dialog.congratulations_activated',
        btnText: 'user_account.dialog.ok',
      })
      setIsValidated(true)
    } catch (e) {
      const errorResult = e?.response?.data?.result
      if (
        errorResult?.error_data?.[0]?.msg === 'value is not a valid uuid' ||
        errorResult?.error_message?.includes('Invalid invite token')
      ) {
        setIsInvalidKey(true)
      } else {
        openDialog({
          type: 'warning',
          title: 'user_account.dialog.failed_to_activate_license',
          btnText: 'user_account.dialog.ok',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const isActivationDisabled = !isValidUUID(productKey)

  return (
    <Wrapper>
      <TopBlock>
        <Headline fontWeight={theme.fontWeight.semibold}>
          {t('user_account.product_key_section.enter_company_key')}
        </Headline>
        <TextMedium
          fontWeight={theme.fontWeight.light}
          color={theme.colors.text.secondary}
          style={{ marginTop: '0.375rem', marginBottom: '1rem' }}
        >
          {t('user_account.product_key_section.if_no_key')}
        </TextMedium>
        <CustomInput
          label={t('user_account.product_key_section.product_key')}
          value={productKey}
          onChange={(value) => {
            setProductKey(value)
            setIsInvalidKey(false)
          }}
          placeholder={'00000000-0000-0000-0000-000000000000'}
          withClearButton
          warning={
            isInvalidKey && t('user_account.product_key_section.incorrect_key')
          }
          isValidated={isValidated}
        />
        {!!productKey?.length && (
          <CustomButton
            type={isActivationDisabled ? 'secondary' : 'accent'}
            gap={'0.25rem'}
            onClick={activate}
            disabled={isActivationDisabled}
            style={{ marginTop: '1.25rem' }}
          >
            <Icon iconType={'tick'} size={'1rem'} />
            <span>{t('user_account.product_key_section.activate')}</span>
          </CustomButton>
        )}
      </TopBlock>
      <BottomBlock>
        <TextMedium fontWeight={theme.fontWeight.light}>
          {t('user_account.product_key_section.request_to_contact')}
        </TextMedium>
        <CustomButton type={'secondary'} gap={'0.25rem'} onClick={write}>
          <Icon iconType={'telegram'} size={'1rem'} />
          <span>{t('user_account.product_key_section.write')}</span>
        </CustomButton>
      </BottomBlock>
    </Wrapper>
  )
})

ProductKeySection.displayName = 'ProductKeySection'

export default ProductKeySection
