import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import SkeletonComponent from '../skeleton'
import { CustomSwitchProps } from './index.types'
import { CountTag, StyledBtn, SwitchBtn, SwitchWrapper } from './index.style'

const CustomSwitch: FC<CustomSwitchProps> = ({
  items,
  disabled,
  active,
  wrapperWidth,
  btnWidth,
  minBtnWidth,
  onClick,
  keyTemplate,
  bg = 'grey',
  withCount,
}) => {
  const { t } = useTranslation()
  return (
    <SwitchWrapper wrapperWidth={wrapperWidth} bg={bg}>
      {items.map((item, i) => (
        <SwitchBtn
          key={`${keyTemplate}-${i}`}
          active={active === item.type}
          disabled={disabled}
          btnWidth={btnWidth}
          minBtnWidth={minBtnWidth}
          onClick={() => !disabled && onClick(item.type)}
          bg={bg}
        >
          <StyledBtn color="inherit">
            {t(item.title)}
            {withCount &&
              (typeof item?.count === 'number' ? (
                <CountTag active={active === item.type} disabled={disabled}>
                  {item.count}
                </CountTag>
              ) : (
                <SkeletonComponent
                  height="1.5rem"
                  width="2rem"
                  variant="dark"
                  borderRadius="0.375rem"
                  key={`skeleton-total-${i}`}
                />
              ))}
          </StyledBtn>
        </SwitchBtn>
      ))}
    </SwitchWrapper>
  )
}

export default CustomSwitch
