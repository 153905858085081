import { v4 as uuidv4 } from 'uuid'
import { fetch, store, update, storeV2 } from '../../services/rest'
import {
  put,
  takeLatest,
  takeEvery,
  takeLeading,
  select,
  delay,
} from 'redux-saga/effects'
import { CONFLICT_OPTIONS } from 'components/Search/LiteratureFilter/constants'
import { t } from 'i18next'
import {
  checkMarkushFieldsError,
  convertLitSearchConfig,
  countAddedMolItems,
  getBasketsQueryParams,
  getDatasetTransferName,
  getMarkushNotification,
  getSmilesBySuccessType,
} from './utils/utils'
import {
  BASKET_SEARCH_PATH,
  BASKETS_ENDPOINT_BY_TYPE,
  BINGO_PAGINATION_LIMIT,
  BINGO_SEARCH_PATH,
  LIT_SEARCH_PATH,
  MOLECULE_SEARCH_PATH,
  REACTION_SEARCH_PATH,
  REACTIONS_PAGINATION_LIMIT,
  TIPS_SEARCH_PATH,
} from './utils/config'
import { compareArrayValues } from 'pages/Baskets/helpers/helpers'
import { setStorage } from 'utils/storage/storage'
import { ACCESS_RIGHTS_ERROR } from 'config/constants'
import { setPaginationConfig, setSearchText } from 'store/slices/searchSlice'
import {
  setLastSearchedText,
  setLitFilterConfig,
  setPrevSearchDataId,
} from 'store/slices/literatureSlice'
import {
  INIT_QTY_ON_PAGE,
  setBasketType,
  settingsRehydrate,
} from 'store/slices/settingsSlice'
import { setReactionsCompoundId } from 'store/slices/reactionsSearchSlice'
import {
  checkCustomerDatasetsAccess,
  handleTransferToCustomerDatasets,
  removeBasketIdsFromDbError,
  removeBasketIdsFromDbSuccess,
  removeLastActionsIDsFromDB,
  setSelected,
} from 'store/slices/basketSlice'
import {
  addNotification,
  removeNotification,
  updateNotification,
} from 'store/slices/notificationsSlice'
import {
  setFilterButtonsActive,
  setFilterButtonsDisabled,
  setMarkushMoleculeMaxWeight,
} from 'store/slices/filterSlice'
import {
  addBasket,
  addBasketFail,
  addBasketSuccess,
  addMolecule,
  addMoleculeFail,
  addMoleculesFromFile,
  addMoleculesPack,
  addMoleculesPackFail,
  addMoleculesPackSuccess,
  addMoleculeSuccess,
  calcTlight,
  copyBasket,
  copyBasketFail,
  copyBasketSuccess,
  copyMolecules,
  copyMoleculesFail,
  deleteBasket,
  deleteBasketFail,
  deleteBasketSuccess,
  deleteMolecule,
  deleteMoleculeFail,
  deleteMoleculeSuccess,
  joinBaskets,
  joinBasketsFail,
  joinBasketsSuccess,
  loadBaskets,
  loadBasketsFail,
  loadBasketsSuccess,
  loadLastChange,
  loadLastChangeFail,
  loadLastChangeSuccess,
  loadMolecules,
  loadMoleculesFail,
  loadMoleculesSuccess,
  loadMoreMolecules,
  loadMoreMoleculesSuccess,
  moveMolecules,
  moveMoleculesFail,
  moveMoleculesSuccess,
  rehydrateStore,
  setBingoSearch,
  setBingoSearchError,
  setBingoSearchIsLongtask,
  setBingoSearchLoading,
  setBingoSearchResult,
  setBingoSearchTaskId,
  setCachedPage,
  setLiteratureSearch,
  setLiteratureSearchError,
  setLiteratureSearchLoading,
  setLiteratureSearchResult,
  setLiteratureSearchTaskId,
  setLitSearchState,
  setReactionsSearch,
  setReactionsSearchError,
  setReactionsSearchLoading,
  setReactionsSearchResult,
  setSearchError,
  setSearchLoading,
  setSearchResult,
  setSearchTransitDone,
  setSearchTransitError,
  setSearchTransitLoading,
  setSearchV2,
  showSearchV2Result,
  updateBasket,
  updateBasketFail,
  updateBasketSuccess,
  updateMolecule,
  updateMoleculeFail,
  updateMoleculeSuccess,
} from 'store/slices/crudSlice'
import { mmapRehydrate } from 'store/slices/mmapSlice'
import { compareParamsRehydrate } from 'store/slices/compareParamsSlice'

const MOVE_ERROR_MESSAGES = [
  'User is not source and target owner',
  'At least one of molecules already exists in target or does not exist in source',
]

function* loadBasket({ payload }) {
  const { basketType, withSearch = true, withPublic } = payload || {}
  try {
    const sortingConfig = yield select((state) => state.basket.sorting)
    const searchName = yield select((state) => state.basket.searchName)
    let resp
    const searchCondition = withSearch && searchName?.length >= 3

    if (searchCondition) {
      resp = yield fetch(
        `/search_baskets?${getBasketsQueryParams(
          sortingConfig,
          searchName,
          basketType
        )}`
      )
    } else {
      resp = yield fetch(
        `/baskets/${
          BASKETS_ENDPOINT_BY_TYPE[basketType]
        }?${getBasketsQueryParams(sortingConfig, '')}`
      )
    }
    yield put(
      loadBasketsSuccess({
        data: resp.data,
        isBasketsAfterSearch: searchCondition,
      })
    )
  } catch (err) {
    yield put(loadBasketsFail(err))
  }
}

function* loadLastChangeFn() {
  try {
    const resp = yield fetch('/last-change')

    yield put(loadLastChangeSuccess(resp.data))
  } catch (err) {
    yield put(loadLastChangeFail(err))
  }
}

function* loadMoleculesFn({ payload }) {
  const { basket, search, limit } = payload
  try {
    let perPage = limit
    if (!limit) {
      perPage = yield select((state) => state.settings.qtyOnPage?.dataset) || 20
    }

    const req = {
      legacySearch: search,
      withPredict: true,
      limit: perPage,
    }
    const { data } = yield store(
      `/molecules/${isNaN(basket) ? 1 : basket}`,
      req
    )

    if (data) {
      const config = {
        total: data.count,
        pagesAmount: Math.ceil(data.count / perPage),
        perPage,
        activePage: 1,
      }

      yield put(setPaginationConfig(config))
    }

    yield put(
      loadMoleculesSuccess({
        openedBasketID: isNaN(basket) ? 1 : basket,
        data: data.data,
        public: data.public,
        withSearch: data.withSearch,
        pending: data.pending,
      })
    )
  } catch (err) {
    yield put(loadMoleculesFail(err))
  }
}

function* calcTLightFn({ payload }) {
  const { ids, basket } = payload || {}
  try {
    yield storeV2('traffic_light/calculate', {
      molecules_ids: ids,
    })
    const notify = {
      id: uuidv4(),
      name: 'notification.calc_tlight_success',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }

    yield put(addNotification(notify))
    yield put(loadMolecules({ basket }))
  } catch (e) {
    console.log(e)
  }
}

function* loadMoreMoleculesFn({ payload }) {
  const { basket, limit, page } = payload || {}
  try {
    let perPage = limit
    if (!limit) {
      perPage = yield select((state) => state.settings.qtyOnPage?.dataset) || 20
    }

    const req = { withPredict: true, limit, page }
    const { data } = yield store(`/molecules/${basket}`, req)

    yield put(loadMoreMoleculesSuccess(data.data))
  } catch (err) {
    console.log(err)
  }
}

function* createMolecule({ payload }) {
  const {
    smiles,
    shouldGoToBasket,
    label,
    t,
    isTargetEqualsSource,
    isSelected,
  } = payload || {}
  let { basket } = payload
  const basketType = yield select((state) => state.settings.basketType)

  try {
    if (basket === -1) {
      let resp = yield store('/baskets', {
        name: label,
        desc: label,
      })
      basket = resp.data.id
    }

    const res = yield store(`/molecules_pack`, {
      basket_id: basket,
      list: smiles,
    })
    if (res.status === 200) {
      const { succeedCount, failedCount } = countAddedMolItems(res)
      const info = succeedCount.length
        ? 'notification.not_all_molecules_added_to_basket'
        : smiles.length > 1
        ? 'notification.molecules_not_added_to_basket'
        : 'notification.molecule_not_added_to_basket'
      const translationNameParams = {
        name: label || '',
      }

      if (failedCount.length) {
        const { invalidSmiles, repeatedSmiles } = getSmilesBySuccessType(res)

        if (repeatedSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            translationNameParams,
            text: 'Structure already exists in basket',
            notification_type: 'warning',
            autoRemove: false,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              type: 'createMolecule',
              smiles: repeatedSmiles,
              target: basket,
            },
          }
          yield put(addNotification(notify))
        }

        if (invalidSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure is not valid',
            notification_type: 'warning',
            translationNameParams,
            autoRemove: false,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              type: 'createMolecule',
              smiles: invalidSmiles,
              target: basket,
            },
          }

          yield put(addNotification(notify))
        }

        if (!succeedCount.length) yield put(addMoleculeFail())
      }
      if (succeedCount.length) {
        yield put(addMoleculeSuccess())

        if (shouldGoToBasket) {
          yield put(loadMolecules({ basket }))

          yield put(loadBaskets({ withPublic: true, basketType }))
        }

        if (!failedCount.length) {
          const id = uuidv4()
          const oneMolText = isSelected
            ? 'notification.selected_molecule_add'
            : 'notification.molecule_add'

          const manyMolsText = isSelected
            ? 'notification.selected_molecules_add'
            : 'notification.molecules_add'

          const notify = {
            id,
            name: succeedCount.length > 1 ? manyMolsText : oneMolText,
            translationNameParams,
            notification_type: 'success',
            timeout: 5000,
            autoRemove: true,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              target: basket,
            },
          }

          yield put(addNotification(notify))
        }
      }
    }
  } catch (err) {
    yield put(addMoleculeFail())

    const notify = {
      id: uuidv4(),
      name: 'notification.molecules_not_added_to_basket',
      text: 'Network error',
      translationNameParams: {
        name: label || '',
      },
      notification_type: 'error',
      withActions: true,
      autoRemove: false,
      params: {
        type: 'createMolecule',
        basket,
        smiles,
        shouldGoToBasket,
        label,
        t,
        isTargetEqualsSource,
        isSelected,
      },
    }

    yield put(addNotification(notify))
  }
}

function* createMoleculesFromFile({ payload }) {
  const { basket, file, smiles_target_col, delimiter } = payload || {}
  try {
    const headers = { 'Content-type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('basket_id', basket)
    smiles_target_col && formData.append('csv_smiles_header', smiles_target_col)
    delimiter && formData.append('delimiter', delimiter)

    yield store('/uploadFileToBasket', formData, headers)
    yield put(addMoleculeSuccess())

    const notify = {
      id: uuidv4(),
      name: 'notification.uploading_started',
      notification_type: 'success',
      autoRemove: true,
    }

    yield put(addNotification(notify))

    let isBasketReady = false
    while (!isBasketReady) {
      const tasks = yield fetch(`/userTasksStatus`)
      yield delay(2000)
      if (
        tasks.data.some(
          (el) =>
            el?.basket_id === basket &&
            !(el?.status === 'initiated' || el?.status === 'running')
        )
      )
        isBasketReady = true
    }
    if (isBasketReady) {
      const openedBasket = yield select((state) => state.crud.openedBasketID)
      if (openedBasket === basket) yield put(loadMolecules({ basket }))
    }
  } catch (e) {
    yield put(addMoleculesPackFail(e))
  }
}

function* createMoleculesPack({ payload }) {
  const { basket, list, t, basket_name } = payload
  try {
    const res = yield store(`/molecules_pack`, {
      basket_id: basket,
      list,
    })
    if (res.status === 200) {
      const { succeedCount, failedCount } = countAddedMolItems(res)
      const info = succeedCount.length
        ? 'notification.not_all_molecules_added_to_basket'
        : 'notification.molecules_not_added_to_basket'

      if (failedCount.length) {
        const { invalidSmiles, repeatedSmiles } = getSmilesBySuccessType(res)

        if (repeatedSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure already exists in basket',
            notification_type: 'warning',
            autoRemove: false,
            translationNameParams: {
              name: basket_name ?? '',
            },
            params: {
              type: 'createMolecule',
              smiles: repeatedSmiles,
            },
          }

          yield put(addNotification(notify))
        }

        if (invalidSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure is not valid',
            notification_type: 'warning',
            autoRemove: false,
            params: {
              type: 'createMolecule',
              smiles: invalidSmiles,
            },
          }

          yield put(addNotification(notify))
        }

        if (!succeedCount.length) yield put(addMoleculeFail())
      }

      if (succeedCount.length) {
        yield put(addMoleculesPackSuccess())
        yield put(loadMolecules({ basket }))
        if (!failedCount?.length) {
          const id = uuidv4()

          const notify = {
            id,
            name:
              succeedCount?.length > 1
                ? 'notification.molecules_add'
                : 'notification.molecule_add',

            translationNameParams: {
              name: basket_name ?? '',
            },
            notification_type: 'success',
            timeout: 5000,
            autoRemove: true,
          }

          yield put(addNotification(notify))
        }
      }
    }
  } catch (err) {
    yield put(addMoleculeFail())
    const notify = {
      id: uuidv4(),
      name: 'notification.molecules_not_added_to_basket',
      text: 'Network error',
      notification_type: 'error',
      withActions: true,
      autoRemove: false,
      translationNameParams: {
        name: basket_name ?? '',
      },
      params: {
        type: 'createMolecule',
        basket,
        list,
        t,
      },
    }

    yield put(addNotification(notify))
  }
}

function* deleteMoleculeFn({ payload }) {
  const { id, basket } = payload || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    yield store(`/molecules/`, {
      basket_id: basket,
      id: Array.isArray(id) ? id : [id],
    })
    yield put(deleteMoleculeSuccess())
    yield put(loadMolecules({ basket }))
    yield put(loadBaskets({ withPublic: true, basketType }))
  } catch (err) {
    if (err?.response?.data?.result?.error_message === ACCESS_RIGHTS_ERROR) {
      const notify = {
        id: uuidv4(),
        name: 'notification.error',
        text: 'moleculeslist.topblock.you_must_be_admin',
        notification_type: 'error',
        autoRemove: true,
      }

      yield put(addNotification(notify))
    }
    yield put(deleteMoleculeFail(err))
  }
}

function* updateMoleculeFn({ payload }) {
  const { id, basket, smiles } = payload || {}
  try {
    yield update(`/molecules/${id}`, { smiles, basket_id: basket })
    yield put(updateMoleculeSuccess())
    yield put(loadMolecules({ basket }))
  } catch (err) {
    yield put(updateMoleculeFail(err))
  }
}

function* addBasketFn({ payload }) {
  const { name, datasetType } = payload || {}
  try {
    const res = yield store('/baskets', {
      name,
      desc: ' ',
      ...(datasetType === 'companies' ? { by_customer: true } : {}),
    })

    const isSuccess = res?.status === 200
    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_add_success'
        : 'notification.dataset_add_error',
      notification_type: isSuccess ? 'success' : 'error',
      autoRemove: true,
    }
    yield put(addBasketSuccess())
    yield put(addNotification(notify))
    yield put(setBasketType(datasetType))
  } catch (e) {
    console.log(e)
    yield put(addBasketFail(e))
  } finally {
    yield put(
      loadBaskets({
        withPublic: true,
        basketType: datasetType,
      })
    )
  }
}

function* deleteBasketFn({ payload }) {
  const { list } = payload || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    const res = yield store(`/delete-baskets/`, { basket_ids: list })
    if (res && res?.status === 200) {
      yield put(deleteBasketSuccess)
      const notify = {
        id: uuidv4(),
        name:
          list?.length > 1
            ? 'notification.datasets_delete_success'
            : 'notification.dataset_delete_success',
        notification_type: 'cancel',
        autoRemove: true,
        params: {
          actionType: 'baskets-delete',
          deleteIDs: list,
        },
      }
      yield put(addNotification(notify))
    }
  } catch (e) {
    console.log(e)
    yield put(deleteBasketFail(e))
    if (e?.response?.data?.result?.error_message === ACCESS_RIGHTS_ERROR) {
      const notify = {
        id: uuidv4(),
        name: 'notification.error',
        text: 'baskets.topblock.you_must_be_admin',
        notification_type: 'error',
        autoRemove: true,
      }

      yield put(addNotification(notify))
    }
  } finally {
    yield put(loadBaskets({ withPublic: true, basketType }))
  }
}

function* removeBasketFromDB({ payload }) {
  const data = payload || {}
  const ids = data?.ids || []
  const lastChange = yield fetch(`/last-change`)
  const lastChangeRemovedIDs = lastChange?.data?.[0]?.deleted_baskets || []

  if (Array.isArray(ids) && Array.isArray(lastChangeRemovedIDs)) {
    const isEqualIDs = compareArrayValues(ids, lastChangeRemovedIDs)
    if (isEqualIDs) {
      try {
        const res = yield store(`/completely-remove-baskets/`, {
          basket_ids: [...ids],
        })
        if (res.status === 200) yield put(removeBasketIdsFromDbSuccess())
      } catch (e) {
        console.log(e)
        yield put(removeBasketIdsFromDbError())
      }
    }
  }
}

function* copyBasketFn({ payload }) {
  if (!payload) return
  const { basket_id, new_basket_name, new_basket_desc } = payload || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    const res = yield store(`/copy-basket/`, {
      baskets: [
        {
          basket_id,
          new_basket_name,
          new_basket_desc,
        },
      ],
    })
    const isSuccess = res?.status === 200

    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_copy_success'
        : 'notification.dataset_copy_error',
      notification_type: isSuccess ? 'success' : 'error',
      autoRemove: true,
    }
    yield put(copyBasketSuccess())
    yield put(addNotification(notify))
  } catch (e) {
    console.log(e)
    yield put(copyBasketFail(e))
  } finally {
    yield put(loadBaskets({ withPublic: true, basketType }))
  }
}

function* joinBasketsFn({ payload }) {
  if (!payload) return
  const { basket_ids, new_basket_name, new_basket_desc } = payload || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    const res = yield store('/baskets_join', {
      basket_ids,
      new_basket_name,
      new_basket_desc,
    })
    const isSuccess = res?.status === 200

    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_merge_success'
        : 'notification.dataset_merge_error',
      notification_type: isSuccess ? 'cancel' : 'error',
      autoRemove: true,
      params: {
        actionType: 'baskets-join',
        deleteIDs: basket_ids,
      },
    }
    yield put(joinBasketsSuccess())
    yield put(loadBaskets({ withPublic: true, basketType }))
    yield put(addNotification(notify))
  } catch (e) {
    console.log(e)
    yield put(joinBasketsFail(e))

    const notify = {
      id: uuidv4(),
      name: 'notification.dataset_merge_error',
      notification_type: 'error',
      autoRemove: true,
    }
    yield put(addNotification(notify))
  }
}

function* updateBasketFn({ payload }) {
  const { id, name, desc } = payload || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    yield update(`/baskets/${id}`, { name, desc })
    yield put(updateBasketSuccess())
  } catch (e) {
    console.log(e)
    yield put(updateBasketFail(e))
  } finally {
    yield put(loadBaskets({ withPublic: true, basketType }))
  }
}

function* moveMoleculesFn({ payload }) {
  const { source, molecules, label = 'Moved molecules', t } = payload || {}
  let { target } = payload

  const basketType = yield select((state) => state.settings.basketType)

  try {
    if (target === -1) {
      let resp = yield store('/baskets', {
        name: label,
        desc: label,
      })
      target = resp.data.id
    }
    const res = yield store('/molecules_move', {
      target,
      source,
      molecules,
    })
    if (Object.prototype.hasOwnProperty.call(res?.data?.result, 'is_success')) {
      const { error_message, is_success } = res.data.result || {}
      const hasError = is_success === false && !!error_message
      const text =
        !!error_message && MOVE_ERROR_MESSAGES.includes(error_message)
          ? `notification.${error_message}`
          : 'notification.unknown_error'
      const id = uuidv4()
      let notify
      if (hasError) {
        notify = {
          id: uuidv4(),
          name: 'notification.molecules_move_error',
          text,
          notification_type: 'error',
          autoRemove: false,
        }
      } else {
        notify = {
          id,
          name:
            molecules.length > 1
              ? 'notification.selected_molecules_moved'
              : 'notification.selected_molecule_moved',
          translationNameParams: { name: label || '' },
          notification_type: 'success',
          timeout: 5000,
          autoRemove: true,
          withActions: target === source ? false : true,
          params: {
            target,
          },
        }
      }
      yield put(addNotification(notify))
      if (!hasError) {
        yield put(moveMoleculesSuccess())
      } else {
        yield put(moveMoleculesFail())
      }
    }
  } catch (e) {
    yield put(moveMoleculesFail())
  } finally {
    yield delay(1000)
    yield put(loadMolecules({ basket: source }))
    yield put(loadBaskets({ withPublic: true, basketType }))
  }
}

function* copyMoleculesFn({ payload }) {
  const { target, fromSet } = payload || {}

  try {
    yield store('/molecules_copy', {
      target: target === -1 ? undefined : target,
      fromGen: [],
      fromSet: fromSet.map((item) => ({
        basket_id: item.basket_id,
        structure_ids: item.structure_ids,
      })),
    })
  } catch (e) {
    yield put(copyMoleculesFail(e))
  }
}

/*eslint-disable*/

export function* searchV2({ payload }) {
  const { ids = [], smiles = '', text = '', basket = null } = payload || {}
  try {
    yield put(setBingoSearchTaskId(''))
    yield put(setSearchLoading())
    yield put(setFilterButtonsDisabled())
    const openedBasket = yield select((state) => state.crud.openedBasketID)

    const searchBasket = openedBasket || basket
    const path = searchBasket
      ? `${BASKET_SEARCH_PATH}${searchBasket}`
      : MOLECULE_SEARCH_PATH

    const request = {
      params: { query: ids[0] || smiles || text },
    }

    const { data } = yield storeV2(path, request)
    const searchData = searchBasket ? data : data.result
    const { exact_match, similar_results } = searchData.data
    yield put(
      setSearchResult({ result: searchData.data, total: searchData.total })
    )

    const config = yield select((state) => state.filter.config)
    if (config?.match_type === 'exact match') {
      const { structure = INIT_QTY_ON_PAGE, dataset = INIT_QTY_ON_PAGE } =
        yield select((state) => state.settings.qtyOnPage)
      const limit = basket ? dataset : structure

      if (similar_results?.length > limit) {
        const paginConfig = {
          pagesAmount: Math.ceil(similar_results.length / limit),
          perPage: limit,
          total: similar_results.length,
          activePage: 1,
          offset: 0,
        }
        yield put(setPaginationConfig(paginConfig))
      }
    }

    if (config?.match_type === 'exact match' && !exact_match) {
      const id = uuidv4()
      const notify = {
        id,
        name: t(
          similar_results.length > 0
            ? 'notification.we_couldnt_found_best_match'
            : 'notification.we_couldnt_find_desired_structure'
        ),
        text: t(
          similar_results.length > 0
            ? 'notification.showed_you_similar_results'
            : 'notification.check_your_spelling_or_enter_SMILES'
        ),
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }

      yield put(addNotification(notify))
    }
  } catch (e) {
    console.log(e)
    yield put(setSearchError())
  } finally {
    yield put(setFilterButtonsActive())
  }
}

export function* showSearchV2ResultFn({ payload }) {
  const { taskUuid } = payload || {}
  try {
    yield put(setSearchLoading())
    yield put(setFilterButtonsDisabled())

    let status = 'initiated'
    while (status === 'initiated') {
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=0&limit=100`,
        2
      )

      status = searchData.data.metadata.status

      if (status === 'ok') {
        yield put(
          setSearchResult({
            result: searchData.data.result.data,
            total: searchData.data.result.total,
          })
        )

        break
      }

      if (status === 'error') {
        yield put(setSearchResult({ result: [] }))
        break
      }

      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put(setSearchResult({ result: [] }))
  }
}

export function* bingoSearch({ payload }) {
  const {
    ids = [],
    smiles = '',
    text = '',
    task_uuid,
    offset = 0,
    limit = BINGO_PAGINATION_LIMIT,
    showPastResult = false,
  } = payload || {}
  try {
    let taskUuid = task_uuid

    const config = yield select((state) => state.filter.config)
    const {
      similarity,
      match_type,
      molecular_weight,
      molecular_weight_default,
    } = config
    const isMarkush = match_type === 'markush structures'

    const weightDiff =
      Math.round(
        (Number(molecular_weight?.[1]) - Number(molecular_weight?.[0])) * 1000
      ) / 1000

    const isMarkushFieldsError =
      isMarkush &&
      checkMarkushFieldsError(
        molecular_weight,
        molecular_weight_default,
        weightDiff
      )

    yield put(setBingoSearchLoading())
    yield put(setFilterButtonsDisabled())
    if (!taskUuid) {
      yield put(setCachedPage({ pageItems: [], pageNumber: 0 }))
      yield put(setBingoSearchIsLongtask(true))

      const request = {
        params: { query: ids[0] || smiles || text },
      }
      let searchData

      try {
        const slicedString = text.slice(0, text.indexOf('|'))
        const isSubstructure = slicedString.split('').filter((el) => el === '*')

        if (
          isSubstructure.length > 2 ||
          (isMarkush && !text.includes('|')) ||
          isMarkushFieldsError
        ) {
          const { notificationName, translationNameParams } =
            getMarkushNotification(
              text,
              isSubstructure,
              isMarkush,
              molecular_weight,
              molecular_weight_default,
              weightDiff
            )
          const notify = {
            id: uuidv4(),
            name: notificationName,
            translationNameParams,
            notification_type: 'error',
            timeout: 5000,
            autoRemove: true,
          }

          yield put(addNotification(notify))
          if (!showPastResult) yield put(setFilterButtonsActive())
          yield put(setBingoSearchError())
          yield put(setSearchTransitError())
          return
        }
        yield put(setSearchTransitLoading())

        if (!isSubstructure.length) {
          const { data } = yield storeV2(MOLECULE_SEARCH_PATH, request)
          searchData = data.result.data
        }

        yield put(setSearchTransitDone())
      } catch (e) {
        console.log(e)
        yield put(setSearchTransitError())
        yield put(setBingoSearchResult({ result: [] }))
      }

      const foundSmiles = searchData?.exact_match
        ? searchData.exact_match.smiles
        : searchData?.similar_results?.[0]?.smiles

      if (foundSmiles || isMarkush) {
        if (!isMarkush) yield put(setSearchText(foundSmiles))
        let params = {
          smiles: foundSmiles,
          method: 'sub',
        }
        if (match_type === 'similar structures') {
          params = {
            smiles: foundSmiles,
            method: 'sim',
            metric: 'Tanimoto',
            bottom: similarity[0],
            top: similarity[1],
          }
        }
        if (isMarkush) {
          const getTop = () => {
            if (Number(molecular_weight?.[0]) && !molecular_weight?.[1])
              return Number(molecular_weight?.[0]) + 100
            if (molecular_weight?.[1]) return Number(molecular_weight[1])
            return 100
          }

          params = {
            smiles: text,
            method: 'markush',
            bottom:
              molecular_weight && molecular_weight[0]
                ? Number(molecular_weight[0])
                : 0,
            top: getTop(),
          }
          yield put(setMarkushMoleculeMaxWeight(params.top.toString()))
        }
        const { data } = yield storeV2(BINGO_SEARCH_PATH, { params })
        taskUuid = data.id
      } else {
        let params = {
          smiles: request.params.query,
          method: 'sub',
        }
        const { data } = yield storeV2(BINGO_SEARCH_PATH, { params })
        taskUuid = data.id
      }

      yield put(setBingoSearchTaskId(taskUuid))
    }

    let status = 'initiated'
    while (status === 'initiated') {
      const bingoStatus = yield select((state) => state.crud.bingoSearch.status)
      if (bingoStatus === 'done') {
        yield put(setBingoSearchIsLongtask(false))
        if (!showPastResult) yield put(setFilterButtonsActive())
        break
      }
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=${offset}&limit=${limit}`,
        2
      )

      status = searchData.data.metadata.status

      if (status === 'ok') {
        yield put(
          setBingoSearchResult({
            result: searchData.data.result.data,
            total: searchData.data.result.total,
          })
        )
        if (!task_uuid) {
          yield put(setBingoSearchIsLongtask(false))

          const searchHistoryData = yield fetch(
            `/search_history?offset=0&limit=1`
          )
          if (searchHistoryData) {
            const config = {
              total: searchHistoryData.data[0].result_count,
              pagesAmount: Math.ceil(
                searchHistoryData.data[0].result_count / 50
              ),
              perPage: 50,
              activePage: 1,
            }

            yield put(setPaginationConfig(config))
          }
        }
        const pastResult = yield select(
          (state) => state.searchHistory.pastResult
        )
        if (!showPastResult && !pastResult) yield put(setFilterButtonsActive())
      }
      if (status === 'error') {
        if (showPastResult) yield put(setBingoSearchResult({ result: [] }))
        else {
          yield put(setBingoSearchError())
          yield put(setSearchTransitError())
          yield put(setFilterButtonsActive())
          const notify = {
            id: uuidv4(),
            name: 'notification.we_couldnt_find_desired_structure',
            text: 'notification.check_your_spelling_or_enter_SMILES',
            notification_type: 'warning',
            autoRemove: true,
            timeout: 5000,
          }
          yield put(addNotification(notify))
        }
      }
      if (status === 'initiated' && showPastResult) {
        yield put(setBingoSearchResult({ result: [] }))
        break
      }

      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put(setBingoSearchError())
    yield put(setSearchTransitError())
    yield put(setFilterButtonsActive())
    const notify = {
      id: uuidv4(),
      name: 'notification.we_couldnt_find_desired_structure',
      text: 'notification.check_your_spelling_or_enter_SMILES',
      notification_type: 'warning',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

export function* literatureSearch({ payload }) {
  const {
    task_uuid = '',
    offset = 0,
    limit = 20,
    showPastResult = false,
  } = payload || {}
  const idForNotification = uuidv4()
  try {
    const pastResult = yield select((state) => state.searchHistory.pastResult)
    if (showPastResult) yield put(setFilterButtonsDisabled())
    else if (!pastResult) {
      put(setFilterButtonsActive())
    }

    let taskUuid = task_uuid
    yield put(setLiteratureSearchLoading())

    if (!taskUuid) {
      const notify = {
        id: uuidv4(),
        name: 'notification.literature_search_started',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      yield put(addNotification(notify))
      const text = yield select((state) => state.search.searchText)
      const lastSearchedText = yield select(
        (state) => state.literature.lastSearchedText
      )
      const syntellyId = yield select((state) => state.literature.syntellyId)
      const rightFilterConfig = yield select(
        (state) => state.literature.filter.config
      )
      const leftFilterConfig = yield select((state) => state.filter.config)

      const sortingConfig = yield select((state) => state.search.sorting)
      // get searchDataId. If get obj with smiles and id -  we use structures_id . If get null - we use title and abstract. If we have rightFilterConfig.name - skip `/get_structure_by_default_view` step
      let searchDataId = syntellyId || null

      try {
        if (!rightFilterConfig?.name && !!text.trim().length && !syntellyId) {
          yield put(setSearchTransitLoading())
          const reqText =
            rightFilterConfig?.name && lastSearchedText === text ? '' : text
          const { data } = yield store(`/get_structure_by_default_view`, {
            text: reqText,
          })
          if (data?.id) searchDataId = data.id
          yield put(setSearchTransitDone())
        }
      } catch (e) {
        console.log(e)
        yield put(setSearchTransitError())
      }

      // get filtered type for searching in conflict conditions
      let filteredType = {
        type: !leftFilterConfig?.document_type?.length
          ? ['patent', 'article']
          : [...leftFilterConfig.document_type],
      }
      // get ( structuresId ) or ( title and abstract if molecules_search not found synId && if first request )
      let structuresId = {}
      const prevsearchDataId = yield select(
        (state) => state.literature.prevSearchDataId
      )

      if (searchDataId) {
        const { title, abstract, ...rest } = rightFilterConfig || {}
        yield put(
          setLitFilterConfig(
            prevsearchDataId === null ? { ...rest } : rightFilterConfig
          )
        )

        structuresId = {
          structures_ids: {
            operator: 'should',
            exact: true,
            values: [searchDataId],
          },
        }
      }
      // clear prev filter config if new text given  //если в абстракт или тайтл введено не подменять
      let filteredTitleAbstract = {}
      if (searchDataId === null && !rightFilterConfig?.name) {
        const getUpdatedData = () => {
          const newObj = {
            title: {
              operator: rightFilterConfig?.title?.operator ?? 'should',
              exact: false,
              values:
                prevsearchDataId !== null
                  ? [text]
                  : [
                      ...(rightFilterConfig?.title?.values &&
                      lastSearchedText === text
                        ? [...rightFilterConfig.title.values]
                        : [text]),
                    ],
              not_values: rightFilterConfig.title?.not_values || [],
            },
            abstract: {
              operator: rightFilterConfig?.abstract?.operator ?? 'should',
              exact: false,
              values:
                prevsearchDataId !== null
                  ? [text]
                  : [
                      ...(rightFilterConfig?.abstract?.values &&
                      lastSearchedText === text
                        ? [...rightFilterConfig.abstract.values]
                        : [text]),
                    ],
              not_values: rightFilterConfig.abstract?.not_values || [],
            },
          }

          return {
            ...(newObj.title.values?.[0]?.length
              ? { title: newObj?.title }
              : {}),
            ...(newObj.abstract.values?.[0]?.length
              ? { abstract: newObj.abstract }
              : {}),
          }
        }
        const newObj = getUpdatedData()
        //delete structures_ids if searchData === null
        const { structures_ids, ...restConfig } = rightFilterConfig

        try {
          yield put(
            setLitFilterConfig({
              ...restConfig,
              ...newObj,
            })
          )
          filteredTitleAbstract = { ...newObj }
        } catch (e) {
          console.log(e)
        }
      }
      yield put(setPrevSearchDataId(searchDataId))
      setPrevSearchDataId(searchDataId)
      // left filter has priority over right one. If author in left filter - overwriting the right value with the left one
      const filteredAuthor = () => {
        if (leftFilterConfig?.author) {
          return {
            authors: {
              operator: 'should',
              exact: false,
              values: [leftFilterConfig.author],
            },
          }
        }
        return {}
      }

      // left filter has priority over right one. Left one filters out the right one
      const filteredLanguage = () => {
        const isRightFilterLanguage =
          rightFilterConfig?.language?.values?.length
        const isLeftFilterLanguage = leftFilterConfig?.language?.length

        if (!isRightFilterLanguage && isLeftFilterLanguage) {
          return {
            language: {
              operator:
                leftFilterConfig.language?.length > 1 ? 'should' : 'must',
              exact: true,
              values: [...leftFilterConfig.language],
            },
          }
        }
        if (isRightFilterLanguage && isLeftFilterLanguage) {
          return {
            language: {
              operator: rightFilterConfig.language.operator,
              exact: true,
              values: [
                ...rightFilterConfig.language.values.filter((value) =>
                  leftFilterConfig.language.includes(value)
                ),
              ],
            },
          }
        }
        if (isRightFilterLanguage && !isLeftFilterLanguage) {
          return {
            language: {
              operator:
                rightFilterConfig.language.values?.length > 1
                  ? rightFilterConfig.language.operator
                  : 'must',
              exact: true,
              values: [...rightFilterConfig.language.values],
            },
          }
        }
        return {}
      }

      // update right filter config after change when add structures id above
      const newRightFilterConfig = yield select(
        (state) => state.literature.filter.config
      )

      if (
        newRightFilterConfig?.published_date &&
        !Object.values(newRightFilterConfig.published_date).some((el) => el)
      ) {
        delete newRightFilterConfig.published_date
      }

      // only left filter
      const filteredPublishedDate = () => {
        const isLeftFilterDate = Object.values(
          leftFilterConfig?.published_date || []
        ).some((el) => el)

        if (!isLeftFilterDate) return {}

        const fromDate = leftFilterConfig?.published_date[0]
          ? {
              from_date: `${Number(leftFilterConfig?.published_date[0])}-01-01`,
            }
          : {}

        const toDate = leftFilterConfig?.published_date[1]
          ? {
              to_date: `${Number(leftFilterConfig?.published_date[1])}-12-31`,
            }
          : {}
        return { published_date: { ...fromDate, ...toDate } }
      }

      const sorting = () => {
        if (sortingConfig.type === 'relevance') return {}
        else
          return {
            sort: {
              key: sortingConfig.type,
              order_type: sortingConfig.direction,
            },
          }
      }
      const { title, abstract, ...rest } = newRightFilterConfig

      const keysToCheck = ['title', 'abstract'] //rm from final config keys with empty value
      for (const key of keysToCheck) {
        if (
          newRightFilterConfig.hasOwnProperty(key) &&
          newRightFilterConfig[key]['values'] &&
          newRightFilterConfig[key]['values']?.length === 1 &&
          newRightFilterConfig[key]['values'][0] === ''
        ) {
          delete newRightFilterConfig[key]
        }
      }
      const config = {
        ...newRightFilterConfig,
        ...filteredTitleAbstract,
        ...filteredType,
        ...structuresId,
        ...filteredAuthor(),
        ...filteredLanguage(),
        ...filteredPublishedDate(),
        ...sorting(),
      }

      // check conflcited types
      if (filteredType?.type?.length === 1) {
        const conflictCondition = CONFLICT_OPTIONS[filteredType.type[0]]
        const configValues = Object.keys(config)

        conflictCondition.map((condition) => {
          if (configValues.includes(condition)) {
            delete config[condition]
          }
        })
      }
      const shouldBlocks = yield select(
        (state) => state.literature.filter.shouldBlocksIndexes
      )

      const newConfig = {
        params: convertLitSearchConfig(config, shouldBlocks),
      }

      const { data } = yield storeV2(LIT_SEARCH_PATH, newConfig)
      if (lastSearchedText !== text) {
        yield put(setLastSearchedText(text))
      }

      if (!data.id) throw new Error('error')
      taskUuid = data.id

      yield put(setLiteratureSearchTaskId(taskUuid))

      const progress_notify = {
        id: idForNotification,
        name: 'notification.literature_search_progress',
        autoRemove: false,
        notification_type: 'progress',
        progress: 0,
        timeout: 5000,
        withIcon: false,
      }
      yield put(addNotification(progress_notify))
    }
    let status = 'initiated'

    while (
      (status === 'initiated' || status === 'running') &&
      status !== 'error'
    ) {
      const paginationState = yield select((state) => state.search.pagination)
      const { activePage, perPage } = paginationState || {}
      const offsetToUse = activePage === 1 ? 0 : (activePage - 1) * perPage
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=${offsetToUse}&limit=${limit}`,
        2
      )
      status = searchData.data.metadata.status
      const progress = searchData?.data?.metadata?.progress || 0
      const error = searchData?.data?.error

      if (
        error &&
        error?.message?.toString()?.includes('Total documents found is zero.')
      ) {
        status === 'ok'
        yield put(
          setLitSearchState({
            status: 'done',
            result: {
              smiles: '',
              data: [],
            },
          })
        )
        yield put(removeNotification(idForNotification))
        const notify = {
          id: uuidv4(),
          name: 'notification.literature_search_finished',
          autoRemove: true,
          notification_type: 'success',
          timeout: 5000,
        }
        yield put(addNotification(notify))
      }
      yield put(
        updateNotification({
          id: idForNotification,
          updated_data: {
            progress: progress,
          },
        })
      )
      if (
        progress >= 10 &&
        searchData?.data?.result &&
        searchData.data.result.total !== paginationState
      ) {
        const config = {
          pagesAmount: Math.ceil(searchData.data.result.total / limit),
          perPage: limit,
          total: searchData.data.result.total,
          activePage: activePage,
        }
        yield put(setPaginationConfig(config))
        yield put(
          setLiteratureSearchResult({
            result: searchData.data,
            totalLitFound: searchData.data.result.total,
            status: 'loading',
          })
        )
      }
      if (status === 'ok') {
        yield put(
          setLiteratureSearchResult({
            result: searchData.data,
            totalLitFound: searchData.data.result.total,
          })
        )
        if (!task_uuid) {
          const searchHistoryData = yield fetch(
            `/search_history?offset=${offsetToUse}&limit=${limit}`
          )
          if (searchHistoryData) {
            const config = {
              total: searchHistoryData.data[0].result_count,
              pagesAmount: Math.ceil(
                searchHistoryData.data[0].result_count / limit
              ),
              perPage: limit,
              activePage: activePage,
            }

            yield put(setPaginationConfig(config))
          }

          const notify = {
            id: uuidv4(),
            name: 'notification.literature_search_finished',
            autoRemove: true,
            notification_type: 'success',
            timeout: 5000,
          }

          yield put(addNotification(notify))
          yield put(removeNotification(idForNotification))
        }
      }
      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put(setLiteratureSearchError())
    yield put(removeNotification(idForNotification))
  }
}

export function* checkCustomerDatasetsAccessFn() {
  try {
    const res = yield fetch('/customer/check_available_customer_baskets', 2)
    if (res.status === 200) {
      setStorage('showCustomerDatasets', true)
    } else {
      setStorage('showCustomerDatasets', false)
    }
  } catch (e) {
    console.log(e)
  }
}

export function* transferToCustomerDatasets({ payload }) {
  const { basket_ids = [], basketType, withCopy = false } = payload || {}
  if (!basket_ids.length) return
  const isMultiple = basket_ids.length > 1

  try {
    const res = yield store(
      withCopy ? '/copy-basket-to-customer/' : '/transfer-basket-to-customer/',
      {
        basket_ids,
      }
    )
    if (res.status === 200) {
      const notificationName = getDatasetTransferName(
        withCopy,
        isMultiple,
        true
      )
      yield put(loadBaskets({ withPublic: true, basketType }))
      yield put(setSelected([]))

      const notify = {
        id: uuidv4(),
        name: notificationName,
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }

      yield put(addNotification(notify))
    }
  } catch (e) {
    console.log(e)
    const notificationName = getDatasetTransferName(withCopy, isMultiple, false)
    const notify = {
      id: uuidv4(),
      name: notificationName,
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }

    yield put(addNotification(notify))
  }
}

export function* reactionsSearch({ payload }) {
  const {
    offset = 0,
    limit = REACTIONS_PAGINATION_LIMIT,
    compound_id: exactID,
    text,
    task_uuid,
  } = payload || {}
  const pastResult = yield select((state) => state.searchHistory.pastResult)
  const leftFilterConfig = yield select((state) => state.filter.config)
  const compound_id = yield select((state) => state.reactionsSearch.compound_id)
  const paginationState = yield select((state) => state.search.pagination)
  const stateSearchText = yield select((state) => state.search.searchText)
  const textToUse = text || stateSearchText

  const {
    compound_types,
    yield: yield_range,
    reaction_display,
  } = leftFilterConfig || {}

  const { activePage } = paginationState || {}
  try {
    let reactionsSearchData = []

    yield put(setReactionsSearchLoading())
    yield put(setFilterButtonsDisabled())

    if (!task_uuid) {
      const request_body = {
        params: {
          compound_types,
          yield_range: { from: yield_range[0], to: yield_range[1] },
          yield_unknown: !!reaction_display.length,
          offset,
          limit,
        },
      }

      if (exactID) {
        const { data: search_data } = yield storeV2(
          `${REACTION_SEARCH_PATH}${compound_id}`,
          request_body
        )

        reactionsSearchData = search_data?.result
        yield put(setReactionsCompoundId(exactID ?? compound_id))
      } else {
        const { data } = yield storeV2(TIPS_SEARCH_PATH, {
          params: {
            query: textToUse,
          },
        })

        const bestMatchId = data?.result?.items?.find((el) => {
          const indx = el.label.indexOf(': ')
          if (indx === -1) {
            return false
          }
          return el.label.slice(indx + 2) === textToUse
        })?.id

        if (bestMatchId) {
          const { data: search_data } = yield storeV2(
            `${REACTION_SEARCH_PATH}${bestMatchId}`,
            request_body
          )

          reactionsSearchData = search_data?.result

          yield put(setReactionsCompoundId(bestMatchId))
        }
      }
    } else {
      let status = 'initiated'
      while (status === 'initiated') {
        const searchData = yield fetch(
          `/search_history/${task_uuid}?offset=${offset}&limit=${limit}`,
          2
        )

        status = searchData.data.metadata.status

        if (status === 'ok') {
          reactionsSearchData = {
            items: searchData.data.result.data,
            count: searchData.data.result.total,
          }
          break
        }

        if (status === 'error') {
          throw new Error()
        }

        yield delay(2000)
      }
    }

    if (reactionsSearchData.count > limit) {
      const config = {
        pagesAmount: Math.ceil(reactionsSearchData.count / limit),
        perPage: limit,
        total: reactionsSearchData.count,
        activePage: activePage,
      }
      yield put(setPaginationConfig(config))
    }

    yield put(
      setReactionsSearchResult({
        result: reactionsSearchData,
      })
    )
  } catch (e) {
    console.log(e)
    yield put(setReactionsSearchError())
  } finally {
    if (!pastResult) yield put(setFilterButtonsActive())
  }
}

export function* rehydrateStoreFn({ payload }) {
  const { settings, mmap, compareParams } = payload || {}
  if (mmap) {
    yield put(mmapRehydrate(mmap))
  }
  if (settings) {
    yield put(settingsRehydrate(settings))
  }
  if (compareParams) {
    yield put(compareParamsRehydrate(compareParams))
  }
}

export function* addBasketWatcher() {
  yield takeEvery(addBasket.type, addBasketFn)
}
export function* deleteBasketWatcher() {
  yield takeEvery(deleteBasket.type, deleteBasketFn)
}
export function* copyBasketWatcher() {
  yield takeEvery(copyBasket.type, copyBasketFn)
}
export function* joinBasketWatcher() {
  yield takeEvery(joinBaskets.type, joinBasketsFn)
}

export function* basketLoadWatcher() {
  yield takeLatest(loadBaskets.type, loadBasket)
}

export function* loadLastChangeWatcher() {
  yield takeLatest(loadLastChange.type, loadLastChangeFn)
}

export function* moleculesLoadWatcher() {
  yield takeLatest(loadMolecules.type, loadMoleculesFn)
}

export function* moleculeCreateWatcher() {
  yield takeEvery(addMolecule.type, createMolecule)
}

export function* moleculeCreateFromFileWatcher() {
  yield takeEvery(addMoleculesFromFile.type, createMoleculesFromFile)
}

export function* moleculeDeleteWatcher() {
  yield takeLatest(deleteMolecule.type, deleteMoleculeFn)
}

export function* moleculeUpdateWatcher() {
  yield takeEvery(updateMolecule.type, updateMoleculeFn)
}

export function* updateBasketWatcher() {
  yield takeEvery(updateBasket.type, updateBasketFn)
}

export function* loadMoreMoleculesWatcher() {
  yield takeLeading(loadMoreMolecules.type, loadMoreMoleculesFn)
}

export function* moveMoleculesWatcher() {
  yield takeEvery(moveMolecules.type, moveMoleculesFn)
}

export function* createMoleculesPackWatcher() {
  yield takeEvery(addMoleculesPack.type, createMoleculesPack)
}

export function* calcTLightWatcher() {
  yield takeEvery(calcTlight.type, calcTLightFn)
}

export function* searchV2Watcher() {
  yield takeEvery(setSearchV2.type, searchV2)
}

export function* showSearchV2ResultWatcher() {
  yield takeEvery(showSearchV2Result.type, showSearchV2ResultFn)
}

export function* bingoSearchWatcher() {
  yield takeEvery(setBingoSearch.type, bingoSearch)
}

export function* bingoMarkushSearchWatcher() {
  yield takeEvery(SET_BINGO_MARKUSH_SEARCH, bingoMarkushSearch)
}

export function* litSearchWatcher() {
  yield takeEvery(setLiteratureSearch.type, literatureSearch)
}

export function* removeBasketFromDBWatcher() {
  yield takeEvery(removeLastActionsIDsFromDB.type, removeBasketFromDB)
}

export function* checkCustomerDatasetsAccessWatcher() {
  yield takeEvery(
    checkCustomerDatasetsAccess.type,
    checkCustomerDatasetsAccessFn
  )
}

export function* transferToCustomerDatasetWatcher() {
  yield takeLatest(
    handleTransferToCustomerDatasets.type,
    transferToCustomerDatasets
  )
}
export function* reactionsSearchWatcher() {
  yield takeEvery(setReactionsSearch.type, reactionsSearch)
}

export function* rehydrateWatcher() {
  yield takeEvery(rehydrateStore.type, rehydrateStoreFn)
}

export function* copyMoleculesWatcher() {
  yield takeEvery(copyMolecules.type, copyMoleculesFn)
}
