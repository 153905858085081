import { put } from 'redux-saga/effects'
import { updateMoleculePropsError } from 'store/slices/moleculeSlice'

export const handleCalculationsError = (name) => {
  switch (name) {
    case 'iupacarticle':
      return put(
        updateMoleculePropsError({
          data: {
            iupacarticle: 'Unable to generate valid IUPAC name',
          },
          sources: {},
        })
      )
    default:
      return
  }
}
