import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import crudSlice from '../slices/crudSlice'
import searchSlice from '../slices/searchSlice'
import contextMenuSlice from '../slices/contextMenuSlice'
import literatureSlice from '../slices/literatureSlice'
import reactionsSlice from '../slices/reactionsSlice'
import compareParamsSlice from '../slices/compareParamsSlice'
import blurSlice from '../slices/blurSlice'
import authSlice from '../slices/authSlice'
import feedbackSlice from '../slices/feedbackSlice'
import retrosynthesisSlice from '../slices/retrosynthesisSlice'
import smilesIupacSlice from '../slices/smilesIupacSlice'
import synthcostSlice from '../slices/synthcostSlice'
import structuresCompareSlice from '../slices/structuresCompareSlice'
import settingsSlice from '../slices/settingsSlice'
import statisticsSlice from '../slices/statisticsSlice'
import surveySlice from '../slices/surveySlice'
import reactionsSearchSlice from '../slices/reactionsSearchSlice'
import adminPanelSlice from '../slices/adminPanelSlice'
import tasksSlice from '../slices/tasksSlice'
import spectraSlice from '../slices/spectraSlice'
import pdf2SmilesSlice from '../slices/pdf2SmilesSlice'
import basketSlice from '../slices/basketSlice'
import searchHistorySlice from '../slices/searchHistorySlice'
import notificationsSlice from '../slices/notificationsSlice'
import confirmationSlice from '../slices/confirmationSlice'
import filterSlice from '../slices/filterSlice'
import moleculeSlice from '../slices/moleculeSlice'
import mmapSlice from '../slices/mmapSlice'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    crud: crudSlice,
    mmap: mmapSlice,
    reactions: reactionsSlice,
    auth: authSlice,
    settings: settingsSlice,
    search: searchSlice,
    tasks: tasksSlice,
    notifications: notificationsSlice,
    spectra: spectraSlice,
    confirmation: confirmationSlice,
    molecule: moleculeSlice,
    filter: filterSlice,
    literature: literatureSlice,
    basket: basketSlice,
    pdf2Smiles: pdf2SmilesSlice,
    synthcost: synthcostSlice,
    smilesIupac: smilesIupacSlice,
    searchHistory: searchHistorySlice,
    retrosynthesis: retrosynthesisSlice,
    feedback: feedbackSlice,
    statistics: statisticsSlice,
    contextMenu: contextMenuSlice,
    blur: blurSlice,
    adminPanel: adminPanelSlice,
    reactionsSearch: reactionsSearchSlice,
    survey: surveySlice,
    structuresCompare: structuresCompareSlice,
    compareParams: compareParamsSlice,
  })

export default rootReducer
