export const CONFLICT_OPTIONS = {
  patent: ['journal', 'publisher'],
  article: ['company', 'applicant', 'text', 'claims'],
}

export const OPERATOR_OPTIONS = [
  { value: 'must', label: 'filter.literature.must' },
  { value: 'should', label: 'filter.literature.should' },
]
export const OPERATOR_OPTIONS_CONFIG = [
  { type: 'must', title: 'filter.literature.must' },
  { type: 'should', title: 'filter.literature.should' },
]

export const OPERATOR_OPTIONS_CONFIG_WITH_NOT = [
  ...OPERATOR_OPTIONS_CONFIG,
  { type: 'not_operator', title: 'filter.literature.not' },
]

// first add new conditions here

export const CONDITIONS_ROW_TYPES = {
  title: 'filterInputRow',
  author: 'filterInputRow',
  abstract: 'filterInputRow',
  journal: 'filterInputRow',
  pyblisher: 'filterInputRow',
  company: 'filterInputRow',
  language: 'filterSelectRow',
  published_date: 'filterDoubleInputRow',
}

export const INITIAL_TYPES_LIST = [
  'title',
  'abstract',
  'authors',
  'doi',
  'patent_number',
  'journal',
  'publisher',
  'owner',
  'applicant',
  'text',
  'claims',
]

export const LANGUAGES_OPTIONS = [
  { value: 'ru', label: 'filter.structure.ru' },
  { value: 'en', label: 'filter.structure.en' },
  // { value: 'de', label: 'filter.structure.de' },
]
