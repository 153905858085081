import {
  configureStore,
  Dispatch,
  Middleware,
  UnknownAction,
} from '@reduxjs/toolkit'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import logger from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './reducers'
import rootSaga from './sagas'
import history from '../services/history'
import {
  setDataToLocalForage,
  getDataFromLocalForage,
} from '../utils/storage/storage'
import {
  INIT_QTY_ON_PAGE_SETTING,
  initialState as settingsDefaultStore,
} from './slices/settingsSlice'
import { initialState as mmapDefaultStore } from './slices/mmapSlice'
import { initialState as compareParamsDefaultStore } from './slices/compareParamsSlice'
import { WITH_LOGGER } from '../config/config'
import { rehydrateStore } from './slices/crudSlice'

const loadPersistedState = async (): Promise<Partial<RootState>> => {
  try {
    let persistedSettingsState = await getDataFromLocalForage('s_settings')
    let persistedMmapState = await getDataFromLocalForage('mmap')
    let persistedCompareParamsState = await getDataFromLocalForage(
      'compare_params'
    )

    if (!persistedSettingsState) {
      persistedSettingsState = { settings: settingsDefaultStore }
    } else {
      //hot fix for missed param, need to implement common methodics
      persistedSettingsState.settings.qtyOnPage = {
        ...INIT_QTY_ON_PAGE_SETTING,
        ...persistedSettingsState.settings.qtyOnPage,
      }
    }
    await setDataToLocalForage('s_settings', persistedSettingsState)

    if (!persistedMmapState) {
      persistedMmapState = { mmap: mmapDefaultStore }
      await setDataToLocalForage('mmap', persistedMmapState)
    }

    if (!persistedCompareParamsState) {
      persistedCompareParamsState = {
        compareParams: compareParamsDefaultStore,
      }
      await setDataToLocalForage('compare_params', persistedCompareParamsState)
    }

    return {
      ...persistedSettingsState,
      ...persistedMmapState,
      ...persistedCompareParamsState,
    }
  } catch (error) {
    return {
      settings: settingsDefaultStore,
      mmap: mmapDefaultStore,
      compareParams: compareParamsDefaultStore,
    }
  }
}

const sagaMiddleware: SagaMiddleware = createSagaMiddleware()

const middlewaresList: Middleware<{}, any, Dispatch<UnknownAction>>[] =
  WITH_LOGGER
    ? [
        sagaMiddleware,
        logger as Middleware<{}, any, Dispatch<UnknownAction>>,
        routerMiddleware(history),
      ]
    : [sagaMiddleware, routerMiddleware(history)]

const store = configureStore({
  reducer: rootReducer(history),
  preloadedState: {
    settings: settingsDefaultStore,
    mmap: mmapDefaultStore,
    compareParams: compareParamsDefaultStore,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(...middlewaresList),
  devTools: process.env.NODE_ENV !== 'production',
})
store.subscribe(() => {
  const state = store.getState()
  setDataToLocalForage('s_settings', { settings: state.settings })
  setDataToLocalForage('mmap', { mmap: state.mmap })
  setDataToLocalForage('compare_params', {
    compareParams: state.compareParams,
  })
})
const hydrateStore = async () => {
  try {
    const persistedState = await loadPersistedState()
    store.dispatch(rehydrateStore(persistedState as any))
  } catch (error) {
    console.error('Error loading persisted state:', error)
  }
}

sagaMiddleware.run(rootSaga)

try {
  await hydrateStore()
} catch (error) {
  console.error('Failed to hydrate store:', error)
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
export { store }
