import { v4 as uuidv4 } from 'uuid'
import { fetch, storeV2 } from 'services/rest'
import { delay, put, select, takeEvery } from 'redux-saga/effects'
import { MAX_STRUCTURES_COUNT } from 'components/StructuresCompare/config'
import { convertResponseToCalculationArr } from './utils/utils'
import {
  addStructureToCompare,
  addStructureToCompareSuccess,
  clearStructuresComparisonList,
  clearStructuresComparisonListSuccess,
  getStructuresCalculation,
  getStructuresCalculationSuccess,
  getStructuresComparisonList,
  getStructuresComparisonListSuccess,
  uncompareStructure,
  uncompareStructureSuccess,
} from 'store/slices/structuresCompareSlice'
import {
  addNotification,
  addSimpleNotification,
} from 'store/slices/notificationsSlice'

function* getStructuresComparisonListFn() {
  try {
    const { data } = yield storeV2('molecule/compare_structures/get')

    yield put(getStructuresComparisonListSuccess(data.result))
  } catch (e) {
    console.log(e)
  }
}

function* addStructureToCompareFn({ payload }) {
  const { id, smiles } = payload || {}
  const structures = yield select((state) => state.structuresCompare.structures)
  if (structures.length >= MAX_STRUCTURES_COUNT) {
    const notify = {
      id: uuidv4(),
      name: 'structures_compare.failed_to_add_to_compare',
      text: 'structures_compare.maximum_number_exceeded',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  } else {
    try {
      const { data } = yield storeV2('molecule/compare_structures/add', {
        params: {
          baseID: Number(id),
        },
      })

      yield put(
        addStructureToCompareSuccess({
          id: data.result.id,
          baseID: Number(id),
          smiles,
        })
      )
      yield put(
        addSimpleNotification({
          text: 'structures_compare.add_to_compare_success',
          withOpenBtn: true,
          route: '/molecules-compare',
        })
      )
    } catch (e) {
      console.log(e)
      const notify = {
        id: uuidv4(),
        name: 'structures_compare.failed_to_add_to_compare',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      yield put(addNotification(notify))
    }
  }
}

function* uncompareStructureFn({ payload }) {
  const { baseID, needNotification } = payload || {}
  const structures = yield select((state) => state.structuresCompare.structures)
  const id = structures.find((el) => el.baseID === Number(baseID)).id
  try {
    yield storeV2('molecule/compare_structures/delete_by_id', {
      params: {
        id,
      },
    })

    yield put(uncompareStructureSuccess({ id, baseID }))
    if (needNotification) {
      yield put(
        addSimpleNotification({
          text: 'structures_compare.uncompare_success',
          withOpenBtn: true,
          route: '/molecules-compare',
        })
      )
    }
  } catch (e) {
    console.log(e)
    const notify = {
      id: uuidv4(),
      name: 'structures_compare.failed_to_uncompare',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* clearStructuresComparisonListFn() {
  try {
    yield storeV2('molecule/compare_structures/clear')

    yield put(clearStructuresComparisonListSuccess())
  } catch (e) {
    console.log(e)
    const notify = {
      id: uuidv4(),
      name: 'structures_compare.failed_to_clear',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* getStructuresCalculationFn() {
  let currentTaskId
  try {
    const response = yield storeV2('/molecule/compare_structures/calculation')
    const { structures } = yield select((state) => state.structuresCompare)

    if (response?.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response?.data?.task_uuid

        const tasks = yield fetch(`/userTasksStatus`)

        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          currentTaskId = task
          if (task?.status === 'ok') {
            status = 'ok'
            let calculationResponse = yield storeV2(
              '/molecule/compare_structures/result'
            )

            if (!calculationResponse?.data?.result) throw new Error()

            const calculation = calculationResponse.data.result

            yield put(
              getStructuresCalculationSuccess(
                convertResponseToCalculationArr(calculation, structures)
              )
            )
          }
        }
        if (currentTaskId && !task) {
          currentTaskId = 'canceled'
          status = 'canceled'
          yield put(getStructuresCalculationSuccess([]))
        }

        yield delay(2000)
      }
    }
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put(getStructuresCalculationSuccess([]))
    } else if (error) {
      console.log('error', error)
    }
  }
}

function* getStructuresComparisonListWatcher() {
  yield takeEvery(
    getStructuresComparisonList.type,
    getStructuresComparisonListFn
  )
}

function* addStructureToCompareWatcher() {
  yield takeEvery(addStructureToCompare.type, addStructureToCompareFn)
}

function* uncompareStructureWatcher() {
  yield takeEvery(uncompareStructure.type, uncompareStructureFn)
}

function* clearStructuresComparisonListWatcher() {
  yield takeEvery(
    clearStructuresComparisonList.type,
    clearStructuresComparisonListFn
  )
}

function* getStructuresCalculationWatcher() {
  yield takeEvery(getStructuresCalculation.type, getStructuresCalculationFn)
}

const watchers = [
  addStructureToCompareWatcher(),
  getStructuresComparisonListWatcher(),
  uncompareStructureWatcher(),
  clearStructuresComparisonListWatcher(),
  getStructuresCalculationWatcher(),
]

export default watchers
