import { put, select, takeEvery } from 'redux-saga/effects'
import { fetch } from 'services/rest'
import {
  BINGO_PAGINATION_LIMIT,
  INNER_LANG_OPERATOR,
  LIT_PAGINATION_LIMIT,
  REACTIONS_PAGINATION_LIMIT,
} from './utils/config'
import {
  convertNewOperatorToOld,
  extractValues,
  flattenOperators,
  getField,
} from './utils/utils'
import { INITIAL_TYPES_LIST } from 'components/Search/LiteratureFilter/constants'
import {
  setPaginationConfig,
  setSearchSortingConfig,
  setSearchText,
  setSearchType,
} from 'store/slices/searchSlice'
import {
  setLitFilterConfig,
  setLitFilterDocType,
  setShouldBlocksIndexes,
  updateLastSearchValues,
} from 'store/slices/literatureSlice'
import { setReactionsCompoundId } from 'store/slices/reactionsSearchSlice'
import {
  getSearchHistory,
  getSearchHistoryError,
  getSearchHistorySuccess,
  repeatSearch,
} from 'store/slices/searchHistorySlice'
import {
  setFilterButtonsDisabled,
  setFilterConfig,
  setOpenFilter,
} from 'store/slices/filterSlice'
import {
  setBingoSearch,
  setLiteratureSearch,
  setReactionsSearch,
  setSearchV2,
  showSearchV2Result,
} from 'store/slices/crudSlice'

function* getSearchHistoryFn({ payload }) {
  const { offset, limit } = payload || {}
  try {
    const response = yield fetch(
      `/search_history?offset=${offset}&limit=${limit}`
    )

    if (response.status === 200) {
      yield put(getSearchHistorySuccess(response.data))
    } else {
      yield put(getSearchHistoryError(response.statusText))
    }
  } catch (error) {
    yield put(getSearchHistoryError(error?.message || 'Error'))
  }
}

function* repeatSearchFn({ payload }) {
  const { data, showPastResult } = payload || {}
  const { params } = data.query
  const filterConfig = yield select((state) => state.filter.config)
  const isFilterOpen = yield select((state) => state.filter.open)

  if (showPastResult) {
    yield put(setFilterButtonsDisabled())

    if (data.query.type === 'molecules_search') {
      yield put(setSearchType('structure'))
      let match_type = 'exact match'

      const config = { ...filterConfig, match_type }

      yield put(setFilterConfig(config))

      yield put(
        setSearchText(
          params?.query ||
            params?.searchV2?.text ||
            params?.searchV2?.smiles ||
            ''
        )
      )

      if (!isFilterOpen) {
        yield put(setOpenFilter(true))
      }

      yield put(showSearchV2Result({ taskUuid: data.id }))
    } else {
      const limit =
        data.query.type === 'full_text_search'
          ? LIT_PAGINATION_LIMIT
          : data.query.type === 'reaction_search'
          ? REACTIONS_PAGINATION_LIMIT
          : BINGO_PAGINATION_LIMIT
      const config = {
        total: data.result_count,
        pagesAmount: Math.ceil(data.result_count / limit),
        perPage: limit,
        activePage: 1,
      }
      yield put(setPaginationConfig(config))
    }
  }

  if (data.query.type === 'molecules_search' && !showPastResult) {
    yield put(setSearchType('structure'))
    let match_type = 'exact match'

    const config = { ...filterConfig, match_type }

    yield put(setFilterConfig(config))

    yield put(
      setSearchText(
        params?.query ||
          params?.searchV2?.text ||
          params?.searchV2?.smiles ||
          ''
      )
    )
    if (!isFilterOpen) {
      yield put(setOpenFilter(true))
    }
    yield put(
      setSearchV2({
        ids: params?.searchV2?.ids,
        text: params?.searchV2?.text || params?.query,
        smiles: params?.searchV2?.smiles,
      })
    )
  }

  if (data.query.type === 'bingo_search') {
    yield put(setSearchType('structure'))
    let match_type = 'exact match'
    if (params.method === 'sub') match_type = 'substructural search'
    if (params.method === 'sim') match_type = 'similar structures'
    if (params.method === 'markush') match_type = 'markush structures'

    const similarity = {
      0:
        params?.bottom && params.method !== 'markush'
          ? params.bottom.toString()
          : 0,
      1:
        params?.top && params.method !== 'markush'
          ? params.top.toString()
          : 100,
    }
    const molecular_weight = {
      0:
        params?.bottom && params.method === 'markush'
          ? params.bottom.toString()
          : '',
      1:
        params?.top && params.method === 'markush' ? params.top.toString() : '',
    }

    const config = { ...filterConfig, match_type, similarity, molecular_weight }

    yield put(setFilterConfig(config))

    yield put(
      setSearchText(
        params?.text ||
          params?.smiles ||
          params?.searchV2?.text ||
          params?.searchV2?.smiles ||
          ''
      )
    )
    if (!isFilterOpen) {
      yield put(setOpenFilter(true))
    }
    yield put(
      setBingoSearch({
        text: params.smiles,
        label: params.smiles,
        task_uuid: showPastResult ? data.id : null,
        showPastResult,
      })
    )
  } else if (data.query.type === 'full_text_search') {
    yield put(setSearchType('literature'))
    if (params?.name) {
      const DOIRe = /\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\\'<>])\S)+)\b/g
      // const patentRe = /([a-zA-Z]{2})([\d\\/]+)([a-zA-Z]\d)/gm
      const checkDOI = new RegExp(DOIRe).test(params.name)
      const docType = checkDOI ? 'doi' : 'patent_number'
      yield put(setLitFilterDocType(docType))
    }
    if (params?.title) {
      yield put(setSearchText(params.title.values[0]))
    }
    const { type, structures_ids, published_date, language, sort, ...rest } =
      params
    if (structures_ids) {
      try {
        const { data: fetchData } = yield fetch(
          `/molecule/${params.structures_ids.values[0]}`,
          2
        )
        const data = fetchData?.result || {}
        yield put(setSearchText(data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O'))
      } catch (e) {
        console.log(e)
        yield put(setSearchText('O=C(C)Oc1ccccc1C(=O)O'))
      }
    }

    if (sort) {
      yield put(
        setSearchSortingConfig({
          type: sort.key,
          direction: sort.order_type,
        })
      )
    }
    yield put(setLitFilterConfig({ ...rest }))
    const config = {}
    if (type) {
      config.document_type = params.type
    }
    if (params?.authors) {
      if (params.authors.values.length === 1)
        config.author = params.authors.values[0]
    }
    if (params?.published_date) {
      const { from_date, to_date } = params.published_date
      config.published_date = {
        0: from_date ? from_date.split('-')[0] : '',
        1: to_date ? to_date.split('-')[0] : '',
      }
    }
    if (params?.language) {
      config.language = params.language.values
    }
    yield put(setFilterConfig({ ...filterConfig, ...config }))
    if (!isFilterOpen) {
      yield put(setOpenFilter(true))
    }
    yield put(
      setLiteratureSearch({
        task_uuid: showPastResult ? data.id : null,
        showPastResult,
      })
    )
  } else if (data.query.type === 'advanced_text_search') {
    let config = {}
    let litSearchConfig = {}
    const sort = params?.sort
    const paramsArr = params?.query?.['and_operator'] || []

    let languages = []
    let structures_ids

    // у ключей с '.ru'/'.en' внутри всегда есть INNER_LANG_OPERATOR
    const paramsWithLang = paramsArr.filter((el) =>
      (el?.['and_operator'] || el?.['or_operator'])?.some(
        (el) => el?.[INNER_LANG_OPERATOR]
      )
    )

    const firstParam = (paramsWithLang[0]?.['and_operator'] ||
      paramsWithLang[0]?.['or_operator'])?.[0][INNER_LANG_OPERATOR]
    const { languages: langs } = getField(paramsArr)
    if (langs) {
      languages = langs
    } else if (firstParam.length < 2) {
      // выбран один язык, в languages кладем этот язык
      const lang = firstParam[0]?.field?.split('.')[1]
      languages = [lang]
    } else {
      languages = ['en', 'ru']
    }

    const result = []
    const allParams = []
    const extractedResults = []
    paramsArr.forEach((obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'or_operator')) {
        result.push(obj.or_operator)
      }
    })
    result.forEach((res) => {
      const { fields: values } = getField(res)
      if (!['type', 'published_date', 'language'].includes(values[0])) {
        extractedResults.push(values)
        allParams.push(...values)
      }
    })

    const topLevelBlocks = []
    paramsArr.forEach((obj) => {
      const flattened = flattenOperators(obj)
      if (flattened.fields.length > 0) {
        const fields = [...new Set(flattened.fields)].filter(
          (field) => !['type', 'published_date', 'language'].includes(field)
        )
        if (fields.length > 0) {
          topLevelBlocks.push({
            operator: flattened.operator,
            fields,
          })
        }
      }
    })
    const andBlocksData = topLevelBlocks
      .slice(0, -1)
      .map((block) =>
        block.fields.length > 1
          ? block.fields[block.fields.length - 1]
          : block.fields[0]
      )

    // Добавляем все параметры с логическим or/should/ИЛИ
    const andBlocksSet = new Set(andBlocksData)
    const shouldBlocksToUse = INITIAL_TYPES_LIST.filter(
      (item) => !andBlocksSet.has(item)
    )

    yield put(setShouldBlocksIndexes(shouldBlocksToUse))

    paramsArr?.forEach((param) => {
      const key = Object.keys(param)?.[0]
      const operator = convertNewOperatorToOld(key)
      const conditions = param[key]

      const { fields } = getField(conditions)
      const fieldsToUse =
        fields?.[0] === 'type' && conditions?.length > 1
          ? [...fields, 'type']
          : fields
      let extractedValues = []
      const notValues = []
      fieldsToUse?.forEach((field, index) => {
        if (fieldsToUse.length > 1) {
          const { extractedValues: extracted, notValuesList } = extractValues(
            conditions[index]
          )
          extractedValues = extracted
          notValues.push(...notValuesList)
        } else {
          conditions?.forEach((condition, idx) => {
            const { extractedValues: extracted, notValuesList } =
              extractValues(condition)
            extractedValues.push(...extracted)
            notValues.push(...notValuesList)
          })
        }
        if (field === 'type') {
          config.document_type = [
            ...(config.document_type ?? []),
            ...extractedValues,
          ]
        } else if (field === 'language') {
          languages = extractedValues
        } else if (field === 'structures_ids') {
          structures_ids = {
            operator,
            exact: false,
            values: extractedValues,
          }
        } else if (field === 'published_date') {
          const { gte, lte } = extractedValues[0]
          config.published_date = {
            0: gte ? gte.split('-')[0] : '',
            1: lte ? lte.split('-')[0] : '',
          }
        } else if (field === 'name') {
          litSearchConfig.name = extractedValues[0]
        } else {
          const not_values = []
          notValues.forEach((item) => {
            const valueIndex = extractedValues.indexOf(item)
            if (valueIndex > 0) not_values.push(valueIndex)
          })

          litSearchConfig[field] = {
            operator,
            exact: false,
            values: extractedValues,
            not_values,
          }
        }
      })
    })

    if (languages.length) {
      config.language = languages
    }

    if (structures_ids) {
      try {
        const { data: fetchData } = yield fetch(
          `/molecule/${structures_ids.values[0]}`,
          2
        )
        const data = fetchData?.result || {}
        yield put(
          updateLastSearchValues({
            prevId: structures_ids?.values?.[0] || null,
            text: data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O',
          })
        )
        yield put(setSearchText(data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O'))
      } catch (e) {
        console.log(e)
        yield put(setSearchText('O=C(C)Oc1ccccc1C(=O)O'))
      }
    }

    if (litSearchConfig?.name) {
      const DOIRe = /\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\\'<>])\S)+)\b/g
      // const patentRe = /([a-zA-Z]{2})([\d\\/]+)([a-zA-Z]\d)/gm
      const checkDOI = new RegExp(DOIRe).test(litSearchConfig.name)
      const docType = checkDOI ? 'doi' : 'patent_number'
      yield put(setLitFilterDocType(docType))
    }

    if (litSearchConfig?.title && !structures_ids) {
      // добавлено условие !structures_ids чтобы если есть и structures_ids, и title
      // в поисковую строку подставлялся smiles из structures_ids, а не title
      yield put(
        updateLastSearchValues({
          prevId: null,
          text: litSearchConfig.title.values[0],
        })
      )

      yield put(setSearchText(litSearchConfig.title.values[0]))
    }

    if (sort) {
      yield put(
        setSearchSortingConfig({
          type: sort.key,
          direction: sort.order_type,
        })
      )
    }

    yield put(setSearchType('literature'))

    yield put(setFilterConfig({ ...filterConfig, ...config }))

    yield put(setLitFilterConfig({ ...litSearchConfig }))

    if (!isFilterOpen) {
      yield put(setOpenFilter(true))
    }
    yield put(
      setLiteratureSearch({
        task_uuid: showPastResult ? data.id : null,
        showPastResult,
      })
    )
  } else if (data.query.type === 'reaction_search') {
    yield put(setSearchType('reaction'))
    let match_type = 'exact match'

    const config = {
      ...filterConfig,
      match_type,
    }

    if (params?.yield_range) {
      config.yield = {
        0: params.yield_range.from,
        1: params.yield_range.to,
      }
    }
    if (params?.compound_types) {
      config.compound_types = params.compound_types
    }

    config.reaction_display =
      params?.yield_unknown === false ? [] : ['yield_unknown']

    yield put(setFilterConfig(config))

    if (!isFilterOpen) {
      yield put(setOpenFilter(true))
    }

    yield put(setSearchText(params?.smiles || ''))

    yield put(setReactionsCompoundId(params?.compound_id))

    yield put(
      setReactionsSearch({
        text: params?.smiles || '',
        compound_id: params?.compound_id,
        task_uuid: showPastResult ? data?.id : null,
      })
    )
  }
}

export function* getSearchHistoryWatcher() {
  yield takeEvery(getSearchHistory.type, getSearchHistoryFn)
}

export function* repeatSearchWatcher() {
  yield takeEvery(repeatSearch.type, repeatSearchFn)
}
