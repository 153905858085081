import { FC, memo } from 'react'
import { CloseIcon } from './index.style'
import { CustomCloseIconProps } from './index.types'
/**
 *  only 2 sizes 1 and 1.25rem
 */
export const CustomCloseIcon: FC<CustomCloseIconProps> = memo(
  ({ onClick, size = '1.25rem', color, children }) => (
    <CloseIcon
      {...{ size, color, onClick }}
      iconType="close"
      data-test="close-icon"
    >
      {children}
    </CloseIcon>
  )
)

CustomCloseIcon.displayName = 'CustomCloseIcon'
