import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContextMenuStateItem } from 'components/common/contextMenu/index.types'

import { ContextMenuState } from './types/contextMenuTypes'

const initialState: ContextMenuState = {
  contextMenuState: {},
}

const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    handleShowContextMenu: (
      state,
      action: PayloadAction<ContextMenuState['contextMenuState']>
    ) => {
      state.contextMenuState = action.payload
    },
    hideContextMenu: (state) => {
      state.contextMenuState = {}
    },
    updateDisabledActions: (
      state,
      action: PayloadAction<ContextMenuStateItem['disabledActions']>
    ) => {
      state.contextMenuState = {
        ...state.contextMenuState,
        item: {
          disabledActions: action.payload,
        },
      }
    },
  },
})

export const { handleShowContextMenu, hideContextMenu, updateDisabledActions } =
  contextMenuSlice.actions

export default contextMenuSlice.reducer
