export const isNotificationClicked = (event: MouseEvent) => {
  const exceptions = [
    'close-notify-btn',
    'notifications-actions',
    'element',
    'element hidden',
  ]
  let parentCount = 10
  let tg = event?.target as HTMLElement | null
  while (tg && parentCount) {
    // eslint-disable-next-line no-loop-func
    if (tg?.classList && exceptions.some((el) => tg?.classList?.contains(el))) {
      return true
    }
    tg = tg?.parentElement
    parentCount--
  }

  return
}
