import { v4 as uuidv4 } from 'uuid'
import { put, select, takeEvery } from 'redux-saga/effects'
import {
  PDF2SMILES_GET_DOCS_URL,
  PDF2SMILES_UPDATE_DOCUMENT_URL,
} from 'pages/Pdf2Smiles/config/config'
import { store } from 'services/rest'
import {
  deleteHighlights,
  editSmiles,
  getPdf2SmilesDocument,
  getPdf2SmilesDocumentError,
  getPdf2SmilesDocumentSuccess,
  setCurrentHighlightId,
  setSelectedHighlights,
  updateMarkup,
} from 'store/slices/pdf2SmilesSlice'
import { addNotification } from 'store/slices/notificationsSlice'

function* getPdf2SmilesDocumentFn({ payload: id }) {
  let isError = false

  try {
    const res = yield store(PDF2SMILES_GET_DOCS_URL, {
      params: {
        filters: {
          ids: [id],
        },
      },
    })
    if (res?.data?.result?.items?.length === 1) {
      const { filename, file_url, markup } = res.data.result.items[0]
      yield put(
        getPdf2SmilesDocumentSuccess({ id, filename, file_url, markup })
      )
    } else {
      isError = true
    }
  } catch (e) {
    isError = true
  } finally {
    if (isError) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notifications.loading_error',
        notification_type: 'error',
        text: 'pdf2smiles.viewer.failed_to_load_document',
      }

      yield put(getPdf2SmilesDocumentError())
      yield put(addNotification(notify))
    }
  }
}

function* deletePdf2SmilesHighlights({ payload }) {
  const { ids, document_id } = payload || {}
  const pdf2Smiles = yield select((state) => state.pdf2Smiles)
  const { markup, currentHighlightId, selectedHighlights } = pdf2Smiles
  const filteredHighlights = markup.filter((h) => !ids.includes(h.id))
  try {
    yield store(PDF2SMILES_UPDATE_DOCUMENT_URL, {
      params: {
        document_id,
        markup: filteredHighlights,
      },
    })

    yield put(updateMarkup(filteredHighlights))

    yield put(
      setSelectedHighlights(
        ids.length > 1 ? [] : selectedHighlights.filter((el) => el !== ids[0])
      )
    )

    if (ids.includes(currentHighlightId)) {
      yield put(setCurrentHighlightId(''))
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      notification_type: 'error',
      text:
        ids.length === 1
          ? 'pdf2smiles.viewer.failed_to_delete_molecule'
          : 'pdf2smiles.viewer.failed_to_delete_molecules',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

function* editHighlightSmiles({ payload }) {
  const { id, smiles, document_id } = payload || {}
  if (!smiles) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      notification_type: 'error',
      text: 'pdf2smiles.viewer.invalid_smiles',
      autoRemove: true,
      timeout: 5000,
    }

    yield put(addNotification(notify))

    return
  }

  const markup = yield select((state) => state.pdf2Smiles.markup)

  try {
    const newMarkup = markup.map((h) =>
      h.id === id
        ? {
            ...h,
            structure: {
              ...h.structure,
              smiles: [smiles, ...h.structure.smiles],
            },
          }
        : h
    )
    yield store(PDF2SMILES_UPDATE_DOCUMENT_URL, {
      params: {
        document_id,
        markup: newMarkup,
      },
    })

    yield put(updateMarkup(newMarkup))
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      notification_type: 'error',
      text: 'pdf2smiles.viewer.failed_to_edit_smiles',
      autoRemove: true,
      timeout: 5000,
    }
    yield put(addNotification(notify))
  }
}

export function* getPdf2SmilesDocumentWatcher() {
  yield takeEvery(getPdf2SmilesDocument.type, getPdf2SmilesDocumentFn)
}

export function* deleteHighlightsWatcher() {
  yield takeEvery(deleteHighlights.type, deletePdf2SmilesHighlights)
}

export function* editSmilesWatcher() {
  yield takeEvery(editSmiles.type, editHighlightSmiles)
}

const watchers = [
  getPdf2SmilesDocumentWatcher(),
  deleteHighlightsWatcher(),
  editSmilesWatcher(),
]
export default watchers
