import React from 'react'
import styled, { css } from 'styled-components'

import { ElementWithChildren } from 'types/common'

import { Label } from '../text/index.style'
import { LabelPositionType } from './index.types'

interface RadioCheckboxContainerProps extends ElementWithChildren {
  checked: boolean
  labelPosition: LabelPositionType
  disabled?: boolean
  withCustomLink?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

interface RadioCheckboxLabelProps {
  disabled?: boolean
  withWrap?: boolean
  value: boolean
}

export const RadioCheckboxContainer = styled.div<RadioCheckboxContainerProps>`
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  &,
  & > div {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  &:hover > div {
    color: ${({ theme, disabled, withCustomLink }) =>
      disabled
        ? theme.colors.text.tertiary
        : withCustomLink
        ? theme.colors.text.secondary
        : theme.colors.text.primary};
  }

  flex-direction: ${({ labelPosition }) =>
    labelPosition === 'right' ? 'row' : 'row-reverse'};

  ${({ withCustomLink, checked }) =>
    withCustomLink &&
    css`
      a {
        text-decoration: none;
        color: ${({ theme }) =>
          checked ? theme.colors.text.primary : theme.colors.statuses.linkNew};
      }
    `}
`

export const RadioCheckboxLabel = styled(Label)<RadioCheckboxLabelProps>`
  width: 100%;
  white-space: ${({ withWrap }) => (withWrap ? 'pre-wrap' : 'nowrap')};
  color: ${({ theme, disabled, value }) => {
    if (disabled) return theme.colors.text.tertiary
    if (value) return theme.colors.text.primary
    return theme.colors.text.secondary
  }};
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
  }
  transition: all 0.3s ease;
`
