import { useSelector } from 'react-redux'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import RadioCheckbox from 'components/common/customRadioCheckbox'
import {
  FilterCheckboxItems,
  FilterCheckboxesTitle,
  FilterCheckboxesWrapper,
  FilterCheckboxesContainer,
} from './index.style'
import { HideBtn } from '../../index.style'
import { CONFLICT_OPTIONS } from '../../../Search/LiteratureFilter/constants'

const FilterCheckboxes = memo(({ setState, state, config }) => {
  const { title, values, key, withoutHideBtn } = config
  const [isFilterOpened, setFilterOpened] = useState(true)

  const compoundTypes = useSelector(
    (state) => state.filter.config.compound_types
  )

  const rightFilterConfig = useSelector(
    (state) => state.literature.filter.config
  )

  const { t } = useTranslation()
  const handleOpen = () => {
    if (withoutHideBtn) return
    setFilterOpened(!isFilterOpened)
  }

  const handleToggleValue = (value) => {
    if (key === 'document_type') {
      if (
        Object.keys(rightFilterConfig).find((el) =>
          CONFLICT_OPTIONS[value].includes(el)
        ) ||
        Object.keys(rightFilterConfig).includes('name')
      ) {
        return
      }
    }
    if (
      key === 'compound_types' &&
      compoundTypes.length === 1 &&
      compoundTypes[0] === value
    ) {
      return
    }

    const newArr = Array.isArray(state[key]) ? [...state[key]] : []

    setState({
      [key]: newArr.includes(value)
        ? [...newArr.filter((el) => el !== value)]
        : [...newArr, value],
    })
  }

  return (
    <FilterCheckboxesWrapper opened={isFilterOpened}>
      <FilterCheckboxesTitle
        onClick={handleOpen}
        withoutHideBtn={withoutHideBtn}
      >
        {t(title)}

        {!withoutHideBtn && (
          <HideBtn opened={isFilterOpened}>
            <Icon iconType="arrowDown" size="0.75rem" />
          </HideBtn>
        )}
      </FilterCheckboxesTitle>

      <FilterCheckboxesContainer opened={isFilterOpened} count={values.length}>
        <FilterCheckboxItems>
          {values.map(({ value, name }, idx) => {
            return (
              <RadioCheckbox
                key={`${value}-${idx}`}
                id={`${value}-${idx}`}
                checkbox
                handler={() => handleToggleValue(value)}
                value={state[key] && state[key].includes(value)}
                disabled={Object.keys(rightFilterConfig).includes('name')}
                label={t(name)}
              />
            )
          })}
        </FilterCheckboxItems>
      </FilterCheckboxesContainer>
    </FilterCheckboxesWrapper>
  )
})

FilterCheckboxes.displayName = 'FilterCheckboxes'

export default FilterCheckboxes
