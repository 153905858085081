import { createSlice, createAction } from '@reduxjs/toolkit'

export const initialState = {
  layers: [],
  options: [],
  selectedDatasets: [],
  generators: [],
  loading: false,
  error: false,
  is3dMapMode: false,
  layers3d: [],
  selected: [],
  selectedArea: null,
  generationProps: [],
  optimizationProps: [], //unused?
  fastView: {
    x: null,
    y: null,
    data: {},
  },
}

export const addToMMap = createAction('mmap/addToMMap')
export const startGeneratorAction = createAction('mmap/startGeneratorAction')
export const checkGeneratorStatusAction = createAction(
  'mmap/checkGeneratorStatusAction'
)
export const mmapGeneratorDataIncomeAction = createAction(
  'mmap/mmapGeneratorDataIncomeAction'
)

const mmapSlice = createSlice({
  name: 'mmap',
  initialState,
  reducers: {
    setFastView: (state, action) => {
      state.fastView = action.payload
    },
    clearData: (state) => {
      state.layers = []
      state.options = []
      state.selectedDatasets = []
      state.generators = []
      state.layers3d = []
      state.selected = []
      state.selectedArea = null
    },
    addGen: (state, action) => {
      state.generators = [...state.generators, action.payload.generator]
    },
    deleteGen: (state, action) => {
      const delIndex = state.options.findIndex(
        ({ id }) => id === action.payload
      )
      const newLayers = state.layers.filter(
        (el, id) => el.length > 0 && id !== delIndex
      )
      const newOpts = state.options.filter((el) => el.id !== action.payload)
      const newGeneratorList = state.generators.filter(
        (el) => el.token !== action.payload
      )
      const layers3d = state.layers3d.filter(
        ({ generatorId }) => generatorId !== action.payload
      )
      state.generators = newGeneratorList
      state.layers = newLayers
      state.options = newOpts
      state.layers3d = layers3d
    },
    changeGenStatus: (state, action) => {
      const { token, status } = action.payload
      const newGeneratorList = state.generators.map((el) =>
        el.token !== token ? el : { ...el, status: status }
      )
      state.generators = newGeneratorList
    },
    createGeneratorLayer: (state, action) => {
      state.layers = [...state.layers, []]
      state.options = [...state.options, action.payload.options]
      state.loading = false
      state.error = false
      state.layers3d = [
        ...state.layers3d,
        {
          generatorId: action.payload.options.id,
          status: 'new',
        },
      ]
    },
    deleteLayer: (state, action) => {
      const delIndex = state.options.findIndex(
        ({ datasetId, id }) =>
          id === action.payload || datasetId === action.payload
      )
      if (delIndex < 0) return state
      const foundDatasetId = state.options[delIndex].datasetId

      state.layers3d = state.layers3d.filter(
        ({ datasetId }) => datasetId !== foundDatasetId
      )
      state.options = state.options.filter((_, idx) => delIndex !== idx)
      state.layers = state.layers.filter((_, idx) => delIndex !== idx)
    },
    changeMMapOptions: (state, action) => {
      state.options = action.payload
    },
    addDataset: (state, action) => {
      state.selectedDatasets = [...state.selectedDatasets, action.payload]
    },
    deleteDataset: (state, action) => {
      state.selectedDatasets = state.selectedDatasets.filter(
        (datasetId) => datasetId !== action.payload
      )
    },
    clearDataset: (state) => {
      state.selectedDatasets = []
    },
    toggleIs3dMode: (state) => {
      state.is3dMapMode = !state.is3dMapMode
    },
    load3dLayer: (state, action) => {
      const { task_uuid, data } = action.payload
      state.layers3d = state.layers3d.map((layer) => {
        return layer.task_uuid === task_uuid
          ? {
              ...layer,
              status: 'done',
              data,
            }
          : layer
      })
    },
    delete3dLayer: (state, action) => {
      state.layers3d = state.layers3d.filter(
        (layer) => layer.task_uuid !== action.payload
      )
      state.is3dMapMode = state.layers3d.length < 2 ? false : true
    },
    reset3dLayer: (state, action) => {
      state.layers3d = state.layers3d.map((layer) =>
        layer.task_uuid === action.payload ? { ...layer, status: 'new' } : layer
      )
      state.is3dMapMode = false
    },
    setSelected: (state, action) => {
      state.selected = structuredClone(action.payload)
    },
    setSelectedArea: (state, action) => {
      if (!action.payload) state.selectedArea = null
      else {
        const { points, range, selections } = action.payload || {}
        const clonedPayload = {
          points: points.map((point) => {
            const { fullData, ...rest } = point || {}
            return { ...rest } //убираем лишние данные с циклическими зависимостями, которые не переваривает immer в redux-toolkit
          }), // Copy points deeply
          range: { ...range },
          selections: selections
            ? structuredClone(selections).map((sel) => ({ ...sel }))
            : [], // Deep copy of selections
        }

        state.selectedArea = structuredClone(clonedPayload)
      }
    },

    mmapAddOptions: (state, action) => {
      state.layers = [...state.layers, []]
      state.options = [...state.options, action.payload]
      state.loading = false
      state.error = false
    },
    mmapLoadSuccess: (state, action) => {
      const { options, layer, datasetId } = action.payload

      const idx = state.options.findIndex(({ id }) => id === options?.id)
      if (idx >= 0) {
        state.layers = state.layers.map((l, i) => {
          if (idx !== i) return l
          return layer
        })
        state.options = state.options.map((l, i) => {
          if (idx !== i) return l
          return { ...l, loading: false }
        })
        state.is3dMapMode = false
        state.layers3d = [
          ...state.layers3d,
          {
            datasetId: datasetId,
            status: 'new',
          },
        ]
      }
    },
    mmapAddFail: (state, action) => {
      const idx = state.options.findIndex(({ id }) => id === action.payload?.id)
      if (idx >= 0) {
        state.layers = state.layers.filter((_, i) => idx !== i)
        state.options = state.options.filter((_, i) => idx !== i)
        state.is3dMapMode = false
      }
    },
    mmapUpdateGenerator: (state, action) => {
      const { idx, layer, iter, status, token, progress } = action.payload

      state.layers[idx] = layer
      state.options[idx] = { ...state.options[idx], epoch: iter, status }
      state.generators = state.generators.map((el) =>
        el.token === token ? { ...el, progress, status } : el
      )
    },
    mmap3DLoadInit: (state, action) => {
      state.layers3d = state.layers3d.map((layer) => {
        const found = action.payload.layers3d.find(
          ({ datasetId, generatorId }) =>
            datasetId
              ? datasetId === layer.datasetId
              : generatorId === layer.generatorId
        )

        return found
          ? { ...layer, status: 'progress', task_uuid: found.task_uuid }
          : layer
      })
    },
    mmap3DLoadInitFetching: (state, action) => {
      state.layers3d = state.layers3d.map((layer) => {
        const found = action.payload.layers3d.find(
          ({ datasetId, generatorId }) =>
            datasetId
              ? datasetId === layer.datasetId
              : generatorId === layer.generatorId
        )

        return found ? { ...layer, status: 'loading' } : layer
      })
    },

    mmap3DLoadFail: (state, action) => {
      state.is3dMapMode = false
      state.layers3d = state.layers3d.map((layer) => {
        const found = action.payload.layers3d.find(
          ({ datasetId, generatorId }) =>
            datasetId
              ? datasetId === layer.datasetId
              : generatorId === layer.generatorId
        )

        return found ? { ...layer, status: 'new' } : layer
      })
    },
    mmapRehydrate: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const {
  setFastView,
  mmapRehydrate,
  clearData,
  addGen,
  deleteGen,
  changeGenStatus,
  createGeneratorLayer,
  deleteLayer,
  changeMMapOptions,
  addDataset,
  deleteDataset,
  clearDataset,
  toggleIs3dMode,
  load3dLayer,
  delete3dLayer,
  reset3dLayer,
  setSelected,
  setSelectedArea,
  mmapAddOptions,
  mmapLoadSuccess,
  mmapAddFail,
  mmapUpdateGenerator,
  mmap3DLoadInit,
  mmap3DLoadInitFetching,
  mmap3DLoadFail,
} = mmapSlice.actions

export default mmapSlice.reducer
