import { API_URL } from 'config/config'

export const SMILES_TO_MOL_URL = `${API_URL}smiles2mol`

export const KETCHER_EDITOR_BTNS_CONFIG = {
  arom: { hidden: true },
  layout: { hidden: true },
  clean: { hidden: true },
  dearom: { hidden: true },
  cip: { hidden: true },
  check: { hidden: true },
  recognize: { hidden: true },
  analyse: { hidden: true },
  miew: { hidden: true },
  about: { hidden: true },
  help: { hidden: true },
}

//aren't used now, as outboundClickEnabled={false}
export const KETCHER_OUTBOUND_EXCEPTIONS = [
  'ToolbarMultiToolItem-module_icon',
  'ToolbarMultiToolItem-module',
  'ToolbarMultiToolItem-module_portal',
  'ActionButton-module_button',
  'ToolbarMultiToolItem-module_portal',
  'DefaultMultiTool-module_default',
]

export const KETCHER_OUTBOUND_ID = 'ketcher'
//

export const logError = (err) => console.log(err)
