import { createAction, createSlice } from '@reduxjs/toolkit'

export const initialState = {
  categories: {
    data: [],
    loading: false,
    error: false,
    blurNonApplicableProperties: [],
  },
  personal_properties: {
    data: [],
    loading: false,
    error: false,
    show_properties_window: false,
  },
  services: {
    data: [],
    loading: false,
    error: false,
  },
  properties: {
    mainId: null,
    basketId: null,
    data: null,
    sources: null,
    loading: false,
    error: false,
    isNeedHidePred: false,
  },
  ruIupac: '',
  applicability: {
    show: true,
    error: false,
    loading: false,
    data: null,
  },
  isAddMoleculeDialogOpen: false,
  addMoleculeDialogNodeId: null,
  editorIndividualId: null,
  moleculeNoteDialogId: null,
  noteConfig: {
    note: null,
    alias: null,
  },
}

export const editMoleculeNote = createAction('molecule/editMoleculeNote')

const moleculeSlice = createSlice({
  name: 'molecule',
  initialState,
  reducers: {
    loadCategories: (state) => {
      state.categories.data = []
      state.categories.loading = true
      state.categories.error = false
      state.categories.blurNonApplicableProperties = []
    },
    loadCategoriesSuccess: (state, action) => {
      state.categories.data = action.payload
      state.categories.loading = false
      state.categories.error = false
      state.categories.blurNonApplicableProperties = []
    },
    loadCategoriesError: (state) => {
      state.categories.data = []
      state.categories.loading = false
      state.categories.error = true
      state.categories.blurNonApplicableProperties = []
    },
    loadServices: (state) => {
      state.services.data = []
      state.services.loading = true
      state.services.error = false
    },
    loadServicesSuccess: (state, action) => {
      state.services.data = action.payload
      state.services.loading = false
      state.services.error = false
    },
    loadServicesError: (state) => {
      state.services.data = []
      state.services.loading = false
      state.services.error = true
    },
    loadMolecule: (state) => {
      state.properties.mainId = null
      state.properties.basketId = null
      state.properties.data = null
      state.properties.sources = null
      state.properties.loading = true
      state.properties.error = false
    },
    loadMoleculeSuccess: (state, action) => {
      const { mainId, basketId, data, loading, isNeedHidePred } =
        action.payload || {}
      state.properties.mainId = mainId || null
      state.properties.basketId = basketId || null
      state.properties.data = data
      state.properties.sources = {}
      state.properties.loading = loading
      state.properties.error = false
      state.properties.isNeedHidePred = isNeedHidePred
    },
    loadMoleculeError: (state) => {
      state.properties = {
        loading: false,
        error: true,
      }
    },
    resetMolecule: (state) => {
      state.properties = initialState.properties
    },
    loadRuIupac: (state) => {
      state.ruIupac = ''
    },
    loadRuIupacSuccess: (state, action) => {
      state.ruIupac = action.payload
    },
    loadRuIupacError: (state) => {
      state.ruIupac = 'Невозможно создать корректное имя IUPAC'
    },
    resetRuIupac: (state) => {
      state.ruIupac = ''
    },
    toggleApplicability: (state) => {
      state.applicability.show = !state.applicability.show
    },
    loadApplicability: (state) => {
      state.applicability.data = null
      state.applicability.loading = true
      state.applicability.error = false
    },
    loadApplicabilitySuccess: (state, action) => {
      state.applicability.data = action.payload
      state.applicability.loading = false
      state.applicability.error = false
    },
    openAddMoleculeDialog: (state, action) => {
      const { id, nodeId = null } = action.payload || {}
      state.isAddMoleculeDialogOpen = id
      state.addMoleculeDialogNodeId = nodeId || null
    },
    closeAddMoleculeDialog: (state) => {
      state.isAddMoleculeDialogOpen = false
      state.addMoleculeDialogNodeId = null
    },
    setEditorIndividualId: (state, action) => {
      state.editorIndividualId = action.payload
    },
    setBlurNonApplicable: (state, action) => {
      if (action.payload.length === 0) {
        state.categories.blurNonApplicableProperties = []
      } else {
        state.categories.blurNonApplicableProperties.push(action.payload)
      }
    },
    setMoleculeNoteDialogId: (state, action) => {
      state.moleculeNoteDialogId = action.payload
    },
    updateMoleculeNoteConfig: (state, action) => {
      state.noteConfig = action.payload
    },
    resetNoteConfig: (state) => {
      state.noteConfig = initialState.noteConfig
    },
    loadPersonalProperties: (state) => {
      state.personal_properties.data = []
      state.personal_properties.loading = true
      state.personal_properties.error = false
    },
    loadPersonalPropertiesSuccess: (state, action) => {
      state.personal_properties.data = action.payload
      state.personal_properties.loading = false
      state.personal_properties.error = false
    },
    loadPersonalPropertiesError: (state) => {
      state.personal_properties.data = []
      state.personal_properties.loading = false
      state.personal_properties.error = true
    },
    updatePersonalProperties: (state) => {
      state.personal_properties.loading = true
      state.personal_properties.error = false
    },
    updatePersonalPropertiesSuccess: (state, action) => {
      state.personal_properties.data = action.payload
      state.personal_properties.loading = false
      state.personal_properties.error = false
    },
    updatePersonalPropertiesError: (state) => {
      state.personal_properties.loading = false
      state.personal_properties.error = true
    },
    togglePropsWindow: (state, action) => {
      state.personal_properties.show_properties_window = action.payload
    },
    updateMoleculePropsInit: (state) => {
      state.properties.sources = null
      state.properties.loading = true
    },
    updateMoleculePropsSuccess: (state, action) => {
      const { data, sources } = action.payload
      state.properties.data =
        data && Object.keys(data).length
          ? {
              ...state.properties.data,
              ...data,
            }
          : state.properties.data

      state.properties.sources =
        sources && Object.keys(sources).length
          ? {
              ...state.properties.sources,
              ...sources,
            }
          : state.properties.sources
    },
    updateMoleculePropsError: (state, action) => {
      const { data, sources } = action.payload || {}
      state.properties.data =
        data && Object.keys(data).length
          ? {
              ...state.properties.data,
              ...data,
            }
          : state.properties.data

      state.properties.sources =
        sources && Object.keys(sources).length
          ? {
              ...state.properties.sources,
              ...sources,
            }
          : state.properties.sources
    },
    updateMoleculePropsFinished: (state) => {
      state.properties.loading = false
    },
  },
})

export const {
  loadCategories,
  loadCategoriesSuccess,
  loadCategoriesError,
  loadServices,
  loadServicesSuccess,
  loadServicesError,
  loadMolecule,
  loadMoleculeSuccess,
  loadMoleculeError,
  resetMolecule,
  loadRuIupac,
  loadRuIupacSuccess,
  loadRuIupacError,
  resetRuIupac,
  toggleApplicability,
  loadApplicability,
  loadApplicabilitySuccess,
  openAddMoleculeDialog,
  closeAddMoleculeDialog,
  setEditorIndividualId,
  setBlurNonApplicable,
  setMoleculeNoteDialogId,
  updateMoleculeNoteConfig,
  resetNoteConfig,
  loadPersonalProperties,
  loadPersonalPropertiesSuccess,
  loadPersonalPropertiesError,
  updatePersonalProperties,
  updatePersonalPropertiesSuccess,
  updatePersonalPropertiesError,
  togglePropsWindow,
  updateMoleculePropsInit,
  updateMoleculePropsSuccess,
  updateMoleculePropsError,
  updateMoleculePropsFinished,
} = moleculeSlice.actions

export default moleculeSlice.reducer
