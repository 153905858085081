import styled from 'styled-components'

import { Label } from 'components/common/text/index.style'

export const FilterCheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterCheckboxesTitle = styled(Label)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: ${({ withoutHideBtn }) => (withoutHideBtn ? 'default' : 'pointer')};
`

export const FilterCheckboxesContainer = styled.div`
  height: ${({ opened }) => (opened ? '100%' : '0px')};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened, count }) => (!opened ? 0 : `${count * 1.25}rem`)};
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  transition: all 0.3s;
  display: flex;
  column-gap: 0.5rem;
`

export const FilterCheckboxItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`
