import { FC } from 'react'
import Icon from 'components/Icon'

import {
  CustomInputAddButton,
  CustomInputClearButton,
  CustomInputHideButton,
} from './index.style'
import { RightButtonsProps } from './index.types'
import NumberInputButtons from '../NumberInputButtons'
import { memo, useCallback } from 'react'

const RightButtons: FC<RightButtonsProps> = memo(
  ({
    min,
    max,
    type,
    value,
    btnSize,
    disabled,
    onChange,
    iconSize,
    onAddItem,
    onIncrement,
    onDecrement,
    clearBtnProps,
    isValueHidden,
    showAddButton,
    btnPaddingSize,
    showHideButton,
    btnBorderRadius,
    showClearButton,
    onHideBtnClick,
    withWhiteBackground,
  }) => {
    const { onClear, id: clearBtnId } = clearBtnProps || {}

    const clear = useCallback(() => {
      onClear ? onClear() : onChange('')
    }, [onChange, onClear])

    return (
      <>
        <CustomInputHideButton
          onClick={onHideBtnClick}
          visible={showHideButton}
          {...{
            iconSize,
            btnSize,
            btnPaddingSize,
            btnBorderRadius,
            withWhiteBackground,
          }}
        >
          <Icon
            iconType={isValueHidden ? 'eyeClose' : 'eyeOpen'}
            size={iconSize}
          />
        </CustomInputHideButton>
        <CustomInputClearButton
          onClick={clear}
          id={clearBtnId || 'clear'}
          visible={showClearButton && !disabled}
          {...{
            iconSize,
            btnSize,
            clearBtnProps,
            showAddButton,
            showHideButton,
            btnPaddingSize,
            btnBorderRadius,
            withWhiteBackground,
          }}
        >
          <Icon iconType="close" size={iconSize} />
        </CustomInputClearButton>
        <CustomInputAddButton
          onClick={onAddItem}
          visible={showAddButton}
          {...{
            type,
            btnSize,
            iconSize,
            btnPaddingSize,
            btnBorderRadius,
            withWhiteBackground,
          }}
        >
          <Icon iconType="add" size={iconSize} />
        </CustomInputAddButton>

        {type === 'number' && (
          <NumberInputButtons
            {...{
              min,
              max,
              value,
              btnSize,
              iconSize,
              onIncrement,
              onDecrement,
              btnBorderRadius,
              withWhiteBackground,
            }}
          />
        )}
      </>
    )
  }
)

RightButtons.displayName = 'RightButtons'
export default RightButtons
