import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  Wrapper,
  EditBlock,
  Fields,
  NewPasswordBlock,
  ChangesBlock,
} from './index.style'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { validatePasswordTotal } from 'utils/common/common'
import { update } from 'services/rest'
import { addNotification } from 'store/slices/notificationsSlice'

const LoginDetailsSection = memo(({ openDialog }) => {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [checkingPassword, setCheckingPassword] = useState('')
  const [checkingPasswordError, setCheckingPasswordError] = useState('')
  const [isNewPasswordsMatch, setIsNewPasswordsMatch] = useState(false)

  const userdata = useSelector((state) => state.auth.userdata)
  const { email } = userdata || {}

  const isPasswordValidationError = validatePasswordTotal(newPassword, email)
  const isConfirmDisabled =
    !newPassword ||
    !checkingPassword ||
    isPasswordValidationError ||
    checkingPasswordError ||
    !isNewPasswordsMatch

  const hideEditBlock = () => {
    setIsEditMode(false)
    setNewPassword('')
    setCheckingPassword('')
  }

  const confirm = async () => {
    try {
      await update('/profile/password', { password: newPassword })
      openDialog({
        type: 'success',
        title: 'user_account.dialog.password_changed_successfully',
        btnText: 'user_account.dialog.ok',
      })
      hideEditBlock()
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'user_account.login_section.failed_to_change_password',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      dispatchEvent(addNotification(notify))
    }
  }

  const handleBlur = () => {
    if (newPassword && checkingPassword) {
      if (newPassword !== checkingPassword) {
        setCheckingPasswordError('account.password2Error')
        setIsNewPasswordsMatch(false)
      } else {
        if (!isNewPasswordsMatch) setIsNewPasswordsMatch(true)
        setCheckingPasswordError('')
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  const saveChangesBtnsConfig = [
    {
      icon: 'tick',
      onClick: confirm,
      text: 'user_account.login_section.confirm',
      disabled: isConfirmDisabled,
    },
    {
      icon: 'back',
      onClick: hideEditBlock,
      text: 'user_account.login_section.cancel_changes',
    },
  ]

  useEffect(() => {
    if (!checkingPassword && checkingPasswordError) setCheckingPasswordError('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingPassword])

  return (
    <Wrapper>
      <EditBlock>
        <Fields>
          <CustomInput
            label={t('user_account.login_section.email')}
            value={email}
            readOnly
          />
          <CustomInput
            label={t(
              isEditMode
                ? 'user_account.login_section.old_password'
                : 'user_account.login_section.password'
            )}
            value={email}
            readOnly
            type={'password'}
          />
        </Fields>
        {!isEditMode && (
          <CustomButton
            type={'secondary'}
            gap={'0.25rem'}
            onClick={() => setIsEditMode(true)}
          >
            <Icon iconType={'edit'} size={'1rem'} />
            <span>{t('user_account.login_section.change_password')}</span>
          </CustomButton>
        )}
        {isEditMode && (
          <>
            <NewPasswordBlock>
              <CustomInput
                id="pass1"
                label={t('user_account.login_section.new_password')}
                value={newPassword}
                onChange={(pass) => {
                  setIsNewPasswordsMatch(false)
                  setNewPassword(pass)
                }}
                placeholder={t('user_account.login_section.enter_new_password')}
                withClearButton
                withHideButton
                warning={isPasswordValidationError}
                isValidated={!!newPassword && !isPasswordValidationError}
                withPasswordTooltip={isPasswordValidationError}
                email={email}
              />
              <CustomInput
                id="pass2"
                label={t('user_account.login_section.repeat_new_password')}
                value={checkingPassword}
                onChange={(pass) => {
                  setIsNewPasswordsMatch(false)
                  setCheckingPassword(pass)
                }}
                placeholder={t('user_account.login_section.enter_new_password')}
                withClearButton
                withHideButton
                warning={checkingPasswordError}
                isValidated={
                  !!checkingPassword &&
                  !checkingPasswordError &&
                  isNewPasswordsMatch
                }
                onBlur={handleBlur}
                onKeyUp={handleKeyUp}
              />
            </NewPasswordBlock>
            <ChangesBlock>
              {saveChangesBtnsConfig.map(
                ({ icon, onClick, text, disabled }) => (
                  <CustomButton
                    key={icon}
                    type={'secondary'}
                    gap={'0.25rem'}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    <Icon iconType={icon} size={'1rem'} />
                    <span>{t(text)}</span>
                  </CustomButton>
                )
              )}
            </ChangesBlock>
          </>
        )}
      </EditBlock>
      {/* <DeleteAccount> // temporarily hidden
        <Caption
          color={theme.colors.text.tertiary}
          fontWeight={theme.fontWeight.medium}
          lineHeight={theme.text[14]}
        >
          {t(
            'user_account.login_section.if_you_want_to_delete_your_account_click'
          )}
        </Caption>
        <Caption
          color={theme.colors.text.accentPrimary}
          fontWeight={theme.fontWeight.medium}
          lineHeight={theme.text[14]}
        >
          {t('user_account.login_section.here')}
        </Caption>
      </DeleteAccount> */}
    </Wrapper>
  )
})

LoginDetailsSection.displayName = 'LoginDetailsSection'

export default LoginDetailsSection
