import styled from 'styled-components'

import { CustomInputClearButtonProps } from './index.types'

export const CustomInputClearButton = styled.div<CustomInputClearButtonProps>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: ${({ visible }) => !visible && 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.buttons.secondary
      : theme.colors.buttons.primary};
  color: ${({ theme }) => theme.colors.icons.tertiary};
  border-radius: ${({ btnBorderRadius }) => btnBorderRadius};
  min-width: ${({ visible, btnSize }) => (visible ? `${btnSize}rem` : '0rem')};
  width: ${({ visible, btnSize }) => (visible ? `${btnSize}rem` : '0rem')};
  height: ${({ btnSize }) => `${btnSize}rem`};
  padding: ${({ btnPaddingSize, visible }) => {
    if (!visible) return 0
    return btnPaddingSize
  }};
  cursor: pointer;
  position: relative;
  margin-left: ${({ visible, showHideButton }) =>
    visible ? (showHideButton ? '0.25rem' : '0.75rem') : 0};
  transition: all 0.2s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const CustomInputAddButton = styled(CustomInputClearButton)`
  margin-left: ${({ visible }) => (visible ? '0.25rem' : 0)};
  margin-right: ${({ type, visible }) =>
    type === 'number' && visible ? '0.25rem' : 0};
`

export const CustomInputHideButton = styled(CustomInputClearButton)`
  margin-left: ${({ visible }) => (visible ? '0.75rem' : 0)};
`
