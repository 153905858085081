import { BASKET_TYPES } from '../config/config'

export const compareArrayValues = (a, b) => {
  if (a?.length !== b?.length) return false

  a?.sort()
  b?.sort()

  for (let i = 0; i < a?.length; i++) {
    if (a[i] !== b[i]) return false
  }

  return true
}

export const getAllowedBasketTypes = (sourceBasketType, mode) => {
  const withoutThematic = BASKET_TYPES.filter(type => type !== 'thematic')
  
  const allowedTypes = {
    copy: [],
    move: [],
    new: withoutThematic
  }

  switch (sourceBasketType) {
    case 'thematic':
      allowedTypes.copy = withoutThematic
      allowedTypes.move = []
      break

    case 'companies':
      allowedTypes.copy = withoutThematic
      allowedTypes.move = ['companies']
      break

    case 'private':
      allowedTypes.copy = withoutThematic
      allowedTypes.move = withoutThematic
      break

    default:
      break
  }

  return mode === 'copy' ? allowedTypes.copy : mode === 'move' ? allowedTypes.move : allowedTypes.new
}
