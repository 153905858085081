import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResultDataType, SmilesIupacInitState } from './types/smilesIupac.types'
import { GetIupacPayload } from 'components/SmilesIupacConverter/index.types'

export const initialState: SmilesIupacInitState = {
  loading: false,
  error: false,
  error_text: '',
  results: {
    ru: [],
    en: [],
  },
}

const smilesIupac = createSlice({
  name: 'smilesIupac',
  initialState,
  reducers: {
    getRuIupac: (state, action: PayloadAction<GetIupacPayload>): void => {
      state.loading = true
      state.error = false
      state.results.ru = []
    },
    getRuIupacSuccess: (
      state,
      action: PayloadAction<ResultDataType[]>
    ): void => {
      state.loading = false
      state.error = false
      state.results.ru = action.payload
    },
    getRuIupacError: (state, action: PayloadAction<string>): void => {
      state.loading = false
      state.error = true
      state.error_text = action.payload
      state.results.ru = []
    },
    getEnIupacInit: (state, action: PayloadAction<GetIupacPayload>): void => {
      state.loading = true
      state.error = false
      state.results.en = []
    },
    getEnIupacSuccess: (
      state,
      action: PayloadAction<ResultDataType[]>
    ): void => {
      state.loading = false
      state.error = false
      state.results.en = action.payload
    },
    getEnIupacError: (state, action: PayloadAction<string>): void => {
      state.loading = false
      state.error = true
      state.error_text = action.payload
      state.results.en = []
    },
    resetIupac: (state): void => {
      state.loading = false
      state.error = false
      state.error_text = ''
      state.results.en = []
      state.results.ru = []
    },
  },
})

export const {
  getRuIupac,
  getRuIupacSuccess,
  getRuIupacError,
  getEnIupacInit,
  getEnIupacSuccess,
  getEnIupacError,
  resetIupac,
} = smilesIupac.actions

export default smilesIupac.reducer
