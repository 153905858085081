export const apiPath = '/api/v1/'
export const apiV2Path = '/api/v2/'

export const DEBUG = process.env.REACT_APP_DEBUG === 'true'
export const ANALYTICS_ENABLE = process.env.REACT_APP_ANALYTICS_ENABLE === 'true'

export const WITH_LOGGER = false

// eslint-disable-next-line no-undef
export const HOST = DEBUG
  ? `${window.location.hostname}`
  : // eslint-disable-next-line no-undef
    REACT_APP_BACKEND

export const BASE_URL = DEBUG ? `http://${HOST}` : `https://${HOST}`

export const API_URL = `${BASE_URL}${apiPath}`
export const API_V2_URL = `${BASE_URL}${apiV2Path}`
