import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  calculation: null,
  loading: false,
  error: '',
  mode: 'forward',
}

const reactionsSlice = createSlice({
  name: 'reactions',
  initialState,
  reducers: {
    addMoleculeToReaction: (state, action) => {
      state.list = [...state.list, action.payload]
    },
    removeMoleculeFromReaction: (state, action) => {
      state.list = state.list.filter((_, index) => index !== action.payload)
    },
    removeAllMoleculesFromReaction: (state) => {
      state.list = []
    },
    resetCalculations: (state) => {
      state.calculation = null
      state.loading = false
      state.error = ''
    },
    editMoleculeInReaction: (state, action) => {
      const { id, smiles } = action.payload || {}
      state.list = [
        ...state.list.slice(0, id),
        smiles,
        ...state.list.slice(id + 1),
      ]
    },
    calcReaction: (state) => {
      state.calculation = null
      state.loading = true
    },
    calcSynth: (state) => {
      state.calculation = null
      state.loading = false
    },
    calcRetro: (state) => {
      state.calculation = null
      state.loading = true
      state.error = ''
    },
    setMode: (state, action) => {
      state.mode = action.payload
    },
    reactionCalcSuccess: (state, action) => {
      state.calculation = action.payload
      state.loading = false
      state.error = ''
    },
    reactionCalcFail: (state, action) => {
      state.error = action.payload
      state.calculation = null
      state.loading = false
    },
  },
})

export const {
  addMoleculeToReaction,
  removeMoleculeFromReaction,
  removeAllMoleculesFromReaction,
  resetCalculations,
  editMoleculeInReaction,
  calcReaction,
  calcSynth,
  calcRetro,
  setMode,
  reactionCalcSuccess,
  reactionCalcFail,
} = reactionsSlice.actions

export default reactionsSlice.reducer
