import { createAction, createSlice } from '@reduxjs/toolkit'

const initialFilter = {
  theme: 'all',
  modules: [],
  date: [null, null],
  sort: {
    type: 'created_at',
    direction: 'desc',
  },
  email: '',
}
const initialState = {
  show_btn: true,
  show: false,
  defaultTheme: null,
  show_feedback_card: false,
  feedback_card_id: null,
  feedback_selected_card: null,
  pagination: {
    pagesAmount: 0,
    total: 0,
    perPage: 24,
    activePage: 1,
  },
  loading: false,
  data: [],
  filter: initialFilter,
  offset: 0,
}

export const applyFeedbackFilter = createAction('APPLY_FEEDBACK_FILTER')

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    openFeedbackForm: (state, action) => {
      state.show = true
      state.defaultTheme = action.payload
    },
    closeFeedbackForm: (state) => {
      state.show = false
      state.defaultTheme = null
    },
    openFeedbackCard: (state, action) => {
      state.show_feedback_card = true
      state.feedback_selected_card = action.payload
    },
    closeFeedbackCard: (state) => {
      state.show_feedback_card = false
      state.feedback_selected_card = null
    },
    setFeedbackPaginationConfig: (state, action) => {
      state.pagination = action.payload
    },
    getFeedback: (state) => {
      state.loading = true
    },
    setFeedbackFilter: (state, action) => {
      const { key, value } = action.payload
      state.filter[key] = value
    },
    clearFeedbackFilter: (state, action) => {
      state.filter = initialFilter
    },
    showFeedbackBtn: (state) => {
      state.show_btn = true
    },
    hideFeedbackBtn: (state) => {
      state.show_btn = false
    },
    getfeedbackSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getfeedbackError: (state, action) => {
      state.loading = false
    },
  },
})

export const {
  openFeedbackForm,
  closeFeedbackForm,
  openFeedbackCard,
  closeFeedbackCard,
  setFeedbackPaginationConfig,
  getFeedback,
  setFeedbackFilter,
  clearFeedbackFilter,
  showFeedbackBtn,
  hideFeedbackBtn,
  getfeedbackSuccess,
  getfeedbackError,
} = feedbackSlice.actions

export default feedbackSlice.reducer
