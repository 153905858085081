import { animated } from 'react-spring'
import styled from 'styled-components'

import {
  CustomInputFieldProps,
  CustomInputValueProps,
  CustomInputWrapperProps,
  CustomInputFieldWrapperProps,
} from './index.types'
import { Label } from '../text/index.style'
import { SMALL_INPUT_LEFT_PADDING, STANDART_INPUT_LEFT_PADDING } from './config'

export const CustomInputWrapper = styled.div<CustomInputWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '0.5rem'};
  width: ${({ width }) => width} !important;
`

export const CustomInputField = styled.input<CustomInputFieldProps>`
  width: 100%;
  appearance: none;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  outline: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme, withLargeText }) =>
    withLargeText ? theme.text[14] : theme.text[12]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme, withLargeText }) =>
    withLargeText ? theme.text[18] : theme.text[16]};
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
  padding-left: ${({ showSearchIcon }) => showSearchIcon && '0.5rem'};
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: ${({ theme, withWhiteBackground }) =>
      `0 0 0px 1000px ${
        withWhiteBackground
          ? theme.colors.backgrounds.primary
          : theme.colors.backgrounds.secondary
      } inset`};
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const CustomInputFieldWrapper = styled.div<CustomInputFieldWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};
  border-radius: ${({ inputBorderRadius }) => inputBorderRadius};

  padding: ${({ size, padding }) => {
    const leftPadding =
      size === 'small' ? SMALL_INPUT_LEFT_PADDING : STANDART_INPUT_LEFT_PADDING
    if (padding) return padding
    return `0.25rem 0.25rem 0.25rem ${leftPadding}`
  }};
  overflow: hidden;

  & > svg {
    min-width: ${({ iconSize }) => iconSize};
    transition: all 0.3s;
    color: ${({ theme, focused }) =>
      focused ? theme.colors.icons.primary : theme.colors.icons.tertiary};
  }
`

export const PseudoLabel = styled(Label)`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 0.25rem;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const CustomInputValue = styled(Label)<CustomInputValueProps>`
  position: absolute;
  color: ${({ theme }) => theme.colors.text.primary};
  left: ${({ afterLabelPadding }) => afterLabelPadding};
`

export const AnimatedNumber = styled(animated.div as any)`
  position: absolute;
  width: 100%;
  left: ${({ size }) =>
    size === 'small' ? SMALL_INPUT_LEFT_PADDING : STANDART_INPUT_LEFT_PADDING};
`
