import { put, takeEvery } from 'redux-saga/effects'
import { store } from 'services/rest'
import {
  getMarkushMoleculeWeight,
  setMarkushInputsDisabled,
  setMarkushMoleculeWeight,
} from 'store/slices/filterSlice'

function* getWeight({ payload }) {
  try {
    const res = yield store('/run-task', {
      service: 'rdkit',
      params: {
        smiles: payload.toString().split('|')[0].trim(),
      },
      method: 'count_molw',
    })
    if (res.status === 200 && !res.data.result?.error) {
      yield put(setMarkushMoleculeWeight(Number(res.data.result).toFixed(3)))
      yield put(setMarkushInputsDisabled(false))
    }
  } catch (e) {
    console.log(e)
  }
}

function* getWeightWatcher() {
  yield takeEvery(getMarkushMoleculeWeight.type, getWeight)
}

const watchers = [getWeightWatcher()]
export default watchers
