export const SMILES_SIZE = {
  width: 184,
  height: 214,
}

export const INITIAL_OUTPUT = 100

export const CANVAS_ARR = ['product', 'reagent']

export const INIT_SETTINGS = {
  product: '',
  reagent: '',
  weight: '',
  stage_start: '',
  stage_end: '',
}

export const REQUIRED_SETTINGS = ['product', 'weight']

export const DEFAULT_STAGE_START = 1
export const DEFAULT_STAGE_END = 6

export const DEFAULT_SCHEME = {
  output: INITIAL_OUTPUT,
  stages: [
    {
      name: '',
      price: '',
      weight: '',
      vendor: '',
    },
  ],
}
