import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  isStructuresLoading: false,
  isCalculationLoading: false,
  structures: [],
  blurNonApplicableProperties: [],
  calculation: [],
}

export const addStructureToCompare = createAction(
  'structuresCompare/addStructureToCompare'
)
export const uncompareStructure = createAction(
  'structuresCompare/uncompareStructure'
)
export const clearStructuresComparisonList = createAction(
  'structuresCompare/clearStructuresComparisonList'
)

const structuresCompareSlice = createSlice({
  name: 'structuresCompare',
  initialState,
  reducers: {
    getStructuresComparisonList: (state) => {
      state.isStructuresLoading = true
      state.structures = []
    },
    getStructuresCalculation: (state) => {
      state.blurNonApplicableProperties = []
      state.isCalculationLoading = true
      state.calculation = []
    },
    addBlurNonApplicableProperty: (state, action) => {
      state.blurNonApplicableProperties =
        action.payload.length === 0
          ? []
          : [...state.blurNonApplicableProperties, action.payload]
    },
    getStructuresComparisonListSuccess: (state, action) => {
      state.isStructuresLoading = false
      state.structures = action.payload
    },
    addStructureToCompareSuccess: (state, action) => {
      const { id, baseID, smiles } = action.payload || {}
      state.structures = [...state.structures, { id, baseID, smiles }]
      state.calculation = []
    },
    uncompareStructureSuccess: (state, action) => {
      const { id, baseID } = action.payload || {}
      state.structures = state.structures.filter((el) => el.id !== id)
      state.calculation = state.calculation.filter(
        (el) => el.data.baseID !== baseID
      )
      state.blurNonApplicableProperties =
        state.blurNonApplicableProperties.filter((p) => p.baseID !== baseID)
    },
    clearStructuresComparisonListSuccess: (state) => {
      state.structures = []
      state.blurNonApplicableProperties = []
      state.calculation = []
    },
    getStructuresCalculationSuccess: (state, action) => {
      state.isCalculationLoading = false
      state.calculation = action.payload
    },
  },
})

export const {
  getStructuresComparisonList,
  getStructuresCalculation,
  addBlurNonApplicableProperty,
  getStructuresComparisonListSuccess,
  addStructureToCompareSuccess,
  uncompareStructureSuccess,
  clearStructuresComparisonListSuccess,
  getStructuresCalculationSuccess,
} = structuresCompareSlice.actions

export default structuresCompareSlice.reducer
