import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getHeaders, storeV2 } from 'services/rest'
import { Wrapper } from './index.style'
import NoLicenseInfo from './components/NoLicenseInfo'
import TrialPeriodInfo from './components/TrialPeriodInfo'
import { addNotification } from 'store/slices/notificationsSlice'

const LicenseProblemsBlock = ({ licenseError, setLicenseError, onLogin }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  // If there is an license_request_id and license_request_is_handled is not equal to true
  // then the application for the test period is under consideration or denied
  const showTrialPeriodInfo =
    licenseError?.error_data?.license_request_id &&
    !licenseError?.error_data?.license_request_is_handled

  const handleActivate = async (user_id, customer_invite_token, token) => {
    setLoading(true)
    try {
      await storeV2(
        'user/update',
        {
          params: {
            user_id,
            customer_invite_token,
          },
        },
        getHeaders(token)
      )
      onLogin(true, () => setLoading(false))
    } catch (e) {
      setLoading(false)
      const error =
        e?.response?.data?.result?.error_data?.[0]?.msg ===
          'value is not a valid uuid' ||
        e?.response?.data?.result?.error_message?.includes(
          'Invalid invite token'
        )
          ? 'account.invalid_license_key'
          : 'account.license_activation_failed'
      const notify = {
        id: uuidv4(),
        name: 'notification.error',
        text: error,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      dispatch(addNotification(notify))
    }
  }

  return (
    <Wrapper>
      {showTrialPeriodInfo ? (
        <TrialPeriodInfo {...{ licenseError }} />
      ) : (
        <NoLicenseInfo
          onActivate={handleActivate}
          {...{ loading, licenseError, setLicenseError }}
        />
      )}
    </Wrapper>
  )
}

export default LicenseProblemsBlock
