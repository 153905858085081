import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  smilesToAdd: null,
  fastViewMoleculeId: null,
  contextMenuId: null,
}

const retrosynthesisSlice = createSlice({
  name: 'retrosynthesis',
  initialState,
  reducers: {
    toggleAddMoleculeDialog: (state, action) => {
      state.smilesToAdd = action.payload
    },
    toggleMoleculeDialog: (state, action) => {
      state.fastViewMoleculeId = action.payload
    },
    openRetroSynthContextMenu: (state, action) => {
      state.contextMenuId = action.payload
    },
    closeRetroSynthContextMenu: (state) => {
      state.contextMenuId = null
    },
  },
})

export const {
  toggleAddMoleculeDialog,
  toggleMoleculeDialog,
  openRetroSynthContextMenu,
  closeRetroSynthContextMenu,
} = retrosynthesisSlice.actions

export default retrosynthesisSlice.reducer
