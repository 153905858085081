import { put, select, takeEvery } from 'redux-saga/effects'
import { getUserData } from 'store/selectors'
import {
  loadMoreMolecules,
  setBingoSearch,
  setLiteratureSearch,
  setReactionsSearch,
} from 'store/slices/crudSlice'
import { resetPagination, setPaginationConfig } from 'store/slices/searchSlice'
import { settingsLangChange, updateQtyOnPage } from 'store/slices/settingsSlice'
import { updateLanguage } from 'utils/common/common'
import { BINGO_PAGINATION_LIMIT, LIT_PAGINATION_LIMIT } from './utils/config'

function* setLanguage({ payload }) {
  const data = yield select(getUserData)
  if (!Object.getOwnPropertyNames(data)?.length) return
  yield updateLanguage(payload)
}

function* updateQtyOnPageFn() {
  const { searchType, pagination } = yield select((state) => state.search)
  const {
    reaction: reactionPerPage,
    literature: litPerPage,
    structure: strPerPage,
    dataset: datasetPerPage,
  } = yield select((state) => state.settings.qtyOnPage)

  let basket
  let limit
  if (searchType === 'reaction') {
    limit = reactionPerPage
  } else if (searchType === 'literature') {
    limit = litPerPage
  } else {
    basket = yield select((state) => state.crud.openedBasketID)
    limit = basket ? datasetPerPage : strPerPage
  }

  const maxPagesQty = Math.ceil(pagination.total / limit)
  const activePage =
    pagination.activePage > maxPagesQty ? maxPagesQty : pagination.activePage

  if (pagination.total > limit || basket) {
    const config = {
      ...pagination,
      pagesAmount: Math.ceil(pagination.total / limit),
      perPage: limit,
      activePage,
    }

    yield put(setPaginationConfig(config))
  } else {
    yield put(resetPagination())
  }

  const offset = activePage > 1 ? (activePage - 1) * limit : 0

  if (searchType === 'reaction') {
    const compound_id = yield select(
      (state) => state.reactionsSearch.compound_id
    )

    yield put(
      setReactionsSearch({
        offset,
        limit,
        compound_id,
      })
    )
    return
  }

  if (searchType === 'literature') {
    const task_uuid = yield select((state) => state.crud.litSearch.taskId)
    if (!task_uuid) return

    yield put(
      setLiteratureSearch({
        task_uuid,
        offset,
        limit: limit ?? LIT_PAGINATION_LIMIT,
      })
    )
    return
  }

  if (searchType === 'structure') {
    const task_uuid = yield select((state) => state.crud.bingoSearch.taskId)

    if (task_uuid) {
      yield put(
        setBingoSearch({
          task_uuid,
          offset,
          limit: limit ?? BINGO_PAGINATION_LIMIT,
        })
      )
      return
    }

    const searchV2 = yield select((state) => state.crud.searchV2)

    if (searchV2?.status === 'done') return

    if (!basket) return
    yield put(loadMoreMolecules({ basket, limit, page: activePage }))
  }
}

export function* changeLanguageWatcher() {
  yield takeEvery(settingsLangChange.type, setLanguage)
}

export function* updateQtyOnPageWatcher() {
  yield takeEvery(updateQtyOnPage.type, updateQtyOnPageFn)
}

const watchers = [changeLanguageWatcher(), updateQtyOnPageWatcher()]
export default watchers
