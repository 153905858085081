import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  userdata: {},
  userCreds: { email: '', password: '' },
  isShowProfile: false,
  licenseExpiredInfo: null,
}

export const loadMe = createAction('auth/getMeInit')
export const resetMe = createAction('auth/getMeFail')

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getMeSuccess: (state, action) => {
      state.userdata = action.payload
    },
    getMeFail: (state) => {
      state.userdata = initialState.userdata
    },
    setUserTempCreds: (state, action) => {
      state.userCreds = action.payload
    },
    clearUserTempCreds: (state) => {
      state.userCreds = initialState.userCreds
    },
    toggleUserProfile: (state, action) => {
      state.isShowProfile = action.payload
    },
    setLicenseExpiredInfo: (state, action) => {
      state.licenseExpiredInfo = action.payload
    },
  },
})

export const {
  getMeSuccess,
  getMeFail,
  setUserTempCreds,
  clearUserTempCreds,
  toggleUserProfile,
  setLicenseExpiredInfo,
} = authSlice.actions

export default authSlice.reducer
